import React, {useState , useEffect} from 'react';
import {ArticleFile} from "../../Child/Supplier/Child/style";
import {Images} from "../../../../assets/scripts/Images";

// plugins
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function GuideUploadingFile(props) {

    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    // for onchange event
    const [pdfFile, setPdfFile] = useState(null);
    const [pdfFileError, setPdfFileError] = useState('');

    useEffect(() => {
        if(props.value){
            setPdfFile(props.value)
        }
    }, [props]);


    // onchange event
    const fileType = ['application/pdf'];
    const handlePdfFileChange = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsDataURL(selectedFile);
                reader.onloadend = (e) => {
                    setPdfFile(e.target.result);
                    setPdfFileError('');
                }
                props.onChangeGuideFile({file : selectedFile , name : props.name})
            } else {
                setPdfFile(null);
                setPdfFileError('لطفا فایل با فرمت pdf بارگزاری کنید');
            }
        }
    }


    const removeImage = (name) => {
        setPdfFile(null)
        props.onChangeGuideFile({file : null, name})
    }

    return (
        <>
            <div className="afa-upload-file-parent">
                <ArticleFile className={pdfFileError && 'afa-error-input'}>
                    {
                        (pdfFile) ?
                            <div className="afa-img">
                                <img
                                    src={Images?.pdf_place_holder?.default}
                                    alt="آتاویتا"/>
                                <span
                                    className='afa-remove-position icon-delete'
                                    onClick={() => removeImage(props.name)}
                                />
                            </div> :
                            <label
                                className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                <img src={Images?.article_upload?.default}
                                     className='afa-article-image-to-upload' alt="آتاویتا"/>
                                <input type="file"
                                       onChange={handlePdfFileChange}
                                       name={`pdf_file_${props.name}`}
                                       className='dv-upload-file'/>
                                {
                                    !pdfFile &&
                                    <span className='upload-text'>اپلود فایل راهنمای {props.title}</span>
                                }
                            </label>
                    }
                </ArticleFile>
                <p className='afa-error-text'>{pdfFileError}</p>
            </div>
        </>
    );
}

export default GuideUploadingFile;