import './App.css';
import {NavLink, Redirect, Route, Switch, useHistory, Link} from "react-router-dom";
import PublicRoute from "./components/Main/Routes/PublicRoute";
import PrivateRoute from "./components/Main/Routes/PrivateRoute";
import Login from "./components/Login/Login";
import RealRegister from "./components/Login/RealRegister";
import LegalRegister from "./components/Login/LegalRegister";
import VerifyCode from "./components/Login/VerifyCode";
import Dashboard from "./components/Panel/super admin child/Dashboard";
import {Provider, useDispatch, useSelector} from 'react-redux';
import store from "./redux/store";
import SuperAdmin from "./components/Panel/Main_Dashboard/SuperAdmin";
import React, {useEffect, useState} from "react";
import {getData} from "./assets/scripts/GeneralFunctions";
import {MAIN_URL} from "./assets/scripts/GeneralVariables";
import Swal from "sweetalert2";
import {Dropdown} from "react-bootstrap";
import {Images} from "./assets/scripts/Images";
import UserInformation from "./components/Panel/Child/UserInformation/UserInformation";
import SupplierParent from "./components/Panel/Child/Supplier/SupplierParent";
import HumanResourcesManagement from "./components/Panel/Child/HumanResources/HumanResourcesManagement";
import HumanResourcesDetails from "./components/Panel/Child/HumanResources/HumanResourcesDetails";
import SuppliersList from "./components/Panel/Child/SupplierMangement/SuppliersList";
import SuppliersTabsDetails from "./components/Panel/Child/SupplierMangement/SuppliersTabsDetails";
import Chats from "./components/Panel/Child/Chat/Chats";
import Profile from "./components/Panel/super admin child/Profile";
import Articles from "./components/Panel/super admin child/Article/Articles";
import Units from "./components/Panel/super admin child/units/Units";
import Sofrwares from "./components/Panel/super admin child/Sofrwares";
import Products from "./components/Panel/super admin child/products/Products";
import Users from "./components/Panel/super admin child/Users";
import Suppliers from "./components/Panel/super admin child/Suppliers";
import Setting from "./components/Panel/super admin child/Setting/Setting";
import Notifications from "./components/Panel/super admin child/Notifications";
import Archive from "./components/Panel/super admin child/Article/Archive";
import StaffInfo from "./components/Panel/super admin child/units/staffInfo";
import NewArticle from "./components/Panel/super admin child/Article/NewArticle";
import Testimonial from "./components/Panel/super admin child/testimonial/Testimonial";
import TestimonialArchive from "./components/Panel/super admin child/testimonial/TestimonialArchive";
import NewTestimonial from "./components/Panel/super admin child/testimonial/NewTestimonial";
import ProductInfo from "./components/Panel/super admin child/products/ProductInfo";
import NewProduct from "./components/Panel/super admin child/products/NewProduct";
import ProductPrice from "./components/Panel/super admin child/products/ProductPrice";
import SettingEn from "./components/Panel/super admin child/Setting/SettingEn";
import {setNotificationCount} from "./redux/user_information/action";
import Gallery from "./components/Panel/super admin child/Gallery";
import Services from "./components/Panel/super admin child/Services";
import Laboratory from "./components/Panel/super admin child/Laboratory";
import Skills from "./components/Panel/super admin child/Skills";
import CommunicationWithUniversity from "./components/Panel/super admin child/Contact-us/CommunicationWithUniversity";
import CommentsAndComplaints from "./components/Panel/super admin child/Contact-us/CommentsAndComplaints";
import ProposedSystem from "./components/Panel/super admin child/Contact-us/ProposedSystem";
import CommunicationWithUniversityInfo
    from "./components/Panel/super admin child/Contact-us/CommunicationWithUniversityInfo";
import CommentsAndComplaintsInfo from "./components/Panel/super admin child/Contact-us/CommentsAndComplaintsInfo";
import ProposedSystemInfo from "./components/Panel/super admin child/Contact-us/ProposedSystemInfo";

// Skeleton style
import 'react-loading-skeleton/dist/skeleton.css'
import MessageFromManagement from "./components/Panel/super admin child/AboutUs/MessageFromManagement";
import SystemPolicy from "./components/Panel/super admin child/AboutUs/SystemPolicy";
import MissionVision from "./components/Panel/super admin child/AboutUs/MissionVision";
import BoardDirectors from "./components/Panel/super admin child/AboutUs/BoardDirectors/BoardDirectors";
import BoardDirectorsInfo from "./components/Panel/super admin child/AboutUs/BoardDirectors/BoardDirectorsInfo";
import NewBoardDirectors from "./components/Panel/super admin child/AboutUs/BoardDirectors/NewBoardDirectors";
import CommunicationManagement from "./components/Panel/super admin child/Contact-us/CommunicationManagement";
import CommunicationManagementInfo from "./components/Panel/super admin child/Contact-us/CommunicationManagementInfo";
import MachineManufacturing from "./components/Panel/super admin child/machineManufacturing";

const AppWrapper = () => {
    return (
        <Provider store={store}>
            <App/>
        </Provider>
    )
}

function App() {

    const [show_menu, setShowMenu] = useState(false)
    const [user, setUserInfo] = useState({
        card_id: null,
        first_name: null,
        is_personnel: false,
        last_name: null,
        personnel_id: null,
        purpose: null,
        type: null,
        permissions: [],
        is_superadmin: false
    })
    const [tab_key, setTabKey] = useState('')
    const user_info = JSON.parse(localStorage.getItem("user_info"))
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(async () => {
        if (localStorage.getItem('AFAToken')) {
            let api_result = await getData(MAIN_URL, "splash", 'get', {}, true, true);
            if (api_result?.status === 200) {
                setUserInfo(api_result?.data)
                dispatch(setNotificationCount(api_result?.data?.messages_count))
            }
        }
    }, []);

    const handleExit = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید از سایت خارج شوید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله خارج می شوم',
            cancelButtonText: 'نه خارج نمی شوم',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                // let remove_result = await getData(MAIN_URL, `account/portfolio/remove/${id}`, 'get', {}, token, false);
                // if (remove_result?.status === 200) {
                localStorage.clear()
                window.location.replace('/login')
                swalWithBootstrapButtons.fire(
                    'خارج شدید',
                    'امیدواریم دوباره میزبان شما باشیم',
                    'success'
                )
                // setRender(!is_render)
                // }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    'از حسن اعتمادتان ممنونیم',
                    'success'
                )
            }
        })
    }

    const showMenu = () => {
        setShowMenu(!show_menu)
    }

    const tabHandler = (key) => {
        setTabKey(key)
    }

    let notification_count = useSelector(state => state.notification_count)

    return (
        <>
            <div className={'rtl'}>
                {
                    localStorage.getItem('AFAToken') ?
                        <>
                            <header>
                                <div
                                    className="d-flex align-items-center justify-content-end afa-dashboard-header px-2 px-md-5">
                                    <div
                                        className="afa-dashboard-tab-icons d-flex align-items-center position-relative">
                                        <Link to={'/notification'}
                                              className="icon-uniE937 afa-notification-icon f-28"/>
                                        <Link to={'/notification'}
                                              className="afa-notification-count">{notification_count}</Link>
                                    </div>
                                    <div className="d-flex align-items-center px-1">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-user-info">
                                                {
                                                    (user?.first_name && user?.last_name) && `${user?.first_name} ${user?.last_name}`
                                                }
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='dv-dropdown-menu-content'>
                                                {
                                                    <>
                                                        <div
                                                            className='d-flex align-items-center justify-content-between dv-dropdown-items-text'>
                                                            <span>نقش شما : </span>
                                                            <strong className='px-2'>
                                                                {
                                                                    user?.is_personnel === true ? 'پرسنل' :
                                                                        user?.purpose === "job_seeker" ? 'کارجو' : 'تامین کننده'
                                                                }
                                                            </strong>
                                                        </div>
                                                        {
                                                            user?.is_personnel === true &&
                                                            <>
                                                                <div
                                                                    className='d-flex align-items-center justify-content-between dv-dropdown-items-text'>
                                                                    <span>شماره کارت تردد : </span>
                                                                    <strong className='px-2'>
                                                                        {
                                                                            user?.card_id
                                                                        }
                                                                    </strong>
                                                                </div>
                                                                <div
                                                                    className='d-flex align-items-center justify-content-between dv-dropdown-items-text'>
                                                                    <span> کد پرسنلی :  </span>
                                                                    <strong className='px-2'>
                                                                        {
                                                                            user?.personnel_id
                                                                        }
                                                                    </strong>
                                                                </div>
                                                                {
                                                                    user?.company &&
                                                                    <div
                                                                        className='d-flex align-items-center justify-content-between dv-dropdown-items-text'>
                                                                        <span> شرکت :  </span>
                                                                        <strong className='px-2'>
                                                                            {
                                                                                user?.company?.name
                                                                            }
                                                                        </strong>
                                                                    </div>
                                                                }
                                                                {
                                                                    user?.unit &&
                                                                    <div
                                                                        className='d-flex align-items-center justify-content-between dv-dropdown-items-text'>
                                                                        <span> واحد :  </span>
                                                                        <strong className='px-2'>
                                                                            {
                                                                                user?.unit?.title
                                                                            }
                                                                        </strong>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                                <div
                                                    className='d-flex align-items-center justify-content-between k-cursor-pointer dv-logout-bg'
                                                    onClick={handleExit}>
                                                    <span>خروج</span>
                                                    <div
                                                        className="afa-dashboard-tab-icons d-flex align-items-center">
                                                        <span className="icon-Logout f-24"/>
                                                    </div>
                                                </div>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </header>
                            {
                                user?.purpose &&
                                <section id='main'>
                                    {
                                        user?.is_superadmin ?
                                            <div id='sidebar'>
                                                <div className="afa-sidebar-logo-img d-none d-lg-flex">
                                                    <img src={Images.main_logo.default} className='' alt="afa"/>
                                                </div>
                                                <div className="afa-sidebar-logo-menu d-block d-lg-none">
                                                    <span className="icon-menu f-22" onClick={showMenu}/>
                                                </div>

                                                <div
                                                    className={show_menu ? "afa-sidebar-menu d-flex flex-column p-0 pb-5" : "afa-sidebar-menu d-none d-lg-flex flex-column p-0 pb-5"}>
                                                    {
                                                        // حساب کاربری
                                                        (user?.purpose === 'job_seeker' || user?.purpose === 'supplier') &&
                                                        <NavLink to={'/profile'} activeClassName="active_menu"
                                                                 className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                            <div className="afa-dashboard-tab-icons px-3">
                                                                <span className="icon-Profile "/>
                                                            </div>
                                                            <span className="afa-dashboard-span mb-2">حساب کاربری</span>
                                                        </NavLink>
                                                    }
                                                    <NavLink to={'/manage-hr'} activeClassName="active_menu"
                                                             className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                        <div
                                                            className="afa-dashboard-tab-icons px-3">
                                                            <span className="icon-Document"/>
                                                        </div>
                                                        <span
                                                            className="afa-dashboard-span mb-2">مدیریت منابع انسانی</span>
                                                    </NavLink>
                                                    <NavLink to={'/manage-supplier'} activeClassName="active_menu"
                                                             className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                        <div
                                                            className="afa-dashboard-tab-icons px-3">
                                                            <span className="icon-Profile"/>
                                                        </div>
                                                        <span
                                                            className="afa-dashboard-span mb-2">مدیریت تامین کنندگان</span>
                                                    </NavLink>
                                                    <NavLink to={'/manage-site'} activeClassName="active_menu"
                                                             className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                        <div
                                                            className="afa-dashboard-tab-icons px-3">
                                                            <span className="icon-setting"/>
                                                        </div>
                                                        <span
                                                            className="afa-dashboard-span mb-2">مدیریت سایت</span>
                                                    </NavLink>
                                                    {/*<NavLink to={'/manage-mashin-sazi'} activeClassName="active_menu"*/}
                                                    {/*         className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>*/}
                                                    {/*    <div*/}
                                                    {/*        className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*        <span className="icon-setting"/>*/}
                                                    {/*    </div>*/}
                                                    {/*    <span*/}
                                                    {/*        className="afa-dashboard-span mb-2">مدیریت ماشین سازی</span>*/}
                                                    {/*</NavLink>*/}
                                                    <NavLink to={'/manage-unit'} activeClassName="active_menu"
                                                             className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                        <div className="afa-dashboard-tab-icons px-3">
                                                            <span className="icon-units"><span className="path1"></span><span
                                                                className="path2"></span></span>
                                                        </div>
                                                        <span className="afa-dashboard-span mb-2">واحدها</span>
                                                    </NavLink>
                                                    <NavLink to={'/manage-service'} activeClassName="active_menu"
                                                             className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                        <div
                                                            className="afa-dashboard-tab-icons px-3">
                                                            <span className="icon-service-main"/>
                                                        </div>
                                                        <span
                                                            className="afa-dashboard-span mb-2">خدمات</span>
                                                    </NavLink>
                                                    <NavLink to={'/manage-software'} activeClassName="active_menu"
                                                             className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                        <div
                                                            className="afa-dashboard-tab-icons px-3">
                                                            <span className="icon-software"><span
                                                                className="path1"></span><span className="path2"></span><span
                                                                className="path3"></span><span className="path4"></span><span
                                                                className="path5"></span></span>
                                                        </div>
                                                        <span className="afa-dashboard-span mb-2">نرم افزار ها</span>
                                                    </NavLink>
                                                    <NavLink to={'/manage-skill'} activeClassName="active_menu"
                                                             className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                        <div
                                                            className="afa-dashboard-tab-icons px-3">
                                                            <span className="icon-skill-main"/>
                                                        </div>
                                                        <span className="afa-dashboard-span mb-2">مهارت ها</span>
                                                    </NavLink>
                                                    <NavLink to={'/manage-user'} activeClassName="active_menu"
                                                             className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                        <div
                                                            className="afa-dashboard-tab-icons px-3">
                                                            <span className="icon-user2"/>
                                                        </div>
                                                        <span
                                                            className="afa-dashboard-span mb-2">مدیریت کاربران</span>
                                                    </NavLink>
                                                    {/*<NavLink to={'/manage-directorate'} activeClassName="active_menu"*/}
                                                    {/*         className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>*/}
                                                    {/*    <div*/}
                                                    {/*        className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*        <span className="icon-mail"></span>*/}
                                                    {/*    </div>*/}
                                                    {/*    <span*/}
                                                    {/*        className="afa-dashboard-span mb-2">هیئت مدیره</span>*/}
                                                    {/*</NavLink>*/}
                                                    {/*<NavLink to={'/manage-blog'} activeClassName="active_menu"*/}
                                                    {/*         className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>*/}
                                                    {/*    <div*/}
                                                    {/*        className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*        <span className="icon-blog"/>*/}
                                                    {/*    </div>*/}
                                                    {/*    <span*/}
                                                    {/*        className="afa-dashboard-span mb-2">مدیریت وبلاگ</span>*/}
                                                    {/*</NavLink>*/}
                                                    {/*<NavLink to={'/manage-testimonial'} activeClassName="active_menu"*/}
                                                    {/*         className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>*/}
                                                    {/*    <div*/}
                                                    {/*        className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*        <span className="icon-Articles"/>*/}
                                                    {/*    </div>*/}
                                                    {/*    <span*/}
                                                    {/*        className="afa-dashboard-span mb-2">توصیه نامه ها</span>*/}
                                                    {/*</NavLink>*/}
                                                    {/*<NavLink to={'/manage-gallery'} activeClassName="active_menu"*/}
                                                    {/*         className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>*/}
                                                    {/*    <div*/}
                                                    {/*        className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*        <span className="icon-images"></span>*/}
                                                    {/*    </div>*/}
                                                    {/*    <span*/}
                                                    {/*        className="afa-dashboard-span mb-2">مدیریت گالری</span>*/}
                                                    {/*</NavLink>*/}
                                                    {/*<NavLink to={'/manage-product'} activeClassName="active_menu"*/}
                                                    {/*         className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>*/}
                                                    {/*    <div*/}
                                                    {/*        className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*        <span className="icon-products"><span*/}
                                                    {/*            className="path1"></span><span className="path2"></span></span>*/}
                                                    {/*    </div>*/}
                                                    {/*    <span*/}
                                                    {/*        className="afa-dashboard-span mb-2">مدیریت محصولات</span>*/}
                                                    {/*</NavLink>*/}
                                                    {/*<NavLink to={'/manage-laboratory'} activeClassName="active_menu"*/}
                                                    {/*         className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>*/}
                                                    {/*    <div*/}
                                                    {/*        className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*        <span className="icon-lab"/>*/}
                                                    {/*    </div>*/}
                                                    {/*    <span className="afa-dashboard-span mb-2">آزمایشگاه ها</span>*/}
                                                    {/*</NavLink>*/}
                                                    {/*<div className="d-flex align-items-center w-100 dv-contact-us-btn w-100">*/}
                                                    {/*    <Dropdown className={'w-100'}>*/}
                                                    {/*        <Dropdown.Toggle*/}
                                                    {/*            className={'cursor-pointer d-flex align-items-center justify-content-start py-2 w-100'}>*/}
                                                    {/*            <div className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*                <span className="icon-phone"/>*/}
                                                    {/*            </div>*/}
                                                    {/*            <span*/}
                                                    {/*                className="afa-dashboard-span mb-2">تماس با ما</span>*/}
                                                    {/*        </Dropdown.Toggle>*/}

                                                    {/*        <Dropdown.Menu>*/}
                                                    {/*            {*/}
                                                    {/*                <>*/}
                                                    {/*                    <div className='dv-dropdown-items-text'>*/}
                                                    {/*                        <NavLink to={'/proposed-system'}*/}
                                                    {/*                                 activeClassName="active_menu"*/}
                                                    {/*                                 className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>*/}
                                                    {/*                            <div*/}
                                                    {/*                                className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*                                <span className="icon-mail"></span>*/}
                                                    {/*                            </div>*/}
                                                    {/*                            <span*/}
                                                    {/*                                className="afa-dashboard-span mb-2">نظام پیشنهادات</span>*/}
                                                    {/*                        </NavLink>*/}
                                                    {/*                        <NavLink to={'/comments-complaints'}*/}
                                                    {/*                                 activeClassName="active_menu"*/}
                                                    {/*                                 className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>*/}
                                                    {/*                            <div*/}
                                                    {/*                                className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*                                <span className="icon-bubbles"/>*/}
                                                    {/*                            </div>*/}
                                                    {/*                            <span*/}
                                                    {/*                                className="afa-dashboard-span mb-2">فرم نظرات و شکایات</span>*/}
                                                    {/*                        </NavLink>*/}
                                                    {/*                        <NavLink to={'/communication-university'}*/}
                                                    {/*                                 activeClassName="active_menu"*/}
                                                    {/*                                 className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>*/}
                                                    {/*                            <div*/}
                                                    {/*                                className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*                                <span className="icon-bank"></span>*/}
                                                    {/*                            </div>*/}
                                                    {/*                            <span*/}
                                                    {/*                                className="afa-dashboard-span mb-2">فرم ارتباط با دانشگاه</span>*/}
                                                    {/*                        </NavLink>*/}
                                                    {/*                        <NavLink to={'/communication-management'}*/}
                                                    {/*                                 activeClassName="active_menu"*/}
                                                    {/*                                 className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>*/}
                                                    {/*                            <div*/}
                                                    {/*                                className="afa-dashboard-tab-icons px-3">*/}
                                                    {/*                                <span*/}
                                                    {/*                                    className="icon-user-tie"></span>*/}
                                                    {/*                            </div>*/}
                                                    {/*                            <span*/}
                                                    {/*                                className="afa-dashboard-span mb-2">فرم ارتباط با مدیریت</span>*/}
                                                    {/*                        </NavLink>*/}
                                                    {/*                    </div>*/}
                                                    {/*                </>*/}
                                                    {/*            }*/}
                                                    {/*        </Dropdown.Menu>*/}
                                                    {/*    </Dropdown>*/}
                                                    {/*</div>*/}
                                                    <NavLink to={'/notification'} activeClassName="active_menu"
                                                             className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                        <div className="afa-dashboard-tab-icons px-3">
                                                            <span className="icon-Notification "/>
                                                        </div>
                                                        <span className="afa-dashboard-span mb-2">پیام ها</span>
                                                    </NavLink>

                                                    <button className="afa-exit-btn cursor-pointer pt-1 pb-5 px-0"
                                                            onClick={handleExit}>
                                                        <div
                                                            className="d-flex justify-content-start align-items-center">
                                                            <div className="afa-dashboard-tab-icons px-3">
                                                                <span className="icon-Logout"/>
                                                            </div>
                                                            <span className="afa-dashboard-span mb-2">خروج</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div> :
                                            <div id='sidebar'>
                                                <div className="afa-sidebar-logo-img d-none d-lg-flex">
                                                    <img src={Images.main_logo.default} className='' alt="afa"/>
                                                </div>
                                                <div className="afa-sidebar-logo-menu d-block d-lg-none">
                                                    <span className="icon-menu f-22" onClick={showMenu}/>
                                                </div>

                                                <div
                                                    className={show_menu ? "afa-sidebar-menu d-flex flex-column p-0 pb-5" : "afa-sidebar-menu d-none d-lg-flex flex-column p-0 pb-5"}>
                                                    {
                                                        // حساب کاربری
                                                        (user?.purpose === 'job_seeker' || user?.purpose === 'supplier') &&
                                                        <NavLink to={'/profile'} activeClassName="active_menu"
                                                                 className={'cursor-pointer d-flex align-items-center justify-content-start py-2 pt-4'}>
                                                            <div className="afa-dashboard-tab-icons px-3">
                                                                <span className="icon-Profile "/>
                                                            </div>
                                                            <span className="afa-dashboard-span mb-2">حساب کاربری</span>
                                                        </NavLink>
                                                    }

                                                    {
                                                        user?.permissions?.map((row) => (
                                                            <>
                                                                {
                                                                    // (row?.key !== 'manage-contact' && row?.key !== 'ceo-manager-contact' &&
                                                                    //     row?.key !== 'bm-manager-contact' && row?.key !== 'sm-manager-contact' &&
                                                                    //     row?.key !== 'hr-manager-contact')
                                                                    //     ?
                                                                    <NavLink key={row?.id}
                                                                             onClick={() => tabHandler(row?.key)}
                                                                             to={`/${row?.key}`}
                                                                             activeClassName="active_menu"
                                                                             className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                                        <div
                                                                            className="afa-dashboard-tab-icons px-3">
                                                                            {
                                                                                row?.key === 'manage-hr' ?
                                                                                    <span
                                                                                        className="icon-Document"/> :
                                                                                    row?.key === 'manage-supplier' ?
                                                                                        <span
                                                                                            className="icon-Profile"/> :
                                                                                        row?.key === 'manage-unit' ?
                                                                                            <span
                                                                                                className="icon-units"><span
                                                                                                className="path1"></span><span
                                                                                                className="path2"></span></span> :
                                                                                            row?.key === 'manage-service' ?
                                                                                                <span
                                                                                                    className="icon-service-main"/> :
                                                                                                row?.key === 'manage-software' ?
                                                                                                    <span
                                                                                                        className="icon-software"><span
                                                                                                        className="path1"></span><span
                                                                                                        className="path2"></span><span
                                                                                                        className="path3"></span><span
                                                                                                        className="path4"></span><span
                                                                                                        className="path5"></span></span> :
                                                                                                    row?.key === 'manage-skill' ?
                                                                                                        <span
                                                                                                            className="icon-skill-main"/> :
                                                                                                        row?.key === 'manage-user' ?
                                                                                                            <span
                                                                                                                className="icon-user2"/> :
                                                                                                            // row?.key === 'manage-directorate' ?
                                                                                                            //     <span
                                                                                                            //         className="icon-mail"/> :
                                                                                                            row?.key === 'manage-site' &&
                                                                                                            <span
                                                                                                                className="icon-setting"/>
                                                                                // :
                                                                                // row?.key === 'manage-blog' ?
                                                                                //     <span
                                                                                //         className="icon-blog"/> :
                                                                                //     row?.key === 'manage-testimonial' ?
                                                                                //         <span
                                                                                //             className="icon-Articles"/> :
                                                                                //         row?.key === 'manage-gallery' ?
                                                                                //             <span
                                                                                //                 className="icon-images"/> :
                                                                                //             row?.key === 'manage-product' ?
                                                                                //                 <span
                                                                                //                     className="icon-Document"/> :
                                                                                //                 row?.key === 'manage-mashin-sazi' ?
                                                                                //                     <span
                                                                                //                         className="icon-setting"/> :
                                                                                //                     row?.key === 'manage-laboratory' &&
                                                                                //                     <span
                                                                                //                         className="icon-lab"/>
                                                                            }
                                                                        </div>
                                                                        <span className="afa-dashboard-span mb-2">
                                                                                {
                                                                                    row?.key === 'manage-hr' ? 'مدیریت منابع انسانی' :
                                                                                        row?.key === 'manage-supplier' ? 'مدیریت تامین کننده ها' :
                                                                                            row?.key === 'manage-unit' ? "مدیریت واحد ها" :
                                                                                                row?.key === 'manage-service' ? "مدیریت خدمات" :
                                                                                                    row?.key === 'manage-software' ? "مدیریت نرم افزار ها" :
                                                                                                        row?.key === 'manage-skill' ? "مدیریت مهارت ها" :
                                                                                                            row?.key === 'manage-user' ? 'مدیریت کاربران' :
                                                                                                                // row?.key === 'manage-directorate' ? 'هیئت مدیره' :
                                                                                                                row?.key === 'manage-site' && 'تنظیمات'
                                                                                    // :
                                                                                    // row?.key === 'manage-blog' ? 'مدیریت وبلاگ' :
                                                                                    //     row?.key === 'manage-testimonial' ? "مدیریت توصیه نامه ها" :
                                                                                    //         row?.key === 'manage-gallery' ? 'گالری تصاویر' :
                                                                                    //             row?.key === 'manage-product' ? "مدیریت محصولات" :
                                                                                    //                 row?.key === 'manage-mashin-sazi' ? "مدیریت ماشین سازی" :
                                                                                    //                     row?.key === 'manage-laboratory' && "مدیریت آزمایشگاه"
                                                                                }
                                                                            </span>
                                                                    </NavLink>
                                                                    //     : row?.key === 'manage-contact' &&
                                                                    //     <div
                                                                    //         className="d-flex align-items-center w-100 dv-contact-us-btn w-100">
                                                                    //         <Dropdown className={'w-100'}>
                                                                    //             <Dropdown.Toggle
                                                                    //                 className={'cursor-pointer d-flex align-items-center justify-content-start py-2 w-100'}>
                                                                    //                 <div
                                                                    //                     className="afa-dashboard-tab-icons px-3">
                                                                    //                     <span className="icon-phone"/>
                                                                    //                 </div>
                                                                    //                 <span
                                                                    //                     className="afa-dashboard-span mb-2">تماس با ما</span>
                                                                    //             </Dropdown.Toggle>
                                                                    //
                                                                    //             <Dropdown.Menu>
                                                                    //                 {
                                                                    //                     <>
                                                                    //                         <div
                                                                    //                             className='dv-dropdown-items-text'>
                                                                    //                             <NavLink
                                                                    //                                 to={'/proposed-system'}
                                                                    //                                 activeClassName="active_menu"
                                                                    //                                 className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                                    //                                 <div
                                                                    //                                     className="afa-dashboard-tab-icons px-3">
                                                                    //                                     <span
                                                                    //                                         className="icon-mail"></span>
                                                                    //                                 </div>
                                                                    //                                 <span
                                                                    //                                     className="afa-dashboard-span mb-2">نظام پیشنهادات</span>
                                                                    //                             </NavLink>
                                                                    //                             <NavLink
                                                                    //                                 to={'/comments-complaints'}
                                                                    //                                 activeClassName="active_menu"
                                                                    //                                 className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                                    //                                 <div
                                                                    //                                     className="afa-dashboard-tab-icons px-3">
                                                                    //                                     <span
                                                                    //                                         className="icon-bubbles"/>
                                                                    //                                 </div>
                                                                    //                                 <span
                                                                    //                                     className="afa-dashboard-span mb-2">فرم نظرات و شکایات</span>
                                                                    //                             </NavLink>
                                                                    //                             <NavLink
                                                                    //                                 to={'/communication-university'}
                                                                    //                                 activeClassName="active_menu"
                                                                    //                                 className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                                    //                                 <div
                                                                    //                                     className="afa-dashboard-tab-icons px-3">
                                                                    //                                     <span
                                                                    //                                         className="icon-bank"></span>
                                                                    //                                 </div>
                                                                    //                                 <span
                                                                    //                                     className="afa-dashboard-span mb-2">فرم ارتباط با دانشگاه</span>
                                                                    //                             </NavLink>
                                                                    //                             <NavLink
                                                                    //                                 to={'/communication-management'}
                                                                    //                                 activeClassName="active_menu"
                                                                    //                                 className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                                    //                                 <div
                                                                    //                                     className="afa-dashboard-tab-icons px-3">
                                                                    // <span
                                                                    //     className="icon-user-tie"></span>
                                                                    //                                 </div>
                                                                    //                                 <span
                                                                    //                                     className="afa-dashboard-span mb-2">فرم ارتباط با مدیریت</span>
                                                                    //                             </NavLink>
                                                                    //                         </div>
                                                                    //                     </>
                                                                    //                 }
                                                                    //             </Dropdown.Menu>
                                                                    //         </Dropdown>
                                                                    //     </div>
                                                                }
                                                            </>
                                                        ))
                                                    }


                                                    <NavLink to={'/notification'} activeClassName="active_menu"
                                                             className={'cursor-pointer d-flex align-items-center justify-content-start py-2'}>
                                                        <div className="afa-dashboard-tab-icons px-3">
                                                            <span className="icon-Notification "/>
                                                        </div>
                                                        <span className="afa-dashboard-span mb-2">پیام ها</span>
                                                    </NavLink>

                                                    <button className="afa-exit-btn cursor-pointer pb-5"
                                                            onClick={handleExit}>
                                                        <div
                                                            className="d-flex justify-content-start align-items-center pt-1 pb-5 px-0">
                                                            <div className="afa-dashboard-tab-icons px-3">
                                                                <span className="icon-Logout "/>
                                                            </div>
                                                            <span className="afa-dashboard-span">خروج</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                    }
                                    <div id={'afa-article-content'}>
                                        <Switch>
                                            {/*profile*/}
                                            <PrivateRoute
                                                component={(user?.purpose === 'job_seeker' || localStorage.getItem('purpose') === 'job_seeker') ? UserInformation : (user?.purpose === 'supplier' || localStorage.getItem('purpose' === 'supplier')) && SupplierParent}
                                                path="/profile" exact/>
                                            <Redirect from='/' to='profile' exact/>

                                            {/*manage hr*/}
                                            <PrivateRoute component={HumanResourcesManagement} path="/manage-hr"
                                                          exact={true}/>
                                            <PrivateRoute component={HumanResourcesDetails} path="/manage-hr/:user_id"
                                                          exact={true}/>

                                            {/*supplier hr*/}
                                            <PrivateRoute component={SuppliersList} path="/manage-supplier" exact/>
                                            <PrivateRoute component={SuppliersTabsDetails}
                                                          path="/manage-supplier/:user_id" exact/>

                                            <PrivateRoute component={Dashboard} path="/dashboard" exact/>
                                            <PrivateRoute component={Setting} path="/manage-site" exact/>
                                            <PrivateRoute component={SettingEn} path="/manage-site-en" exact/>
                                            <PrivateRoute component={Users} path="/manage-user" exact/>
                                            <PrivateRoute component={Products} path="/manage-product" exact/>
                                            <PrivateRoute component={ProductPrice}
                                                          path="/manage-product/:product_id/product_price" exact/>
                                            <PrivateRoute component={NewProduct} path="/new_product" exact/>
                                            <PrivateRoute component={ProductInfo} path="/manage-product/:product_id"
                                                          exact/>
                                            <PrivateRoute component={Sofrwares} path="/manage-software" exact/>
                                            <PrivateRoute component={Skills} path="/manage-skill" exact/>
                                            <PrivateRoute component={Laboratory} path="/manage-laboratory" exact/>
                                            <PrivateRoute component={Units} path="/manage-unit" exact/>
                                            <PrivateRoute component={StaffInfo} path="/manage-unit/:unit_id" exact/>
                                            {/*<PrivateRoute component={Articles} path="/manage-blog" exact/>*/}
                                            {/*<PrivateRoute component={NewArticle} path="/new_articles" exact/>*/}
                                            {/*<PrivateRoute component={Archive} path="/manage-blog/:article_id" exact/>*/}
                                            {/*<PrivateRoute component={Gallery} path="/manage-gallery" exact/>*/}
                                            <PrivateRoute component={Services} path="/manage-service" exact/>

                                            {/*contact us*/}
                                            {/*<PrivateRoute component={CommunicationWithUniversity}*/}
                                            {/*              path="/communication-university" exact/>*/}
                                            {/*<PrivateRoute component={CommunicationWithUniversityInfo}*/}
                                            {/*              path="/communication-university/:id" exact/>*/}

                                            {/*<PrivateRoute component={CommentsAndComplaints} path="/comments-complaints"*/}
                                            {/*              exact/>*/}
                                            {/*<PrivateRoute component={CommentsAndComplaintsInfo}*/}
                                            {/*              path="/comments-complaints/:id" exact/>*/}

                                            {/*<PrivateRoute component={CommunicationManagement}*/}
                                            {/*              path="/communication-management" exact/>*/}
                                            {/*<PrivateRoute component={CommunicationManagementInfo}*/}
                                            {/*              path="/communication-management/:id" exact/>*/}

                                            {/*<PrivateRoute component={ProposedSystem} path="/proposed-system" exact/>*/}
                                            {/*<PrivateRoute component={ProposedSystemInfo} path="/proposed-system/:id"*/}
                                            {/*              exact/>*/}

                                            {/*About us*/}
                                            <PrivateRoute component={MessageFromManagement} path="/message-management"
                                                          exact/>
                                            {/*<PrivateRoute component={SystemPolicy} path="/system-policy" exact/>*/}
                                            {/*<PrivateRoute component={MissionVision} path="/mission-vision" exact/>*/}
                                            {/*<PrivateRoute component={BoardDirectors} path="/manage-directorate" exact/>*/}
                                            {/*<PrivateRoute component={NewBoardDirectors} path="/manage-directorate/new"*/}
                                            {/*              exact/>*/}
                                            {/*<PrivateRoute component={BoardDirectorsInfo} path="/manage-directorate/:id"*/}
                                            {/*              exact/>*/}

                                            {/*<PrivateRoute component={Testimonial} path="/manage-testimonial" exact/>*/}
                                            {/*<PrivateRoute component={NewTestimonial} path="/new_testimonial" exact/>*/}
                                            {/*<PrivateRoute component={TestimonialArchive}*/}
                                            {/*              path="/manage-testimonial/:testimonial_id" exact/>*/}

                                            <PrivateRoute component={Chats} path="/notification" exact/>
                                            {/*<PrivateRoute component={MachineManufacturing} path="/manage-mashin-sazi"*/}
                                            {/*              exact/>*/}

                                            <Route exact path="*" render={() => (<Redirect to="/404"/>)}/>

                                        </Switch>
                                    </div>
                                </section>
                            }
                        </>
                        :
                        <Switch>
                            <PublicRoute component={Login} path="/" exact={true}/>
                            <PublicRoute component={Login} path="/login" exact={true}/>
                            <PublicRoute component={RealRegister} path="/real_register" exact={true}/>
                            <PublicRoute component={LegalRegister} path="/legal_register" exact={true}/>
                            <PublicRoute component={VerifyCode} path="/verify_code" exact={true}/>
                            <PrivateRoute component={UserInformation} path="/profile" exact/>
                            <Route exact path="*" render={() => (<Redirect to="/404"/>)}/>
                        </Switch>
                }
            </div>
        </>
    );
}

export default AppWrapper;
