import {SHOW_USER_INFORMATION , NOTIFICATION_COUNT} from "./types"

const INITIAL_STATE={
    show_information : false,
    notification_count : 0,
};

const reducer= (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case SHOW_USER_INFORMATION: {
            return {
                ...state,
                show_information:action.payload,
            }
        }
        case NOTIFICATION_COUNT: {
            return {
                ...state,
                notification_count:action.payload,
            }
        }
        default:
            return {state}
    }
};


export default reducer;