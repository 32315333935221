import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import {InformationButton} from "../style";
import {getData, getFormDataPost} from "../../../../../assets/scripts/GeneralFunctions";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";

function Notices(props) {

    // states
    const [inputs, setInput] = useState({
        sheets: [
            'personal_info',
            'family_info',
            'educational_background',
            'professional_experience',
            'foreign_languages',
            'skills',
            'certifications',
            'softwares',
            'documents'
        ],
        sheets_selected: []
    });

    // custom checkboxes
    const CheckBoxHandler = async (value, title) => {

        let selected = [];
        let obj = {};

        for (let i = 0; i < inputs?.[`${title}_selected`]?.length; i++) {
            delete obj[`${title}[${i}]`];
        }

        if (inputs?.[`${title}_selected`]?.length > 0) {
            selected = inputs?.[`${title}_selected`]
        }

        let find = selected?.indexOf(value)

        if (find > -1) {
            selected?.splice(find, 1)
        } else {
            selected?.push(value)
        }

        for (let i = 0; i < selected?.length; i++) {
            obj[`${title}[${i}]`] = selected[i]
        }
        setInput(prevState => ({
            ...prevState,
            [`${title}_selected`]: selected
        }))
    }

    console.log(props?.user_id, inputs)

    const saveSheetsNotices = async () => {

        let formData = new FormData()

        if (inputs['sheets_selected']?.length > 0) {
            for (let i = 0; i < inputs['sheets_selected']?.length; i++) {
                formData.append(`sheets[${i}]`, inputs['sheets_selected'][i])
            }

            let api_result = await getFormDataPost(`hr-management/denied-sheets/${props.user_id}`, formData)
            if (api_result?.status === 200) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: 'اطلاع رسانی انجام شد',
                    showConfirmButton: false,
                    timer: 3500
                })
            }
        } else {
            toast.error('لطفا بخش های ناقص راانتخاب کنید')
        }
    }
    return (
        <>
            <div className="dv-bg-gray-notices">
                <h6 className={'mb-3'}>بخش های ناقص</h6>
                <Accordion className='afa-information-collapse' allowZeroExpanded>
                    <AccordionItem className='mb-3'>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <div dir={'rtl'}>
                                    <span className='afa-title'>انتخاب کنید</span>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="d-flex flex-column">
                                {
                                    inputs?.sheets?.map((item, index) => (
                                        <label className="afa-custom-checkbox" key={index}>{
                                            item === 'personal_info' ? 'اطلاعات فردی' :
                                                item === 'family_info' ? 'اطلاعات اعضای خانواده' :
                                                    item === 'educational_background' ? ' سوابق تحصیلی ' :
                                                        item === 'professional_experience' ? 'سوابق شغلی' :
                                                            item === 'foreign_languages' ? 'زبان های خارجی' :
                                                                item === 'skills' ? 'مهارت ها' :
                                                                    item === 'certifications' ? 'گواهی ها' :
                                                                        item === 'softwares' ? 'نرم افزار های کامپیوتری' : 'اپلود مدارک'
                                        }
                                            <input type="checkbox" name='company_type'
                                                   value={item}
                                                   onChange={() => CheckBoxHandler(item, 'sheets')}
                                                   checked={inputs.sheets_selected?.includes(item)}
                                            />
                                            <span className="checkmark2"></span>
                                        </label>
                                    ))
                                }
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>

            <InformationButton>
                <button onClick={saveSheetsNotices}>
                    <span className='afa-information-btn-title'>ذخیره</span>
                </button>
                <Link to={'/manage-hr'}>
                    <span className='afa-information-btn-title'>لیست کاربران</span>
                </Link>
            </InformationButton>
        </>
    );
}

export default Notices;