import React, {useEffect, useState} from 'react';
import {PanelContent, Rows, SearchInput, CustomTabs, InformationButton} from "./style";
import {Inputs, LargeFileInputs, LeftIcon} from "../UserInformation/Child/style";
import {fixNumber, getData, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import HumanResourcesDetails from "./HumanResourcesDetails";
import {useDispatch, useSelector} from "react-redux";
import {setUserInformation} from "../../../../redux";
import ExcelExport from "./ExcleExport/ExcleExport";
import {Modal} from "react-bootstrap";
import Multiselect from 'multiselect-react-dropdown';
import {Link} from "react-router-dom";
import RangeSlider from "../../../utils/RangeSlider/RangeSlider";

// Skeleton
import Skeleton from 'react-loading-skeleton'
import InsuranceExcelExport from "./ExcleExport/InsuranceExcelExport";

function HumanResourcesManagement(props) {

    const dispatch = useDispatch()
    const [inputs, setInputs] = useState({
        search_value: '',
        status: 'pending',
        certificate_type: [
            "پایه1",
            "پایه2",
            "پایه3",
        ],
        selected: [],
        software_selected: [],
        skill_selected: [],
        gender_selected: [],
        gender_type: ['زن', 'مرد'],
        military_status_selected: [],
        military_type: ['پایان خدمت', 'معاف', 'سایر'],

        education_list: ['ابتدایی', 'راهنمایی', 'دبیرستان', 'کاردانی', 'کارشناسی', 'کارشناسی ارشد', 'دکتری'],
        education_selected: [],

        drivers_licences_list: ['پایه1', 'پایه2', 'پایه3', 'جرثقیل', 'بیل مکانیکی', 'لیفتراک', 'تراکتور', 'لودر'],
        drivers_licences_selected: [],

        companies: [],
        companies_selected: [],

        pending_count: 0,
        denied_count: 0,
        completed_count: 0,
        incomplete_count: 0,
        personnel_count: 0,
        softwares: [],
        skills: [],
        items: [NaN, NaN, NaN, NaN, NaN],
        show_excel_download: false,
        show_insurance_excel_download: false,
        slider_value: '',
        pending_interview: 'انتخاب کنید',
        pending_interview_modal: false,
        rejection_interview: '',
        pending_interview_id: ''
    })
    const [user_id, setId] = useState(null)
    const [error, setError] = useState({})
    const [is_all_items_load, setAllLoad] = useState(true)
    const [formData, setFormData] = useState({
        'limit': 30,
        'offset': 0,
        'excel': 0
    })
    let show_details_with_state = useSelector(state => state.state.show_information)
    let show_details = useSelector(state => state.show_information)
    let show = show_details ? show_details : show_details_with_state

    useEffect(async () => {
        setTitle('آتاویتا | منابع انسانی')
        setInputs(prevState => ({...prevState , items: [NaN, NaN, NaN, NaN, NaN]}))
        let api_result = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', formData, true, false);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                incomplete_count: data?.incomplete_count,
                personnel_count: data?.personnel_count,
                softwares: data?.softwares,
                skills: data?.skills,
                items: data?.items,
                states: data?.states,
                companies: data?.companies,
            }))
        }
    }, [inputs?.status]);

    const searchHandler = async (e) => {
        let obj = {};
        obj = formData;
        obj['search'] = e.target.value
        setInputs(prevState => ({
            ...prevState,
            search_value: e.target.value
        }))
        let api_result = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                incomplete_count: data?.incomplete_count,
                personnel_count: data?.personnel_count,
                softwares: data?.softwares,
                skills: data?.skills,
                items: data?.items,
            }))
            setFormData(obj)
        }
    }


    // software
    const softwareCheckBoxHandlerSelected = async (value, selectedItem) => {
        let selected = [];
        let obj = {};
        obj = formData;
        let title = 'software'
        for (let i = 0; i < inputs?.[`${title}_selected`]?.length; i++) {
            delete obj[`${title}[${i}]`];
        }

        if (inputs?.[`${title}_selected`]?.length > 0) {
            selected = inputs?.[`${title}_selected`]
        }

        selected?.push(selectedItem.id)

        for (let i = 0; i < selected?.length; i++) {
            obj[`${title}[${i}]`] = selected[i]
        }
        let api_result = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                incomplete_count: data?.incomplete_count,
                personnel_count: data?.personnel_count,
                softwares: data?.softwares,
                skills: data?.skills,
                items: data?.items,
                [`${title}_selected`]: selected
            }))
            setFormData(obj)
        }
    }
    const softwareCheckBoxHandlerRemove = async (value, removedItem) => {
        let selected = [];
        let obj = {};
        obj = formData;
        let title = 'software'

        for (let i = 0; i < inputs?.[`${title}_selected`]?.length; i++) {
            delete obj[`${title}[${i}]`];
        }

        if (inputs?.[`${title}_selected`]?.length > 0) {
            selected = inputs?.[`${title}_selected`]
        }

        let find = selected?.indexOf(removedItem.id)

        selected?.splice(find, 1)

        for (let i = 0; i < selected?.length; i++) {
            obj[`${title}[${i}]`] = selected[i]
        }
        let api_result = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                incomplete_count: data?.incomplete_count,
                personnel_count: data?.personnel_count,
                softwares: data?.softwares,
                skills: data?.skills,
                items: data?.items,
                [`${title}_selected`]: selected
            }))
            setFormData(obj)
        }
    }

    // skills
    const skillsCheckBoxHandlerSelected = async (value, selectedItem) => {
        let selected = [];
        let obj = {};
        obj = formData;
        let title = 'skill'
        for (let i = 0; i < inputs?.[`${title}_selected`]?.length; i++) {
            delete obj[`${title}[${i}]`];
        }

        if (inputs?.[`${title}_selected`]?.length > 0) {
            selected = inputs?.[`${title}_selected`]
        }

        selected?.push(selectedItem.id)

        for (let i = 0; i < selected?.length; i++) {
            obj[`${title}[${i}]`] = selected[i]
        }
        let api_result = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                incomplete_count: data?.incomplete_count,
                personnel_count: data?.personnel_count,
                softwares: data?.softwares,
                skills: data?.skills,
                items: data?.items,
                [`${title}_selected`]: selected
            }))
            setFormData(obj)
        }
    }
    const skillsCheckBoxHandlerRemove = async (value, removedItem) => {
        let selected = [];
        let obj = {};
        obj = formData;
        let title = 'skill'

        for (let i = 0; i < inputs?.[`${title}_selected`]?.length; i++) {
            delete obj[`${title}[${i}]`];
        }

        if (inputs?.[`${title}_selected`]?.length > 0) {
            selected = inputs?.[`${title}_selected`]
        }

        let find = selected?.indexOf(removedItem.id)

        selected?.splice(find, 1)

        for (let i = 0; i < selected?.length; i++) {
            obj[`${title}[${i}]`] = selected[i]
        }
        let api_result = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                incomplete_count: data?.incomplete_count,
                personnel_count: data?.personnel_count,
                softwares: data?.softwares,
                skills: data?.skills,
                items: data?.items,
                [`${title}_selected`]: selected
            }))
            setFormData(obj)
        }
    }

    // states
    const stateCheckBoxHandlerSelected = async (value, selectedItem) => {
        let selected = [];
        let obj = {};
        obj = formData;
        let title = 'states'
        for (let i = 0; i < inputs?.[`${title}_selected`]?.length; i++) {
            delete obj[`${title}[${i}]`];
        }

        if (inputs?.[`${title}_selected`]?.length > 0) {
            selected = inputs?.[`${title}_selected`]
        }

        selected?.push(selectedItem.id)

        for (let i = 0; i < selected?.length; i++) {
            obj[`${title}[${i}]`] = selected[i]
        }
        let api_result = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                incomplete_count: data?.incomplete_count,
                personnel_count: data?.personnel_count,
                softwares: data?.softwares,
                skills: data?.skills,
                items: data?.items,
                [`${title}_selected`]: selected
            }))
            setFormData(obj)
        }
    }
    const stateCheckBoxHandlerRemove = async (value, removedItem) => {
        let selected = [];
        let obj = {};
        obj = formData;
        let title = 'states'

        for (let i = 0; i < inputs?.[`${title}_selected`]?.length; i++) {
            delete obj[`${title}[${i}]`];
        }

        if (inputs?.[`${title}_selected`]?.length > 0) {
            selected = inputs?.[`${title}_selected`]
        }

        let find = selected?.indexOf(removedItem.id)

        selected?.splice(find, 1)

        for (let i = 0; i < selected?.length; i++) {
            obj[`${title}[${i}]`] = selected[i]
        }
        let api_result = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                incomplete_count: data?.incomplete_count,
                personnel_count: data?.personnel_count,
                softwares: data?.softwares,
                skills: data?.skills,
                items: data?.items,
                [`${title}_selected`]: selected
            }))
            setFormData(obj)
        }
    }

    // custom checkboxes
    const CheckBoxHandler = async (value, title) => {

        let selected = [];
        let obj = {};
        obj = formData;

        for (let i = 0; i < inputs?.[`${title}_selected`]?.length; i++) {
            delete obj[`${title}[${i}]`];
        }

        if (inputs?.[`${title}_selected`]?.length > 0) {
            selected = inputs?.[`${title}_selected`]
        }

        let find = selected?.indexOf(value)

        if (find > -1) {
            selected?.splice(find, 1)
        } else {
            selected?.push(value)
        }

        for (let i = 0; i < selected?.length; i++) {
            obj[`${title}[${i}]`] = selected[i]
        }
        let api_result = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            console.log(api_result?.data)
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                incomplete_count: data?.incomplete_count,
                personnel_count: data?.personnel_count,
                softwares: data?.softwares,
                skills: data?.skills,
                items: data?.items,
                [`${title}_selected`]: selected
            }))
            setFormData(obj)
        }
    }

    const rangeValue = (value) => {
        setInputs(prevState => ({...prevState, slider_value: value}))
    }

    const handlerRangeValue = async (title) => {
        let value = inputs?.slider_value.join(';')

        let obj = {};
        obj = formData;

        obj[`${title}`] = value

        let api_result = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                incomplete_count: data?.incomplete_count,
                personnel_count: data?.personnel_count,
                softwares: data?.softwares,
                skills: data?.skills,
                items: data?.items,
            }))
            setFormData(obj)
        }
    }

    const showTabItems = async (name) => {
        setInputs(prevState => ({...prevState, status: name}))
        setAllLoad(true)
    }

    // lazy load
    const getTableDataLazyLoad = async (obj_data) => {
        if (parseFloat(obj_data.target.offsetHeight + obj_data.target.scrollTop) + 1 >= parseFloat(obj_data.target.scrollHeight)) {
            if (inputs?.items?.length % 30 === 0 && is_all_items_load === true) {

                let obj = {};
                obj = formData;
                obj['offset'] = inputs?.items?.length

                let moreData = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', obj, true, true);
                if (moreData) {
                    setFormData(obj)
                    setInputs(prevState => ({...prevState, items: inputs?.items.concat(moreData?.data?.items)}))
                    if (moreData?.data.items?.length < 30) {
                        setAllLoad(false)
                    }
                }
            }
        }
    }

    const excelExport = () => {
        setInputs(prevState => ({...prevState, show_excel_download: true}))
    }

    const excelInsuranceExport = () => {
        setInputs(prevState => ({...prevState, show_insurance_excel_download: true}))
    }

    const closeModal = async () => {
        setInputs(prevState => ({...prevState, show_excel_download: false, show_insurance_excel_download: false}))
        let obj = {};
        obj = formData;
        obj['excel'] = 0;
        setFormData(obj)

        let api_result = await getData(MAIN_URL, `hr-management/${inputs?.status}`, 'get', obj, true, false);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                incomplete_count: data?.incomplete_count,
                personnel_count: data?.personnel_count,
                softwares: data?.softwares,
                skills: data?.skills,
                items: data?.items,
            }))
        }
    }

    return (
        <PanelContent>
            <div className="w-100 d-flex flex-column flex-xl-row justify-content-md-between align-items-md-center">
                {/*Search*/}
                <SearchInput className='mb-3'>
                    <span className='icon-Search dv-search-icon'/>
                    <input type="search" value={inputs?.search_value} onChange={searchHandler} placeholder='جستجو'/>
                </SearchInput>
                <div className="d-flex flex-column flex-sm-row align-items-center">
                    {/*Excel Export*/}
                    {
                        (!inputs?.show_insurance_excel_download && inputs?.status === 'personnel') &&
                        <button className='afa-excel-export2 mb-3' onClick={excelInsuranceExport}>
                            <span className='icon-excel afa-btn-icon'/>
                            <span className='afa-information-btn-title'>خروجی اکسل بیمه</span>
                        </button>
                    }
                    {
                        !inputs?.show_excel_download &&
                        <button className='afa-excel-export2 mb-3' onClick={excelExport}>
                            <span className='icon-excel afa-btn-icon'/>
                            <span className='afa-information-btn-title'>خروجی اکسل</span>
                        </button>
                    }

                    {/*Filter*/}
                    <Accordion className='dv-filter-drop-down mb-3' allowZeroExpanded>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="d-flex align-items-center">
                                        <span>فیلتر</span>
                                        <span className="icon-Filter dv-filter-icon"/>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <Accordion className='dv-filter-drop-down-items' allowZeroExpanded>

                                    {/*software*/}
                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>تسلط به نرم افزار</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>

                                            <Multiselect

                                                options={inputs?.softwares} // Options to display in the dropdown
                                                // selectedValues={inputs?.selected_supply_list} // Preselected value to persist in dropdown
                                                onSelect={softwareCheckBoxHandlerSelected} // Function will trigger on select event
                                                onRemove={softwareCheckBoxHandlerRemove} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                                placeholder={'انتخاب کنید'}
                                            />

                                            {/*<div className="d-flex flex-column">*/}
                                            {/*    {*/}
                                            {/*        inputs?.softwares?.map((item, index) => (*/}
                                            {/*            <label className="afa-custom-checkbox"*/}
                                            {/*                   key={index}>{item?.name}*/}
                                            {/*                <input type="checkbox" name='certificate_type'*/}
                                            {/*                       value={item?.id}*/}
                                            {/*                       onChange={() => softwareCheckBoxHandler(item?.id, 'software')}*/}
                                            {/*                       checked={inputs.software_selected?.includes(item?.id)}*/}
                                            {/*                />*/}
                                            {/*                <span className="checkmark2"></span>*/}
                                            {/*            </label>*/}
                                            {/*        ))*/}
                                            {/*    }*/}
                                            {/*</div>*/}
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    {/*skills*/}
                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>مهارت ها</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                <Multiselect
                                                    options={inputs?.skills}
                                                    onSelect={skillsCheckBoxHandlerSelected}
                                                    onRemove={skillsCheckBoxHandlerRemove}
                                                    displayValue="name"
                                                    placeholder={'انتخاب کنید'}
                                                />
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    {/*gender*/}
                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>جنسیت</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                {
                                                    inputs?.gender_type?.map((item, index) => (
                                                        <label className="afa-custom-checkbox" key={index}>{item}
                                                            <input type="checkbox" name='certificate_type'
                                                                   value={item}
                                                                   onChange={() => CheckBoxHandler(item, 'gender')}
                                                                   checked={inputs.gender_selected?.includes(item)}
                                                            />
                                                            <span className="checkmark2"></span>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    {/*military_status*/}
                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>وضعیت نظام وظیفه</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                {
                                                    inputs?.military_type?.map((item, index) => (
                                                        <label className="afa-custom-checkbox" key={index}>{item}
                                                            <input type="checkbox" name='certificate_type'
                                                                   value={item}
                                                                   onChange={() => CheckBoxHandler(item, 'military_status')}
                                                                   checked={inputs.military_status_selected?.includes(item)}
                                                            />
                                                            <span className="checkmark2"></span>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    {/*education*/}
                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>مدرک تحصیلی</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                {
                                                    inputs?.education_list?.map((item, index) => (
                                                        <label className="afa-custom-checkbox" key={index}>{item}
                                                            <input type="checkbox" name='certificate_type'
                                                                   value={item}
                                                                   onChange={() => CheckBoxHandler(item, 'education')}
                                                                   checked={inputs.education_selected?.includes(item)}
                                                            />
                                                            <span className="checkmark2"></span>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    {/*states*/}
                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>استان ها</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>

                                            <Multiselect

                                                options={inputs?.states}
                                                onSelect={stateCheckBoxHandlerSelected}
                                                onRemove={stateCheckBoxHandlerRemove}
                                                displayValue="name"
                                                placeholder={'انتخاب کنید'}
                                            />
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    {/*drivers_licences*/}
                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>گواهینامه های رانندگی</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                {
                                                    inputs?.drivers_licences_list?.map((item, index) => (
                                                        <label className="afa-custom-checkbox" key={index}>{item}
                                                            <input type="checkbox" name='certificate_type'
                                                                   value={item}
                                                                   onChange={() => CheckBoxHandler(item, 'drivers_licences')}
                                                                   checked={inputs.drivers_licences_selected?.includes(item)}
                                                            />
                                                            <span className="checkmark2"></span>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    {/*companies*/}
                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>شرکت</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                {
                                                    inputs?.companies?.map((item, index) => (
                                                        <label className="afa-custom-checkbox" key={index}>{item?.name}
                                                            <input type="checkbox" name='company_type'
                                                                   value={item?.id}
                                                                   onChange={() => CheckBoxHandler(item?.id, 'companies')}
                                                                   checked={inputs.companies_selected?.includes(item?.id)}
                                                            />
                                                            <span className="checkmark2"></span>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    {/*age range slider*/}
                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>بازه سنی</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                <RangeSlider
                                                    maxRenge={65}
                                                    minRenge={18}
                                                    title={'سن'}
                                                    rangeValueProps={rangeValue}/>
                                                <button className="dv_add_filter_range"
                                                        onClick={() => handlerRangeValue('age_range')}>
                                                    اعمال فیلتر
                                                </button>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    {/*professional_experience slider*/}
                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>سابقه کاری</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                <RangeSlider
                                                    maxRenge={50}
                                                    minRenge={1}
                                                    title={'سابقه'}
                                                    rangeValueProps={rangeValue}/>
                                                <button className="dv_add_filter_range"
                                                        onClick={() => handlerRangeValue('professional_experience')}>
                                                    اعمال فیلتر
                                                </button>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                </Accordion>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            {/*Tabs*/}
            <CustomTabs>
                <div className="afa-tab-list d-flex flex-wrap">
                    <div
                        className={inputs?.status === 'pending' ? "d-flex align-items-center afa-tab-title active" : "d-flex align-items-center afa-tab-title"}
                        onClick={() => showTabItems('pending')}>
                        <span>در انتظار بررسی</span>
                        <div className="afa-number-tabs">{inputs?.pending_count}</div>
                    </div>
                    <div
                        className={inputs?.status === 'incomplete' ? "d-flex align-items-center afa-tab-title active" : "d-flex align-items-center afa-tab-title"}
                        onClick={() => showTabItems('incomplete')}>
                        <span>ناقص</span>
                        <div className="afa-number-tabs">{inputs?.incomplete_count}</div>
                    </div>
                    <div
                        className={inputs?.status === 'completed' ? "d-flex align-items-center afa-tab-title active" : "d-flex align-items-center afa-tab-title"}
                        onClick={() => showTabItems('completed')}>
                        <span>در انتظار مصاحبه</span>
                        <div className="afa-number-tabs">{inputs?.completed_count}</div>
                    </div>
                    <div
                        className={inputs?.status === 'personnel' ? "d-flex align-items-center afa-tab-title active" : "d-flex align-items-center afa-tab-title"}
                        onClick={() => showTabItems('personnel')}>
                        <span>پرسنل</span>
                        <div className="afa-number-tabs">{inputs?.personnel_count}</div>
                    </div>
                    <div
                        className={inputs?.status === 'denied' ? "d-flex align-items-center afa-tab-title active" : "d-flex align-items-center afa-tab-title"}
                        onClick={() => showTabItems('denied')}>
                        <span>رد از مصاحبه</span>
                        <div className="afa-number-tabs">{inputs?.denied_count}</div>
                    </div>
                </div>
                <div className='afa-lazyload' style={{overflowX: 'auto'}} onScroll={getTableDataLazyLoad}>
                    <Rows>
                        <tbody>
                        {
                            inputs?.items?.length > 0 ?
                                !inputs?.items[0] ?
                                    inputs?.items?.map((row, index) => (
                                        <tr key={index}>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                        </tr>
                                    )) :
                                    inputs?.items?.map((row, index) => (
                                        <tr key={index}>
                                            <td>
                                                <span className='pl-2 afa-tbody-text'>{parseInt(index) + 1} - </span>
                                                {/*<span>نام و نام خانوادگی :</span>*/}
                                                <span>{`${row?.first_name} ${row?.last_name}`}</span>
                                                {
                                                    row?.confirmed_information === 1 &&
                                                    <span className="badge bg-success me-3 p-2">تکمیل شده</span>
                                                }

                                            </td>
                                            <td>
                                                <span>کد ملی :</span>
                                                <span>{row?.identification_code}</span>
                                            </td>
                                            <td>
                                                <span>شماره تماس :</span>
                                                <span>{row?.mobile}</span>
                                            </td>
                                            <td>
                                                <Link className={'text'} to={`/manage-hr/${row?.id}`}>مشاهده<span
                                                    className="icon-cheveron-left"></span></Link>
                                            </td>
                                        </tr>
                                    )) : <tr>
                                    <td>آیتمی وجود ندارد</td>
                                </tr>
                        }
                        </tbody>
                    </Rows>
                </div>
            </CustomTabs>

            <Modal style={{textAlign: 'center'}} centered={true} show={inputs?.show_excel_download} onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <div className="row py-4">
                        <div className="mb-5 w-100">
                            <h5 className='k-text-right'>دانلود فایل اکسل</h5>
                        </div>
                        <div
                            className='col-12 w-100 d-flex justify-content-between align-items-center'>
                            <ExcelExport filterData={formData} status={inputs?.status}/>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal w-25'>انصراف
                            </button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal style={{textAlign: 'center'}} centered={true} show={inputs?.show_insurance_excel_download}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <div className="row py-4">
                        <div className="mb-5 w-100">
                            <h5 className='k-text-right'>دانلود فایل اکسل بیمه</h5>
                        </div>
                        <div
                            className='col-12 w-100 d-flex justify-content-between align-items-center'>
                            <InsuranceExcelExport filterData={formData} status={inputs?.status}/>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal w-25'>انصراف
                            </button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>


        </PanelContent>
    );
}

export default HumanResourcesManagement;