import React from 'react';
import {LeftContent, LoginTheme, RightContent} from "./styles";
import {Images} from "../../assets/scripts/Images";
import {Tabs , Tab} from "react-bootstrap";
import LegalPerson from "./LegalPerson";
import RealPerson from "./RealPerson";
import {useLocation} from "react-router";
function Login(props) {
    let location = useLocation();
    return (
        <>
        <LoginTheme>
            <RightContent>
                <img src={Images.main_logo.default} alt="آتاویتا"/>
                <h6>ورود به حساب کاربری</h6>
                <Tabs defaultActiveKey={location.state?.user_type ? location.state?.user_type : 'real'} id="login-tabs" className="mb-3">
                    <Tab eventKey="legal" title="حقوقی">
                        <LegalPerson/>
                    </Tab>
                    <Tab eventKey="real" title="حقیقی">
                        <RealPerson/>
                    </Tab>
                </Tabs>
            </RightContent>
            <LeftContent>
                <img src={Images.login_left.default} alt="آتاویتا"/>
            </LeftContent>
        </LoginTheme>
        </>
    );
}

export default Login;