import React, {useEffect, useState} from 'react';
import {
    checkCodeMelli, CommaFormat,
    fileUpload, fixNumber,
    getData,
    getFormDataPost, just_number,
    priceFormat
} from "../../../../../assets/scripts/GeneralFunctions";
import {FileInputs, Inputs, LeftIcon, TabStatusComplete, TabStatusContent, TabStatusNotComplete} from "./style";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import moment from "moment-jalaali";
import {toast} from "react-toastify";
import {MAIN_URL} from "../../../../../assets/scripts/GeneralVariables";
import Swal from "sweetalert2";

const commaNumber = require('comma-number')

function WorkExperience(props) {
    const [error, setError] = useState({})
    const [error2, setError2] = useState({})
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});
    const [inputs, setInputs] = useState({
        organization: '',
        position: '',
        salary: '',
        from_date: '',
        to_date: '',
        termination_of_cooperation_reason: '',
        professional_experiences: []
    });
    const [inputs2, setInputs2] = useState({
        organization: '',
        position: '',
        salary: '',
        from_date: '',
        to_date: '',
        termination_of_cooperation_reason: '',
    });
    const [is_render, setRender] = useState(false);
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [new_collapse, setNewCollapse] = useState(false);
    const [show_add_more, setShowAddMore] = useState(false);

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/professional-experiences", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let professional_experiences = data?.professional_experiences
            if (professional_experiences?.length === 0) {
                setInputs({
                    professional_experiences: [],
                    organization: '',
                    position: '',
                    salary: '',
                    from_date: '',
                    to_date: '',
                    termination_of_cooperation_reason: ''
                })
                setShowAddMore(false)
            } else {
                setInputs(prevState => ({
                    ...prevState,
                    insurance_types: data.insurance_types,
                    professional_experiences: professional_experiences
                }))
                setShowAddMore(true)
            }

            setMoreData({sheet_status: data?.sheet_status, status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }
    const inputHandler2 = (e) => {
        setInputs2(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = inputs.professional_experiences?.find((row) => {
            return row.id === id
        })
        if (data) {
            setInputs((prevState => ({
                ...prevState,
                organization: data?.organization,
                position: data?.position,
                salary: data?.salary,
                from_date: data?.from_date,
                to_date: data?.to_date,
                termination_of_cooperation_reason: data?.termination_of_cooperation_reason
            })))
        }
    }

    const addMoreCollapse = () => {
        setInputs2(prevState => ({
            ...prevState,
            organization: '',
            position: '',
            salary: '',
            from_date: '',
            to_date: '',
            termination_of_cooperation_reason: ''
        }));
        setShowAddMore(false)
        setNewCollapse(true)
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/professional-experiences/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    setRender(!is_render)
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const editHandler = async (id) => {
        let formData = new FormData()

        if (inputs['organization']?.length > 0) {
            formData.append('organization', inputs['organization'])
        }
        if (inputs['position']?.length > 0) {
            formData.append('position', inputs['position'])
        }
        if (inputs['salary']?.length > 0) {
            formData.append('salary', inputs['salary'].replace(/,/g, ''))
        }
        if (inputs['termination_of_cooperation_reason']?.length > 0) {
            formData.append('termination_of_cooperation_reason', inputs['termination_of_cooperation_reason'])
        }

        formData.append('from_date', inputs?.from_date)
        formData.append('to_date', inputs?.to_date)

        let api_result = await getFormDataPost(`profile/professional-experiences/${id}`, formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            setShowAddMore(true)
            // setNewCollapse(false)
            setRender(!is_render)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const removeNewCollapse = () => {
        setNewCollapse(false)
        setShowAddMore(true)
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs['organization']?.length > 0) {
            formData.append('organization', inputs['organization'])
        }
        if (inputs['position']?.length > 0) {
            formData.append('position', inputs['position'])
        }
        if (inputs['salary']?.length > 0) {
            formData.append('salary', inputs['salary'].replace(/,/g, ''))
        }
        if (inputs['termination_of_cooperation_reason']?.length > 0) {
            formData.append('termination_of_cooperation_reason', inputs['termination_of_cooperation_reason'])
        }

        formData.append('from_date', inputs?.from_date)
        formData.append('to_date', inputs?.to_date)

        let api_result = await getFormDataPost('profile/professional-experiences', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setShowAddMore(true)
            setNewCollapse(false)
            setRender(!is_render)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addNewWhenCollapseHave = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs2['organization']?.length > 0) {
            formData.append('organization', inputs2['organization'])
        }
        if (inputs2['position']?.length > 0) {
            formData.append('position', inputs2['position'])
        }
        if (inputs2['salary']?.length > 0) {
            formData.append('salary', inputs2['salary'].replace(/,/g, ''))
        }
        if (inputs2['termination_of_cooperation_reason']?.length > 0) {
            formData.append('termination_of_cooperation_reason', inputs2['termination_of_cooperation_reason'])
        }

        formData.append('from_date', inputs2?.from_date)
        formData.append('to_date', inputs2?.to_date)

        let api_result = await getFormDataPost('profile/professional-experiences', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setShowAddMore(true)
            setNewCollapse(false)
            setRender(!is_render)
            setInputs2({
                organization: '',
                position: '',
                salary: '',
                from_date: '',
                to_date: '',
                termination_of_cooperation_reason: '',
            })
        } else if (api_result?.status === 400) {
            setError2(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const goToNextPage = () => {
        props.handleChange(4)
    }

    let professional_experiences = inputs?.professional_experiences
    return (
        <>
            <form onSubmit={formHandler} className="row">
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <TabStatusComplete
                        style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                        {
                            more_data.sheet_status?.status === 'pending' || more_data.sheet_status?.status === 'denied' ?
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-tick afa-icon-tick'/>
                        }
                        <p className='mb-0 f-14 '>
                            <span>وضعیت : </span><span>{more_data.status_colors?.[more_data.sheet_status?.status]?.title}</span>
                        </p>
                    </TabStatusComplete>
                </div>
                <div className="col-12 col-sm-6 col-lg-8 mb-4">
                    {
                        more_data.sheet_status?.message &&
                        <TabStatusContent>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <p className='mb-0 f-14'>
                                <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                            </p>
                        </TabStatusContent>
                    }
                </div>
                {
                    professional_experiences?.length > 0 ?
                        <>

                            <Accordion defaultActiveKey="0">
                                {
                                    professional_experiences?.map((row, index) => (
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey={row.id}
                                                              onClick={() => changeArrow(row.id)} key={index}>

                                                <div className="d-flex flex-wrap">
                                            <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                  onClick={() => removeTab(row.id)}></span>
                                                    {
                                                        row?.organization &&
                                                        <span
                                                            className="pl-4 f-14 my-2"> شرکت : {row.organization}</span>
                                                    }
                                                    {
                                                        row?.position &&
                                                        <span className="pl-4 f-14 my-2"> سمت : {row?.position}</span>
                                                    }
                                                    {
                                                        row?.from_date &&
                                                        <span className="pl-4 f-14 my-2 d-flex align-items-center"> از تاریخ : <p
                                                            className="mb-0 px-2">{row?.from_date}</p></span>
                                                    }
                                                    {
                                                        row?.to_date &&
                                                        <span className="pl-4 f-14 my-2 d-flex align-items-center"> تا تاریخ : <p
                                                            className="px-2 mb-0">{row?.to_date}</p></span>
                                                    }
                                                    {
                                                        row?.salary &&
                                                        <span
                                                            className="pl-4 f-14 my-2"> حقوق دریافتی : {priceFormat(row?.salary)}</span>
                                                    }
                                                </div>
                                                <span
                                                    className={(arrow_item.arrow_id === row.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={row.id}>
                                                <Card.Body className='row'>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <span>نام سازمان/ شرکت
                                                            <span className='dv-required-star'>*</span>
                                                            </span>

                                                            <input
                                                                className={error['organization'] && 'afa-error-input'}
                                                                type="text"
                                                                maxLength={199}

                                                                name={'organization'}

                                                                value={inputs.organization} onChange={inputHandler}

                                                            />

                                                            <p className='afa-error-text'>{error['organization']}</p>

                                                        </Inputs>

                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                        <Inputs>

                                                            <span>سمت
                                                            <span className='dv-required-star'>*</span>
                                                            </span>

                                                            <input className={error['position'] && 'afa-error-input'}
                                                                   type="text"
                                                                   maxLength={199}
                                                                   name={'position'}

                                                                   value={inputs.position} onChange={inputHandler}

                                                            />

                                                            <p className='afa-error-text'>{error['position']}</p>

                                                        </Inputs>

                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                        <Inputs>

                                                            <span>حقوق دریافتی ( ریال )
                                                            <span className='dv-required-star'>*</span>
                                                            </span>

                                                            <input className={error['salary'] && 'afa-error-input'}
                                                                   type="text"

                                                                   name={'salary'}

                                                                   value={inputs.salary}
                                                                   onChange={(e) => setInputs(prevState => ({
                                                                       ...prevState,
                                                                       [e.target.name]: CommaFormat(e.target.value)
                                                                   }))}

                                                            />

                                                            <p className='afa-error-text'>{error['salary']}</p>

                                                        </Inputs>

                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>

                                                            <span>از تاریخ ( سال )
                                                            <span className='dv-required-star'>*</span>
                                                            </span>

                                                            <input
                                                                className={error['from_date'] && 'afa-error-input'}
                                                                type="text"
                                                                max={9999}
                                                                maxLength={4}
                                                                name={'from_date'}
                                                                value={inputs.from_date}
                                                                onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                                                    ...prevState,
                                                                    [`from_date`]: fixNumber(e.target.value)
                                                                }))}
                                                            />

                                                        </Inputs>

                                                        <p className='afa-error-text'>{error['from_date']}</p>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                        <Inputs>

                                                            <span>تا تاریخ ( سال )
                                                            <span className='dv-required-star'>*</span>
                                                            </span>

                                                            <input
                                                                className={error['to_date'] && 'afa-error-input'}
                                                                type="text"
                                                                max={9999}
                                                                maxLength={4}
                                                                name={'to_date'}
                                                                value={inputs.to_date}
                                                                onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                                                    ...prevState, [`to_date`]: fixNumber(e.target.value)
                                                                }))}
                                                            />

                                                        </Inputs>

                                                        <p className='afa-error-text'>{error['to_date']}</p>

                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                        <Inputs>

                                                            <span>علت قطع همکاری
                                                            <span className='dv-required-star'>*</span>
                                                            </span>

                                                            <textarea name="termination_of_cooperation_reason"

                                                                      value={inputs.termination_of_cooperation_reason}
                                                                      onChange={inputHandler}

                                                                      className={error['termination_of_cooperation_reason'] && 'afa-error-input'}

                                                                      id="termination_of_cooperation_reason" cols="30"
                                                                      rows="1"/>

                                                            <p className='afa-error-text'>{error['termination_of_cooperation_reason']}</p>

                                                        </Inputs>

                                                    </div>

                                                    <div className="col-12 d-flex justify-content-center">
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                            <Inputs>

                                                                <button type='button'
                                                                        onClick={() => editHandler(row.id)}>

                                                                    <span>ثبت تغییرات</span>

                                                                    <LeftIcon><span
                                                                        className='icon-cheveron-left'/></LeftIcon>

                                                                </button>

                                                            </Inputs>

                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))
                                }
                                {
                                    new_collapse &&
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => changeArrow(0)}>

                                            <div className="d-flex flex-wrap">

                                                <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                      onClick={removeNewCollapse}></span>

                                                {
                                                    inputs2?.organization &&
                                                    <span
                                                        className="pl-4 f-14 my-2"> شرکت : {inputs2.organization}</span>
                                                }
                                                {
                                                    inputs2?.position &&
                                                    <span className="pl-4 f-14 my-2"> سمت : {inputs2?.position}</span>
                                                }
                                                {
                                                    inputs2?.from_date &&
                                                    <span className="pl-4 f-14 my-2 d-flex align-items-center"> از تاریخ : <p
                                                        className="mb-0 px-2">{inputs2?.from_date}</p></span>
                                                }
                                                {
                                                    inputs2?.to_date &&
                                                    <span className="pl-4 f-14 my-2 d-flex align-items-center"> تا تاریخ : <p
                                                        className="px-2 mb-0">{inputs2?.to_date}</p></span>
                                                }
                                                {
                                                    inputs2?.salary &&
                                                    <span
                                                        className="pl-4 f-14 my-2"> حقوق دریافتی : {commaNumber(inputs2?.salary)}</span>
                                                }

                                            </div>

                                            <span
                                                className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}></span>

                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className='row'>
                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                    <Inputs>

                                                        <span>نام سازمان/ شرکت
                                                        <span className='dv-required-star'>*</span>
                                                        </span>

                                                        <input className={error2['organization'] && 'afa-error-input'}
                                                               type="text"
                                                               maxLength={199}
                                                               name={'organization'}

                                                               value={inputs2.organization} onChange={inputHandler2}

                                                        />

                                                        <p className='afa-error-text'>{error2['organization']}</p>

                                                    </Inputs>

                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                    <Inputs>

                                                        <span>سمت
                                                        <span className='dv-required-star'>*</span>
                                                        </span>

                                                        <input className={error2['position'] && 'afa-error-input'}
                                                               type="text"
                                                               maxLength={199}
                                                               name={'position'}

                                                               value={inputs2.position} onChange={inputHandler2}

                                                        />

                                                        <p className='afa-error-text'>{error2['position']}</p>

                                                    </Inputs>

                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                    <Inputs>

                                                        <span>حقوق دریافتی ( ریال )
                                                        <span className='dv-required-star'>*</span>
                                                        </span>

                                                        <input className={error2['salary'] && 'afa-error-input'}
                                                               type="text"
                                                               name={'salary'}
                                                               value={inputs2.salary}
                                                               onChange={(e) => setInputs2(prevState => ({
                                                                   ...prevState,
                                                                   [e.target.name]: CommaFormat(e.target.value)
                                                               }))}

                                                        />

                                                        <p className='afa-error-text'>{error2['salary']}</p>

                                                    </Inputs>

                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <Inputs>

                                                        <span>از تاریخ ( سال )
                                                        <span className='dv-required-star'>*</span>
                                                        </span>

                                                        <input
                                                            className={error['from_date'] && 'afa-error-input'}
                                                            type="text"
                                                            max={9999}
                                                            maxLength={4}
                                                            name={'from_date'}
                                                            value={inputs2.from_date}
                                                            onChange={(e) => just_number(e.target.value) && setInputs2(prevState => ({
                                                                ...prevState, [`from_date`]: fixNumber(e.target.value)
                                                            }))}
                                                        />

                                                    </Inputs>

                                                    <p className='afa-error-text'>{error2['from_date']}</p>

                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                    <Inputs>

                                                        <span>تا تاریخ ( سال )
                                                        <span className='dv-required-star'>*</span>
                                                        </span>

                                                        <input
                                                            className={error['to_date'] && 'afa-error-input'}
                                                            type="text"
                                                            max={9999}
                                                            maxLength={4}
                                                            name={'to_date'}
                                                            value={inputs2.to_date}
                                                            onChange={(e) => just_number(e.target.value) && setInputs2(prevState => ({
                                                                ...prevState, [`to_date`]: fixNumber(e.target.value)
                                                            }))}
                                                        />

                                                    </Inputs>

                                                    <p className='afa-error-text'>{error2['to_date']}</p>

                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                    <Inputs>

                                                        <span>علت قطع همکاری
                                                        <span className='dv-required-star'>*</span>
                                                        </span>

                                                        <textarea name="termination_of_cooperation_reason"

                                                                  value={inputs2.termination_of_cooperation_reason}
                                                                  onChange={inputHandler2}

                                                                  className={error2['termination_of_cooperation_reason'] && 'afa-error-input'}

                                                                  id="termination_of_cooperation_reason" cols="30"
                                                                  rows="1"/>

                                                        <p className='afa-error-text'>{error2['termination_of_cooperation_reason']}</p>

                                                    </Inputs>

                                                </div>


                                                <div className="col-12">
                                                    <div className="row d-flex flex-column flex-sm-row justify-content-between">
                                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>
                                                            <Inputs>
                                                                <button type='button' onClick={addNewWhenCollapseHave}>
                                                                    <span className='icon-add f-20'/>
                                                                    <span className='pr-3'>ثبت این صفحه</span>
                                                                </button>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                                                        onClick={goToNextPage}>
                                                                    <span>مرحله بعد</span>
                                                                    <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                                                </button>
                                                            </Inputs>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>

                                    </Card>
                                }
                            </Accordion>

                            {
                                show_add_more &&
                                <div className="row d-flex flex-column flex-sm-row justify-content-between">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>
                                        <Inputs>
                                            <button type='button' onClick={addMoreCollapse}>
                                                <span className='icon-add f-20'/>
                                                <span className='pr-3'>افزودن جدید</span>
                                            </button>

                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                                    onClick={goToNextPage}>
                                                <span>مرحله بعد</span>
                                                <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                            </button>
                                        </Inputs>
                                    </div>
                                </div>
                            }

                        </>
                        :
                        <>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Inputs>
                                    <span>نام سازمان/ شرکت
                                    <span className='dv-required-star'>*</span>
                                    </span>
                                    <input className={error['organization'] && 'afa-error-input'} type="text"
                                           name={'organization'}
                                           maxLength={199}
                                           value={inputs.organization} onChange={inputHandler}
                                    />
                                    <p className='afa-error-text'>{error['organization']}</p>
                                </Inputs>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Inputs>
                                    <span>سمت
                                    <span className='dv-required-star'>*</span>
                                    </span>
                                    <input className={error['position'] && 'afa-error-input'} type="text"
                                           name={'position'} maxLength={199}
                                           value={inputs.position} onChange={inputHandler}
                                    />
                                    <p className='afa-error-text'>{error['position']}</p>
                                </Inputs>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Inputs>
                                    <span>حقوق دریافتی ( ریال )
                                    <span className='dv-required-star'>*</span>
                                    </span>
                                    <input className={error['salary'] && 'afa-error-input'} type="text"
                                           name={'salary'}
                                           value={inputs.salary} onChange={(e) => setInputs(prevState => ({
                                        ...prevState,
                                        [e.target.name]: CommaFormat(e.target.value)
                                    }))}
                                    />
                                    <p className='afa-error-text'>{error['salary']}</p>
                                </Inputs>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Inputs>
                                    <span>از تاریخ ( سال )
                                    <span className='dv-required-star'>*</span>
                                    </span>
                                    <input
                                        className={error['from_date'] && 'afa-error-input'}
                                        type="text"
                                        max={9999}
                                        maxLength={4}
                                        name={'from_date'}
                                        value={inputs.from_date}
                                        onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                            ...prevState, [`from_date`]: fixNumber(e.target.value)
                                        }))}
                                    />
                                </Inputs>
                                <p className='afa-error-text'>{error['from_date']}</p>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Inputs>
                                    <span>تا تاریخ ( سال )
                                    <span className='dv-required-star'>*</span>
                                    </span>
                                    <input
                                        className={error['to_date'] && 'afa-error-input'}
                                        type="text"
                                        max={9999}
                                        maxLength={4}
                                        name={'to_date'}
                                        value={inputs.to_date}
                                        onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                            ...prevState, [`to_date`]: fixNumber(e.target.value)
                                        }))}
                                    />
                                </Inputs>
                                <p className='afa-error-text'>{error['to_date']}</p>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Inputs>
                                    <span>علت قطع همکاری
                                    <span className='dv-required-star'>*</span>
                                    </span>
                                    <textarea name="termination_of_cooperation_reason"
                                              value={inputs.termination_of_cooperation_reason} onChange={inputHandler}
                                              className={error['termination_of_cooperation_reason'] && 'afa-error-input'}
                                              id="termination_of_cooperation_reason" cols="30" rows="1"/>
                                    <p className='afa-error-text'>{error['termination_of_cooperation_reason']}</p>
                                </Inputs>
                            </div>

                            <div className="col-12">
                                <div className="row d-flex flex-column flex-sm-row justify-content-between">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>
                                        <Inputs>
                                            <button type='submit'>
                                                <span className='icon-add f-20'/>
                                                <span className='pr-3'>ثبت این صفحه</span>
                                            </button>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                                    onClick={goToNextPage}>
                                                <span>مرحله بعد</span>
                                                <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                            </button>
                                        </Inputs>
                                    </div>
                                </div>
                            </div>

                            {
                                show_add_more &&
                                <div className="col-12">
                                    <div className="row d-flex flex-column flex-sm-row justify-content-between">
                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>
                                            <Inputs>

                                                <button type='button' onClick={addMoreCollapse}
                                                        className='afa-add-more-btn'>

                                                    <span className='icon-add f-20'/>

                                                    <span className='pr-3'>افزودن جدید</span>

                                                </button>

                                            </Inputs>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                            <Inputs>
                                                <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                                        onClick={goToNextPage}>
                                                    <span>مرحله بعد</span>
                                                    <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                                </button>
                                            </Inputs>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                }
            </form>
        </>
    )
}

export default WorkExperience;