import React from "react";
import ReactExport from "react-export-excel";
import {getData} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../../assets/scripts/GeneralVariables";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class InsuranceExcelExport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
        }
    }

    async componentDidMount() {
        let obj = {};
        obj = this.props.filterData;
        obj['excel'] = 2
        let api_result = await getData(MAIN_URL, `hr-management/${this.props?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            this.setState({
                data: api_result?.data?.items,
            });
        }
    }

    render() {

        let users = [];

        if (this.state?.data?.length > 0) {
            for(let i = 0 ; i<this.state.data?.length ; i++){
                let obj = this.state?.data[i];
                obj = {...obj , count : parseInt(i + 1)}
                users.push(obj)
            }
        }
        return (
            <ExcelFile filename={'InsuranceInformation'}
                       dir="rtl"
                       element={<button className='afa-excel-export dv-main-btn mb-3'>
                           <span className='icon-excel afa-btn-icon'/>
                           <span className='afa-information-btn-title'>دانلود فایل</span>
                       </button>}>
                {
                    users &&
                    <ExcelSheet data={users} name="لیست بیمه شدگان">
                        <ExcelColumn label="ردیف" value='count'/>
                        <ExcelColumn label="نام" value='first_name'/>
                        <ExcelColumn label="نام خانوادگی" value='last_name'/>
                        <ExcelColumn label="نام پدر" value='father_name'/>
                        <ExcelColumn label="کد ملی بیمه شده" value='identification_code'/>
                        <ExcelColumn label="نام بیمه شده اصلی" value='original_insured_first_name'/>
                        <ExcelColumn label="نام خانوادگی بیمه شده اصلی" value='original_insured_last_name'/>
                        <ExcelColumn label="روز تولد" value='birth_day'/>
                        <ExcelColumn label="ماه تولد" value='birth_month'/>
                        <ExcelColumn label="تاریخ تولد" value='birth_year'/>
                        <ExcelColumn label="نسبت با بیمه شده" value='relation'/>
                        <ExcelColumn label="حذف یا اضافه" value='delete_or_add'/>
                        <ExcelColumn label="کد ملی بیمه شده اصلی" value='original_insured_identification_code'/>
                        <ExcelColumn label="نوع طرح" value={(col) => col.supplementary_insurance === 'عمومی' ? `بدون دندان` : col.supplementary_insurance === 'عمومی + دندانپزشکی' ?
                            `دندان` : ''}/>
                        <ExcelColumn label="طرح"
                                     value={(col) => col.supplementary_insurance === 'عمومی' ? `کارکنان شاغل وافراد تحت تکفل (طرح 2 بدون دندانپزشکی)` : col.supplementary_insurance === 'عمومی + دندانپزشکی' ?
                                         `کارکنان شاغل وافراد تحت تکفل (طرح 1 و طرح 2 دندان پزشکی)` : ''}/>
                        <ExcelColumn label="شماره شبا" value='shaba_id'/>
                        <ExcelColumn label="تلفن همراه" value='phone'/>
                    </ExcelSheet>
                }
            </ExcelFile>
        );
    }
}
