import styled from "styled-components";

const dark_green = '#379521';
const custom_gray = '#ECECEC';
const light_red = '#FC4F59';
const label_text = '#2F4858'
const main_color = '#E73444';
const main_btn_color = 'linear-gradient(263.94deg, #E73444 32.54%, #895193 109.18%)';
const main_btn_color_hover = 'linear-gradient(263.94deg, #E73444 100%, #895193 0%)';
const next_btn_color = '#16A596';
const stepper_color = '#844E8E';
const bg_gray = '#F6F6F6';

export const MainContent = styled.div`
  background-color: #fff;
  border-radius: 0;
  border: none;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.13);
  margin: 3rem;
  padding: 2rem;
  border-radius: 20px;


  @media (max-width: 992px) {
    box-shadow: none;
    padding: 1rem !important;
    margin: 1rem !important;
  }
  @media (max-width: 576px) {
    box-shadow: none;
    padding: 0.5rem;
  }

  .afa-edit-btn {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;

    span {
      color: ${main_color} !important;
      font-size: 16px;
    }

    span.icon-edit {
      font-size: 18px;
      margin-bottom: 0;
      padding-left: 0.5rem;
    }
  }

  .afa-profile-save-btn {
    width: 20%;
    display: block;
    margin: auto;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`

export const Inputs = styled.label`
  width: 100%;

  span {
    font-size: 14px;
    //margin-bottom: 0.6rem;
    color: ${label_text};
    font-weight: 500;
  }

  input, select, textarea {
    background-color: ${main_color};
    padding: 0.75rem 1rem;
    border-radius: 10px !important;
    width: 100%;
    color: #212121;
    border: none;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: #C2C2C2;
    }
  }

  button {
    background: ${main_btn_color};
    border-radius: 50px;
    width: 100%;
    height: 48px;
    border: none;
    margin-bottom: 1rem;
    position: relative;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: ${main_btn_color};
    }

    span {
      color: #fff;
    }

    @media (max-width: 576px) {
      justify-content: start;
      padding-right: 1rem;
    }
  }

`

export const ArticleList = styled.div`
  background: #F4F5F9;
  border-radius: 10px;
  border: none;
  padding: 1rem;
  margin-bottom: 1rem;
  span.dv-icon-delete{
    color: ${main_color};
    font-size: 24px;
    cursor: pointer;
  }
  div.afa-article-img{
    width: 90px;
    height: 107px;
    border-radius: 10px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  h6.afa-header-article{
    font-size: 16px;
    font-weight: 600;
  }
  p.afa-text-p{
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }
  @media (max-width: 576px) {
    div.afa-article-img{
      width: 170px;
      height: 170px;
      img{
        width: 100%;
        height: 100%;
        //object-fit: contain;
      }
    }
  }
`