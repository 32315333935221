import React, {useState} from 'react';
import {LoginInput} from "./styles";
import {checkShMelli, fixNumber, getData} from "../../assets/scripts/GeneralFunctions";
import Swal from "sweetalert2";
import {useHistory, useLocation} from "react-router";
import {MAIN_URL} from "../../assets/scripts/GeneralVariables";
import {LoopCircleLoading} from 'react-loadingg';

function LegalPerson(props) {
    const [error, setError] = useState({})
    const history = useHistory()
    let location = useLocation();
    const [data, setData] = useState({sh_value: location.state?.entity_identification_code})
    const [loader, setLoader] = useState(false)
    const formHandler = async (e) => {
        e.preventDefault()

        if (checkShMelli(data.sh_value)) {
            setLoader(true)
            let api_result = await getData(MAIN_URL, "sign-in", 'post', {
                type: 'حقوقی',
                entity_identification_code: data.sh_value
            }, null, false);
            if (api_result?.status === 200) {
                setLoader(false)
                if (api_result.data['action'] === 'verify') {
                    history.push({
                        pathname: '/verify_code',
                        state: {
                            time: api_result.data['remaining'],
                            user_type: 'legal',
                            mobile: api_result.data['mobile'],
                            entity_identification_code: data.sh_value
                        }
                    })
                } else {
                    history.push({
                        pathname: '/legal_register',
                        user_type: 'legal',
                        state: {entity_identification_code: data.sh_value}
                    })
                }
            }
        } else {
            setLoader(false)
            let error_result = true
            let error = {};
            error['entity_identification_code'] = 'شناسه ملی وارد شده معتبر نمی باشد'
            error_result = false

            setError(error)
            return error_result
        }
    }
    return (
        <>
            <LoginInput onSubmit={formHandler}>
                <span>شناسه ملی</span>
                <input className='text-left mb-3' dir='ltr' type="number" autoFocus name='code' value={data.sh_value}
                       onChange={(e) => setData(prevState => ({...prevState, sh_value: fixNumber(e.target.value)}))}
                       placeholder={'0000000000'}/>
                <p className='afa-error-text'>{error['entity_identification_code']}</p>
                <div className="position-relative w-100">
                    <button type='submit' className='w-100' disabled={data.sh_value?.length > 0 ? false : true}>ادامه
                    </button>
                    {
                        loader && <LoopCircleLoading/>
                    }
                </div>
            </LoginInput>
        </>
    );
}

export default LegalPerson;