import React, {useEffect, useState} from 'react';
import {PanelContent, TopInformation} from "./style";
import {Modal, ProgressBar} from "react-bootstrap";
import {Stepper} from '@progress/kendo-react-layout';
import {getData, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../assets/scripts/GeneralVariables";
import UserInformation from "./ResourcesChild/UserInformation";
import FamilyInformation from "./ResourcesChild/FamilyInformation";
import EdcInformation from "./ResourcesChild/EdcInformation";
import WorkInformation from "./ResourcesChild/WorkInformation";
import LanguageInformation from "./ResourcesChild/LanguageInformation";
import SkillsInformation from "./ResourcesChild/SkillsInformation";
import CertificateInformation from "./ResourcesChild/CertificateInformation";
import SoftwareInformation from "./ResourcesChild/SoftwareInformation";
import DocumentsInformations from "./ResourcesChild/DocumentsInformations";
import {toast} from "react-toastify";
import Notices from "./ResourcesChild/Notices";

const items = [{
    label: 'اطلاعات فردی',
    icon: 'icon-steper_user'
}, {
    label: 'اعضای خانواده',
    icon: 'icon-steper_users'
}, {
    label: ' سوابق تحصیلی ',
    icon: 'icon-steper_tahsil'
}, {
    label: 'سوابق شغلی',
    icon: 'icon-steper_job',
}, {
    label: 'زبان های خارجی',
    icon: 'icon-steper_lanquage'
},
    {
        label: 'مهارت ها',
        icon: 'icon-steper_maharat'
    },
    {
        label: 'گواهی ها',
        icon: 'icon-steper_certificate'
    },
    {
        label: 'نرم افزار ها',
        icon: 'icon-steper_software'
    },
    {
        label: 'اپلود مدارک',
        icon: 'icon-steper_upload'
    },
    {
        label: 'اطلاع رسانی',
        icon: 'icon-skills'
    }
];

function HumanResourcesDetails(props) {
    let user_id = props.match.params.user_id;
    const [stepper_value, setValue] = useState(0);
    const [modal, setModal] = useState({show_user_type: false});
    const [inputs, setInput] = useState({
        is_personnel: 1,
        description: '',
        card_id: '',
        personnel_id: '',
        units: [],
        companies :[],
        company : 'selected',
        unit: 'انتخاب کنید',
        identification_code: '',
        full_name: '',
        mobile: '',
        pending_interview_modal: false,
        rejection_interview: '',
        pending_interview_id: '',
        is_denied : 0,
        denied_description : '',
    });
    const [error, setError] = useState({})
    useEffect(async () => {
        setTitle('آتاویتا | منابع انسانی')
        let api_result = await getData(MAIN_URL, `hr-management/info/${user_id}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            let user = api_result?.data?.user
            setInput(prevState => ({
                ...prevState,
                full_name: `${user?.first_name} ${user?.last_name}`,
                identification_code: user?.identification_code,
                mobile: user?.mobile,
                units: api_result?.data?.units,
                companies: api_result?.data?.companies,
                card_id: user?.card_id,
                unit: user?.unit_id ? user?.unit_id : 'انتخاب کنید',
                company: user?.unit ? user?.unit?.company_id : 'selected',
                personnel_id: user?.personnel_id,
                is_personnel: user?.is_personnel,
                is_denied: user?.is_denied,
                denied_description: user?.denied_description,
            }))
        }
    }, []);

    const companiesHandler = async (e) => {
        let company = e.target.value;
        let api_result = await getData(MAIN_URL, `hr-management/units/${company}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            setInput(prevState => ({
                ...prevState,
                units: api_result?.data?.units,
                unit: 'انتخاب کنید',
                company
            }))
        }
    }

    const handleChange = e => {
        setValue(e.value);
    };

    // user type
    const userType = () => {
        setModal(prevState => ({...prevState, show_user_type: true}))
    }
    const userTypeFormHandler = async (e) => {
        e.preventDefault()
        let formData = {};
        formData['is_personnel'] = inputs?.is_personnel;
        if (inputs?.is_personnel == 1) {
            formData['unit'] = inputs?.unit;
            formData['personnel_id'] = inputs?.personnel_id;
            formData['card_id'] = inputs?.card_id;
        }else{
            setInput(prevState => ({...prevState , unit : 'انتخاب کنید' , company: 'selected' , card_id: '' , personnel_id: ''}))
        }
        let api_result = await getData(MAIN_URL, `hr-management/personnel-info/edit/${user_id}`, 'post', formData, true, true);
        if (api_result?.status === 200) {
            setModal(prevState => ({...prevState, show_user_type: false}))
            setError('')
            toast.success('با موفقیت تغییر یافت')
        } else if (api_result?.status === 400) {
            setError(api_result?.error)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }
    const closeModal = () => {
        setModal({show_user_type: false})
    }
    const inputHandler = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const pendingInterview = async (e) => {
        setInput(prevState => ({
            ...prevState,
            pending_interview_modal: true,
        }))
    }
    const rejectionInterviewFormHandler = async(e) => {
        e.preventDefault()

        let api_result = await getData(MAIN_URL, `hr-management/denied-status/${user_id}`, 'post', {
            is_denied: 1,
            denied_description : inputs?.denied_description
        }, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            console.log(data)
            setInput(prevState => ({...prevState, pending_interview_modal: false}))
        }
    }

    const closeInterviewModal = () => {
        setInput(prevState => ({...prevState, pending_interview_modal: false}))
    }
    return (
        <>
            <TopInformation>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex align-items-center">
                        <div>
                            <span className='afa-title'>نام و نام خانوادگی : </span>
                            <span className="afa-value">{inputs?.full_name ? inputs?.full_name : '- - -'}</span>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex align-items-center">
                        <div>
                            <span className='afa-title'>کد ملی : </span>
                            <span
                                className="afa-value">{inputs?.identification_code ? inputs?.identification_code : '- - -'}</span>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex align-items-center">
                        <div>
                            <span className='afa-title'>شماره تماس : </span>
                            <span className="afa-value">{inputs?.mobile ? inputs?.mobile : '- - -'}</span>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-3 d-flex flex-column flex-sm-row align-items-center justify-content-center">
                        {
                            inputs?.is_denied == 0 &&
                            <button className={'afa-excel-export'} onClick={pendingInterview}>
                                <span className='afa-information-btn-title'>رد از مصاحبه</span>
                            </button>
                        }
                        <button className={'afa-excel-export'} onClick={userType}>
                            <span className='afa-information-btn-title'>اطلاعات پرسنلی</span>
                        </button>
                    </div>

                    {
                        inputs?.denied_description &&
                        <div className="col-12 mb-3">
                            <span className='afa-title'>دلیل رد از <strong className={'afa-text-red'}>مصاحبه</strong> : </span>
                            <span className={'afa-value'}>{inputs?.denied_description}</span>
                        </div>
                    }
                </div>
            </TopInformation>
            <PanelContent>
                <div className="d-flex flex-row-reverse flex-lg-column">
                    <Stepper className='mb-3' value={stepper_value} onChange={handleChange}
                             orientation={window.innerWidth > 992 ? 'horizontal' : 'vertical'} items={items}/>
                    <div className="afa-size-parent">
                        {
                            stepper_value === 0 &&
                            <UserInformation user_id={user_id}/>
                        }
                        {
                            stepper_value === 1 &&
                            <FamilyInformation user_id={user_id}/>
                        }
                        {
                            stepper_value === 2 &&
                            <EdcInformation user_id={user_id}/>
                        }
                        {
                            stepper_value === 3 &&
                            <WorkInformation user_id={user_id}/>
                        }
                        {
                            stepper_value === 4 &&
                            <LanguageInformation user_id={user_id}/>
                        }
                        {
                            stepper_value === 5 &&
                            <SkillsInformation user_id={user_id}/>
                        }
                        {
                            stepper_value === 6 &&
                            <CertificateInformation user_id={user_id}/>
                        }
                        {
                            stepper_value === 7 &&
                            <SoftwareInformation user_id={user_id}/>
                        }
                        {
                            stepper_value === 8 &&
                            <DocumentsInformations user_id={user_id}/>
                        }
                        {
                            stepper_value === 9 &&
                            <Notices user_id={user_id}/>
                        }
                    </div>
                </div>
            </PanelContent>

            <Modal style={{textAlign: 'center'}} className='afa-user-status-modal' centered={true}
                   show={modal?.show_user_type}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={userTypeFormHandler}>
                        <div className="col-12 d-flex align-items-center mb-4">
                            <span style={{marginLeft: '1rem'}}>نوع کاربر : </span>
                            <div className="d-flex align-items-center">
                                <label className="afa-custom-checkbox ml-4 mb-0">
                                    پرسنل
                                    <input type="radio" value={1}
                                           onChange={inputHandler}
                                           checked={inputs[`is_personnel`] == 1}
                                           name={`is_personnel`}/>
                                    <span className="checkmark"/>
                                </label>
                                <label className="afa-custom-checkbox ml-4 mb-0">
                                    غیر پرسنل
                                    <input type="radio" value={0}
                                           onChange={inputHandler}
                                           checked={inputs[`is_personnel`] == 0}
                                           name={`is_personnel`}/>
                                    <span className="checkmark"/>
                                </label>
                            </div>
                        </div>
                        {
                            inputs[`is_personnel`] == 1 &&
                            <>
                                <div className="mb-3 d-flex flex-column flex-sm-row">
                                    <div className="d-flex flex-column w-100 w-md-50 pl-md-2">
                                        <span className='k-text-right'>شماره کارت تردد</span>
                                        <input type="text" maxLength={8} className='afa-top-inputs ml-2' placeholder='شماره کارت تردد'
                                               name='card_id' value={inputs?.card_id} onChange={inputHandler}/>
                                        <p className='afa-error-text'>{error[`card_id`]}</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 w-md-50">
                                        <span className='k-text-right'>کد پرسنلی</span>
                                        <input type="text" className='afa-top-inputs ml-2' placeholder='کد پرسنلی'
                                               name='personnel_id' value={inputs?.personnel_id}
                                               onChange={inputHandler}/>
                                        <p className='afa-error-text'>{error[`personnel_id`]}</p>
                                    </div>
                                </div>
                                <div className="mb-3 d-flex flex-column flex-sm-row flex-wrap">
                                    <div className="d-flex flex-column w-100 w-md-50 pl-md-2">
                                        <span className='k-text-right'>شرکت</span>
                                        <select className='afa-top-inputs' name='company' value={inputs?.company}
                                                onChange={companiesHandler}>
                                            <option value='selected' disabled={true}>انتخاب کنید</option>
                                            {
                                                inputs?.companies?.map((row, i) => (
                                                    <option value={row.id} key={i}>{row?.name}</option>
                                                ))
                                            }
                                        </select>
                                        <p className='afa-error-text'>{error[`company`]}</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 w-md-50 pl-md-2">
                                        <span className='k-text-right'>واحد</span>
                                        <select className='afa-top-inputs' name='unit' value={inputs?.unit}
                                                onChange={inputHandler}>
                                            <option value='انتخاب کنید' disabled={true}>انتخاب کنید</option>
                                            {
                                                inputs?.units?.map((row, i) => (
                                                    <option value={row.id} key={i}>{row?.title}</option>
                                                ))
                                            }
                                        </select>
                                        <p className='afa-error-text'>{error[`unit`]}</p>
                                    </div>
                                </div>
                            </>
                        }
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            <Modal style={{textAlign: 'center'}} centered={true} show={inputs?.pending_interview_modal}
                   onHide={closeInterviewModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={rejectionInterviewFormHandler}>

                        <div className="col-12 d-flex flex-column">
                            <span className='k-text-right'>علت رد از مصاحبه</span>
                            <textarea rows='3' className='afa-top-inputs ml-2' placeholder='علت رد از مصاحبه'
                                      name='denied_description' value={inputs?.denied_description}
                                      onChange={inputHandler}/>
                            <p className='afa-error-text'>{error[`denied_description`]}</p>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeInterviewModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default HumanResourcesDetails;