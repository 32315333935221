import React, {useEffect, useState} from 'react';
import {PanelContent, Rows, SearchInput, CustomTabs} from "./style";
import {getData, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../assets/scripts/GeneralVariables";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";

import Skeleton from 'react-loading-skeleton'

function SuppliersList(props) {

    const [inputs, setInputs] = useState({
        search_value: '',
        status: 'pending',

        legal_list: ['سهامی عام', 'سهامی خاص', 'مسئولیت محدود', 'سایر'],
        legal_list_selected: [],
        ownership_list: ['دولتی', 'خصوصی', 'تحت پوشش', 'خارجی'],
        ownership_type_selected: [],
        supply_type_list: ['شرکت اصلی', 'نمایندگی'],
        supply_type_selected: [],
        supplier_bachelor_type_list: ['گواهی بین المللی', 'لیسانس', 'گواهی ملی'],
        supplier_bachelor_type_selected: [],
        distance_from_afa_list: ['تا 50 کیلومتر', '50 تا 300 کیلومتر', 'بیشتر از 300 کیلومتر'],
        distance_from_afa_selected: [],
        supplier_financial_bank_guarantee_list: ['1', '0'],
        supplier_financial_bank_guarantee_selected: [],

        pending_count: 0,
        denied_count: 0,
        completed_count: 0,
        items: [NaN, NaN, NaN, NaN, NaN],
        show_excel_download: false,
        services: []
    })
    const [user_id, setId] = useState(null)
    const [is_all_items_load, setAllLoad] = useState(true)
    const [formData, setFormData] = useState({
        'limit': 30,
        'offset': 0
    })
    let show_details_with_state = useSelector(state => state.state.show_information)
    let show_details = useSelector(state => state.show_information)
    let show = show_details ? show_details : show_details_with_state

    useEffect(async () => {
        setTitle('آتاویتا | تامین کنندگان')
        window.scroll(0,0)
        let api_result = await getData(MAIN_URL, `supplier-management/${inputs?.status}`, 'get', formData, true, false);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                services: data?.services,
                items: data?.items,
            }))
        }
    }, [inputs?.status]);

    const searchHandler = async (e) => {
        let obj = {};
        obj = formData;
        obj['search'] = e.target.value
        setInputs(prevState => ({
            ...prevState,
            search_value: e.target.value
        }))
        let api_result = await getData(MAIN_URL, `supplier-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                services: data?.services,
                items: data?.items,
            }))
            setFormData(obj)
        }
    }

    const CheckBoxHandler = async (value, title) => {
        let selected = [];
        let obj = {};
        obj = formData;

        for (let i = 0; i < inputs?.[`${title}_selected`]?.length; i++) {
            delete obj[`${title}[${i}]`];
        }

        if (inputs?.[`${title}_selected`]?.length > 0) {
            selected = inputs?.[`${title}_selected`]
        }

        let find = selected?.indexOf(value)

        if (find > -1) {
            selected?.splice(find, 1)
        } else {
            selected?.push(value)
        }

        for (let i = 0; i < selected?.length; i++) {
            obj[`${title}[${i}]`] = selected[i]
        }
        let api_result = await getData(MAIN_URL, `supplier-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                services: data?.services,
                items: data?.items,
                [`${title}_selected`]: selected
            }))
            setFormData(obj)
        }
    }

    const serviceCheckBoxHandlerSelected = async (value, selectedItem) => {
        let selected = [];
        let obj = {};
        obj = formData;
        let title = 'services'
        for (let i = 0; i < inputs?.[`${title}_selected`]?.length; i++) {
            delete obj[`${title}[${i}]`];
        }

        if (inputs?.[`${title}_selected`]?.length > 0) {
            selected = inputs?.[`${title}_selected`]
        }

        selected?.push(selectedItem.id)

        for (let i = 0; i < selected?.length; i++) {
            obj[`${title}[${i}]`] = selected[i]
        }
        let api_result = await getData(MAIN_URL, `supplier-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                services: data?.services,
                items: data?.items,
                [`${title}_selected`]: selected
            }))
            setFormData(obj)
        }
    }

    const serviceCheckBoxHandlerRemove = async (value, removedItem) => {
        let selected = [];
        let obj = {};
        obj = formData;
        let title = 'services'

        for (let i = 0; i < inputs?.[`${title}_selected`]?.length; i++) {
            delete obj[`${title}[${i}]`];
        }

        if (inputs?.[`${title}_selected`]?.length > 0) {
            selected = inputs?.[`${title}_selected`]
        }

        let find = selected?.indexOf(removedItem.id)

        selected?.splice(find, 1)

        for (let i = 0; i < selected?.length; i++) {
            obj[`${title}[${i}]`] = selected[i]
        }
        let api_result = await getData(MAIN_URL, `supplier-management/${inputs?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                pending_count: data?.pending_count,
                denied_count: data?.denied_count,
                completed_count: data?.completed_count,
                services: data?.services,
                items: data?.items,
                [`${title}_selected`]: selected
            }))
            setFormData(obj)
        }
    }

    const showTabItems = async (name) => {
        setInputs(prevState => ({...prevState, status: name}))
    }

    // lazy load
    const getTableDataLazyLoad = async (obj_data) => {
        if (parseFloat(obj_data.target.offsetHeight + obj_data.target.scrollTop) + 1 >= parseFloat(obj_data.target.scrollHeight)) {
            if (inputs?.items?.length % 30 === 0 && is_all_items_load === true) {

                let obj = {};
                obj = formData;
                obj['offset'] = inputs?.items?.length
                let moreData = await getData(MAIN_URL, `supplier-management/${inputs?.status}`, 'get', obj, true, true);
                if (moreData) {
                    setFormData(obj)
                    setInputs(prevState => ({...prevState, items: inputs?.items.concat(moreData.data?.items)}))
                    if (moreData?.data.items?.length < 30) {
                        setAllLoad({is_all_items_load: false})
                    }
                }
            }
        }
    }

    return (
        <PanelContent>
            <div className="w-100 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                {/*Search*/}
                <SearchInput className='mb-3'>
                    <span className='icon-Search dv-search-icon'/>
                    <input type="search" value={inputs?.search_value} onChange={searchHandler} placeholder='جستجو'/>
                </SearchInput>
                <div className="d-flex flex-column flex-sm-row align-items-center">
                    {/*Filter*/}
                    <Accordion className='dv-filter-drop-down mb-3' allowZeroExpanded>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="d-flex align-items-center">
                                        <span>فیلتر</span>
                                        <span className="icon-Filter dv-filter-icon"/>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <Accordion className='dv-filter-drop-down-items' allowZeroExpanded>

                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>خدمات</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <Multiselect

                                                options={inputs?.services} // Options to display in the dropdown
                                                // selectedValues={inputs?.selected_supply_list} // Preselected value to persist in dropdown
                                                onSelect={serviceCheckBoxHandlerSelected} // Function will trigger on select event
                                                onRemove={serviceCheckBoxHandlerRemove} // Function will trigger on remove event
                                                displayValue="title" // Property name to display in the dropdown options
                                                placeholder={'انتخاب کنید'}
                                            />
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>نوع مالکیت</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                {
                                                    inputs?.ownership_list?.map((item, index) => (
                                                        <label className="afa-custom-checkbox"
                                                               key={index}>{item}
                                                            <input type="checkbox" name='ownership_type'
                                                                   value={item}
                                                                   onChange={() => CheckBoxHandler(item, 'ownership_type')}
                                                                   checked={inputs.ownership_type_selected?.includes(item)}
                                                            />
                                                            <span className="checkmark2"></span>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>شیوه تامین</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                {
                                                    inputs?.supply_type_list?.map((item, index) => (
                                                        <label className="afa-custom-checkbox"
                                                               key={index}>{item}
                                                            <input type="checkbox" name='supply_type'
                                                                   value={item}
                                                                   onChange={() => CheckBoxHandler(item, 'supply_type')}
                                                                   checked={inputs.supply_type_selected?.includes(item)}
                                                            />
                                                            <span className="checkmark2"></span>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>مسافت از اذرفولاد</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                {
                                                    inputs?.distance_from_afa_list?.map((item, index) => (
                                                        <label className="afa-custom-checkbox"
                                                               key={index}>{item}
                                                            <input type="checkbox" name='distance_from_afa'
                                                                   value={item}
                                                                   onChange={() => CheckBoxHandler(item, 'distance_from_afa')}
                                                                   checked={inputs.distance_from_afa_selected?.includes(item)}
                                                            />
                                                            <span className="checkmark2"></span>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>نوع گواهی</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                {
                                                    inputs?.supplier_bachelor_type_list?.map((item, index) => (
                                                        <label className="afa-custom-checkbox"
                                                               key={index}>{item}
                                                            <input type="checkbox" name='supplier_bachelor_type'
                                                                   value={item}
                                                                   onChange={() => CheckBoxHandler(item, 'supplier_bachelor_type')}
                                                                   checked={inputs.supplier_bachelor_type_selected?.includes(item)}
                                                            />
                                                            <span className="checkmark2"/>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem className='mb-3'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>ارایه ضمانت نامه بانکی</span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="d-flex flex-column">
                                                {
                                                    inputs?.supplier_financial_bank_guarantee_list?.map((item, index) => (
                                                        <label className="afa-custom-checkbox"
                                                               key={index}>{item === '1' ? 'بله' : 'خیر'}
                                                            <input type="checkbox"
                                                                   name='supplier_financial_bank_guarantee'
                                                                   value={item}
                                                                   onChange={() => CheckBoxHandler(item, 'supplier_financial_bank_guarantee')}
                                                                   checked={inputs.supplier_financial_bank_guarantee_selected?.includes(item)}
                                                            />
                                                            <span className="checkmark2"/>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                </Accordion>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            {/*Tabs*/}
            <CustomTabs>
                <div className="afa-tab-list">
                    <div
                        className={inputs?.status === 'pending' ? "d-flex align-items-center afa-tab-title active" : "d-flex align-items-center afa-tab-title"}
                        onClick={() => showTabItems('pending')}>
                        <span>در انتظار بررسی</span>
                        <div className="afa-number-tabs">{inputs?.pending_count}</div>
                    </div>
                    <div
                        className={inputs?.status === 'completed' ? "d-flex align-items-center afa-tab-title active" : "d-flex align-items-center afa-tab-title"}
                        onClick={() => showTabItems('completed')}>
                        <span>تایید شده ها</span>
                        <div className="afa-number-tabs">{inputs?.completed_count}</div>
                    </div>
                    <div
                        className={inputs?.status === 'denied' ? "d-flex align-items-center afa-tab-title active" : "d-flex align-items-center afa-tab-title"}
                        onClick={() => showTabItems('denied')}>
                        <span>رد شده ها</span>
                        <div className="afa-number-tabs">{inputs?.denied_count}</div>
                    </div>
                </div>
                <div className='afa-lazyload' style={{overflowX: 'auto'}} onScroll={getTableDataLazyLoad}>
                    <Rows>
                        <tbody>
                        {
                            inputs?.items?.length > 0 ?
                                !inputs?.items[0] ?
                                    inputs?.items?.map((row, index) => (
                                        <tr key={index}>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                        </tr>
                                    )) :
                                inputs?.items?.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span className='pl-2 afa-tbody-text'>{parseInt(index) + 1} - </span>
                                            {/*<span>نام و نام خانوادگی :</span>*/}
                                            {
                                                (row?.supplier_info?.supplier_first_name && row?.supplier_info?.supplier_last_name) ?
                                                    <span>{`${row?.supplier_info?.supplier_first_name} ${row?.supplier_info?.supplier_last_name}`}</span> :
                                                    <span className="afa-value"
                                                          style={{verticalAlign: 'text-top'}}><span
                                                        className='icon-Info-Circle afa-info-circle'
                                                        title={'فیلد مورد نظر خالی است'}/></span>
                                            }
                                        </td>
                                        <td>
                                            <span>کد ملی :</span>
                                            {row?.identification_code ?
                                                <span>{row?.identification_code}</span> :
                                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                    className='icon-Info-Circle afa-info-circle'
                                                    title={'فیلد مورد نظر خالی است'}/></span>
                                            }
                                        </td>
                                        <td>
                                            <span>شماره تماس :</span>
                                            {row?.mobile ?
                                                <span>{row?.mobile}</span> :
                                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                    className='icon-Info-Circle afa-info-circle'
                                                    title={'فیلد مورد نظر خالی است'}/></span>
                                            }
                                        </td>
                                        <td>
                                            <Link className={'text'} to={`/manage-supplier/${row.id}`}>مشاهده<span
                                                className="icon-cheveron-left"></span></Link>
                                        </td>
                                    </tr>
                                )) : <tr>
                                    <td>آیتمی وجود ندارد</td>
                                </tr>
                        }
                        </tbody>
                    </Rows>
                </div>
            </CustomTabs>

        </PanelContent>
    );
}

export default SuppliersList;