import React, {useEffect, useState} from 'react';
import {
    checkCodeMelli,
    fileUpload,
    getData,
    just_persian,
    fixNumber, getFormDataPost, just_number
} from "../../../../../assets/scripts/GeneralFunctions";
import {
    FileInputs,
    Inputs,
    LargeFileInputs,
    LeftIcon,
    TabStatusComplete,
    TabStatusContent,
    TabStatusNotComplete
} from "./style";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import Swal from "sweetalert2";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {toast} from "react-toastify";

function Certificates(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        certificate: 'yes',
        certificate_type: [
            "پایه1",
            "پایه2",
            "پایه3",
            "تراکتور",
            "لودر",
            "لیفتراک",
            "بیل مکانیکی",
            "جرثقیل"
        ],
        selected: [], duration: '', year: null, is_show_more: false, certificate_image: null,
        certificates: [], drivers_licence: null, document_uploading: null, document: null,
        course: '', location: '', created_certificates: [], edit_items: []
    })
    const [inputs2, setInputs2] = useState({
        certificate: 'yes', duration: '', year: null,
        course: '', location: '', document_uploading: null, document: null
    })
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [uploading_docs, setUploadingDocs] = useState([])
    const [uploading_preview_docs, setUploadingPreviewDocs] = useState([])
    const [remove_id, setRemoveId] = useState({})
    const [is_render, setRender] = useState(false);
    const [new_collapse, setNewCollapse] = useState(false);
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});
    let docsObj = [];
    let fileArray = [];

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/certifications", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let certificates = data?.certificates
            if (certificates?.length === 0) {
                setInputs(prevState => ({
                    ...prevState,
                    certificates: [],
                    drivers_licence: data?.drivers_licence,
                    selected: data?.drivers_licence?.licences
                }))
            } else {
                setInputs(prevState => ({
                    ...prevState,
                    drivers_licence: data?.drivers_licence,
                    certificates: certificates,
                    selected: data?.drivers_licence?.licences
                }))
            }
            setMoreData({sheet_status: data?.sheet_status,status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);


    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const checkBoxHandler = (id) => {
        let selected = [];
        if (inputs?.selected?.length > 0) {
            selected = inputs?.selected
        }
        let find = selected?.indexOf(id)

        if (find > -1) {
            selected?.splice(find, 1)
        } else {
            selected?.push(id)
        }
        setInputs(prevState => ({...prevState, selected: selected}))
    }

    const removeHandlerDocs = (id) => {
        setRemoveId(prevState => ({
            ...prevState, remove_doc_id: id
        }))
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این گواهینامه را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                // let remove_result = await getData(MAIN_URL, `account/portfolio/remove/${id}`, 'get', {}, token, false);
                // if (remove_result?.status === 200) {
                swalWithBootstrapButtons.fire(
                    'حذف شد!',
                    'گواهینامه مورد نظر شما با موفقیت حذف شد',
                    'success'
                )
                // setRender(!is_render)
                // }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    'گواهینامه مورد نظر شما بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let file_upload = await fileUpload(files[0])
        setInputs(prevState => ({
            ...prevState, [e.target.name]: file, document: file_upload
        }))
    }

    const saveNewCollapse = () => {
        let obj = {};
        let arr = [];
        arr = inputs?.created_certificates
        obj['course'] = inputs['course'];
        obj['location'] = inputs['location'];
        obj['year'] = inputs['year'];
        obj['duration'] = inputs['duration'];
        obj['document'] = inputs['document'];
        obj['document_uploading'] = inputs['document_uploading'];
        if (inputs['document_uploading']) {
            obj['certificate'] = 'yes';
        } else {
            obj['certificate'] = 'no';
        }
        arr.push(obj)
        toast.success('با موفقیت به لیست اولیه اضافه شد')
        setInputs(prevState => ({
            ...prevState, certificate: 'no', course: '', created_certificates: arr, is_show_more: true,
            location: '', document_uploading: '', year: null, duration: '',
        }))
        // setNewCollapse(false);
    }

    // open from new collapse list and edit and save without api
    const changeArrowNewCollapseList = (index, i) => {
        if (arrow_item.arrow_id === index && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: index, arrow_status: false})

        } else if (arrow_item.arrow_id === index && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: index, arrow_status: true})

        } else {
            setArrowItem({arrow_id: index, arrow_status: true})
        }

        let data = created_certificates[i]
        if (data) {
            setInputs(prevState => ({
                ...prevState,
                [`certificate[${i}]`]: data.certificate,
                [`document[${i}]`]: data.document,
                [`course[${i}]`]: data.course,
                [`location[${i}]`]: data.location,
                [`document_uploading[${i}]`]: data.document_uploading,
                [`year[${i}]`]: data.year,
                [`duration[${i}]`]: data.duration
            }))
        }
    }
    const removeHandlerDocs1 = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این عکس را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = created_certificates[id];
                data[`document_uploading[${id}]`] = null;
                data[`document_uploading`] = null;
                data[`document[${id}]`] = null;
                data[`document`] = null;
                setInputs(prevState => ({
                    ...prevState,
                    created_certificates: created_certificates,
                    [`document_uploading[${id}]`]: null,
                    [`document[${id}]`]: null
                }))
                swalWithBootstrapButtons.fire(
                    'حذف شد!',
                    'با موفقیت حذف شد',
                    'success'
                )

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }
    const saveEditNewCollapse = async (inx) => {
        let arr = inputs?.created_certificates;
        let item = arr[inx]
        item['course'] = inputs[`course[${inx}]`];
        item['location'] = inputs[`location[${inx}]`];
        item['year'] = inputs[`year[${inx}]`];
        item['duration'] = inputs[`duration[${inx}]`];
        item['document'] = inputs[`document[${inx}]`];
        item['document_uploading'] = inputs[`document_uploading[${inx}]`];
        if (inputs[`document_uploading[${inx}]`]) {
            item['certificates'] = 'yes';
        } else {
            item['certificates'] = 'no';
        }
        toast.success('تغییرات اولیه با موفقیت ثبت شد')
        setInputs(prevState => ({...prevState, created_certificates: arr}))
    }

    // add new collapse for collaspe list with out api
    const addNewCollapseForList = () => {
        setNewCollapse(true);
        setInputs(prevState => ({
            ...prevState, certificate: 'no', course: '',
            location: '', document_uploading: '', year: null, duration: '', is_show_more: true
        }))
    }
    const changeArrowNewCollapses = () => {
        console.log('')
    }
    const thisUploadDocs2 = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let file_upload = await fileUpload(files[0])
        setInputs2(prevState => ({
            ...prevState, [e.target.name]: file, [`document`]: file_upload
        }))
    }
    const removeHandlerDocs2 = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این گواهینامه را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                // let remove_result = await getData(MAIN_URL, `account/portfolio/remove/${id}`, 'get', {}, token, false);
                // if (remove_result?.status === 200) {
                setInputs(prevState => ({
                    ...prevState, document_uploading: null, document: null
                }))
                swalWithBootstrapButtons.fire(
                    'حذف شد!',
                    'گواهینامه مورد نظر شما با موفقیت حذف شد',
                    'success'
                )
                // setRender(!is_render)
                // }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    'گواهینامه مورد نظر شما بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }
    const addNewCollapse = () => {
        setNewCollapse(false)
        let obj = {};
        let arr = [];
        arr = inputs?.created_certificates
        obj['course'] = inputs2[`course`];
        obj['location'] = inputs2[`location`];
        obj['year'] = inputs2[`year`];
        obj['duration'] = inputs2[`duration`];
        obj['document'] = inputs2[`document`];
        obj['document_uploading'] = inputs2[`document_uploading`];
        if (inputs2[`document_uploading`]) {
            obj['certificate'] = 'yes';
        } else {
            obj['certificate'] = 'no';
        }
        arr.push(obj)
        toast.success('با موفقیت به لیست اولیه اضافه شد')
        setInputs(prevState => ({
            ...prevState, created_certificates: arr,
        }))
        setInputs2(prevState => ({
            ...prevState, certificate: 'no', course: '', document: '',
            location: '', document_uploading: '', year: null, duration: '',
        }))
    }

    // remove a collapse for collaspe list with out api
    const removeCollapseFromCreatedList = (i) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (inputs?.created_certificates?.length === 1 && i === 0) {
                    setInputs(prevState => ({...prevState, created_certificates: []}))
                } else {
                    let arr = inputs?.created_certificates?.splice(i, 1);
                    setInputs(prevState => ({...prevState, created_certificates: arr}))
                }
                swalWithBootstrapButtons.fire(
                    'حذف شد!',
                    ' با موفقیت حذف شد',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    'بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }
    const removeNewCollapse = () => {
        setNewCollapse(false)
        setInputs(prevState => ({
            ...prevState,
            document: null,
            document_uploading: null,
            duration: '',
            year: '',
            location: '',
            course: '',
            certificate: 'no'
        }))
    }

    // Main list get from api
    const changeArrow = (id, i) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = certificates[i]

        if (data) {
            setInputs(prevState => ({
                ...prevState,
                [`certificate[${i}]`]: data.certificate ? 'yes' : 'no',
                [`course[${i}]`]: data.course,
                [`location[${i}]`]: data.location,
                [`document[${i}]`]: null,
                [`document_uploading[${i}]`]: null,
                [`year[${i}]`]: data.year,
                [`duration[${i}]`]: data.duration,
                [`certificate_image[${i}`]: data.certificate
            }))
        }
    }
    const removeCollapse = (id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {

                let formData = new FormData()
                formData.append(`remove_certificates[0]`, id)

                let api_result = await getFormDataPost('profile/certifications', formData)

                if (api_result?.status === 200) {
                    setError('')
                    setRender(!is_render)
                } else if (api_result?.status === 400) {
                    setError(api_result?.data)
                }


                swalWithBootstrapButtons.fire(
                    'حذف شد!',
                    ' با موفقیت حذف شد',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    'بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }
    const removeHandlerDocs12 = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این عکس را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                certificates[id][`document_uploading[${id}]`] = null;
                certificates[id][`document_uploading`] = null;
                certificates[id][`document[${id}]`] = null;
                certificates[id][`document`] = null;
                setInputs(prevState => ({
                    ...prevState,
                    certificates: certificates
                }))
                swalWithBootstrapButtons.fire(
                    'حذف شد!',
                    'با موفقیت حذف شد',
                    'success'
                )

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }
    const saveEditCollapse = async (inx, id) => {

        let arr = inputs?.edit_items;
        let item = {}

        item['course'] = inputs[`course[${inx}]`];
        item['location'] = inputs[`location[${inx}]`];
        item['year'] = inputs[`year[${inx}]`];
        item['duration'] = inputs[`duration[${inx}]`];
        item['document'] = inputs[`document`];
        item['document_uploading'] = inputs[`document_uploading[${inx}]`];
        item['id'] = id;
        if (inputs[`document_uploading[${inx}]`]) {
            item['certificate'] = 'yes';
        } else {
            item['certificate'] = 'no';
        }

        if (arr?.length > 0) {
            let itm = arr?.find((row) => row.id === id)
            if (itm) {
                let index = arr?.indexOf(itm);
                arr[index] = item
            } else {
                arr.push(item)
            }
        } else {
            arr.push(item)
        }
        toast.success('تغییرات با موفقیت ثبت شد')
        setInputs(prevState => ({...prevState, edit_items: arr}))
    }


    const formHandler = async (e) => {

        e.preventDefault()

        let formData = new FormData()

        for (let i = 0; i < inputs?.selected?.length; i++) {
            formData.append(`drivers_licence[${i}]`, inputs?.selected[i])
        }

        let image_obj = {}

        let main_array = inputs?.edit_items.concat(inputs?.created_certificates);
        if (main_array?.length > 0) {
            for (let i = 0; i < main_array?.length; i++) {
                formData.append(`certificates[${i}][course]`, main_array[i]['course'])
                formData.append(`certificates[${i}][location]`, main_array[i]['location'])
                formData.append(`certificates[${i}][year]`, main_array[i]['year'])
                formData.append(`certificates[${i}][duration]`, main_array[i]['duration'])
                if (main_array[i]['id']) {
                    formData.append(`certificates[${i}][id]`, main_array[i]['id'])
                }
                if (main_array[i]['document']) {
                    image_obj[i] = main_array[i]['document']
                }
                if (main_array[i]['remove_image'] !== undefined && main_array[i]['id']) {
                    formData.append(`certificates[${i}][remove_image]`, main_array[i]['id'])
                }
                if (main_array[i]['certificate'] === 'no' && main_array[i]['id']) {
                    formData.append(`certificates[${i}][remove_image]`, main_array[i]['id'])
                }
            }
        }

        for (const [key, value] of Object.entries(image_obj)) {
            formData.append(`certificate_images[${key}]`, value)
        }

        // for (let pair of formData.entries()) {
        //     console.log(pair[0] + ', ' + pair[1]);
        // }

        let api_result = await getFormDataPost('profile/certifications', formData)


        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            setRender(!is_render)
            setInputs(prevState => ({
                ...prevState,
                created_certificates: [],
                course: '',
                location: '',
                year: null,
                duration: '',
                certificate: 'no',
                document_uploading: '',
                document: '',
                edit_items: []
            }))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const goToNextPage = () => {
        props.handleChange(7)
    }


    let certificates = inputs?.certificates;
    let drivers_licence = inputs?.drivers_licence;
    let created_certificates = inputs?.created_certificates;
    return (
        <>
            {
                !more_data["isLoad"] && <form onSubmit={formHandler} className="row">
                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                        <TabStatusComplete style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                            {
                                more_data.sheet_status?.status === 'pending' || more_data.sheet_status?.status === 'denied' ?
                                    <span style={{
                                        color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                        backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                    }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                    <span style={{
                                        color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                        backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                    }} className='icon-tick afa-icon-tick'/>
                            }
                            <p className='mb-0 f-14 '><span>وضعیت : </span><span>{more_data.status_colors?.[more_data.sheet_status?.status]?.title}</span></p>
                        </TabStatusComplete>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-8 mb-4">
                        {
                            more_data.sheet_status?.message &&
                            <TabStatusContent style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                                <p className='mb-0 f-14'>
                                    <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                                </p>
                            </TabStatusContent>
                        }
                    </div>
                    {
                        certificates?.length > 0 ?
                            <>
                                <Accordion defaultActiveKey="0">
                                    {
                                        certificates?.map((row, i) => (
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} key={i} eventKey={row.id}
                                                                  onClick={() => changeArrow(row.id, i)}>
                                                    <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeCollapse(row.id)}/>
                                                        <span
                                                            className="pl-4 f-14 my-2"> نام دوره : {row?.course}</span>
                                                        <span
                                                            className="pl-4 f-14 my-2"> محل آموزش : {row?.location}</span>
                                                        <span
                                                            className="pl-4 f-14 my-2"> سال : {row?.year}</span>
                                                        <span
                                                            className="pl-4 f-14 my-2"> مدت : {row?.duration}</span>
                                                    </div>
                                                    <span
                                                        className={(arrow_item.arrow_id === row.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={row.id}>
                                                    <Card.Body className='row'>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs className='mb-3'>
                                                                <span>نام دوره
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`course[${i}]`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`course[${i}]`}
                                                                    value={inputs[`course[${i}]`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`course[${i}]`]: e.target.value
                                                                        }))
                                                                    }
                                                                />
                                                                <p className='afa-error-text'>{error[`course[${i}]`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs className='mb-3'>
                                                                <span>محل آموزش
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`location[${i}]`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`location[${i}]`}
                                                                    value={inputs[`location[${i}]`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`location[${i}]`]: e.target.value
                                                                        }))
                                                                    }
                                                                />
                                                                <p className='afa-error-text'>{error[`location[${i}]`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs className='mb-3'>
                                                                <span>سال
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`year[${i}]`] && 'afa-error-input'}
                                                                    type="text"
                                                                    max={9999}
                                                                    maxLength={4}
                                                                    name={`year[${i}]`}
                                                                    value={inputs[`year[${i}]`]}
                                                                    onChange={(e)=>just_number(e.target.value) && setInputs(prevState => ({
                                                                        ...prevState, [`year[${i}]`]: fixNumber(e.target.value)
                                                                    }))}/>
                                                                <p className='afa-error-text'>{error[`year[${i}]`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs className='mb-3'>
                                                                <span>مدت (ماه)
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`duration[${i}]`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`duration[${i}]`}
                                                                    value={inputs[`duration[${i}]`]}
                                                                    onChange={(e)=>just_number(e.target.value) && setInputs(prevState => ({
                                                                        ...prevState, [`duration[${i}]`]: fixNumber(e.target.value)
                                                                    }))}
                                                                />
                                                                <p className='afa-error-text'>{error[`duration[${i}]`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <span className=' f-14'>گواهی نامه
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <div className="d-flex flex-wrap mt-3">
                                                                <label className="afa-custom-checkbox ml-2">
                                                                    دارد
                                                                    <input type="radio" value='yes'
                                                                           onChange={inputHandler}
                                                                           checked={inputs[`certificate[${i}]`] === 'yes'}
                                                                           name={`certificate[${i}]`}/>
                                                                    <span className="checkmark"/>
                                                                </label>
                                                                <label className="afa-custom-checkbox ml-2">
                                                                    ندارد
                                                                    <input type="radio" value='no'
                                                                           onChange={inputHandler}
                                                                           checked={inputs[`certificate[${i}]`] === 'no'}
                                                                           name={`certificate[${i}]`}/>
                                                                    <span className="checkmark"/>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {
                                                            inputs[`certificate[${i}]`] === 'yes' &&
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <LargeFileInputs
                                                                    className={(error[`document[${i}]`]) && 'afa-error-input'}>
                                                                    {
                                                                        (inputs[`document_uploading[${i}]`] || inputs[`certificate_image[${i}`]) &&
                                                                        <div className="afa-perseneli-img">
                                                                            <img
                                                                                src={(inputs[`document_uploading[${i}]`] === null && inputs[`certificate_image[${i}`]) ? `${MAIN_URL_IMAGE}${inputs[`certificate_image[${i}`]}`
                                                                                    : inputs[`document_uploading[${i}]`]}
                                                                                alt="بساز بفروش"/>
                                                                            <span
                                                                                className='icon-close-solid afa-remove-position'
                                                                                onClick={() => removeHandlerDocs12(i)}/>
                                                                        </div>
                                                                    }
                                                                    <label
                                                                        className={(inputs[`document_uploading[${i}]`] || inputs[`certificate_image[${i}`]) ? 'afa-content-after-img' : 'afa-center-pos'}>

                                                                        <span className='icon-cloud-computing'/>

                                                                        <input type="file" accept="image/*"
                                                                               multiple={false}
                                                                               onChange={(e) => thisUploadDocs(e)}
                                                                               name={`document_uploading[${i}]`}
                                                                               className='dv-upload-file'/>
                                                                        <span
                                                                            className='upload-text'>آپلود</span>
                                                                    </label>
                                                                </LargeFileInputs>
                                                                <p className='afa-error-text'>{error[`document[${i}]`]}</p>
                                                            </div>
                                                        }
                                                        <div className="col-12 d-flex justify-content-center">
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <button type='button'
                                                                            onClick={() => saveEditCollapse(parseInt(i), row.id)}>
                                                                        <span>ثبت تغییرات</span>
                                                                        <LeftIcon><span
                                                                            className='icon-cheveron-left'></span></LeftIcon>
                                                                    </button>
                                                                </Inputs>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))
                                    }
                                    {
                                        // زمانی که لیست تازه وارد ها موجود است
                                        created_certificates?.length > 0 &&
                                        <>
                                            {
                                                created_certificates?.map((row, i) => (
                                                    <Card>
                                                        <Accordion.Toggle as={Card.Header} key={i}
                                                                          eventKey={parseInt((i * 100000) + 1)}
                                                                          onClick={() => changeArrowNewCollapseList(parseInt((i * 100000) + 1), i)}>
                                                            <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeCollapseFromCreatedList(i)}></span>
                                                                <span
                                                                    className="pl-4 f-14 my-2"> نام دوره : {row?.course}</span>
                                                                <span
                                                                    className="pl-4 f-14 my-2"> محل آموزش : {row?.location}</span>
                                                                <span
                                                                    className="pl-4 f-14 my-2"> سال : {row?.year}</span>
                                                                <span
                                                                    className="pl-4 f-14 my-2"> مدت : {row?.duration}</span>
                                                            </div>
                                                            <span
                                                                className={(arrow_item.arrow_id === parseInt((i * 100000) + 1) && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey={parseInt((i * 100000) + 1)}>
                                                            <Card.Body className='row'>
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs className='mb-3'>
                                                                        <span>نام دوره
                                                                        <span className='dv-required-star'>*</span>
                                                                        </span>
                                                                        <input
                                                                            className={error[`course[${i}]`] && 'afa-error-input'}
                                                                            type="text"
                                                                            name={`course[${i}]`}
                                                                            value={inputs[`course[${i}]`]}
                                                                            onChange={(e) =>
                                                                                setInputs(prevState => ({
                                                                                    ...prevState,
                                                                                    [`course[${i}]`]: e.target.value
                                                                                }))
                                                                            }
                                                                        />
                                                                        <p className='afa-error-text'>{error[`course[${i}]`]}</p>
                                                                    </Inputs>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs className='mb-3'>
                                                                        <span>محل آموزش
                                                                        <span className='dv-required-star'>*</span>
                                                                        </span>
                                                                        <input
                                                                            className={error[`location[${i}]`] && 'afa-error-input'}
                                                                            type="text"
                                                                            name={`location[${i}]`}
                                                                            value={inputs[`location[${i}]`]}
                                                                            onChange={(e) =>
                                                                                setInputs(prevState => ({
                                                                                    ...prevState,
                                                                                    [`location[${i}]`]: e.target.value
                                                                                }))
                                                                            }
                                                                        />
                                                                        <p className='afa-error-text'>{error[`location[${i}]`]}</p>
                                                                    </Inputs>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs className='mb-3'>
                                                                        <span>سال
                                                                        <span className='dv-required-star'>*</span>
                                                                        </span>
                                                                        <input
                                                                            className={error[`year[${i}]`] && 'afa-error-input'}
                                                                            type="text"
                                                                            max={9999}
                                                                            maxLength={4}
                                                                            name={`year[${i}]`}
                                                                            value={inputs[`year[${i}]`]}
                                                                            onChange={(e)=>just_number(e.target.value) && setInputs(prevState => ({
                                                                                ...prevState, [`year[${i}]`]: fixNumber(e.target.value)
                                                                            }))}/>
                                                                        <p className='afa-error-text'>{error[`year[${i}]`]}</p>
                                                                    </Inputs>
                                                                </div>

                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs className='mb-3'>
                                                                        <span>مدت (ماه)
                                                                        <span className='dv-required-star'>*</span>
                                                                        </span>
                                                                        <input
                                                                            className={error[`duration[${i}]`] && 'afa-error-input'}
                                                                            type="text"
                                                                            name={`duration[${i}]`}
                                                                            value={inputs[`duration[${i}]`]}
                                                                            onChange={(e)=>just_number(e.target.value) && setInputs(prevState => ({
                                                                                ...prevState, [`duration[${i}]`]: fixNumber(e.target.value)
                                                                            }))}/>
                                                                        <p className='afa-error-text'>{error[`duration[${i}]`]}</p>
                                                                    </Inputs>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <span className=' f-14'>گواهی نامه
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <div className="d-flex flex-wrap mt-3">
                                                                        <label className="afa-custom-checkbox ml-2">
                                                                            دارد
                                                                            <input type="radio" value='yes'
                                                                                   onChange={inputHandler}
                                                                                   checked={inputs[`certificate[${i}]`] === 'yes'}
                                                                                   name={`certificate[${i}]`}/>
                                                                            <span className="checkmark"/>
                                                                        </label>
                                                                        <label className="afa-custom-checkbox ml-2">
                                                                            ندارد
                                                                            <input type="radio" value='no'
                                                                                   onChange={inputHandler}
                                                                                   checked={inputs[`certificate[${i}]`] === 'no'}
                                                                                   name={`certificate[${i}]`}/>
                                                                            <span className="checkmark"/>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    inputs[`certificate[${i}]`] === 'yes' &&
                                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                        <LargeFileInputs
                                                                            className={(error[`document[${i}]`]) && 'afa-error-input'}>
                                                                            {
                                                                                inputs[`document_uploading[${i}]`] &&
                                                                                <div className="afa-perseneli-img">
                                                                                    <img
                                                                                        src={inputs[`document_uploading[${i}]`]}
                                                                                        alt="بساز بفروش"/>
                                                                                    <span
                                                                                        className='icon-close-solid afa-remove-position'
                                                                                        onClick={() => removeHandlerDocs1(i)}/>
                                                                                </div>
                                                                            }
                                                                            <label
                                                                                className={inputs[`document_uploading[${i}]`] ? 'afa-content-after-img' : 'afa-center-pos'}>

                                                                                    <span
                                                                                        className='icon-cloud-computing'/>

                                                                                <input type="file" accept="image/*"
                                                                                       multiple={false}
                                                                                       onChange={(e) => thisUploadDocs(e)}
                                                                                       name={`document_uploading[${i}]`}
                                                                                       className='dv-upload-file'/>
                                                                                <span
                                                                                    className='upload-text'>آپلود</span>
                                                                            </label>
                                                                        </LargeFileInputs>
                                                                        <p className='afa-error-text'>{error[`document[${i}]`]}</p>
                                                                    </div>
                                                                }
                                                                <div
                                                                    className="col-12 d-flex justify-content-center">
                                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                        <Inputs>
                                                                            <button type='button'
                                                                                    onClick={() => saveEditNewCollapse(parseInt(i))}>
                                                                                <span>ذخیره</span>
                                                                                <LeftIcon><span
                                                                                    className='icon-cheveron-left'></span></LeftIcon>
                                                                            </button>
                                                                        </Inputs>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                ))
                                            }
                                        </>
                                    }
                                    {
                                        new_collapse === true && <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey={'0'}
                                                              onClick={() => changeArrowNewCollapses()}>
                                                <div className="d-flex flex-wrap">
                                                            <span
                                                                className="icon-Delete dv-icon-delete pl-2 my-2"
                                                                onClick={removeNewCollapse}/>
                                                    <span
                                                        className="pl-4 f-14 my-2"> نام دوره : {inputs2[`course`]}</span>
                                                    <span
                                                        className="pl-4 f-14 my-2"> محل آموزش : {inputs2[`location`]}</span>
                                                    <span
                                                        className="pl-4 f-14 my-2"> سال : {inputs2[`year`]}</span>
                                                    <span
                                                        className="pl-4 f-14 my-2"> مدت : {inputs2[`duration`]}</span>
                                                </div>
                                                <span
                                                    className={(arrow_item.arrow_id === 'rasoul' && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={'0'}>
                                                <Card.Body className='row'>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs className='mb-3'>
                                                            <span>نام دوره
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <input
                                                                className={error[`course`] && 'afa-error-input'}
                                                                type="text"
                                                                name={`course`}
                                                                value={inputs2[`course`]}
                                                                onChange={(e) =>
                                                                    setInputs2(prevState => ({
                                                                        ...prevState, [`course`]: e.target.value
                                                                    }))
                                                                }
                                                            />
                                                            <p className='afa-error-text'>{error[`course`]}</p>
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs className='mb-3'>
                                                            <span>محل آموزش
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <input
                                                                className={error[`location`] && 'afa-error-input'}
                                                                type="text"
                                                                name={`location`}
                                                                value={inputs2[`location`]}
                                                                onChange={(e) =>
                                                                    setInputs2(prevState => ({
                                                                        ...prevState,
                                                                        [`location`]: e.target.value
                                                                    }))
                                                                }
                                                            />
                                                            <p className='afa-error-text'>{error[`location`]}</p>
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs className='mb-3'>
                                                            <span>سال
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <input
                                                                className={error[`year`] && 'afa-error-input'}
                                                                type="text"
                                                                max={9999}
                                                                maxLength={4}
                                                                name={`year`}
                                                                value={inputs2[`year`]} onChange={(e)=>just_number(e.target.value) && setInputs2(prevState => ({
                                                                ...prevState, [`year`]: fixNumber(e.target.value)
                                                            }))}/>
                                                            <p className='afa-error-text'>{error[`year`]}</p>
                                                        </Inputs>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs className='mb-3'>
                                                            <span>مدت (ماه)
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <input
                                                                className={error[`duration`] && 'afa-error-input'}
                                                                type="text"
                                                                name={`duration`}
                                                                value={inputs2[`duration`]}
                                                                onChange={(e)=>just_number(e.target.value) && setInputs2(prevState => ({
                                                                    ...prevState, [`duration`]: fixNumber(e.target.value)
                                                                }))}/>
                                                            <p className='afa-error-text'>{error[`duration`]}</p>
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <span className=' f-14'>گواهی نامه
                                                        <span className='dv-required-star'>*</span>
                                                        </span>
                                                        <div className="d-flex flex-wrap mt-3">
                                                            <label className="afa-custom-checkbox ml-2">
                                                                دارد
                                                                <input type="radio" value='yes'
                                                                       onChange={(e) =>
                                                                           setInputs2(prevState => ({
                                                                               ...prevState,
                                                                               [`certificate`]: e.target.value
                                                                           }))}
                                                                       checked={inputs2[`certificate`] === 'yes'}
                                                                       name={`certificate`}/>
                                                                <span className="checkmark"/>
                                                            </label>
                                                            <label className="afa-custom-checkbox ml-2">
                                                                ندارد
                                                                <input type="radio" value='no'
                                                                       onChange={(e) =>
                                                                           setInputs2(prevState => ({
                                                                               ...prevState,
                                                                               [`certificate`]: e.target.value
                                                                           }))}
                                                                       checked={inputs2[`certificate`] === 'no'}
                                                                       name={`certificate`}/>
                                                                <span className="checkmark"/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        inputs2[`certificate`] === 'yes' &&
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <LargeFileInputs
                                                                className={(error[`document`]) && 'afa-error-input'}>
                                                                {
                                                                    inputs2[`document_uploading`] &&
                                                                    <div className="afa-perseneli-img">
                                                                        <img
                                                                            src={inputs2[`document_uploading`]}
                                                                            alt="بساز بفروش"/>
                                                                        <span
                                                                            className='icon-close-solid afa-remove-position'
                                                                            onClick={() => removeHandlerDocs2()}/>
                                                                    </div>
                                                                }
                                                                <label
                                                                    className={inputs2[`document`] ? 'afa-content-after-img' : 'afa-center-pos'}>

                                                                            <span
                                                                                className='icon-cloud-computing'></span>

                                                                    <input type="file" accept="image/*"
                                                                           multiple={false}
                                                                           onChange={(e) => thisUploadDocs2(e)}
                                                                           name={`document_uploading`}
                                                                           className='dv-upload-file'/>
                                                                    <span className='upload-text'>آپلود</span>
                                                                </label>
                                                            </LargeFileInputs>
                                                            <p className='afa-error-text'>{error[`document`]}</p>
                                                        </div>
                                                    }
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <button type='button'
                                                                        onClick={() => addNewCollapse()}>
                                                                    <span>ذخیره</span>
                                                                    <LeftIcon><span
                                                                        className='icon-cheveron-left'/></LeftIcon>
                                                                </button>
                                                            </Inputs>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    }
                                    <div className="row d-flex w-100 justify-content-end">
                                        <div
                                            className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>
                                            <Inputs>
                                                <button type='button' onClick={addNewCollapseForList}
                                                        className='afa-add-more-btn'>
                                                    <span className='icon-add f-20'/>
                                                    <span className='pr-3'>افزودن جدید</span>
                                                </button>
                                            </Inputs>
                                        </div>
                                    </div>
                                </Accordion>
                            </>
                            :
                            <>
                                {
                                    created_certificates?.length > 0 ?
                                        <>
                                            <Accordion>
                                                {
                                                    created_certificates?.map((row, i) => (
                                                        <Card>
                                                            <Accordion.Toggle as={Card.Header} key={i}
                                                                              eventKey={parseInt((i * 100000) + 1)}
                                                                              onClick={() => changeArrowNewCollapseList(parseInt((i * 100000) + 1), i)}>
                                                                <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeCollapseFromCreatedList(i)}></span>
                                                                    <span
                                                                        className="pl-4 f-14 my-2"> نام دوره : {row?.course}</span>
                                                                    <span
                                                                        className="pl-4 f-14 my-2"> محل آموزش : {row?.location}</span>
                                                                    <span
                                                                        className="pl-4 f-14 my-2"> سال : {row?.year}</span>
                                                                    <span
                                                                        className="pl-4 f-14 my-2"> مدت : {row?.duration}</span>
                                                                </div>
                                                                <span
                                                                    className={(arrow_item.arrow_id === parseInt((i * 100000) + 1) && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse
                                                                eventKey={parseInt((i * 100000) + 1)}>
                                                                <Card.Body className='row'>
                                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                        <Inputs className='mb-3'>
                                                                            <span>نام دوره
                                                                            <span className='dv-required-star'>*</span>
                                                                            </span>
                                                                            <input
                                                                                className={error[`course[${i}]`] && 'afa-error-input'}
                                                                                type="text"
                                                                                name={`course[${i}]`}
                                                                                value={inputs[`course[${i}]`]}
                                                                                onChange={(e) =>
                                                                                    setInputs(prevState => ({
                                                                                        ...prevState,
                                                                                        [`course[${i}]`]: e.target.value
                                                                                    }))
                                                                                }
                                                                            />
                                                                            <p className='afa-error-text'>{error[`course[${i}]`]}</p>
                                                                        </Inputs>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                        <Inputs className='mb-3'>
                                                                            <span>محل آموزش
                                                                            <span className='dv-required-star'>*</span>
                                                                            </span>
                                                                            <input
                                                                                className={error[`location[${i}]`] && 'afa-error-input'}
                                                                                type="text"
                                                                                name={`location[${i}]`}
                                                                                value={inputs[`location[${i}]`]}
                                                                                onChange={(e) =>
                                                                                    setInputs(prevState => ({
                                                                                        ...prevState,
                                                                                        [`location[${i}]`]: e.target.value
                                                                                    }))
                                                                                }
                                                                            />
                                                                            <p className='afa-error-text'>{error[`location[${i}]`]}</p>
                                                                        </Inputs>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                        <Inputs className='mb-3'>
                                                                            <span>سال
                                                                            <span className='dv-required-star'>*</span>
                                                                            </span>
                                                                            <input
                                                                                className={error[`year[${i}]`] && 'afa-error-input'}
                                                                                type="text"
                                                                                max={9999}
                                                                                maxLength={4}
                                                                                name={`year[${i}]`}
                                                                                value={inputs[`year[${i}]`]}
                                                                                onChange={(e)=>just_number(e.target.value) && setInputs(prevState => ({
                                                                                    ...prevState, [`year[${i}]`]: fixNumber(e.target.value)
                                                                                }))}/>
                                                                            <p className='afa-error-text'>{error[`year[${i}]`]}</p>
                                                                        </Inputs>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                        <Inputs className='mb-3'>
                                                                            <span>مدت (ماه)
                                                                            <span className='dv-required-star'>*</span>
                                                                            </span>
                                                                            <input
                                                                                className={error[`duration[${i}]`] && 'afa-error-input'}
                                                                                type="text"
                                                                                name={`duration[${i}]`}
                                                                                value={inputs[`duration[${i}]`]}
                                                                                onChange={(e)=>just_number(e.target.value) && setInputs(prevState => ({
                                                                                    ...prevState, [`duration[${i}]`]: fixNumber(e.target.value)
                                                                                }))}/>
                                                                            <p className='afa-error-text'>{error[`duration[${i}]`]}</p>
                                                                        </Inputs>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                        <span className=' f-14'>گواهی نامه
                                                                        <span className='dv-required-star'>*</span>
                                                                        </span>
                                                                        <div className="d-flex flex-wrap mt-3">
                                                                            <label
                                                                                className="afa-custom-checkbox ml-2">
                                                                                دارد
                                                                                <input type="radio" value='yes'
                                                                                       onChange={inputHandler}
                                                                                       checked={inputs[`certificate[${i}]`] === 'yes'}
                                                                                       name={`certificate[${i}]`}/>
                                                                                <span className="checkmark"/>
                                                                            </label>
                                                                            <label
                                                                                className="afa-custom-checkbox ml-2">
                                                                                ندارد
                                                                                <input type="radio" value='no'
                                                                                       onChange={inputHandler}
                                                                                       checked={inputs[`certificate[${i}]`] === 'no'}
                                                                                       name={`certificate[${i}]`}/>
                                                                                <span className="checkmark"/>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        inputs[`certificate[${i}]`] === 'yes' &&
                                                                        <div
                                                                            className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                            <LargeFileInputs
                                                                                className={(error[`document[${i}]`]) && 'afa-error-input'}>
                                                                                {
                                                                                    inputs[`document_uploading[${i}]`] &&
                                                                                    <div
                                                                                        className="afa-perseneli-img">
                                                                                        <img
                                                                                            src={inputs[`document_uploading[${i}]`]}
                                                                                            alt="بساز بفروش"/>
                                                                                        <span
                                                                                            className='icon-close-solid afa-remove-position'
                                                                                            onClick={() => removeHandlerDocs1(i)}/>
                                                                                    </div>
                                                                                }
                                                                                <label
                                                                                    className={inputs[`document_uploading[${i}]`] ? 'afa-content-after-img' : 'afa-center-pos'}>

                                                                                    <span
                                                                                        className='icon-cloud-computing'/>

                                                                                    <input type="file"
                                                                                           accept="image/*"
                                                                                           multiple={false}
                                                                                           onChange={(e) => thisUploadDocs(e)}
                                                                                           name={`document_uploading[${i}]`}
                                                                                           className='dv-upload-file'/>
                                                                                    <span
                                                                                        className='upload-text'>آپلود</span>
                                                                                </label>
                                                                            </LargeFileInputs>
                                                                            <p className='afa-error-text'>{error[`document[${i}]`]}</p>
                                                                        </div>
                                                                    }
                                                                    <div
                                                                        className="col-12 d-flex justify-content-center">
                                                                        <div
                                                                            className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                            <Inputs>
                                                                                <button
                                                                                    type='button'
                                                                                    onClick={() => saveEditNewCollapse(parseInt(i))}>
                                                                                    <span>ذخیره</span>
                                                                                    <LeftIcon><span
                                                                                        className='icon-cheveron-left'></span></LeftIcon>
                                                                                </button>
                                                                            </Inputs>
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    ))
                                                }
                                                {
                                                    new_collapse === true && <Card>
                                                        <Accordion.Toggle as={Card.Header} eventKey={'rasoul'}
                                                                          onClick={() => changeArrowNewCollapses()}>
                                                            <div className="d-flex flex-wrap">
                                                            <span
                                                                className="icon-Delete dv-icon-delete pl-2 my-2"></span>
                                                                <span
                                                                    className="pl-4 f-14 my-2"> نام دوره : {inputs2[`course`]}</span>
                                                                <span
                                                                    className="pl-4 f-14 my-2"> محل آموزش : {inputs2[`location`]}</span>
                                                                <span
                                                                    className="pl-4 f-14 my-2"> سال : {inputs2[`year`]}</span>
                                                                <span
                                                                    className="pl-4 f-14 my-2"> مدت : {inputs2[`duration`]}</span>
                                                            </div>
                                                            <span
                                                                className={(arrow_item.arrow_id === 'rasoul' && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey={'rasoul'}>
                                                            <Card.Body className='row'>
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs className='mb-3'>
                                                                        <span>نام دوره
                                                                        <span className='dv-required-star'>*</span>
                                                                        </span>
                                                                        <input
                                                                            className={error[`course`] && 'afa-error-input'}
                                                                            type="text"
                                                                            name={`course`}
                                                                            value={inputs2[`course`]}
                                                                            onChange={(e) =>
                                                                                setInputs2(prevState => ({
                                                                                    ...prevState,
                                                                                    [`course`]: e.target.value
                                                                                }))
                                                                            }
                                                                        />
                                                                        <p className='afa-error-text'>{error[`course`]}</p>
                                                                    </Inputs>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs className='mb-3'>
                                                                        <span>محل آموزش
                                                                        <span className='dv-required-star'>*</span>
                                                                        </span>
                                                                        <input
                                                                            className={error[`location`] && 'afa-error-input'}
                                                                            type="text"
                                                                            name={`location`}
                                                                            value={inputs2[`location`]}
                                                                            onChange={(e) =>
                                                                                setInputs2(prevState => ({
                                                                                    ...prevState,
                                                                                    [`location`]: e.target.value
                                                                                }))
                                                                            }
                                                                        />
                                                                        <p className='afa-error-text'>{error[`location`]}</p>
                                                                    </Inputs>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs className='mb-3'>
                                                                        <span>سال
                                                                        <span className='dv-required-star'>*</span>
                                                                        </span>
                                                                        <input
                                                                            className={error[`year`] && 'afa-error-input'}
                                                                            type="text"
                                                                            max={9999}
                                                                            maxLength={4}
                                                                            name={`year`}
                                                                            value={inputs2[`year`]} onChange={(e)=>just_number(e.target.value) && setInputs2(prevState => ({
                                                                            ...prevState, [`year`]: fixNumber(e.target.value)
                                                                        }))}/>
                                                                        <p className='afa-error-text'>{error[`year`]}</p>
                                                                    </Inputs>
                                                                </div>

                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs className='mb-3'>
                                                                        <span>مدت (ماه)
                                                                        <span className='dv-required-star'>*</span>
                                                                        </span>
                                                                        <input
                                                                            className={error[`duration`] && 'afa-error-input'}
                                                                            type="text"
                                                                            name={`duration`}
                                                                            value={inputs2[`duration`]}
                                                                            onChange={(e)=>just_number(e.target.value) && setInputs2(prevState => ({
                                                                                ...prevState, [`duration`]: fixNumber(e.target.value)
                                                                            }))}/>
                                                                        <p className='afa-error-text'>{error[`duration`]}</p>
                                                                    </Inputs>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <span className=' f-14'>گواهی نامه
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <div className="d-flex flex-wrap mt-3">
                                                                        <label className="afa-custom-checkbox ml-2">
                                                                            دارد
                                                                            <input type="radio" value='yes'
                                                                                   onChange={(e) =>
                                                                                       setInputs2(prevState => ({
                                                                                           ...prevState,
                                                                                           [`certificate`]: e.target.value
                                                                                       }))}
                                                                                   checked={inputs2[`certificate`] === 'yes'}
                                                                                   name={`certificate`}/>
                                                                            <span className="checkmark"/>
                                                                        </label>
                                                                        <label className="afa-custom-checkbox ml-2">
                                                                            ندارد
                                                                            <input type="radio" value='no'
                                                                                   onChange={(e) =>
                                                                                       setInputs2(prevState => ({
                                                                                           ...prevState,
                                                                                           [`certificate`]: e.target.value
                                                                                       }))}
                                                                                   checked={inputs2[`certificate`] === 'no'}
                                                                                   name={`certificate`}/>
                                                                            <span className="checkmark"/>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    inputs2[`certificate`] === 'yes' &&
                                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                        <LargeFileInputs
                                                                            className={(error[`document`]) && 'afa-error-input'}>
                                                                            {
                                                                                inputs2[`document_uploading`] &&
                                                                                <div className="afa-perseneli-img">
                                                                                    <img
                                                                                        src={inputs2[`document_uploading`]}
                                                                                        alt="بساز بفروش"/>
                                                                                    <span
                                                                                        className='icon-close-solid afa-remove-position'
                                                                                        onClick={() => removeHandlerDocs2()}/>
                                                                                </div>
                                                                            }
                                                                            <label
                                                                                className={inputs2[`document`] ? 'afa-content-after-img' : 'afa-center-pos'}>

                                                                            <span
                                                                                className='icon-cloud-computing'></span>

                                                                                <input type="file" accept="image/*"
                                                                                       multiple={false}
                                                                                       onChange={(e) => thisUploadDocs2(e)}
                                                                                       name={`document_uploading`}
                                                                                       className='dv-upload-file'/>
                                                                                <span
                                                                                    className='upload-text'>آپلود</span>
                                                                            </label>
                                                                        </LargeFileInputs>
                                                                        <p className='afa-error-text'>{error[`document`]}</p>
                                                                    </div>
                                                                }
                                                                <div
                                                                    className="col-12 d-flex justify-content-center">
                                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                        <Inputs>
                                                                            <button disabled={
                                                                                inputs2?.course?.length > 0 && inputs2?.location?.length > 0 && inputs2?.year?.length > 0 && inputs2?.duration?.length > 0 ? false : true
                                                                            } type='button'
                                                                                    onClick={() => addNewCollapse()}>
                                                                                <span>ذخیره</span>
                                                                                <LeftIcon><span
                                                                                    className='icon-cheveron-left'/></LeftIcon>
                                                                            </button>
                                                                        </Inputs>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                }

                                                {
                                                    new_collapse === false &&
                                                    <div className="row d-flex w-100 justify-content-end">
                                                        <div
                                                            className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>
                                                            <Inputs>
                                                                <button type='button'
                                                                        onClick={addNewCollapseForList}
                                                                        className='afa-add-more-btn'>
                                                                    <span className='icon-add f-20'/>
                                                                    <span className='pr-3'>افزودن جدید</span>
                                                                </button>
                                                            </Inputs>
                                                        </div>
                                                    </div>
                                                }
                                            </Accordion>
                                        </> :
                                        <>

                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                <Inputs className='mb-3'>
                                                    <span>نام دوره
                                                    <span className='dv-required-star'>*</span>
                                                    </span>
                                                    <input className={error['course'] && 'afa-error-input'}
                                                           type="text"
                                                           name={'course'}
                                                           value={inputs.course}
                                                           onChange={(e) =>
                                                               setInputs(prevState => ({
                                                                   ...prevState, 'course': e.target.value
                                                               }))
                                                           }
                                                    />
                                                    <p className='afa-error-text'>{error['course']}</p>
                                                </Inputs>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                <Inputs className='mb-3'>
                                                    <span>محل آموزش
                                                    <span className='dv-required-star'>*</span>
                                                    </span>
                                                    <input className={error['location'] && 'afa-error-input'}
                                                           type="text"
                                                           name={'location'}
                                                           value={inputs.location}
                                                           onChange={(e) =>
                                                               setInputs(prevState => ({
                                                                   ...prevState, 'location': e.target.value
                                                               }))
                                                           }
                                                    />
                                                    <p className='afa-error-text'>{error['location']}</p>
                                                </Inputs>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                <Inputs className='mb-3'>
                                                    <span>سال
                                                    <span className='dv-required-star'>*</span>
                                                    </span>
                                                    <input className={error['year'] && 'afa-error-input'}
                                                           type="text"
                                                           max={9999}
                                                           maxLength={4}
                                                           name={'year'}
                                                           value={inputs.year} onChange={(e) =>
                                                        setInputs(prevState => ({
                                                            ...prevState, 'year': fixNumber(e.target.value)
                                                        }))
                                                    }/>
                                                    <p className='afa-error-text'>{error['year']}</p>
                                                </Inputs>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                <Inputs className='mb-3'>
                                                    <span>مدت (ماه)
                                                    <span className='dv-required-star'>*</span>
                                                    </span>
                                                    <input className={error['duration'] && 'afa-error-input'}
                                                           type="text"
                                                           name={'duration'}
                                                           value={inputs.duration}
                                                           onChange={(e)=>just_number(e.target.value) && setInputs(prevState => ({
                                                               ...prevState, [`duration`]: fixNumber(e.target.value)
                                                           }))}/>
                                                    <p className='afa-error-text'>{error['duration']}</p>
                                                </Inputs>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                <span className=' f-14'>گواهی نامه
                                                <span className='dv-required-star'>*</span>
                                                </span>
                                                <div className="d-flex flex-wrap mt-3">
                                                    <label className="afa-custom-checkbox ml-2">
                                                        دارد
                                                        <input type="radio" value='yes' onChange={inputHandler}
                                                               checked={inputs.certificate === 'yes'}
                                                               name="certificate"/>
                                                        <span className="checkmark"/>
                                                    </label>
                                                    <label className="afa-custom-checkbox ml-2">
                                                        ندارد
                                                        <input type="radio" value='no' onChange={inputHandler}
                                                               checked={inputs.certificate === 'no'}
                                                               name="certificate"/>
                                                        <span className="checkmark"/>
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                inputs.certificate === 'yes' &&
                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <LargeFileInputs
                                                        className={(error['document_uploading']) && 'afa-error-input'}>
                                                        {
                                                            inputs[`document_uploading`] &&
                                                            <div className="afa-perseneli-img">
                                                                <img
                                                                    src={inputs[`document_uploading`]}
                                                                    alt="بساز بفروش"/>
                                                                <span
                                                                    className='icon-close-solid afa-remove-position'
                                                                    onClick={() => removeHandlerDocs('document_uploading')}/>
                                                            </div>
                                                        }
                                                        <label
                                                            className={inputs[`document_uploading`] ? 'afa-content-after-img' : 'afa-center-pos'}>

                                                            <span className='icon-cloud-computing'></span>

                                                            <input type="file" accept="image/*"
                                                                   multiple={false}
                                                                   onChange={(e) => thisUploadDocs(e)}
                                                                   name='document_uploading'
                                                                   className='dv-upload-file'/>
                                                            <span className='upload-text'>آپلود</span>
                                                        </label>
                                                    </LargeFileInputs>
                                                    <p className='afa-error-text'>{error['document_uploading']}</p>
                                                </div>
                                            }
                                            <div className="col-12 d-flex justify-content-center">
                                                <div
                                                    className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>
                                                    <Inputs>
                                                        <button type='button' disabled={
                                                            inputs?.course?.length > 0 && inputs?.location?.length > 0 && inputs?.year?.length > 0 && inputs?.duration?.length > 0 ? false : true
                                                        } onClick={saveNewCollapse}
                                                                className='afa-add-more-btn'>
                                                            <span className='icon-add f-20'/>
                                                            <span className='pr-3'>ذخیره</span>
                                                        </button>
                                                    </Inputs>
                                                </div>
                                            </div>
                                        </>
                                }
                            </>

                    }
                    <div className="col-12 mb-4">
                        <span className=' f-14'>دارای گواهینامه</span>
                        <div className="d-flex flex-wrap mt-3">
                            {
                                inputs?.certificate_type?.map((item, index) => (
                                    <label className="afa-custom-checkbox" key={index}>{item}
                                        <input type="checkbox" name='certificate_type'
                                               value={item}
                                               onChange={() => checkBoxHandler(item)}
                                               checked={inputs.selected?.includes(item)}
                                        />
                                        <span className="checkmark2"></span>
                                    </label>
                                ))
                            }
                            {
                                (error[`drivers_licence`]) &&
                                error[`drivers_licence`]?.map((row, i) => (
                                    <p className='afa-error-text'>{error[`drivers_licence[${i}]`]}</p>
                                ))
                            }
                        </div>
                    </div>

                    <div className="col-12 d-flex flex-column flex-sm-row justify-content-between">
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <Inputs>
                                <button type='submit'>
                                    <span>ثبت این صفحه</span>
                                </button>
                            </Inputs>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <Inputs>
                                <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                        onClick={goToNextPage}>
                                    <span>مرحله بعد</span>
                                    <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                </button>
                            </Inputs>
                        </div>
                    </div>
                </form>
            }
        </>
    )
}

export default Certificates;