import React, {useEffect, useState} from 'react';
import {MainContent} from "../style";
import {
    edit_item_with_id,
    fileUpload,
    fixNumber,
    getData,
    getFormDataPost,
    just_number, remove_item_of_arr_with_id, setTitle
} from "../../../../assets/scripts/GeneralFunctions";
import {Images} from "../../../../assets/scripts/Images";
import {BannerFile, BannerFileMini, Inputs} from "../../Child/Supplier/Child/style";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {Modal} from "react-bootstrap";
import Select from 'react-select';
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import GuideUploadingFile from "./GuideUploadingFile";
import {useDispatch} from "react-redux";
import CkEditorText from "../Article/CkEditorText";

function Setting() {

    const [inputs, setInputs] = useState({
        help_supplier_help: '',
        supplier_help: '',
        help_hr_help: '',
        hr_help: '',
    })
    const [error, setError] = useState({})
    const [is_render, setRender] = useState(false)

    useEffect(async () => {
        setTitle('آتاویتا | مدیریت سایت')
        let api_result = await getData(MAIN_URL, `site-management`, 'get', {}, true, true);
        if (api_result?.status === 200) {

            setInputs(prevState => ({
                ...prevState,
                hr_help: api_result?.data?.hr_help,
                help_hr_help: api_result?.data?.hr_help,
                supplier_help: api_result?.data?.supplier_help,
                help_supplier_help: api_result?.data?.supplier_help,
            }))
            setError('')
        }
    }, [is_render]);


    // guide files function
    const onChangeGuideFile = ({file, name}) => {
        console.log(file, name)
        setInputs(prevState => ({...prevState, [name]: file}))
    }

    const formHandler = async (e) => {
        e.preventDefault()

        let form_data = new FormData()

        // register Guide
        if (inputs?.help_hr_help !== inputs?.hr_help) {
            form_data.append('hr_help', inputs?.hr_help)
        }
        if (inputs?.help_supplier_help !== inputs?.supplier_help) {
            form_data.append('supplier_help', inputs?.supplier_help)
        }


        let api_result = await getFormDataPost(`site-management/edit`, form_data)
        if (api_result?.status === 200) {

            console.log(api_result)
            setError('')
            toast.success('با موفقیت ثبت شد')
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>

                {/*guide files*/}
                <div className="col-12 mb-4">
                    <h6 className='text-red fw-bold'>مدیریت فایل راهنما</h6>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3">
                    <GuideUploadingFile onChangeGuideFile={onChangeGuideFile} value={inputs?.['hr_help']}
                                        name={'hr_help'} title={'منابع انسانی'}/>
                    <p className='afa-error-text'>{error[`hr_help`]}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3">
                    <GuideUploadingFile onChangeGuideFile={onChangeGuideFile} value={inputs?.['supplier_help']}
                                        name={'supplier_help'} title={'تامین کننده'}/>
                    <p className='afa-error-text'>{error[`supplier_help`]}</p>
                </div>


                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <button type='submit'
                            className='m-auto afa-save-super-admin-btn'>
                        <span className='pr-3'>ذخیره</span>
                    </button>
                </div>
            </form>
        </MainContent>
    );
}

export default Setting;