import React, {useEffect, useState} from 'react';
import {
    checkCodeMelli,
    fileUpload,
    getData,
    getFormDataPost,
    priceFormat
} from "../../../../../assets/scripts/GeneralFunctions";
import {FileInputs, Inputs, LeftIcon, TabStatusComplete, TabStatusContent, TabStatusNotComplete} from "./style";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import {MAIN_URL} from "../../../../../assets/scripts/GeneralVariables";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import moment from "moment-jalaali";

function ForeignLanguages(props) {
    const [error, setError] = useState({})
    const [error2, setError2] = useState({})
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});
    const [inputs, setInputs] = useState({
        language: 'انتخاب کنید',
        conversation: 'عالی',
        reading_writing: 'عالی',
        translation_report: 'عالی',
        business_negotiation: 'عالی',
        languages: []
    })
    const [inputs2, setInputs2] = useState({
        language: 'انتخاب کنید',
        conversation: 'عالی',
        reading_writing: 'عالی',
        translation_report: 'عالی',
        business_negotiation: 'عالی',
    })
    const [is_render, setRender] = useState(false);
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [new_collapse, setNewCollapse] = useState(false);
    const [show_add_more, setShowAddMore] = useState(false);

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/languages", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let languages = data?.languages
            if (languages?.length === 0) {
                setInputs({
                    languages: [],
                    language: 'انتخاب کنید',
                    conversation: 'عالی',
                    reading_writing: 'عالی',
                    translation_report: 'عالی',
                    business_negotiation: 'عالی',
                })
                setShowAddMore(false)
            } else {
                setInputs(prevState => ({
                    ...prevState,
                    languages: languages
                }))
                setShowAddMore(true)
            }
            setMoreData({sheet_status: data?.sheet_status, status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const inputHandler2 = (e) => {
        setInputs2(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/languages/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    setRender(!is_render)
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = inputs.languages?.find((row) => {
            return row.id === id
        })
        if (data) {
            setInputs((prevState => ({
                ...prevState,
                language: data?.language,
                [`conversation[${id}]`]: data?.conversation,
                [`reading_writing[${id}]`]: data?.reading_writing,
                [`translation_report[${id}]`]: data?.translation_report,
                [`business_negotiation[${id}]`]: data?.business_negotiation,
            })))
        }
    }

    const editHandler = async (id) => {
        let formData = new FormData()

        if (inputs['language'] !== 'انتخاب کنید') {
            formData.append('language', inputs['language'])
        }
        if (inputs[`conversation[${id}]`]) {
            formData.append('conversation', inputs[`conversation[${id}]`])
        }
        if (inputs[`reading_writing[${id}]`]) {
            formData.append('reading_writing', inputs[`reading_writing[${id}]`])
        }
        if (inputs[`translation_report[${id}]`]) {
            formData.append('translation_report', inputs[`translation_report[${id}]`])
        }
        if (inputs[`business_negotiation[${id}]`]) {
            formData.append('business_negotiation', inputs[`business_negotiation[${id}]`])
        }

        let api_result = await getFormDataPost(`profile/languages/${id}`, formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            setShowAddMore(true)
            // setNewCollapse(false)
            setRender(!is_render)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const removeNewCollapse = () => {
        setNewCollapse(false)
        setShowAddMore(true)
    }

    const addNewWhenCollapseHave = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs2['language'] !== 'انتخاب کنید') {
            formData.append('language', inputs2['language'])
        }
        if (inputs2['conversation']) {
            formData.append('conversation', inputs2['conversation'])
        }
        if (inputs2['reading_writing']) {
            formData.append('reading_writing', inputs2['reading_writing'])
        }
        if (inputs2['translation_report']) {
            formData.append('translation_report', inputs2['translation_report'])
        }
        if (inputs2['business_negotiation']) {
            formData.append('business_negotiation', inputs2['business_negotiation'])
        }

        let api_result = await getFormDataPost('profile/languages', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setShowAddMore(true)
            setNewCollapse(false)
            setRender(!is_render)
            setInputs2({
                language: 'انتخاب کنید',
                conversation: 'عالی',
                reading_writing: 'عالی',
                translation_report: 'عالی',
                business_negotiation: 'عالی',
            })
        } else if (api_result?.status === 400) {
            setError2(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addMoreCollapse = () => {
        setInputs2(prevState => ({
            ...prevState,
            language: 'انتخاب کنید',
            conversation: 'عالی',
            reading_writing: 'عالی',
            translation_report: 'عالی',
            business_negotiation: 'عالی',
        }));
        setShowAddMore(false)
        setNewCollapse(true)
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs['language'] !== 'انتخاب کنید') {
            formData.append('language', inputs['language'])
        }
        if (inputs['conversation']) {
            formData.append('conversation', inputs['conversation'])
        }
        if (inputs['reading_writing']) {
            formData.append('reading_writing', inputs['reading_writing'])
        }
        if (inputs['translation_report']) {
            formData.append('translation_report', inputs['translation_report'])
        }
        if (inputs['business_negotiation']) {
            formData.append('business_negotiation', inputs['business_negotiation'])
        }

        let api_result = await getFormDataPost('profile/languages', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setShowAddMore(true)
            setNewCollapse(false)
            setRender(!is_render)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const goToNextPage = () => {
        props.handleChange(5)
    }

    let languages = inputs?.languages;
    return (
        <>
            <form onSubmit={formHandler} className="row">
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <TabStatusComplete
                        style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                        {
                            more_data.sheet_status?.status === 'pending' || more_data.sheet_status?.status === 'denied' ?
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-tick afa-icon-tick'/>
                        }
                        <p className='mb-0 f-14 '>
                            <span>وضعیت : </span><span>{more_data.status_colors?.[more_data.sheet_status?.status]?.title}</span>
                        </p>
                    </TabStatusComplete>
                </div>
                <div className="col-12 col-sm-6 col-lg-8 mb-4">
                    {
                        more_data.sheet_status?.message &&
                        <TabStatusContent
                            style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <p className='mb-0 f-14'>
                                <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                            </p>
                        </TabStatusContent>
                    }
                </div>
                {
                    languages?.length > 0 ?
                        <>
                            <Accordion defaultActiveKey="0">
                                {
                                    languages?.map((row, index) => (
                                        <Card>
                                            <Accordion.Toggle key={index} as={Card.Header} eventKey={row.id}
                                                              onClick={() => changeArrow(row.id)}>
                                                <div className="d-flex flex-wrap">
                                                    <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                          onClick={() => removeTab(row.id)}></span>
                                                    {
                                                        row?.language &&
                                                        <span className="pl-4 f-14 my-2"> زبان : {row.language}</span>
                                                    }
                                                    {
                                                        row?.conversation &&
                                                        <span
                                                            className="pl-4 f-14 my-2"> مکالمه : {row.conversation}</span>
                                                    }
                                                    {
                                                        row?.reading_writing &&
                                                        <span
                                                            className="pl-4 f-14 my-2"> خواندن نوشتن : {row.reading_writing}</span>
                                                    }
                                                    {
                                                        row?.translation_report &&
                                                        <span
                                                            className="pl-4 f-14 my-2"> ترجمه و گزارش نویسی : {row.translation_report}</span>
                                                    }
                                                    {
                                                        row?.business_negotiation &&
                                                        <span
                                                            className="pl-4 f-14 my-2"> مذاکره تجاری : {row.business_negotiation}</span>
                                                    }
                                                </div>
                                                <span
                                                    className={(arrow_item.arrow_id === row.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}></span>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={row.id}>
                                                <Card.Body className='row'>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <span>عنوان
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <select name={'language'}
                                                                    className={error['language'] && 'afa-error-input'}
                                                                    value={inputs.language} onChange={inputHandler}>
                                                                <option value="انتخاب کنید" disabled>انتخاب کنید
                                                                </option>
                                                                <option value="انگلیسی">انگلیسی</option>
                                                                <option value="آلمانی">آلمانی</option>
                                                                <option value="عربی">عربی</option>
                                                                <option value="فرانسوی">فرانسوی</option>
                                                                <option value="روسی">روسی</option>
                                                                <option value="ترکی استانبولی">ترکی استانبولی</option>
                                                            </select>
                                                            <p className='afa-error-text'>{error['language']}</p>
                                                        </Inputs>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <span className='f-14'>مکالمه</span>
                                                        <Inputs>
                                                            <select name={`conversation[${row.id}]`}
                                                                    onChange={inputHandler}
                                                                    value={inputs[`conversation[${row.id}]`]}>
                                                                <option value="ضعیف">ضعیف</option>
                                                                <option value="متوسط">متوسط</option>
                                                                <option value="عالی">عالی</option>
                                                            </select>
                                                        </Inputs>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <span className=' f-14'>خواندن نوشتن</span>
                                                        <Inputs>
                                                            <select name={`reading_writing[${row.id}]`}
                                                                    onChange={inputHandler}
                                                                    value={inputs[`reading_writing[${row.id}]`]}>
                                                                <option value="ضعیف">ضعیف</option>
                                                                <option value="متوسط">متوسط</option>
                                                                <option value="عالی">عالی</option>
                                                            </select>
                                                        </Inputs>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <span className=' f-14'>ترجمه و گزارش نویسی
                                                        <span className='dv-required-star'>*</span>
                                                        </span>
                                                        <Inputs>
                                                            <select name={`translation_report[${row.id}]`}
                                                                    onChange={inputHandler}
                                                                    value={inputs[`translation_report[${row.id}]`]}>
                                                                <option value="ضعیف">ضعیف</option>
                                                                <option value="متوسط">متوسط</option>
                                                                <option value="عالی">عالی</option>
                                                            </select>
                                                        </Inputs>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <span className=' f-14'>مذاکره تجاری
                                                        <span className='dv-required-star'>*</span>
                                                        </span>
                                                        <Inputs>
                                                            <select name={`business_negotiation[${row.id}]`}
                                                                    onChange={inputHandler}
                                                                    value={inputs[`business_negotiation[${row.id}]`]}>
                                                                <option value="ضعیف">ضعیف</option>
                                                                <option value="متوسط">متوسط</option>
                                                                <option value="عالی">عالی</option>
                                                            </select>
                                                        </Inputs>
                                                    </div>

                                                    <div className="col-12 d-flex justify-content-center">
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                            <Inputs>

                                                                <button type='button'
                                                                        onClick={() => editHandler(row.id)}>

                                                                    <span>ثبت تغییرات</span>

                                                                    <LeftIcon><span
                                                                        className='icon-cheveron-left'/></LeftIcon>

                                                                </button>

                                                            </Inputs>

                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))
                                }
                                {
                                    new_collapse &&
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => changeArrow(0)}>

                                            <div className="d-flex flex-wrap">

                                                <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                      onClick={removeNewCollapse}></span>

                                                {
                                                    inputs2?.language &&
                                                    <span className="pl-4 f-14 my-2"> زبان : {inputs2.language}</span>
                                                }
                                                {
                                                    inputs2?.conversation &&
                                                    <span
                                                        className="pl-4 f-14 my-2"> مکالمه : {inputs2.conversation}</span>
                                                }
                                                {
                                                    inputs2?.reading_writing &&
                                                    <span
                                                        className="pl-4 f-14 my-2"> خواندن نوشتن : {inputs2.reading_writing}</span>
                                                }
                                                {
                                                    inputs2?.translation_report &&
                                                    <span
                                                        className="pl-4 f-14 my-2"> ترجمه و گزارش نویسی : {inputs2.translation_report}</span>
                                                }
                                                {
                                                    inputs2?.business_negotiation &&
                                                    <span
                                                        className="pl-4 f-14 my-2"> مذاکره تجاری : {inputs2.business_negotiation}</span>
                                                }

                                            </div>

                                            <span
                                                className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}></span>

                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className='row'>
                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <Inputs>
                                                        <span>عنوان
                                                        <span className='dv-required-star'>*</span>
                                                        </span>
                                                        <select name={'language'}
                                                                className={error['language'] && 'afa-error-input'}
                                                                value={inputs2.language} onChange={inputHandler2}>
                                                            <option value="انتخاب کنید" disabled>انتخاب کنید</option>
                                                            <option value="انگلیسی">انگلیسی</option>
                                                            <option value="آلمانی">آلمانی</option>
                                                            <option value="عربی">عربی</option>
                                                            <option value="فرانسوی">فرانسوی</option>
                                                            <option value="روسی">روسی</option>
                                                            <option value="ترکی استانبولی">ترکی استانبولی</option>
                                                        </select>
                                                        <p className='afa-error-text'>{error2['language']}</p>
                                                    </Inputs>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <span className=' f-14'>مکالمه
                                                    <span className='dv-required-star'>*</span>
                                                    </span>
                                                    <Inputs>
                                                        <select name={`conversation`} onChange={inputHandler2}
                                                                value={inputs2[`conversation`]}>
                                                            <option value="ضعیف">ضعیف</option>
                                                            <option value="متوسط">متوسط</option>
                                                            <option value="عالی">عالی</option>
                                                        </select>
                                                    </Inputs>
                                                    {/*<div className="d-flex flex-wrap mt-3">*/}
                                                    {/*    <label className="afa-custom-checkbox ml-2">*/}
                                                    {/*        ضعیف*/}
                                                    {/*        <input type="radio" value='ضعیف' onChange={inputHandler2}*/}
                                                    {/*               checked={inputs2.conversation === 'ضعیف'}*/}
                                                    {/*               name="conversation"/>*/}
                                                    {/*        <span className="checkmark"/>*/}
                                                    {/*    </label>*/}

                                                    {/*    <label className="afa-custom-checkbox ml-2">*/}
                                                    {/*        متوسط*/}
                                                    {/*        <input type="radio" value='متوسط' onChange={inputHandler2}*/}
                                                    {/*               checked={inputs2.conversation === 'متوسط'}*/}
                                                    {/*               name="conversation"/>*/}
                                                    {/*        <span className="checkmark"/>*/}
                                                    {/*    </label>*/}

                                                    {/*    <label className="afa-custom-checkbox ml-2">*/}
                                                    {/*        عالی*/}
                                                    {/*        <input type="radio" value='عالی' onChange={inputHandler2}*/}
                                                    {/*               checked={inputs2.conversation === 'عالی'}*/}
                                                    {/*               name="conversation"/>*/}
                                                    {/*        <span className="checkmark"/>*/}
                                                    {/*    </label>*/}
                                                    {/*</div>*/}
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <span className=' f-14'>خواندن نوشتن
                                                    <span className='dv-required-star'>*</span>
                                                    </span>
                                                    <Inputs>
                                                        <select name={`reading_writing`} onChange={inputHandler2}
                                                                value={inputs2[`reading_writing`]}>
                                                            <option value="ضعیف">ضعیف</option>
                                                            <option value="متوسط">متوسط</option>
                                                            <option value="عالی">عالی</option>
                                                        </select>
                                                    </Inputs>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <span className=' f-14'>ترجمه و گزارش نویسی</span>
                                                    <Inputs>
                                                        <select name={`translation_report`} onChange={inputHandler2}
                                                                value={inputs2[`translation_report`]}>
                                                            <option value="ضعیف">ضعیف</option>
                                                            <option value="متوسط">متوسط</option>
                                                            <option value="عالی">عالی</option>
                                                        </select>
                                                    </Inputs>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <span className=' f-14'>مذاکره تجاری</span>
                                                    <Inputs>
                                                        <select name={`business_negotiation`} onChange={inputHandler2}
                                                                value={inputs2[`business_negotiation`]}>
                                                            <option value="ضعیف">ضعیف</option>
                                                            <option value="متوسط">متوسط</option>
                                                            <option value="عالی">عالی</option>
                                                        </select>
                                                    </Inputs>
                                                </div>

                                                <div className="col-12">
                                                    <div className="row d-flex flex-column flex-sm-row justify-content-between">
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <button type='button'
                                                                        disabled={inputs2.language === 'انتخاب کنید'}
                                                                        onClick={addNewWhenCollapseHave}>
                                                                    <span>ثبت نهایی</span>
                                                                </button>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <button type='button'
                                                                        className={'dv-save-information-btn dv-next-btn'}
                                                                        onClick={goToNextPage}>
                                                                    <span>مرحله بعد</span>
                                                                    <LeftIcon><span
                                                                        className='icon-cheveron-left'/></LeftIcon>
                                                                </button>
                                                            </Inputs>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                }
                            </Accordion>
                            {
                                show_add_more &&
                                <div className="row d-flex flex-column flex-sm-row justify-content-between">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>
                                        <Inputs>
                                            <button type='button' onClick={addMoreCollapse}>
                                                <span className='icon-add f-20'/>
                                                <span className='pr-3'>افزودن جدید</span>
                                            </button>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                                    onClick={goToNextPage}>
                                                <span>مرحله بعد</span>
                                                <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                            </button>
                                        </Inputs>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Inputs>
                                    <span>عنوان
                                    <span className='dv-required-star'>*</span>
                                    </span>
                                    <select name={'language'} className={error['language'] && 'afa-error-input'}
                                            value={inputs.language} onChange={inputHandler}>
                                        <option value="انتخاب کنید" disabled>انتخاب کنید</option>
                                        <option value="انگلیسی">انگلیسی</option>
                                        <option value="آلمانی">آلمانی</option>
                                        <option value="عربی">عربی</option>
                                        <option value="فرانسوی">فرانسوی</option>
                                        <option value="روسی">روسی</option>
                                        <option value="ترکی استانبولی">ترکی استانبولی</option>
                                    </select>
                                    <p className='afa-error-text'>{error['language']}</p>
                                </Inputs>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <span className=' f-14'>مکالمه
                                <span className='dv-required-star'>*</span>
                                </span>
                                <Inputs>
                                    <select name={`conversation`} onChange={inputHandler}
                                            value={inputs[`conversation`]}>
                                        <option value="ضعیف">ضعیف</option>
                                        <option value="متوسط">متوسط</option>
                                        <option value="عالی">عالی</option>
                                    </select>
                                </Inputs>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <span className=' f-14'>خواندن نوشتن
                                <span className='dv-required-star'>*</span>
                                </span>
                                <Inputs>
                                    <select name={`reading_writing`} onChange={inputHandler}
                                            value={inputs[`reading_writing`]}>
                                        <option value="ضعیف">ضعیف</option>
                                        <option value="متوسط">متوسط</option>
                                        <option value="عالی">عالی</option>
                                    </select>
                                </Inputs>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <span className=' f-14'>ترجمه و گزارش نویسی
                                <span className='dv-required-star'>*</span>
                                </span>
                                <Inputs>
                                    <select name={`translation_report`} onChange={inputHandler}
                                            value={inputs[`translation_report`]}>
                                        <option value="ضعیف">ضعیف</option>
                                        <option value="متوسط">متوسط</option>
                                        <option value="عالی">عالی</option>
                                    </select>
                                </Inputs>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <span className=' f-14'>مذاکره تجاری
                                <span className='dv-required-star'>*</span>
                                </span>
                                <Inputs>
                                    <select name={`business_negotiation`} onChange={inputHandler}
                                            value={inputs[`business_negotiation`]}>
                                        <option value="ضعیف">ضعیف</option>
                                        <option value="متوسط">متوسط</option>
                                        <option value="عالی">عالی</option>
                                    </select>
                                </Inputs>
                            </div>

                            <div className="col-12 d-flex flex-column flex-sm-row justify-content-between">
                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                    <Inputs>
                                        <button type='submit' disabled={inputs.language === 'انتخاب کنید'}>
                                            <span>ثبت این صفحه</span>
                                        </button>
                                    </Inputs>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                    <Inputs>
                                        <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                                onClick={goToNextPage}>
                                            <span>مرحله بعد</span>
                                            <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                        </button>
                                    </Inputs>
                                </div>
                            </div>
                            {
                                show_add_more &&
                                <div className="d-flex justify-content-end">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>

                                        <Inputs>

                                            <button type='button' onClick={addMoreCollapse}
                                                    className='afa-add-more-btn'>

                                                <span className='icon-add f-20'/>

                                                <span className='pr-3'>افزودن جدید</span>

                                            </button>

                                        </Inputs>

                                    </div>
                                </div>
                            }
                        </>
                }
            </form>
        </>
    )
}

export default ForeignLanguages;