import React, {useEffect} from "react";
import Slider from '@material-ui/core/Slider';
import './range-slider.scss'
import {priceFormat} from "../../../assets/scripts/GeneralFunctions";

const RangeSlider = (props) => {

    // Our States

    const [value, setValue] =  React.useState([parseFloat(props?.minRenge),parseFloat(props?.maxRenge)]);
    // useEffect(()=>(
    //     // props.sliderValue ? setValue([props.sliderValue?.split(';')[0], props.sliderValue?.split(';')[1]]) : '',
    //     props.localValue ? setValue([props.localValue?.split(';')[0], props.localValue?.split(';')[1]]) : ''
    // ), [props.localValue])
    // Changing State when volume increases/decreases
    const rangeSelector = (event, newValue) => {
        setValue(newValue);
        props.rangeValueProps(newValue);
    };


    return (
        <div>
            <div className="d-flex flex-column w-100 mt-3 mb-5">
                <div className="d-flex align-items-center justify-content-between">
                    <span>حداقل {props.title} : </span>
                    <span>{priceFormat(value[0])}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <span>حداکثر {props.title} : </span>
                    <span>{priceFormat(value[1])}</span>
                </div>
            </div>
            <Slider
                value={value}
                min={parseFloat(props?.minRenge)}
                step={1}
                max={parseFloat(props?.maxRenge)}
                onChange={rangeSelector}
                valueLabelDisplay="auto"
            />
        </div>
    );
}

export default RangeSlider;
