import React, {useState, useEffect} from 'react';
import {MAIN_URL} from "../../../assets/scripts/GeneralVariables";
import {
    edit_item_with_id,
    getData,
    getFormDataPost,
    remove_item_of_arr_with_id, setTitle
} from "../../../assets/scripts/GeneralFunctions";
import {MainContent} from "./style";
import Swal from "sweetalert2";
import {SearchInput} from "../Child/SupplierMangement/style";
import {Modal} from "react-bootstrap";
import {Inputs} from "../Child/Supplier/Child/style";
import Skeleton from 'react-loading-skeleton'
import {toast} from "react-toastify";

function Services(props) {
    const [modal, setModal] = useState({show_edit_Service: false, show_new_Service: false});
    const [inputs, setInputs] = useState({
        search_value: '',
        edit_software_name: '',
        title: '', id: null,
        Service_name: '',
        services: [NaN, NaN, NaN, NaN],
        main_services: [],
        prent_services: [],
        parent_service_id: 'انتخاب کنید',
        main_service_id: 'انتخاب کنید',
    })

    const [is_all_items_load, setAllLoad] = useState(true)

    const [data, setObject] = useState({
        offset: 0,
        limit: 30
    })

    useEffect(async () => {
        setTitle('آتاویتا | خدمات')
        window.scrollTo(0, 0)
        let api_result = await getData(MAIN_URL, `service-management/services/list`, 'get', data, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                services: api_result?.data?.services,
                main_services: api_result?.data?.main_services,
            }))
        }
    }, []);

    const searchHandler = async (e) => {
        let object = {};
        object = data;
        object['search'] = e.target.value
        setInputs(prevState => ({
            ...prevState,
            search_value: e.target.value,
            services: [NaN, NaN, NaN, NaN],
        }))
        let api_result = await getData(MAIN_URL, `service-management/services/list`, 'get', object, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                services: api_result?.data?.services,
                main_services: api_result?.data?.main_services,
            }))
            setObject(object)
        }
    }

    const showNewService = () => {
        setModal(prevState => ({...prevState, show_new_Service: true}))
    }

    const newServiceHandler = async (e) => {
        e.preventDefault()
        let form_data = new FormData()
        form_data.append('title', inputs?.title)
        if (inputs?.main_service_id !== 'انتخاب کنید') {
            form_data.append('main_service_id', inputs?.main_service_id)
        }
        if (inputs?.parent_service_id !== 'انتخاب کنید') {
            form_data.append('parent_service_id', inputs?.parent_service_id)
        }

        let api_result = await getFormDataPost(`service-management/services/create`, form_data);

        if (api_result?.status === 200) {
            let arr = []
            arr[0] = api_result?.data?.service
            setInputs(prevState => ({
                ...prevState,
                services: arr.concat(inputs?.services),
                title: '',
                main_service_id: 'انتخاب کنید',
                parent_service_id: 'انتخاب کنید'
            }))
            toast.success('با موفقیت افزوده شد')
        }
        setModal(prevState => ({...prevState, show_new_Service: false}))
    }

    const mainServiceHandler = async (id) => {
        let api_result = await getData(MAIN_URL, `service-management/services/${id}`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                prent_services: api_result?.data?.services, parent_service_id: 'انتخاب کنید', main_service_id: id
            }))
        }
    }

    const editService = async (id, row) => {
        setInputs(prevState => ({
            ...prevState,
            main_service_id: 'انتخاب کنید',
            parent_service_id: 'انتخاب کنید',
            id: id,
        }))
        if (row?.parent !== null) {
            let api_result = await getData(MAIN_URL, `service-management/services/${row?.parent?.parent?.id ? row?.parent?.parent?.id : row?.parent?.id}`, 'get', {}, true, true);
            if (api_result?.status === 200) {
                setModal(prevState => ({...prevState, show_edit_Service: true}))
                setInputs(prevState => ({
                    ...prevState,
                    prent_services: api_result?.data?.services,
                    main_service_id: row?.parent ? (row.parent.parent ? row.parent.parent.id : row.parent.id) : 'انتخاب کنید',
                    parent_service_id: row?.parent ? (row.parent.parent ? row.parent.id : 'انتخاب کنید') : 'انتخاب کنید',
                    title: row?.title
                }))
            }
        } else {
            setInputs(prevState => ({
                ...prevState,
                main_service_id: 'انتخاب کنید',
                parent_service_id: 'انتخاب کنید',
                title: row?.title
            }))
        }
    }

    const editServiceHandler = async (e) => {
        e.preventDefault()
        let form_data = new FormData()
        form_data.append('title', inputs?.title)

        if (inputs?.main_service_id !== 'انتخاب کنید') {
            form_data.append('main_service_id', inputs?.main_service_id)
        }
        if (inputs?.parent_service_id !== 'انتخاب کنید') {
            form_data.append('parent_service_id', inputs?.parent_service_id)
        }

        let api_result = await getFormDataPost(`service-management/services/edit/${inputs?.id}`, form_data);

        if (api_result?.status === 200) {
            let arr = edit_item_with_id(inputs?.services, api_result?.data?.service)
            setInputs(prevState => ({
                ...prevState,
                services: arr, title: '', main_service_id: 'انتخاب کنید', parent_service_id: 'انتخاب کنید'
            }))
            toast.success('با موفقیت تغییر یافت')
        }
        setModal(prevState => ({...prevState, show_edit_Service: false}))
    }

    const removeService = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `service-management/services/remove/${id}`, 'post', {}, true, false);
                if (remove_result?.status === 200) {
                    let arr = inputs?.services;
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, services: new_arr}))
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                }
            }
        })
    }

    // lazy load
    const getTableDataLazyLoad = async (obj_data) => {
        if (parseFloat(obj_data.target.offsetHeight + obj_data.target.scrollTop) == parseFloat(obj_data.target.scrollHeight)) {
            if (inputs?.services?.length % 30 === 0 && is_all_items_load === true) {
                let object = {};
                object = data;
                object['offset'] = inputs?.services?.length
                let moreData = await getData(MAIN_URL, `service-management/services/list`, 'get', object, true, true);
                if (moreData) {
                    setObject(object)
                    setInputs(prevState => ({...prevState, services: inputs?.services.concat(moreData.data?.services)}))
                    if (moreData?.data?.services?.length < 30) {
                        setAllLoad({is_all_items_load: false})
                    }
                }
            }
        }
    }


    const closeModal = () => {
        setModal(prevState => ({...prevState, show_edit_Service: false, show_new_Service: false}))
    }
    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 col-sm-6 col-lg-6 mb-3"><h6 className='text-red fw-bold'>مدیریت خدمات</h6></div>
                <div className="col-12 col-sm-6 col-lg-6 d-flex align-items-center justify-content-end">
                    <SearchInput className='mb-3 w-100'>
                        <span className='icon-Search dv-search-icon'/>
                        <input type="search" value={inputs?.search_value} onChange={searchHandler} placeholder='جستجو'/>
                    </SearchInput>
                    <button className='add-new-btn mb-3' onClick={showNewService}>
                        <span className="icon-add_paper"/>
                        <span>افزودن جدید</span>
                    </button>
                </div>
                <div className="col-12 mb-3 afa-lazyload" onScroll={getTableDataLazyLoad}>
                    <table id={'afa_user_table'}>
                        <thead>
                        <tr>
                            <td>عنوان خدمت</td>
                            <td>نوع خدمت</td>
                            <td>خدمت</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            inputs?.services?.length > 0 ?
                                !inputs?.services[0] ?
                                    inputs?.services?.map((row, index) => (
                                        <tr key={index}>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                        </tr>
                                    )) :
                                    inputs?.services?.map((row, index) => (
                                        <tr key={index}>
                                            <td>
                                                <span className='pl-2 afa-tbody-text'>{parseInt(index) + 1} -</span>
                                                <span
                                                    className="afa-tbody-text">{row?.parent ? (row?.parent?.parent ? row?.parent?.parent?.title : row?.parent?.title) : row?.title}</span>
                                            </td>
                                            <td><span
                                                className="afa-tbody-text">{row?.parent ? (row?.parent?.parent ? row?.parent?.title : row?.title) : '- - -'}</span>
                                            </td>
                                            <td><span
                                                className="afa-tbody-text">{row?.parent ? (row?.parent?.parent ? row?.title : '- - -') : '- - -'}</span>
                                            </td>
                                            <td className='afa-table-btns-width'>
                                                <div className="d-flex align-items-center">
                                                    <button type={'button'} className='afa-user-status-active-table-btn'
                                                            onClick={() => editService(row?.id, row)}>ویرایش
                                                    </button>
                                                    <button type={'button'} className='afa-user-status-table-btn'
                                                            onClick={() => removeService(row?.id)}>حذف
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <tr>
                                    <td></td>
                                    <td className='text-center'>آیتمی وجود ندارد</td>
                                    <td></td>
                                </tr>
                        }


                        </tbody>
                    </table>
                </div>
            </div>

            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_new_Service} onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={newServiceHandler}>
                        <div className="col-12">
                            <h6 className='mb-4 text-red'>افزودن خدمت جدید</h6>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>عنوان خدمت</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`title`}
                                    placeholder={'مکانیکی'}
                                    value={inputs?.[`title`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`title`]: e.target.value
                                        }))
                                    }/>
                            </Inputs>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>نوع خدمت</span>
                                <select
                                    className={'afa-error-input'}
                                    name={`main_service_id`}
                                    value={inputs?.[`main_service_id`]}
                                    onChange={(e) => mainServiceHandler(e.target.value)}>
                                    <option value="انتخاب کنید">انتخاب کنید</option>
                                    {
                                        inputs?.main_services?.map((row, index) => (
                                            <option value={row?.id} key={index}>{row?.title}</option>
                                        ))
                                    }
                                </select>
                            </Inputs>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>خدمات</span>
                                <select
                                    className={'afa-error-input'}
                                    name={`parent_service_id`}
                                    value={inputs?.[`parent_service_id`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`parent_service_id`]: e.target.value
                                        }))
                                    }>
                                    <option value="انتخاب کنید">انتخاب کنید</option>
                                    {
                                        inputs?.prent_services?.map((row, index) => (
                                            <option value={row?.id} key={index}>{row?.title}</option>
                                        ))
                                    }
                                </select>
                            </Inputs>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button disabled={inputs?.[`title`] ? false : true} type='submit'
                                    className='afa-btn-save-modal'>ذخیره
                            </button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_edit_Service}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={editServiceHandler}>
                        <div className="col-12">
                            <h6 className='mb-4 text-red'>ویرایش خدمت</h6>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>عنوان خدمت</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`title`}
                                    placeholder={'مکانیکی'}
                                    value={inputs?.[`title`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`title`]: e.target.value
                                        }))
                                    }/>
                            </Inputs>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>نوع خدمت</span>
                                <select
                                    className={'afa-error-input'}
                                    name={`main_service_id`}
                                    value={inputs?.[`main_service_id`]}
                                    onChange={(e) => mainServiceHandler(e.target.value)}>
                                    <option value="انتخاب کنید">انتخاب کنید</option>
                                    {
                                        inputs?.main_services?.map((row, index) => (
                                            <option value={row?.id} key={index}>{row?.title}</option>
                                        ))
                                    }
                                </select>
                            </Inputs>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>خدمات</span>
                                <select
                                    className={'afa-error-input'}
                                    name={`parent_service_id`}
                                    value={inputs?.[`parent_service_id`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`parent_service_id`]: e.target.value
                                        }))
                                    }>
                                    <option value="انتخاب کنید">انتخاب کنید</option>
                                    {
                                        inputs?.prent_services?.map((row, index) => (
                                            <option value={row?.id} key={index}>{row?.title}</option>
                                        ))
                                    }
                                </select>
                            </Inputs>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button disabled={inputs?.[`title`] ? false : true} type='submit'
                                    className='afa-btn-save-modal'>ذخیره
                            </button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

        </MainContent>
    );
}

export default Services;