import React, {useEffect, useState} from 'react';
import {checkCodeMelli, fileUpload, getData, getFormDataPost} from "../../../../../assets/scripts/GeneralFunctions";
import {FileInputs, Inputs, LeftIcon, TabStatusComplete, TabStatusContent, TabStatusNotComplete} from "./style";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import Swal from "sweetalert2";
import {MAIN_URL} from "../../../../../assets/scripts/GeneralVariables";
import {toast} from "react-toastify";
import {LoopCircleLoading} from "react-loadingg";

function Software(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        level: 'انتخاب کنید',
        software: 'انتخاب کنید',
        computer_softwares: []
    })
    const [inputs2, setInputs2] = useState({
        level: 'انتخاب کنید',
        software: 'انتخاب کنید',
        computer_softwares: []
    })
    const [error2, setError2] = useState({})
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});
    const [is_render, setRender] = useState(false);
    const [new_collapse, setNewCollapse] = useState(false);
    const [show_add_more, setShowAddMore] = useState(false);
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false});

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/softwares", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data

            let softwares = data?.softwares
            if (softwares?.length === 0) {
                setInputs({
                    software: 'انتخاب کنید',
                    level: 'انتخاب کنید',
                    computer_softwares: data?.computer_softwares
                })
                setShowAddMore(false)
            } else {
                setInputs(prevState => ({
                    ...prevState,
                    softwares: softwares,
                    computer_softwares: data?.computer_softwares
                }))
                setShowAddMore(true)
            }
            setMoreData({sheet_status: data?.sheet_status, status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const inputHandler2 = (e) => {
        setInputs2(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs['software']) {
            formData.append('software', inputs['software'])
        }
        if (inputs['level']) {
            formData.append('level', inputs['level'])
        }

        let api_result = await getFormDataPost('profile/softwares', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setShowAddMore(true)
            setNewCollapse(false)
            setRender(!is_render)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addMoreCollapse = () => {
        setInputs2(prevState => ({
            ...prevState,
            level: 'انتخاب کنید',
            software: 'انتخاب کنید',
        }));
        setShowAddMore(false)
        setNewCollapse(true)
    }
    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/softwares/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    setRender(!is_render)
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }


    const changeArrow = async (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }
        let data = inputs.softwares?.find((row) => {
            return row.id === id
        })
        if (data) {
            setInputs((prevState => ({
                ...prevState,
                [`software[${id}]`]: data?.pivot?.software_id,
                [`level[${id}]`]: data?.pivot?.level,
            })))
        }
    }

    const removeNewCollapse = () => {
        setNewCollapse(false)
        setShowAddMore(true)
    }
    const addNewWhenCollapseHave = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs2['software']) {
            formData.append('software', inputs2['software'])
        }
        if (inputs2['level']) {
            formData.append('level', inputs2['level'])
        }

        let api_result = await getFormDataPost('profile/softwares', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setShowAddMore(true)
            setNewCollapse(false)
            setRender(!is_render)
            setInputs2({
                level: 'انتخاب کنید',
                software: 'انتخاب کنید',
            })
        } else if (api_result?.status === 400) {
            setError2(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }


    const editHandler = async (id) => {
        let formData = new FormData()

        if (inputs[`software[${id}]`]) {
            formData.append('software', inputs[`software[${id}]`])
        }
        if (inputs[`level[${id}]`]) {
            formData.append('level', inputs[`level[${id}]`])
        }

        let api_result = await getFormDataPost(`profile/softwares/${id}`, formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            setShowAddMore(true)
            // setNewCollapse(false)
            setRender(!is_render)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
            setError(prevState => ({...prevState, id: id}))
        }
    }

    const goToNextPage = () => {
        props.handleChange(8)
    }

    let softwares = inputs?.softwares;
    let computer_softwares = inputs?.computer_softwares;

    return (
        <>
            <form onSubmit={formHandler} className="row">
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <TabStatusComplete
                        style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                        {
                            more_data.sheet_status?.status === 'pending' || more_data.sheet_status?.status === 'denied' ?
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-tick afa-icon-tick'/>
                        }
                        <p className='mb-0 f-14 '>
                            <span>وضعیت : </span><span>{more_data.status_colors?.[more_data.sheet_status?.status]?.title}</span>
                        </p>
                    </TabStatusComplete>
                </div>
                <div className="col-12 col-sm-6 col-lg-8 mb-4">
                    {
                        more_data.sheet_status?.message &&
                        <TabStatusContent
                            style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <p className='mb-0 f-14'>
                                <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                            </p>
                        </TabStatusContent>
                    }
                </div>
                {
                    softwares?.length > 0 ?
                        <>
                            <Accordion defaultActiveKey="0">
                                {
                                    softwares?.map((row, index) => (
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey={row.id}
                                                              onClick={() => changeArrow(row.id)}>
                                                <div className="d-flex flex-wrap">
                                                    <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                          onClick={() => removeTab(row.id)}/>
                                                    <span className="pl-4 f-14 my-2"> نرم افزار : {row?.name}</span>
                                                    <span className="pl-4 f-14 my-2"> سطح : {row?.pivot?.level}</span>
                                                </div>
                                                <span
                                                    className={(arrow_item.arrow_id === row.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={row.id}>
                                                <Card.Body className='row'>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <span>نرم افزار
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <select
                                                                className={(row.id === error['id'] && error['software']) && 'afa-error-input'}
                                                                value={inputs[`software[${row.id}]`]}
                                                                onChange={inputHandler}
                                                                name={`software[${row.id}]`}>
                                                                <option value='انتخاب کنید'>انتخاب کنید</option>
                                                                {
                                                                    computer_softwares?.map((item, i) => (
                                                                        <option value={item.id}
                                                                                key={i}>{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            {
                                                                (row.id === error['id'] && error['software']) &&
                                                                <p className='afa-error-text'>{error['software']}</p>
                                                            }
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <span className='f-14'>سطح
                                                        <span className='dv-required-star'>*</span>
                                                        </span>
                                                        <Inputs>
                                                            <select name={[`level[${row.id}]`]} onChange={inputHandler}
                                                                    value={inputs[[`level[${row.id}]`]]}>
                                                                <option value="انتخاب کنید" disabled>انتخاب کنید
                                                                </option>
                                                                <option value="مبتدی">مبتدی</option>
                                                                <option value="متوسط">متوسط</option>
                                                                <option value="پیشرفته">پیشرفته</option>
                                                            </select>
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <button
                                                                    disabled={inputs[[`level[${row.id}]`]] !== 'انتخاب کنید' && inputs[[`software[${row.id}]`]] !== 'انتخاب کنید' ? false : true}
                                                                    type='button'
                                                                    onClick={() => editHandler(row.id)}>
                                                                    <span>ثبت این صفحه</span>
                                                                    <LeftIcon><span
                                                                        className='icon-cheveron-left'/></LeftIcon>
                                                                </button>
                                                            </Inputs>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))
                                }
                                {
                                    new_collapse &&
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => changeArrow(0)}>

                                            <div className="d-flex flex-wrap">
                                                <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                      onClick={removeNewCollapse}></span>
                                            </div>

                                            <span
                                                className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}></span>

                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className='row'>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <Inputs>
                                                        <span>نرم افزار
                                                        <span className='dv-required-star'>*</span>
                                                        </span>
                                                        <select className={error2['software'] && 'afa-error-input'}
                                                                value={inputs2.software}
                                                                onChange={inputHandler2}
                                                                name={`software`}>
                                                            <option value='انتخاب کنید'>انتخاب کنید</option>
                                                            {
                                                                computer_softwares?.map((item, i) => (
                                                                    <option value={item.id}
                                                                            key={i}>{item.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <p className='afa-error-text'>{error2['software']}</p>
                                                    </Inputs>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <span className='f-14'>سطح
                                                    <span className='dv-required-star'>*</span>
                                                    </span>
                                                    <Inputs>
                                                        <select name={`level`} onChange={inputHandler2}
                                                                value={inputs2[`level`]}>
                                                            <option value="انتخاب کنید" disabled>انتخاب کنید</option>
                                                            <option value="مبتدی">مبتدی</option>
                                                            <option value="متوسط">متوسط</option>
                                                            <option value="پیشرفته">پیشرفته</option>
                                                        </select>
                                                    </Inputs>
                                                </div>

                                                <div
                                                    className="col-12 d-flex flex-column flex-sm-row justify-content-between">
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <button
                                                                disabled={inputs2[`level`] !== 'انتخاب کنید' && inputs2[`software`] !== 'انتخاب کنید' ? false : true}
                                                                type='button' onClick={addNewWhenCollapseHave}>
                                                                <span>ثبت این صفحه</span>
                                                            </button>
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                                                    onClick={goToNextPage}>
                                                                <span>مرحله بعد</span>
                                                                <LeftIcon><span
                                                                    className='icon-cheveron-left'/></LeftIcon>
                                                            </button>
                                                        </Inputs>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                }
                            </Accordion>
                            {
                                show_add_more &&
                                <div className="row d-flex flex-column flex-sm-row justify-content-between">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>
                                        <Inputs>
                                            <button type='button' onClick={addMoreCollapse}
                                                    className='afa-add-more-btn'>
                                                <span className='icon-add f-20'/>
                                                <span className='pr-3'>افزودن جدید</span>
                                            </button>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                                    onClick={goToNextPage}>
                                                <span>مرحله بعد</span>
                                                <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                            </button>
                                        </Inputs>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Inputs>
                                    <span>نرم افزار
                                    <span className='dv-required-star'>*</span>
                                    </span>
                                    <select className={error['software'] && 'afa-error-input'} value={inputs.software}
                                            onChange={inputHandler} name={'software'}>
                                        <option value='انتخاب کنید'>انتخاب کنید</option>
                                        {
                                            computer_softwares?.map((item, i) => (
                                                <option value={item.id} key={i}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className='afa-error-text'>{error['software']}</p>
                                </Inputs>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <span className='f-14'>سطح
                                <span className='dv-required-star'>*</span>
                                </span>
                                <Inputs>
                                    <select name={`level`} onChange={inputHandler} value={inputs[`level`]}>
                                        <option value="انتخاب کنید" disabled>انتخاب کنید</option>
                                        <option value="مبتدی">مبتدی</option>
                                        <option value="متوسط">متوسط</option>
                                        <option value="پیشرفته">پیشرفته</option>
                                    </select>
                                </Inputs>
                            </div>

                            <div className="col-12 d-flex flex-column flex-sm-row justify-content-between">
                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                    <Inputs>
                                        <button
                                            disabled={inputs[`level`] !== 'انتخاب کنید' && inputs[`software`] !== 'انتخاب کنید' ? false : true}
                                            type='submit'>
                                            <span>ثبت این صفحه</span>
                                        </button>
                                    </Inputs>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                    <Inputs>
                                        <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                                onClick={goToNextPage}>
                                            <span>مرحله بعد</span>
                                            <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                        </button>
                                    </Inputs>
                                </div>
                            </div>

                            {
                                show_add_more &&
                                <div className="d-flex justify-content-end">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>
                                        <Inputs>
                                            <button type='button' onClick={addMoreCollapse}
                                                    className='afa-add-more-btn'>

                                                <span className='icon-add f-20'/>

                                                <span className='pr-3'>افزودن جدید</span>

                                            </button>
                                        </Inputs>
                                    </div>
                                </div>
                            }
                        </>
                }
            </form>
        </>
    )
}

export default Software;