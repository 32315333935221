import React, {useEffect, useState} from 'react';
import {MainContent} from "./style";
import {SearchInput} from "../Child/SupplierMangement/style";
import {Images} from "../../../assets/scripts/Images";
import {Modal} from "react-bootstrap";
import Swal from "sweetalert2";
import {
    edit_item_with_id,
    getData,
    getFormDataPost,
    remove_item_of_arr_with_id, setTitle
} from "../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../assets/scripts/GeneralVariables";
import {Inputs} from "../Child/Supplier/Child/style";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import Skeleton from 'react-loading-skeleton'

function Skills() {
    const [inputs, setInputs] = useState({
        search_value: '',
        edit_skill_name: '',
        new_skill_name: '',
        skills: [NaN, NaN, NaN, NaN],
        skill_name: null,
        skill_id: null,
        technical_department: [],
        show_unit_list: false,
        department_id: 'انتخاب کنید',
        department_name: '',

    });
    const [modal, setModal] = useState({
        show_edit_skill: false,
        show_new_skill: false,
        show_new_department: false
    });
    const [error, setError] = useState({})
    const [is_render, setRender] = useState(false)
    const history = useHistory()
    useEffect(async () => {
        setTitle('آتاویتا | مهارت ها')
        window.scrollTo(0, 0)
        let api_result = await getData(MAIN_URL, "skill-management/skills", 'get', {}, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                skills: api_result?.data?.skills,
                technical_department: api_result?.data?.technical_department,
            }))
        }
    }, [is_render]);

    const searchHandler = async (e) => {
        setInputs(prevState => ({
            ...prevState,
            search_value: e.target.value,
            skills: [NaN, NaN, NaN, NaN],
        }))
        let api_result = await getData(MAIN_URL, `skill-management/skills`, 'get', {search: e.target.value}, true, false);
        if (api_result?.status === 200) {
            let data = api_result?.data
            setInputs(prevState => ({
                ...prevState,
                skills: data?.skills
            }))
        }
    }

    const removeSkill = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `skill-management/skills/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.skills;
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, skills: new_arr}))
                }
            }
        })
    }

    const showEditSkill = (id, row) => {
        setInputs(prevState => ({
            ...prevState,
            skill_id: id,
            skill_name: row?.name,
            department_id: row?.technical_department_id
        }))
        setModal(prevState => ({...prevState, show_edit_skill: true}))
    }

    const editSkillHandler = async (e) => {
        e.preventDefault()
        let api_result = await getData(MAIN_URL, `skill-management/skills/edit/${inputs?.skill_id}`, 'post', {
            name: inputs?.skill_name,
            technical_department_id: inputs?.department_id
        }, true, true);

        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            let arr = edit_item_with_id(inputs?.skills, api_result?.data?.skill)
            setInputs(prevState => ({...prevState, skills: arr, skill_name: ''}))
        }
        setModal(prevState => ({...prevState, show_edit_skill: false}))
    }

    const showNewSkill = () => {
        setModal(prevState => ({...prevState, show_new_skill: true}))
    }

    const newSkillHandler = async (e) => {
        e.preventDefault()
        let api_result = await getData(MAIN_URL, `skill-management/skills/create`, 'post', {
            name: inputs?.skill_name,
            technical_department_id: inputs?.department_id
        }, true, true);
        if (api_result?.status === 200) {
            toast.success('با موفقیت ثبت شد')
            let arr = [];
            arr[0] = api_result?.data?.skill
            setError('')
            setInputs(prevState => ({
                ...prevState,
                skills: arr.concat(inputs?.skills),
                skill_name: '',
                department_id: 'انتخاب کنید'
            }))
            setModal(prevState => ({...prevState, show_new_skill: false}))
        } else {
            setError(api_result?.error)
        }
    }

    // Department
    const showDepartmentModal = () => {
        setModal(prevState => ({...prevState, show_unit_list: true}))
    }
    // create
    const showNewDepartment = () => {
        setInputs(prevState => ({...prevState, department_id: 'انتخاب کنید'}))
        setModal(prevState => ({...prevState, show_new_department: true, show_unit_list: false}))
    }

    const newDepartmentHandler = async (e) => {
        e.preventDefault()
        let api_result = await getData(MAIN_URL, `skill-management/technical-departments/create`, 'post', {name: inputs?.department_name}, true, true);
        if (api_result?.status === 200) {
            toast.success('با موفقیت ثبت شد')
            let arr = [];
            arr[0] = api_result?.data?.technical_department
            setError('')
            setInputs(prevState => ({
                ...prevState,
                technical_department: arr.concat(inputs?.technical_department),
                department_name: ''
            }))
            setModal(prevState => ({...prevState, show_new_department: false, show_unit_list: true}))
        } else {
            setError(api_result?.error)
        }
    }

    // edit
    const showEditDepartment = (id, row) => {
        setInputs(prevState => ({...prevState, department_name: row?.name, department_id: row?.id}))
        setModal(prevState => ({...prevState, show_edit_department: true, show_unit_list: false}))
    }

    const editDepartmentHandler = async (e) => {
        e.preventDefault()
        let api_result = await getData(MAIN_URL, `skill-management/technical-departments/edit/${inputs?.department_id}`, 'post', {name: inputs?.department_name}, true, true);

        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            let arr = edit_item_with_id(inputs?.technical_department, api_result?.data?.technical_department)
            setInputs(prevState => ({
                ...prevState,
                technical_department: arr,
                department_name: '',
                department_id: 'انتخاب کنید'
            }))
            setModal(prevState => ({...prevState, show_edit_department: false, show_unit_list: true}))
            setError('')
        } else {
            setError(api_result?.error)
        }
    }

    // remove
    const removeDepartment = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `skill-management/technical-departments/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.technical_department;
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, technical_department: new_arr}))
                }
            }
        })
    }
    // End Department

    const showUsers = (id, title) => {
        history.push('/manage-user', {id, title, route_type: 'skill'});
    }

    const closeModal = () => {
        setModal(prevState => ({
            ...prevState,
            show_edit_skill: false,
            show_new_skill: false,
            show_users: false,
            show_unit_list: false,
            show_new_department: false,
            show_edit_department: false,
        }))
    }

    const DepartmentListModal = (e) => {
        e.preventDefault()
        setRender(!is_render)
        setModal(prevState => ({...prevState, show_unit_list: false}))
    }
    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 col-sm-3 mb-3"><h6 className='text-red fw-bold'>مهارت ها</h6></div>
                <div
                    className="col-12 col-sm-9 mb-3 d-flex flex-column flex-sm-row align-items-center justify-content-end">
                    <button className='show_units_btn' onClick={showDepartmentModal}>
                        <span>واحد ها</span>
                    </button>
                    <SearchInput className='w-100'>
                        <span className='icon-Search dv-search-icon'/>
                        <input type="search" value={inputs?.search_value} onChange={searchHandler} placeholder='جستجو'/>
                    </SearchInput>
                    <button className='add-new-btn w-100' onClick={showNewSkill}>
                        <span className="icon-add_paper"/>
                        <span>افزودن جدید</span>
                    </button>
                </div>
                <div className="col-12 mb-3 afa-lazyload">
                    <table id={'afa_user_table'}>
                        <thead>
                        <tr>
                            <td>واحد</td>
                            <td>عنوان مهارت</td>
                            <td>تعداد افراد دارای مهارت</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            inputs?.skills?.length > 0 ?
                                !inputs?.skills[0] ?
                                    inputs?.skills?.map((row, index) => (
                                        <tr key={index}>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                            <td><Skeleton borderRadius={8} height={35}/></td>
                                        </tr>
                                    )) :
                                    inputs?.skills?.map((row, index) => (
                                        <tr key={index}>
                                            <td>
                                                <span className='pl-2 afa-tbody-text'>{parseInt(index) + 1} -</span>
                                                <span
                                                    className="afa-tbody-text">{row?.technical_department?.name}</span>
                                            </td>
                                            <td><span className="afa-tbody-text">{row?.name}</span></td>
                                            <td><span className="afa-tbody-text">{row?.users_count}</span></td>
                                            <td className='afa-table-btns-width'>
                                                <div className="d-flex align-items-center">
                                                    <button type={'button'} className='afa-user-status-active-table-btn'
                                                            onClick={() => showUsers(row?.id, row.name)}>مشاهده نفرات
                                                    </button>
                                                    <button className='afa-user-status-table-btn'
                                                            onClick={() => removeSkill(row?.id)}>حذف
                                                    </button>
                                                    <button className="afa-table-edit-btn"
                                                            onClick={() => showEditSkill(row?.id, row)}>
                                                        <span className="icon-edit"/>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <tr>
                                    <td></td>
                                    <td>مهارتی وجود ندارد</td>
                                    <td></td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            {/*new skill*/}
            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_new_skill}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={newSkillHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>افزودن مهارت جدید</h6>
                            <Inputs>
                                <span>واحد</span>
                                <select
                                    className={'afa-error-input'}
                                    name={`department_id`}
                                    value={inputs?.[`department_id`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`department_id`]: e.target.value
                                        }))
                                    }>
                                    <option value={'انتخاب کنید'}>انتخاب کنید</option>
                                    {
                                        inputs?.technical_department?.map((department) => (
                                            <option value={department?.id}
                                                    key={department.id}>{department?.name}</option>
                                        ))
                                    }
                                </select>
                                <p className='afa-error-text'>{error?.['technical_department_id']}</p>
                            </Inputs>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>عنوان مهارت</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`skill_name`}
                                    value={inputs?.[`skill_name`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`skill_name`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error?.['name']}</p>
                            </Inputs>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit'
                                    className='afa-btn-save-modal'>ذخیره
                            </button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            {/*edit skill*/}
            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_edit_skill}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={editSkillHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>ویرایش مهارت</h6>
                            <Inputs>
                                <span>واحد</span>
                                <select
                                    className={'afa-error-input'}
                                    name={`department_id`}
                                    value={inputs?.[`department_id`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`department_id`]: e.target.value
                                        }))
                                    }>
                                    <option value={'انتخاب کنید'}>انتخاب کنید</option>
                                    {
                                        inputs?.technical_department?.map((department) => (
                                            <option value={department?.id} key={department?.id}>{department?.name}</option>
                                        ))
                                    }
                                </select>
                                <p className='afa-error-text'>{error?.['technical_department_id']}</p>
                            </Inputs>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>عنوان مهارت</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`skill_name`}
                                    value={inputs?.[`skill_name`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`skill_name`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error?.['name']}</p>
                            </Inputs>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            {/*department list*/}
            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_unit_list}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={DepartmentListModal}>
                        <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                            <h6 className='mb-4 text-red'>واحد ها</h6>
                            <button type={'button'} className='add-new-btn dv-w-auto' onClick={showNewDepartment}>
                                <span className="icon-add_paper"/>
                                <span>افزودن جدید</span>
                            </button>
                        </div>

                        <div className="col-12">
                            <div className="dv-units-table-parent-bg d-flex flex-column">
                                {
                                    inputs?.technical_department.map((department) => (
                                        <div key={department.id}
                                             className="d-flex align-items-center justify-content-between dv-unit-item">
                                            <span>{department.name}</span>

                                            <div className="d-flex align-items-center">
                                                <button type={'button'} className='afa-user-status-table-btn'
                                                        onClick={() => removeDepartment(department.id)}>حذف
                                                </button>
                                                <button type={'button'}
                                                        className="afa-table-edit-btn afa-table-edit-btn-dark"
                                                        onClick={() => showEditDepartment(department.id, department)}>
                                                    <span className="icon-edit"/>
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit'
                                    className='afa-btn-save-modal'>ذخیره
                            </button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/*new department*/}
            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_new_department}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={newDepartmentHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>افزودن واحد جدید</h6>
                            <Inputs>
                                <span>عنوان واحد</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`department_name`}
                                    value={inputs?.[`department_name`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`department_name`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error?.['name']}</p>
                            </Inputs>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button disabled={inputs?.[`department_name`] ? false : true} type='submit'
                                    className='afa-btn-save-modal'>ذخیره
                            </button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            {/*edit department*/}
            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_edit_department}
                   onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={editDepartmentHandler}>
                        <div className="col-12 mb-4">
                            <h6 className='mb-4 text-red'>ویرایش واحد</h6>
                            <Inputs>
                                <span>عنوان واحد</span>
                                <input
                                    className={'afa-error-input'}
                                    type="text" name={`department_name`}
                                    value={inputs?.[`department_name`]}
                                    onChange={(e) =>
                                        setInputs(prevState => ({
                                            ...prevState,
                                            [`department_name`]: e.target.value
                                        }))
                                    }/>
                                <p className='afa-error-text'>{error?.['name']}</p>
                            </Inputs>
                        </div>
                        <div
                            className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

        </MainContent>
    );
}

export default Skills;