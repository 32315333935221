import React, {useEffect, useState} from 'react';
import {
    checkCodeMelli,
    fileUpload,
    fixNumber,
    getData, getFormDataPost, just_number,
    just_persian, remove_item_with_object
} from "../../../../../assets/scripts/GeneralFunctions";
import {FileInputs, Inputs, LeftIcon, TabStatusContent, TabStatusComplete} from "./style";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import moment from "moment-jalaali";
import Swal from "sweetalert2";
import {toast} from "react-toastify";

function EducationalBackground(props) {
    const [error, setError] = useState({})
    const [new_collapse, setNewCollapse] = useState(false);
    const [inputs, setInputs] = useState({
        grade: 'انتخاب کنید',
        field: '',
        institute: '',
        grade_average: '',
        orientation: '',
        from_date: '',
        to_date: '',
        edited_obj_id: [],
        remove_educational_backgrounds: [],
        educational_document: {},
        educational_backgrounds: []
    })
    const [inputs2, setInputs2] = useState({
        grade: 'انتخاب کنید',
        field: '',
        institute: '',
        grade_average: '',
        orientation: '',
        from_date: '',
        to_date: '',
    })
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [is_render, setRender] = useState(false);
    const [more_data, setMoreData] = useState({isLoad: true});
    const [educational_list, setEducationalList] = useState([])

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/educational-background", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let educational_backgrounds = data?.educational_backgrounds;
            setMoreData({sheet_status: data?.sheet_status, status_colors: data?.status_colors, isLoad: false})

            if (educational_backgrounds?.length === 0) {
                setInputs({
                    supplementary_insurance_status: 'بله',
                    relation: 'انتخاب کنید',
                    child_gender: 'انتخاب کنید',
                    supplementary_insurance: 'انتخاب کنید',
                    full_name: null,
                    identification_code: null,
                    telephone: null,
                    educational_document: data.educational_document,
                    educational_backgrounds: []
                })
            } else {
                setInputs(prevState => ({
                    ...prevState,
                    educational_document: data.educational_document,
                    educational_backgrounds: educational_backgrounds
                }))
                // setEducationalList(educational_backgrounds)
            }

        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const emptyListAddItem = () => {

        let edit_item = {};


        if (inputs?.grade) {
            edit_item['grade'] = inputs?.grade
        }
        if (inputs?.field) {
            edit_item['field'] = inputs?.field
        }
        if (inputs?.orientation) {
            edit_item['orientation'] = inputs?.orientation
        }
        if (inputs?.institute) {
            edit_item['institute'] = inputs?.institute
        }
        if (inputs?.grade_average) {
            edit_item['grade_average'] = inputs?.grade_average
        }
        if (inputs?.from_date) {
            edit_item['from_date'] = inputs?.from_date
        }
        if (inputs?.to_date) {
            edit_item['to_date'] = inputs?.to_date
        }
        let arr = [];
        arr.push(edit_item)

        setNewCollapse(false)

        setEducationalList(arr)
    }

    const addMoreCollapse = () => {
        setInputs2(prevState => ({
            ...prevState,
            to_date: '',
            from_date: '',
            grade_average: '',
            field: '',
            grade: 'انتخاب کنید',
            institute: '',
            orientation: ''
        }))
        setNewCollapse(true)
    }

    const inputHandler2 = (e) => {
        setInputs2(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const footerBtn = (e) => {
        let educational_document = inputs?.educational_document;
        if (educational_document) {
            educational_document.mostـimportantـacademicـachievements = e.target.value
        }
        setInputs(prevState => ({
            ...prevState, educational_document
        }))
    }

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_img = await fileUpload(files[0])

        let educational_document = inputs?.educational_document;
        if (educational_document) {
            educational_document.last_educational_certificate_image = ''
        }


        setInputs(prevState => ({
            ...prevState,
            [e.target.name]: file,
            educational_document,
            remove_last_educational_certificate_image: 1,
            uploading_image: upload_img
        }))
    }

    const removeImage = (val) => {

        let educational_document = inputs?.educational_document;
        educational_document.last_educational_certificate_image = ''

        setInputs(prevState => ({
            ...prevState, [val]: '', educational_document, remove_last_educational_certificate_image: 1
        }))
    }

    const firstSave = () => {
        let edcObj = {}
        if (inputs2?.grade !== "انتخاب کنید") {
            edcObj['grade'] = inputs2?.grade
        }
        if (inputs2?.field) {
            edcObj['field'] = inputs2?.field
        }
        if (inputs2?.orientation) {
            edcObj['orientation'] = inputs2?.orientation
        }
        if (inputs2?.institute) {
            edcObj['institute'] = inputs2?.institute
        }
        if (inputs2?.grade_average) {
            edcObj['grade_average'] = inputs2?.grade_average
        }
        if (inputs2?.from_date) {
            edcObj['from_date'] = inputs2?.from_date
        }
        if (inputs2?.to_date) {
            edcObj['to_date'] = inputs2?.to_date
        }
        let arr = educational_list;
        if (inputs2?.grade) {
            arr.push(edcObj);
            setEducationalList(arr)
            setNewCollapse(false)
            setInputs2({
                grade: 'انتخاب کنید',
                field: '',
                institute: '',
                grade_average: '',
                orientation: '',
                from_date: '',
                to_date: '',
            })
            toast.success('با موفقیت افزوده شد')
        }
    }

    const firstApiResultSave = (id) => {
        let edit_item = inputs?.educational_backgrounds?.find((row) => {
            return row.id === id
        })
        if (inputs?.grade) {
            edit_item['grade'] = inputs?.grade
        }
        if (inputs?.field !== null) {
            edit_item['field'] = inputs?.field
        }
        if (inputs?.orientation !== null) {
            edit_item['orientation'] = inputs?.orientation
        }
        if (inputs?.institute !== null) {
            edit_item['institute'] = inputs?.institute
        }
        if (inputs?.grade_average !== null) {
            edit_item['grade_average'] = inputs?.grade_average
        }
        if (inputs?.from_date !== null) {
            edit_item['from_date'] = inputs?.from_date
        }
        if (inputs?.to_date !== null) {
            edit_item['to_date'] = inputs?.to_date
        }
        let arr = inputs?.educational_backgrounds;
        setInputs(prevState => ({
            ...prevState, educational_backgrounds: arr, edited_obj_id: [...inputs?.edited_obj_id, id]
        }))
        toast.success('تغییرات با موفقیت اعمال شد')
    }

    const editCreatedItems = (id) => {
        let edit_item = inputs?.editCreatedItems[id]
        if (inputs?.grade) {
            edit_item['grade'] = inputs?.grade
        }
        if (inputs?.field) {
            edit_item['field'] = inputs?.field
        }
        if (inputs?.orientation) {
            edit_item['orientation'] = inputs?.orientation
        }
        if (inputs?.institute) {
            edit_item['institute'] = inputs?.institute
        }
        if (inputs?.grade_average) {
            edit_item['grade_average'] = inputs?.grade_average
        }
        if (inputs?.from_date) {
            edit_item['from_date'] = inputs?.from_date
        }
        if (inputs?.to_date) {
            edit_item['to_date'] = inputs?.to_date
        }
        let arr = inputs?.editCreatedItems;
        setInputs(prevState => ({
            ...prevState, editCreatedItems: arr
        }))
        toast.success('تغییرات با موفقیت ثبت شد')
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {

                setEducationalList(remove_item_with_object(educational_list, id))

                setInputs(prevState => ({
                    ...prevState, educational_backgrounds: inputs?.educational_backgrounds?.filter(function (person) {
                        return person.id !== id
                    }), remove_educational_backgrounds: [...inputs.remove_educational_backgrounds, id],
                    grade: 'انتخاب کنید',
                    field: '',
                    institute: '',
                    grade_average: '',
                    orientation: '',
                    from_date: '',
                    to_date: '',
                }))

                swalWithBootstrapButtons.fire(
                    'حذف شد!',
                    'با موفقیت حذف شد',
                    'success'
                )

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }
    const removeTab2 = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {

                setNewCollapse(false)
                setInputs(prevState => ({
                    ...prevState,
                    grade: 'انتخاب کنید',
                    field: '',
                    institute: '',
                    grade_average: '',
                    orientation: '',
                    from_date: '',
                    to_date: '',
                }))
                swalWithBootstrapButtons.fire(
                    'حذف شد!',
                    'با موفقیت حذف شد',
                    'success'
                )

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }
    const removeTab3 = async (item) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (educational_list?.indexOf(item) > -1) {
                    setEducationalList(educational_list?.splice(educational_list?.indexOf(item), 1))
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    if (educational_list?.length === 0) {
                        setNewCollapse(false)
                        setEducationalList([])
                        setInputs(prevState => ({
                            ...prevState,
                            grade: 'انتخاب کنید',
                            field: '',
                            institute: '',
                            grade_average: '',
                            orientation: '',
                            from_date: '',
                            to_date: '',
                        }))
                    }
                }

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})


            let data = inputs?.educational_backgrounds?.find((row) => {
                return row.id === id
            })
            if (data) {
                setInputs((prevState => ({
                    ...prevState,
                    to_date: data.to_date,
                    from_date: data.from_date,
                    orientation: data.orientation,
                    grade_average: data.grade_average,
                    institute: data.institute,
                    field: data.field,
                    grade: data.grade,
                })))
            }
        }
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        console.log(inputs?.grade === undefined && !inputs?.grade && educational_list?.length === 0 &&
            inputs2?.grade === 'انتخاب کنید' && inputs['uploading_image'] === undefined &&
            inputs?.educational_document?.mostـimportantـacademicـachievements === null &&
            inputs['remove_last_educational_certificate_image'] === undefined &&
            inputs['remove_educational_backgrounds'] === undefined)

        if( inputs?.grade === undefined && !inputs?.grade && educational_list?.length === 0 &&
            inputs2?.grade === 'انتخاب کنید' && inputs['uploading_image'] === undefined &&
            inputs?.educational_document?.mostـimportantـacademicـachievements === null &&
            inputs['remove_last_educational_certificate_image'] === undefined &&
            inputs['remove_educational_backgrounds'] === undefined ){
            toast.error('لطفا حداقل یک فیلد تحصیلی انتخاب و ذخیره فرمایید')
            return false
        }
        if(educational_list?.length === 0 && inputs['remove_educational_backgrounds'] === undefined && inputs['uploading_image'] === undefined && inputs['remove_last_educational_certificate_image'] === undefined){
            toast.error('لطفا موارد وارد شده را ذخیره فرمایید')
            return false
        }
        inputs['uploading_image'] && formData.append('last_educational_certificate_image', inputs['uploading_image'])
        if (inputs['remove_last_educational_certificate_image'] !== undefined) {
            formData.append('remove_last_educational_certificate_image[0]', inputs['remove_last_educational_certificate_image'])
        }
        if (inputs?.educational_document?.mostـimportantـacademicـachievements) {
            formData.append('mostـimportantـacademicـachievements', inputs?.educational_document?.mostـimportantـacademicـachievements)
        }

        for (let i = 0; i < educational_list?.length; i++) {
            if (educational_list[i]['grade'] !== 'انتخاب کنید') {
                formData.append(`educational_backgrounds[${i}][grade]`, educational_list[i]['grade'])
            }
            if (educational_list[i]['field']) {
                formData.append(`educational_backgrounds[${i}][field]`, educational_list[i]['field'])
            }
            if (educational_list[i]['institute']) {
                formData.append(`educational_backgrounds[${i}][institute]`, educational_list[i]['institute'])
            }
            if (educational_list[i]['grade_average']) {
                formData.append(`educational_backgrounds[${i}][grade_average]`, educational_list[i]['grade_average'])
            }
            if (educational_list[i]['orientation']) {
                formData.append(`educational_backgrounds[${i}][orientation]`, educational_list[i]['orientation'])
            }
            if (educational_list[i]['from_date']?.split('T')[0]) {
                formData.append(`educational_backgrounds[${i}][from_date]`, educational_list[i]['from_date']?.split('T')[0])
            }
            if (educational_list[i]['to_date']?.split('T')[0]) {
                formData.append(`educational_backgrounds[${i}][to_date]`, educational_list[i]['to_date']?.split('T')[0])
            }
        }

        if ([...new Set(inputs?.edited_obj_id)]?.length > 0) {
            for (let i = 0; i < [...new Set(inputs?.edited_obj_id)]?.length; i++) {
                formData.append(`educational_backgrounds[${i}][id]`, [...new Set(inputs?.edited_obj_id)][i])
                if(inputs?.educational_backgrounds[i]['grade']){
                    formData.append(`educational_backgrounds[${i}][grade]`, inputs?.educational_backgrounds[i]['grade'])
                }
                if(inputs?.educational_backgrounds[i]['field']){
                    formData.append(`educational_backgrounds[${i}][field]`, inputs?.educational_backgrounds[i]['field'])
                }
                if(inputs?.educational_backgrounds[i]['institute']){
                    formData.append(`educational_backgrounds[${i}][institute]`, inputs?.educational_backgrounds[i]['institute'])
                }
                if(inputs?.educational_backgrounds[i]['grade_average']){
                    formData.append(`educational_backgrounds[${i}][grade_average]`, inputs?.educational_backgrounds[i]['grade_average'])
                }
                if(inputs?.educational_backgrounds[i]['orientation']){
                    formData.append(`educational_backgrounds[${i}][orientation]`, inputs?.educational_backgrounds[i]['orientation'])
                }
                if(inputs?.educational_backgrounds[i]['from_date']){
                    formData.append(`educational_backgrounds[${i}][from_date]`, inputs?.educational_backgrounds[i]['from_date'])
                }
                if(inputs?.educational_backgrounds[i]['to_date']){
                    formData.append(`educational_backgrounds[${i}][to_date]`, inputs?.educational_backgrounds[i]['to_date'])
                }
            }
        }


        if (inputs?.remove_educational_backgrounds?.length > 0) {
            for (let i = 0; i < inputs?.remove_educational_backgrounds?.length; i++) {
                formData.append(`remove_educational_backgrounds[${i}]`, inputs?.remove_educational_backgrounds[i])
            }
        }
        if (formData) {
            let api_result = await getFormDataPost('profile/educational-background', formData)
            if (api_result?.status === 200) {
                toast.success('با موفقیت تغییر یافت')
                setRender(!is_render)
                setError({})
                setInputs(prevState => ({...prevState , remove_last_educational_certificate_image : ''}))
                setEducationalList([])
                setInputs(prevState => ({...prevState, edited_obj_id: [], remove_educational_backgrounds: []}))
            } else if (api_result?.status === 400) {
                setError(api_result?.data)
                toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
            }
        }
    }

    const goToNextPage = () => {
        props.handleChange(3)
    }

    let edc = inputs?.educational_backgrounds;
    return (
        <form onSubmit={formHandler}>
            <div className="row">
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <TabStatusComplete
                        style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                        {
                            more_data.sheet_status?.status === 'pending' || more_data.sheet_status?.status === 'denied' ?
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-tick afa-icon-tick'/>
                        }
                        <p className='mb-0 f-14 '>
                            <span>وضعیت : </span><span>{more_data.status_colors?.[more_data.sheet_status?.status]?.title}</span>
                        </p>
                    </TabStatusComplete>
                </div>
                <div className="col-12 col-sm-6 col-lg-8 mb-4">
                    {
                        more_data.sheet_status?.message &&
                        <TabStatusContent
                            style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <p className='mb-0 f-14'>
                                <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                            </p>
                        </TabStatusContent>
                    }
                </div>
            </div>
            <>

                {
                    edc?.length > 0 || educational_list?.length > 0 ?
                        <>
                            <Accordion defaultActiveKey="0">
                                {
                                    edc?.map((item, i) => (
                                        <Card key={i}>
                                            <Accordion.Toggle as={Card.Header} eventKey={item.id}
                                                              onClick={() => changeArrow(item.id)}>
                                                <div className="d-flex flex-wrap">
                                                    <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                          onClick={() => removeTab(item.id)}/>
                                                    {
                                                        item?.grade &&
                                                        <span className="pl-4 f-14 my-2"> مقطع : {item?.grade}</span>
                                                    }
                                                    {
                                                        item?.field && <span
                                                            className="pl-4 f-14 my-2"> رشته تحصیلی : {item?.field}</span>
                                                    }
                                                    {
                                                        item?.orientation && <span
                                                            className="pl-4 f-14 my-2"> گرایش : {item?.orientation}</span>
                                                    }
                                                    {
                                                        item?.institute && <span
                                                            className="pl-4 f-14 my-2"> دانشگاه : {item?.institute}</span>
                                                    }
                                                    {
                                                        item?.grade_average && <span
                                                            className="pl-4 f-14 my-2"> معدل : {item?.grade_average}</span>
                                                    }
                                                    {
                                                        item?.from_date &&
                                                        <span className="pl-4 f-14 my-2 d-flex align-items-center"> از تاریخ : <p
                                                            className='mb-0'>{item?.from_date}</p></span>
                                                    }
                                                    {
                                                        item?.to_date &&
                                                        <span className="pl-4 f-14 my-2 d-flex align-items-center"> تا تاریخ : <p
                                                            className='mb-0'>{item?.to_date}</p></span>
                                                    }
                                                </div>
                                                <span
                                                    className={(arrow_item.arrow_id === item.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={item.id}>
                                                <Card.Body className='row'>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <span>مقطع
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <select name={'grade'}
                                                                    className={error['grade'] && 'afa-error-input'}
                                                                    value={inputs.grade} onChange={inputHandler}>
                                                                <option value="انتخاب کنید" disabled>انتخاب کنید
                                                                </option>
                                                                <option value="ابتدایی">ابتدایی</option>
                                                                <option value="راهنمایی">راهنمایی</option>
                                                                <option value="دبیرستان">دبیرستان</option>
                                                                <option value="کاردانی">کاردانی</option>
                                                                <option value="کارشناسی">کارشناسی</option>
                                                                <option value="کارشناسی ارشد">کارشناسی ارشد</option>
                                                                <option value="دکتری">دکتری</option>
                                                            </select>
                                                            <p className='afa-error-text'>{error['grade']}</p>
                                                        </Inputs>
                                                    </div>
                                                    {
                                                        (inputs.grade !== 'ابتدایی' && inputs.grade !== 'راهنمایی') &&
                                                        <>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                            <span>رشته تحصیلی
                                                            {/*<span className='dv-required-star'>*</span>*/}
                                                            </span>
                                                                    <input
                                                                        className={error['field'] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={'field'}
                                                                        value={inputs.field} onChange={inputHandler}
                                                                    />
                                                                    <p className='afa-error-text'>{error['field']}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                            <span>گرایش
                                                                {/*<span className='dv-required-star'>*</span>*/}
                                                            </span>
                                                                    <input
                                                                        className={error['orientation'] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={'orientation'}
                                                                        value={inputs.orientation}
                                                                        onChange={inputHandler}
                                                                    />
                                                                    <p className='afa-error-text'>{error['orientation']}</p>
                                                                </Inputs>
                                                            </div>
                                                        </>
                                                    }

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <span>دانشگاه/موسسه
                                                                {/*<span className='dv-required-star'>*</span>*/}
                                                            </span>
                                                            <input className={error['institute'] && 'afa-error-input'}
                                                                   type="text"
                                                                   name={'institute'}
                                                                   value={inputs.institute} onChange={inputHandler}
                                                            />
                                                            <p className='afa-error-text'>{error['institute']}</p>
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <span>معدل
                                                                {/*<span className='dv-required-star'>*</span>*/}
                                                            </span>
                                                            <input
                                                                className={error['grade_average'] && 'afa-error-input'}
                                                                type="number"
                                                                name={'grade_average'}
                                                                value={inputs.grade_average} onChange={inputHandler}
                                                            />
                                                            <p className='afa-error-text'>{error['grade_average']}</p>
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <div className="row px-0">
                                                            <div className="col-12 col-sm-6">
                                                                <Inputs>
                                                                    <span>از تاریخ ( سال )
                                                                        {/*<span className='dv-required-star'>*</span>*/}
                                                                    </span>
                                                                    <input
                                                                        className={error['from_date'] && 'afa-error-input'}
                                                                        type="text"
                                                                        max={9999}
                                                                        maxLength={4}
                                                                        name={'from_date'}
                                                                        value={inputs.from_date}
                                                                        onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`from_date`]: fixNumber(e.target.value)
                                                                        }))}
                                                                    />
                                                                    {/*<DateInput*/}
                                                                    {/*    value={inputs?.from_date}*/}
                                                                    {/*    name={'from_date'}*/}
                                                                    {/*    className={error['from_date'] && 'afa-error-input'}*/}
                                                                    {/*    placeholder={'انتخاب کنید'}*/}
                                                                    {/*    onChange={inputHandler}/>*/}
                                                                </Inputs>
                                                                <p className='afa-error-text'>{error['from_date']}</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <Inputs>
                                                                    <span>تا تاریخ ( سال )
                                                                        {/*<span className='dv-required-star'>*</span>*/}
                                                                    </span>
                                                                    <input
                                                                        className={error['to_date'] && 'afa-error-input'}
                                                                        type="text"
                                                                        max={9999}
                                                                        maxLength={4}
                                                                        name={'to_date'}
                                                                        value={inputs.to_date}
                                                                        onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`to_date`]: fixNumber(e.target.value)
                                                                        }))}
                                                                    />
                                                                    {/*<DateInput*/}
                                                                    {/*    value={inputs?.to_date}*/}
                                                                    {/*    name={'to_date'}*/}
                                                                    {/*    className={error['to_date'] && 'afa-error-input'}*/}
                                                                    {/*    placeholder={'انتخاب کنید'}*/}
                                                                    {/*    onChange={inputHandler}/>*/}
                                                                </Inputs>
                                                                <p className='afa-error-text'>{error['to_date']}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 d-flex justify-content-center">
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                            <Inputs>

                                                                <button type='button'
                                                                        onClick={() => firstApiResultSave(item.id)}>

                                                                    <span>ذخیره</span>

                                                                    <LeftIcon><span
                                                                        className='icon-cheveron-left'/></LeftIcon>

                                                                </button>

                                                            </Inputs>

                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))
                                }
                                {
                                    educational_list?.map((item, i) => (
                                        <Card key={i}>
                                            <Accordion.Toggle as={Card.Header} eventKey={parseInt(i) * 100000 + 1}
                                                              onClick={() => changeArrow(parseInt(i) * 100000 + 1)}>
                                                <div className="d-flex flex-wrap">
                                                    <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                          onClick={() => removeTab3(item)}/>
                                                    {
                                                        item?.grade &&
                                                        <span
                                                            className="pl-4 f-14 my-2"> مقطع : {item?.grade}</span>
                                                    }
                                                    {
                                                        item?.field && <span
                                                            className="pl-4 f-14 my-2"> رشته تحصیلی : {item?.field}</span>
                                                    }
                                                    {
                                                        item?.orientation && <span
                                                            className="pl-4 f-14 my-2"> گرایش : {item?.orientation}</span>
                                                    }
                                                    {
                                                        item?.institute && <span
                                                            className="pl-4 f-14 my-2"> دانشگاه : {item?.institute}</span>
                                                    }
                                                    {
                                                        item?.grade_average && <span
                                                            className="pl-4 f-14 my-2"> معدل : {item?.grade_average}</span>
                                                    }
                                                    {
                                                        item?.from_date &&
                                                        <span className="pl-4 f-14 my-2 d-flex align-items-center"> از تاریخ : <p
                                                            className='mb-0'>{item?.from_date}</p></span>
                                                    }
                                                    {
                                                        item?.to_date &&
                                                        <span className="pl-4 f-14 my-2 d-flex align-items-center"> تا تاریخ : <p
                                                            className='mb-0'>{item?.to_date}</p></span>
                                                    }
                                                </div>
                                                <span
                                                    className={(arrow_item.arrow_id === parseInt(i) * 100000 + 1 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={parseInt(i) * 100000 + 1}>
                                                <Card.Body className='row'>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <span>مقطع
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <select name={'grade'}
                                                                    className={error['grade'] && 'afa-error-input'}
                                                                    value={inputs.grade} onChange={inputHandler}>
                                                                <option value="انتخاب کنید" disabled>انتخاب کنید
                                                                </option>
                                                                <option value="ابتدایی">ابتدایی</option>
                                                                <option value="راهنمایی">راهنمایی</option>
                                                                <option value="دبیرستان">دبیرستان</option>
                                                                <option value="کاردانی">کاردانی</option>
                                                                <option value="کارشناسی">کارشناسی</option>
                                                                <option value="کارشناسی ارشد">کارشناسی ارشد</option>
                                                                <option value="دکتری">دکتری</option>
                                                            </select>
                                                            <p className='afa-error-text'>{error['grade']}</p>
                                                        </Inputs>
                                                    </div>
                                                    {
                                                        (inputs.grade !== 'ابتدایی' && inputs.grade !== 'راهنمایی') &&
                                                        <>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                            <span>رشته تحصیلی
                                                            {/*<span className='dv-required-star'>*</span>*/}
                                                            </span>
                                                                    <input
                                                                        className={error['field'] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={'field'}
                                                                        value={item.field} onChange={inputHandler}
                                                                    />
                                                                    <p className='afa-error-text'>{error['field']}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                            <span>گرایش
                                                                {/*<span className='dv-required-star'>*</span>*/}
                                                            </span>
                                                                    <input
                                                                        className={error['orientation'] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={'orientation'}
                                                                        value={item.orientation} onChange={inputHandler}
                                                                    />
                                                                    <p className='afa-error-text'>{error['orientation']}</p>
                                                                </Inputs>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <span>دانشگاه/موسسه
                                                                {/*<span className='dv-required-star'>*</span>*/}
                                                            </span>
                                                            <input
                                                                className={error['institute'] && 'afa-error-input'}
                                                                type="text"
                                                                name={'institute'}
                                                                value={item.institute} onChange={inputHandler}
                                                            />
                                                            <p className='afa-error-text'>{error['institute']}</p>
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <span>معدل
                                                                {/*<span className='dv-required-star'>*</span>*/}
                                                            </span>
                                                            <input
                                                                className={error['grade_average'] && 'afa-error-input'}
                                                                type="number"
                                                                name={'grade_average'}
                                                                value={item.grade_average} onChange={inputHandler}
                                                            />
                                                            <p className='afa-error-text'>{error['grade_average']}</p>
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <div className="row px-0">
                                                            <div className="col-12 col-sm-6">
                                                                <Inputs>
                                                                    <span>از تاریخ ( سال )
                                                                        {/*<span className='dv-required-star'>*</span>*/}
                                                                    </span>
                                                                    <input
                                                                        className={error['from_date'] && 'afa-error-input'}
                                                                        type="text"
                                                                        max={9999}
                                                                        maxLength={4}
                                                                        name={'from_date'}
                                                                        value={inputs.from_date}
                                                                        onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`from_date`]: fixNumber(e.target.value)
                                                                        }))}
                                                                    />
                                                                </Inputs>
                                                                <p className='afa-error-text'>{error['from_date']}</p>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <Inputs>
                                                                    <span>تا تاریخ ( سال )
                                                                        {/*<span className='dv-required-star'>*</span>*/}
                                                                    </span>
                                                                    <input
                                                                        className={error['to_date'] && 'afa-error-input'}
                                                                        type="text"
                                                                        max={9999}
                                                                        maxLength={4}
                                                                        name={'to_date'}
                                                                        value={inputs.to_date}
                                                                        onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`to_date`]: fixNumber(e.target.value)
                                                                        }))}
                                                                    />
                                                                </Inputs>
                                                                <p className='afa-error-text'>{error['to_date']}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <button type='button'
                                                                        onClick={() => editCreatedItems(i)}>
                                                                    <span>ذخیره</span>
                                                                    <LeftIcon><span
                                                                        className='icon-cheveron-left'/></LeftIcon>
                                                                </button>
                                                            </Inputs>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))
                                }

                                {
                                    new_collapse === true &&
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey={'0'}
                                                          onClick={() => changeArrow('0')}>
                                            <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab2('rasoul')}/>
                                                {
                                                    inputs2?.grade &&
                                                    <span className="pl-4 f-14 my-2"> مقطع : {inputs2?.grade}</span>
                                                }
                                                {
                                                    inputs2?.field && <span
                                                        className="pl-4 f-14 my-2"> رشته تحصیلی : {inputs2?.field}</span>
                                                }
                                                {
                                                    inputs2?.orientation && <span
                                                        className="pl-4 f-14 my-2"> گرایش : {inputs2?.orientation}</span>
                                                }
                                                {
                                                    inputs2?.institute && <span
                                                        className="pl-4 f-14 my-2"> دانشگاه : {inputs2?.institute}</span>
                                                }
                                                {
                                                    inputs2?.grade_average && <span
                                                        className="pl-4 f-14 my-2"> معدل : {inputs2?.grade_average}</span>
                                                }
                                                {
                                                    inputs2?.from_date &&
                                                    <span className="pl-4 f-14 my-2 d-flex align-items-center"> از تاریخ : <p
                                                        className='mb-0'>{inputs2?.from_date}</p></span>
                                                }
                                                {
                                                    inputs2?.to_date &&
                                                    <span className="pl-4 f-14 my-2 d-flex align-items-center"> تا تاریخ : <p
                                                        className='mb-0'>{inputs2?.to_date}</p></span>
                                                }
                                            </div>
                                            <span
                                                className={(arrow_item.arrow_id === 'rasoul' && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={'0'}>
                                            <Card.Body className='row'>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <Inputs>
                                                        <span>مقطع
                                                        <span className='dv-required-star'>*</span>
                                                        </span>

                                                        <select name={'grade'}
                                                                className={error['grade'] && 'afa-error-input'}
                                                                value={inputs2.grade}
                                                                onChange={(e) => setInputs2(prevState => ({
                                                                            ...prevState,
                                                                            'grade': e.target.value
                                                                        }
                                                                    )
                                                                )}>
                                                            <option value="انتخاب کنید" disabled>انتخاب کنید</option>
                                                            <option value="ابتدایی">ابتدایی</option>
                                                            <option value="راهنمایی">راهنمایی</option>
                                                            <option value="دبیرستان">دبیرستان</option>
                                                            <option value="کاردانی">کاردانی</option>
                                                            <option value="کارشناسی">کارشناسی</option>
                                                            <option value="کارشناسی ارشد">کارشناسی ارشد</option>
                                                            <option value="دکتری">دکتری</option>
                                                        </select>
                                                        <p className='afa-error-text'>{error['grade']}</p>
                                                    </Inputs>
                                                </div>
                                                {
                                                    (inputs2.grade !== 'ابتدایی' && inputs2.grade !== 'راهنمایی') &&
                                                    <>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                        <span>رشته تحصیلی
                                                        {/*<span className='dv-required-star'>*</span>*/}
                                                        </span>
                                                                <input className={error['field'] && 'afa-error-input'}
                                                                       type="text"
                                                                       name={'field'}
                                                                       value={just_persian(inputs2.field) === true ? inputs2.field : ''}
                                                                       onChange={(e) => {
                                                                           just_persian(e.target.value) === true &&
                                                                           setInputs2(prevState => ({
                                                                                       ...prevState,
                                                                                       'field': e.target.value
                                                                                   }
                                                                               )
                                                                           )
                                                                       }}
                                                                />
                                                                <p className='afa-error-text'>{error['field']}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                        <span>گرایش
                                                            {/*<span className='dv-required-star'>*</span>*/}
                                                        </span>
                                                                <input
                                                                    className={error['orientation'] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={'orientation'}
                                                                    value={just_persian(inputs2.orientation) === true ? inputs2.orientation : ''}
                                                                    onChange={(e) => {
                                                                        just_persian(e.target.value) === true &&
                                                                        setInputs2(prevState => ({
                                                                                    ...prevState,
                                                                                    'orientation': e.target.value
                                                                                }
                                                                            )
                                                                        )
                                                                    }}
                                                                />
                                                                <p className='afa-error-text'>{error['orientation']}</p>
                                                            </Inputs>
                                                        </div>
                                                    </>
                                                }

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <Inputs>
                                                        <span>دانشگاه/موسسه
                                                            {/*<span className='dv-required-star'>*</span>*/}
                                                        </span>
                                                        <input className={error['institute'] && 'afa-error-input'}
                                                               type="text"
                                                               name={'institute'}
                                                               value={just_persian(inputs2.institute) === true ? inputs2.institute : ''}
                                                               onChange={(e) => {
                                                                   just_persian(e.target.value) === true &&
                                                                   setInputs2(prevState => ({
                                                                               ...prevState,
                                                                               'institute': e.target.value
                                                                           }
                                                                       )
                                                                   )
                                                               }}
                                                        />
                                                        <p className='afa-error-text'>{error['institute']}</p>
                                                    </Inputs>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <Inputs>
                                                        <span>معدل
                                                            {/*<span className='dv-required-star'>*</span>*/}
                                                        </span>
                                                        <input className={error['grade_average'] && 'afa-error-input'}
                                                               type="number"
                                                               name={'grade_average'}
                                                               value={inputs2.grade_average} onChange={(e) => {
                                                            setInputs2(prevState => ({
                                                                        ...prevState,
                                                                        'grade_average': fixNumber(e.target.value)
                                                                    }
                                                                )
                                                            )
                                                        }}
                                                        />
                                                        <p className='afa-error-text'>{error['grade_average']}</p>
                                                    </Inputs>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <div className="row px-0">
                                                        <div className="col-12 col-sm-6">
                                                            <Inputs>
                                                                <span>از تاریخ ( سال )
                                                                    {/*<span className='dv-required-star'>*</span>*/}
                                                                </span>
                                                                <input
                                                                    className={error['from_date'] && 'afa-error-input'}
                                                                    type="text"
                                                                    max={9999}
                                                                    maxLength={4}
                                                                    name={'from_date'}
                                                                    value={inputs2.from_date}
                                                                    onChange={(e) => just_number(e.target.value) && setInputs2(prevState => ({
                                                                        ...prevState,
                                                                        [`from_date`]: fixNumber(e.target.value)
                                                                    }))}
                                                                />
                                                            </Inputs>
                                                            <p className='afa-error-text'>{error['from_date']}</p>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <Inputs>
                                                                <span>تا تاریخ ( سال )
                                                                    {/*<span className='dv-required-star'>*</span>*/}
                                                                </span>
                                                                <input
                                                                    className={error['to_date'] && 'afa-error-input'}
                                                                    type="text"
                                                                    max={9999}
                                                                    maxLength={4}
                                                                    name={'to_date'}
                                                                    value={inputs2.to_date}
                                                                    onChange={(e) => just_number(e.target.value) && setInputs2(prevState => ({
                                                                        ...prevState,
                                                                        [`to_date`]: fixNumber(e.target.value)
                                                                    }))}
                                                                />
                                                            </Inputs>
                                                            <p className='afa-error-text'>{error['to_date']}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 d-flex justify-content-center">
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">

                                                        <Inputs>

                                                            <button type='button' onClick={firstSave}>

                                                                <span>ذخیره</span>

                                                                <LeftIcon><span
                                                                    className='icon-cheveron-left'/></LeftIcon>

                                                            </button>

                                                        </Inputs>

                                                    </div>
                                                </div>

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                }
                            </Accordion>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end align-items-center">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>

                                        <Inputs>

                                            <button type='button' onClick={addMoreCollapse} className='afa-add-more-btn'>
                                                <span className='icon-add f-20'/>

                                                <span className='pr-3'>افزودن جدید</span>

                                            </button>

                                        </Inputs>

                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>

                            <div className="row bg-gray">
                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                    <Inputs>
                                        <span>مقطع
                                        <span className='dv-required-star'>*</span>
                                        </span>
                                        <select name={'grade'} className={error['grade'] && 'afa-error-input'}
                                                value={inputs.grade} onChange={inputHandler}>
                                            <option value="انتخاب کنید" disabled>انتخاب کنید</option>
                                            <option value="ابتدایی">ابتدایی</option>
                                            <option value="راهنمایی">راهنمایی</option>
                                            <option value="دبیرستان">دبیرستان</option>
                                            <option value="کاردانی">کاردانی</option>
                                            <option value="کارشناسی">کارشناسی</option>
                                            <option value="کارشناسی ارشد">کارشناسی ارشد</option>
                                            <option value="دکتری">دکتری</option>
                                        </select>
                                        <p className='afa-error-text'>{error['grade']}</p>
                                    </Inputs>
                                </div>
                                {
                                    (inputs.grade !== 'ابتدایی' && inputs.grade !== 'راهنمایی') &&
                                    <>
                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                            <Inputs>
                                        <span>رشته تحصیلی
                                        {/*<span className='dv-required-star'>*</span>*/}
                                        </span>
                                                <input className={error['field'] && 'afa-error-input'} type="text"
                                                       name={'field'}
                                                       value={just_persian(inputs.field) === true ? inputs.field : ''}
                                                       onChange={inputHandler}
                                                />
                                                <p className='afa-error-text'>{error['field']}</p>
                                            </Inputs>
                                        </div>

                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                            <Inputs>
                                        <span>گرایش
                                            {/*<span className='dv-required-star'>*</span>*/}
                                        </span>
                                                <input className={error['orientation'] && 'afa-error-input'} type="text"
                                                       name={'orientation'}
                                                       value={just_persian(inputs.orientation) === true ? inputs.orientation : ''}
                                                       onChange={inputHandler}
                                                />
                                                <p className='afa-error-text'>{error['orientation']}</p>
                                            </Inputs>
                                        </div>
                                    </>
                                }
                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                    <Inputs>
                                        <span>دانشگاه/موسسه
                                            {/*<span className='dv-required-star'>*</span>*/}
                                        </span>
                                        <input className={error['institute'] && 'afa-error-input'} type="text"
                                               name={'institute'}
                                               value={just_persian(inputs.institute) === true ? inputs.institute : ''}
                                               onChange={inputHandler}
                                        />
                                        <p className='afa-error-text'>{error['institute']}</p>
                                    </Inputs>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                    <Inputs>
                                        <span>معدل
                                            {/*<span className='dv-required-star'>*</span>*/}
                                        </span>
                                        <input className={error['grade_average'] && 'afa-error-input'} type="number"
                                               name={'grade_average'}
                                               step={0.01}
                                               value={inputs.grade_average} onChange={(e) => {
                                            setInputs(prevState => ({
                                                        ...prevState,
                                                        'grade_average': fixNumber(e.target.value)
                                                    }
                                                )
                                            )
                                        }}
                                        />
                                        <p className='afa-error-text'>{error['grade_average']}</p>
                                    </Inputs>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                    <div className="row px-0">
                                        <div className="col-12 col-sm-6">
                                            <Inputs>
                                                <span>از تاریخ ( سال )
                                                    {/*<span className='dv-required-star'>*</span>*/}
                                                </span>
                                                <input
                                                    className={error['from_date'] && 'afa-error-input'}
                                                    type="text"
                                                    max={9999}
                                                    maxLength={4}
                                                    name={'from_date'}
                                                    value={inputs.from_date}
                                                    onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                                        ...prevState, [`from_date`]: fixNumber(e.target.value)
                                                    }))}
                                                />
                                            </Inputs>
                                            <p className='afa-error-text'>{error['from_date']}</p>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <Inputs>
                                                <span>تا تاریخ ( سال )
                                                    {/*<span className='dv-required-star'>*</span>*/}
                                                </span>
                                                <input
                                                    className={error['to_date'] && 'afa-error-input'}
                                                    type="text"
                                                    max={9999}
                                                    maxLength={4}
                                                    name={'to_date'}
                                                    value={inputs.to_date}
                                                    onChange={(e) => just_number(e.target.value) && setInputs(prevState => ({
                                                        ...prevState, [`to_date`]: fixNumber(e.target.value)
                                                    }))}
                                                />
                                            </Inputs>
                                            <p className='afa-error-text'>{error['to_date']}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 d-flex justify-content-center">
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <button type='button' onClick={() => emptyListAddItem()}>
                                                <span>ذخیره</span>
                                                <LeftIcon><span
                                                    className='icon-cheveron-left'/></LeftIcon>
                                            </button>
                                        </Inputs>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 d-flex justify-content-end align-items-center">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>
                                        <Inputs>
                                            <button type='button'
                                                    disabled={
                                                        inputs?.grade !== 'انتخاب کنید' && inputs?.to_date?.length > 0 && inputs?.from_date?.length > 0 && inputs?.orientation?.length > 0 && inputs?.institute?.length > 0 && inputs?.field?.length > 0 && inputs?.grade_average?.length > 0 ? false : true
                                                    }
                                                    onClick={emptyListAddItem} className='afa-add-more-btn'>
                                                <span className='icon-add f-20'/>
                                                <span className='pr-3'>افزودن جدید</span>
                                            </button>
                                        </Inputs>
                                    </div>
                                </div>
                            </div>
                        </>
                }
                <div className='row'>
                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                        <span>اپلود اخرین مدرک تحصیلی</span>
                        <FileInputs
                            className={error['last_educational_certificate_image'] && 'afa-error-input'}>

                            {
                                (inputs['last_educational_certificate_image'] || inputs?.educational_document?.last_educational_certificate_image) &&
                                <div className="afa-img">
                                    <img
                                        src={inputs?.educational_document?.last_educational_certificate_image ? `${MAIN_URL_IMAGE}${inputs?.educational_document?.last_educational_certificate_image}` : inputs['last_educational_certificate_image']}
                                        alt="آتاویتا"/>
                                    <span
                                        className='icon-close-solid afa-remove-position'
                                        onClick={() => removeImage('last_educational_certificate_image')}/>
                                </div>
                            }
                            <label className="afa-center-pos">
                                <span className='icon-cloud-computing'></span>
                                <input type="file" accept="image/*"
                                       onChange={thisUploadDocs}
                                       name='last_educational_certificate_image'
                                       className='dv-upload-file'/>
                                {
                                    !(inputs['last_educational_certificate_image'] || inputs?.educational_document?.['last_educational_certificate_image']) &&
                                    <span className='upload-text'>
                                                آپلود
                                                </span>
                                }
                            </label>
                        </FileInputs>
                        <p className='afa-error-text'>{error['last_educational_certificate_image']}</p>

                    </div>
                    <div className="col-12 col-sm-6 col-lg-8 mb-4">
                        <Inputs>
                            <span>دستاوردهای ممتاز تحصیلی </span>
                            <textarea name="mostـimportantـacademicـachievements"
                                      value={inputs?.educational_document?.mostـimportantـacademicـachievements !== 'undefined' ? inputs?.educational_document?.mostـimportantـacademicـachievements : ''}
                                      onChange={footerBtn}
                                      className={error['mostـimportantـacademicـachievements'] && 'afa-error-input'}
                                      id="mostـimportantـacademicـachievements"
                                      cols="30"
                                      rows="2"/>
                            <p className='afa-error-text'>{error['mostـimportantـacademicـachievements']}</p>
                        </Inputs>
                    </div>
                </div>
                <div className="row d-flex flex-column flex-sm-row justify-content-between">
                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                        <Inputs>
                            <button type='submit'>
                                <span>ثبت این صفحه</span>
                                {/*<LeftIcon><span className='icon-cheveron-left'/></LeftIcon>*/}
                            </button>
                        </Inputs>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                        <Inputs>
                            <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                    onClick={goToNextPage}>
                                <span>مرحله بعد</span>
                                <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                            </button>
                        </Inputs>
                    </div>
                </div>
            </>
        </form>
    )
}

export default EducationalBackground;