import React, {useState} from 'react';
import {LoginInput} from "./styles";
import {checkCodeMelli, fixNumber, getData, priceFormat} from "../../assets/scripts/GeneralFunctions";
import Swal from "sweetalert2";
import {useHistory, useLocation} from "react-router";
import {MAIN_URL} from "../../assets/scripts/GeneralVariables";
import {LoopCircleLoading} from "react-loadingg";

function RealPerson(props) {
    const [error, setError] = useState({})
    const history = useHistory()
    let location = useLocation();
    const [data, setData] = useState({melli_code: location.state?.individual_identification_code})
    const [loader, setLoader] = useState(false)
    const formHandler = async (e) => {
        e.preventDefault()
        if (checkCodeMelli(data.melli_code)) {
            setLoader(true)
            let api_result = await getData(MAIN_URL, "sign-in", 'post', {
                type: 'حقیقی',
                individual_identification_code: data.melli_code
            }, null, false);
            if (api_result?.status === 200) {
                setLoader(false)

                if (api_result.data['action'] === 'verify') {
                    history.push({
                        pathname: '/verify_code',
                        state: {
                            time: api_result.data['remaining'],
                            user_type: 'real',
                            mobile: api_result.data['mobile'],
                            individual_identification_code: data.melli_code
                        }
                    })
                } else {
                    history.push({
                        pathname: '/real_register',
                        user_type: 'real',
                        state: {individual_identification_code: data.melli_code}
                    })
                }

            }
        } else {
            setLoader(false)
            let error_result = true
            let error = {};
            error['individual_identification_code'] = 'کد ملی وارد شده معتبر نمی باشد'
            error_result = false

            setError(error)
            return error_result
        }
    }
    return (
        <>
            <LoginInput onSubmit={formHandler}>
                <span>کدملی</span>
                <input className='text-left mb-3' dir='ltr' type="text" autoFocus name='code' value={data.melli_code}
                       onChange={(e) => setData(prevState => ({...prevState, melli_code: fixNumber(e.target.value)}))}
                       placeholder={'0000000000'}/>
                <p className='afa-error-text'>{error['individual_identification_code']}</p>
                <div className="position-relative w-100">
                    <button type='submit' className='w-100'
                            disabled={data.melli_code?.length > 0 ? false : true}>ادامه
                    </button>
                    {
                        loader && <LoopCircleLoading/>
                    }
                </div>
            </LoginInput>
        </>
    );
}

export default RealPerson;