import React from "react";
import ReactExport from "react-export-excel";
import {getData, priceFormat} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../../assets/scripts/GeneralVariables";
import moment from "moment-jalaali";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExcelExport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            personal_info: [],
            family_members: [],
            educational_background: [],
            professional_experience: [],
            languages: [],
            skills: [],
            certifications: [],
            drivers_licences: [],
            softwares: []
        }

    }

    async componentDidMount() {
        let obj = {};
        obj = this.props.filterData;
        obj['excel'] = 1
        let api_result = await getData(MAIN_URL, `hr-management/${this.props?.status}`, 'get', obj, true, true);
        if (api_result?.status === 200) {
            this.setState({
                data: api_result?.data?.items,
                personal_info: api_result?.data?.items?.personal_info,
                family_members: api_result?.data?.items?.family_members,
                educational_background: api_result?.data?.items?.educational_background,
                professional_experience: api_result?.data?.items?.professional_experience,
                languages: api_result?.data?.items?.languages,
                skills: api_result?.data?.items?.skills,
                certifications: api_result?.data?.items?.certifications,
                drivers_licences: api_result?.data?.items?.drivers_licences,
                softwares: api_result?.data?.items?.softwares,
                users: api_result?.data?.items?.users,
            });
        }
    }

    render() {
        let users = this.state?.users
        let statuses = {
            pending: 'در انتظار',
            completed: 'تکمیل',
            denied: 'رد شده',
        }
        for (let index in users) {
            if (users[index].sheet_statuses && users[index].sheet_statuses.length > 0) {
                for (let sheet_status of users[index].sheet_statuses) {
                    users[index][sheet_status.sheet] = statuses[sheet_status.status]
                }
            } else {
                users[index]['personal_info'] = statuses.pending;
                users[index]['family_info'] = statuses.pending;
                users[index]['educational_background'] = statuses.pending;
                users[index]['professional_experience'] = statuses.pending;
                users[index]['foreign_languages'] = statuses.pending;
                users[index]['softwares'] = statuses.pending;
                users[index]['skills'] = statuses.pending;
                users[index]['certifications'] = statuses.pending;
                users[index]['documents'] = statuses.pending;
            }
        }
        return (
            <ExcelFile filename={'HumanResourcesInformation'}
                       element={<button className='afa-excel-export dv-main-btn mb-3'>
                           <span className='icon-excel afa-btn-icon'/>
                           <span className='afa-information-btn-title'>دانلود فایل</span>
                       </button>}>
                {
                    users &&
                    <ExcelSheet data={users} name="کاربران">
                        <ExcelColumn label="کد ملی" value={(col) => col?.identification_code}/>
                        <ExcelColumn label="نام" value={(col) => col?.first_name}/>
                        <ExcelColumn label="نام خانوادگی" value={(col) => col?.last_name}/>
                        <ExcelColumn label="شماره موبایل" value={(col) => col?.mobile}/>

                        <ExcelColumn label="اطلاعات شخصی" value={(col) => col?.personal_info}/>
                        <ExcelColumn label="اطلاعات خانواده" value={(col) => col?.family_info}/>
                        <ExcelColumn label="سوابق تحصیلی" value={(col) => col?.educational_background}/>
                        <ExcelColumn label="سوابق شغلی" value={(col) => col?.professional_experience}/>
                        <ExcelColumn label="زبان های خارجی" value={(col) => col?.foreign_languages}/>
                        <ExcelColumn label="نرم افزار ها" value={(col) => col?.softwares}/>
                        <ExcelColumn label="گواهی ها" value={(col) => col?.certifications}/>
                        <ExcelColumn label="مهارت ها" value={(col) => col?.skills}/>
                        <ExcelColumn label="مدارک" value={(col) => col?.documents}/>


                        {/*<ExcelColumn label="وضعیت" alignment={'horizontal'} value={(col)=> col.sheet_statuses?.map((row)=>row?.status)?.join()}/>*/}
                    </ExcelSheet>
                }

                {
                    this.state?.personal_info &&
                    <ExcelSheet data={this.state?.personal_info} name="اطلاعات شخصی">
                        <ExcelColumn label="کد ملی" value={(col) => col.user?.identification_code}/>
                        <ExcelColumn label="نام" value={(col) => col.user?.first_name}/>
                        <ExcelColumn label="نام خانوادگی" value={(col) => col.user?.last_name}/>
                        <ExcelColumn label="شماره موبایل" value={(col) => col.user?.mobile}/>
                        <ExcelColumn label="نام پدر" value='father_name'/>
                        <ExcelColumn label="تاریخ تولد"
                                     value={(col) => moment(col.birth_date).format('jYYYY-jMM-jDD')}/>
                        <ExcelColumn label="جنسیت" value='gender'/>
                        <ExcelColumn label="وضعیت تاهل" value='marital_status'/>
                        <ExcelColumn label="صادره" value='issued'/>
                        <ExcelColumn label="وضعیت سربازی" value='military_service_status'/>
                        <ExcelColumn label="تعداد فرزندان" value='offspring_count'/>
                        <ExcelColumn label="تعداد تحت تکفل" value='dependents_count'/>
                        <ExcelColumn label="وزن" value='weight'/>
                        <ExcelColumn label="قد" value='height'/>
                        <ExcelColumn label="سیگاری هستید" value={(col) => col.does_smoke ? "بله" : "خیر"}/>
                        <ExcelColumn label="بیماری خاص دارید" value={(col) => col.special_disease ? "بله" : "خیر"}/>
                        <ExcelColumn label="سابقه محکومیت کیفری"
                                     value={(col) => col.criminal_conviction ? "بله" : "خیر"}/>
                        <ExcelColumn label="طریقه آشنایی" value='introduction_method'/>
                        <ExcelColumn label="اطلاعات معرف"
                                     value={(col) => col.introducer_info !== 'null' ? col.introducer_info : "- - -"}/>
                        <ExcelColumn label="آدرس" value='address'/>
                        <ExcelColumn label="کد پستی" value='postal_code'/>
                        <ExcelColumn label="شماره تماس ثابت" value='telephone'/>
                    </ExcelSheet>
                }
                {
                    this.state?.family_members &&
                    <ExcelSheet data={this.state?.family_members} name="اطلاعات اعضای خانواده">
                        <ExcelColumn label="کد ملی" value={(col) => col.user?.identification_code}/>
                        <ExcelColumn label="نام و نام خانوادگی" value='full_name'/>
                        <ExcelColumn label="شماره موبایل"
                                     value={(col) => col.telephone !== null ? col?.telephone : '- - -'}/>
                        <ExcelColumn label="نسبت" value='relation'/>
                        <ExcelColumn label="جنسیت فرزند"
                                     value={(col) => col.child_gender !== null ? col.child_gender : '- - -'}/>
                        <ExcelColumn label="شماره شناسنامه" value='birth_certificate_id'/>
                        <ExcelColumn label="تاریخ تولد"
                                     value={(col) => moment(col.birth_date).format('jYYYY-jMM-jDD')}/>
                        <ExcelColumn label="نوع بیمه"
                                     value={(col) => col.supplementary_insurance !== null ? col?.supplementary_insurance : '- - -'}/>
                    </ExcelSheet>
                }
                {
                    this.state?.educational_background &&
                    <ExcelSheet data={this.state?.educational_background} name="سوابق تحصیلی">
                        <ExcelColumn label="کد ملی" value={(col) => col.user?.identification_code}/>
                        <ExcelColumn label="مقطع" value={(col) => col.grade !== null ? col?.grade : '- - -'}/>
                        <ExcelColumn label="رشته" value={(col) => col.field !== null ? col?.field : '- - -'}/>
                        <ExcelColumn label="گرایش"
                                     value={(col) => col.orientation !== null ? col?.orientation : '- - -'}/>
                        <ExcelColumn label="دانشگاه/موسسه"
                                     value={(col) => col.institute !== null ? col?.institute : '- - -'}/>
                        <ExcelColumn label="معدل"
                                     value={(col) => col.grade_average !== null ? col?.grade_average : '- - -'}/>
                        <ExcelColumn label="از سال" value={(col) => col.from_date !== null ? col?.from_date : '- - -'}/>
                        <ExcelColumn label="تا سال" value={(col) => col.to_date !== null ? col?.to_date : '- - -'}/>
                    </ExcelSheet>
                }
                {
                    this.state?.professional_experience &&
                    <ExcelSheet data={this.state?.professional_experience} name="سوابق شغلی">
                        <ExcelColumn label="کد ملی" value={(col) => col.user?.identification_code}/>
                        <ExcelColumn label="سازمان/شرکت"
                                     value={(col) => col.organization !== null ? col?.organization : '- - -'}/>
                        <ExcelColumn label="سمت" value={(col) => col.position !== null ? col?.position : '- - -'}/>
                        <ExcelColumn label="حقوق"
                                     value={(col) => col.salary !== null ? priceFormat(col?.salary) : '- - -'}/>
                        <ExcelColumn label="علت قطع همکاری"
                                     value={(col) => col.termination_of_cooperation_reason !== null ? col?.termination_of_cooperation_reason : '- - -'}/>
                        <ExcelColumn label="از سال" value={(col) => col.from_date !== null ? col?.from_date : '- - -'}/>
                        <ExcelColumn label="تا سال" value={(col) => col.to_date !== null ? col?.to_date : '- - -'}/>
                    </ExcelSheet>
                }
                {
                    this.state?.languages &&
                    <ExcelSheet data={this.state?.languages} name="زبان های خارجی">
                        <ExcelColumn label="کد ملی" value={(col) => col.user?.identification_code}/>
                        <ExcelColumn label="زبان" value={(col) => col.language !== null ? col?.language : '- - -'}/>
                        <ExcelColumn label="مکالمه"
                                     value={(col) => col.conversation !== null ? col?.conversation : '- - -'}/>
                        <ExcelColumn label="خواندن و نوشتن"
                                     value={(col) => col.reading_writing !== null ? col?.reading_writing : '- - -'}/>
                        <ExcelColumn label="ترجمه و گزارش نویسی‌"
                                     value={(col) => col.translation_report !== null ? col?.translation_report : '- - -'}/>
                        <ExcelColumn label="مذاکره تجاری"
                                     value={(col) => col.business_negotiation !== null ? col?.business_negotiation : '- - -'}/>
                    </ExcelSheet>
                }
                {
                    this.state?.skills &&
                    <ExcelSheet data={this.state?.skills} name="مهارت ها">
                        <ExcelColumn label="کد ملی" value={(col) => col.user?.identification_code}/>
                        <ExcelColumn label="عنوان"
                                     value={(col) => col.skill?.name !== null ? col?.skill?.name : '- - -'}/>
                        <ExcelColumn label="سطح" value={(col) => col.level !== null ? col?.level : '- - -'}/>
                        <ExcelColumn label="توضیحات" value={(col) => col.note !== null ? col?.note : '- - -'}/>
                    </ExcelSheet>
                }
                {
                    this.state?.certifications &&
                    <ExcelSheet data={this.state?.certifications} name="گواهی ها">
                        <ExcelColumn label="کد ملی" value={(col) => col.user?.identification_code}/>
                        <ExcelColumn label="نام دوره" value={(col) => col.course !== null ? col?.course : '- - -'}/>
                        <ExcelColumn label="محل آموزش"
                                     value={(col) => col.location !== null ? col?.location : '- - -'}/>
                        <ExcelColumn label="سال" value={(col) => col.year !== null ? col?.year : '- - -'}/>
                        <ExcelColumn label="مدت" value={(col) => col.duration !== null ? col?.duration : '- - -'}/>
                    </ExcelSheet>
                }
                {
                    this.state?.drivers_licences &&
                    <ExcelSheet data={this.state?.drivers_licences} name="گواهینامه ها">
                        <ExcelColumn label="کد ملی" value={(col) => col.user?.identification_code}/>
                        <ExcelColumn label="نوع گواهینامه" value={(col) => col.licences?.join()}/>
                    </ExcelSheet>
                }
                {
                    this.state?.softwares &&
                    <ExcelSheet data={this.state?.softwares} name="نرم افزار ها">
                        <ExcelColumn label="کد ملی" value={(col) => col?.identification_code}/>
                        <ExcelColumn label="نام نرم افزار"
                                     value={(col) => col.softwares?.map((row) => row?.name)?.join()}/>
                        <ExcelColumn label="سطح"
                                     value={(col) => col.softwares?.map((row) => row?.pivot?.level)?.join()}/>
                    </ExcelSheet>
                }
            </ExcelFile>
        );
    }
}
