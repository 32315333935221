import styled from "styled-components";

const dark_green = '#379521';
const custom_gray = '#ECECEC';
const light_red = '#FC4F59';
const label_text = '#2F4858'
const main_color = '#E73444';
const main_btn_color = 'linear-gradient(263.94deg, #E73444 32.54%, #895193 109.18%)';
const main_btn_color_hover = 'linear-gradient(263.94deg, #E73444 100%, #895193 0%)';
const next_btn_color = '#16A596';
const stepper_color = '#844E8E';
const bg_gray = '#F6F6F6';

export const PanelContent = styled.div`
  background-color: #fff;
  border-radius: 0;
  border: none;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.13);
  margin: 3rem;
  padding: 2rem 2rem 4rem 2rem;
  min-height: 656px;
  position: relative;
  @media (max-width: 768px) {
    padding: 10px;
    margin :0;
  }

  .dv-filter-drop-down {
    border: none !important;

    .accordion__button {
      width: 280px;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      direction: ltr;

      &:hover, &:active {
        background: #fff;
        box-shadow: 0 3px 30px rgba(0, 0, 0, 0.13);
      }

      @media (max-width: 768px) {
        width: 100% !important;
      }
    }

    .accordion__panel {
      width: 280px !important;
      height: 400px !important;
      overflow-y: auto;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 10px;
      margin-top: 1.5rem !important;
      position: absolute;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.13);
      @media (max-width: 768px) {
        width: 100% !important;
        position: unset;
      }
    }

    .dv-filter-icon {
      color: ${main_color};
      font-size: 24px;
      padding-left: 1rem;
    }

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }

  .dv-filter-drop-down-items {
    .accordion__button {
      width: 240px;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 10px 10px 0 0 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: none !important;
      padding: 15px !important;

      &:hover, &:active {
        background: #fff;
        box-shadow: 0 3px 30px rgba(0, 0, 0, 0.13);
      }

      @media (max-width: 768px) {
        width: 100% !important;
      }
    }

    .accordion__panel {
      width: 240px !important;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 0 0 10px 10px !important;
      margin-top: 0 !important;
      height: fit-content !important;
      position: unset !important;
      z-index: 1;
      @media (max-width: 768px) {
        width: 100% !important;
      }
    }
  }
`

export const SearchInput = styled.label`
  position: relative;
  width: 100%;
  max-width: 210px;
  @media (min-width: 992px){
    max-width: 210px;
  }
  @media (max-width: 576px){
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
  }
  input {
    background-color: ${bg_gray};
    padding: 1rem 3rem 1rem 1rem;
    border-radius: 10px;
    border: 1.5px solid transparent !important;
    color: #000;
    height: 50px !important;
    box-shadow: none;
    width: 100%;

    &::placeholder {
      color: #C5C5C5;
    }

    &:focus {
      border: 1.5px solid #E9E9E9 !important;
      box-shadow: none;
      outline: none;
    }
  }

  .dv-search-icon {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    font-size: 20px;
    color: ${main_color};
  }

`

export const CustomTabs = styled.div`
  width: 100%;
  margin: 0 auto;

  div.afa-tab-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
    width: 85%;
    margin: 0 auto;
    @media (max-width: 576px) {
      flex-direction: column;
    }


    .afa-tab-title {
      min-width: 180px;
      justify-content: center;
      padding-bottom: 0.5rem;
      cursor: pointer;

      .afa-number-tabs {
        min-width: 28px;
        min-height: 28px;
        background: #F6F6F6;
        color: #0a0c0d;
        margin-right: 0.75rem;
        border-radius: 5px;
        text-align: center;
      }

      &.active {
        color: ${main_color};
        border-bottom: 2px solid ${main_color} !important;

        .afa-number-tabs {
          color: ${main_color};
        }
      }

    }
  }

  div.afa-tab-items {
    margin-top: 2rem;
    height: 500px;
    overflow: auto;
  }
`

export const Rows = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;

  tbody {
    tr {
      background: ${bg_gray};
      transition: all 0.3s linear;

      td {
        padding: 1.2rem 1.4rem;
        min-width: 200px;

        &:first-child {
          border-right: 4px solid ${main_color};
        }
      }

      &:hover {
        background: #f1f1f1;
      }

      a {
        color: ${next_btn_color};
        font-size: 16px;
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        span {
          font-size: 20px;
          padding-right: 0.5rem;
        }
      }
    }
  }

`

export const InformationContent = styled.div`
  background: ${bg_gray};
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1rem 0;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  align-items: center;

  div {
    //display: flex;
    //align-items: center;
    font-size: 14px;
    padding-left: 2rem;
    margin-bottom: 2rem;

    span.afa-title {
      color: #2F4858;
      padding-left: 0.25rem;
    }

    span.afa-value {
      color: #928f8f;
    }

    span.afa-img-value {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 78px;
      background: #ECECEC;
      border-radius: 8px;
      border: 1px dashed ${main_color};
      padding: 0.75rem;
      margin: 0.5rem;

      span.afa-img-title {
        color: ${main_color};
        margin-bottom: 0.35rem;
        font-size: 10px;
        line-height: 15px;
      }

      img.afa-img-size-information {
        width: 37px;
        height: 33px;
        object-fit: contain;
        transform: scale(1);
        transition: all 0.3s linear;

        &:hover {
          transform: scale(5);
        }
      }

      img.afa-img-size-information2 {
        width: 68px;
        height: 45px;
        object-fit: contain;
        transform: scale(1);
        transition: all 0.3s linear;

        &:hover {
          transform: scale(5);
        }
      }
    }
  }
`

export const InformationButton = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: end;
  margin: 1rem 1rem 1rem 0;
  @media (min-width: 992px) {
    position: absolute;
    bottom: -1.2rem;
    left: 1rem;
  }

  button , a {
    background: ${main_btn_color};
    border: none;
    border-radius: 10px;
    color: #fff !important;
    width: 180px;
    height: 55px;
    font-size: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;
    margin: 0.5rem;
    @media (max-width: 576px) {
      width: 100%;
    }

    span.afa-btn-icon {
      font-size: 28px;
      padding-left: 0.5rem;
    }

    &:hover {
      background: ${main_btn_color_hover};
    }
  }
`

export const TopInformation = styled.div`
  background: #fff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.13);
  padding: 1rem 1rem 0 1rem;
  margin: 2rem 3rem 1rem;
`