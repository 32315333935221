import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {
    CommaFormat,
    edit_item_with_id,
    getData,
    getFormDataPost,
    remove_item_of_arr_with_id
} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../../assets/scripts/GeneralVariables";
import {Inputs, TabStatusComplete, TabStatusContent} from "./style";
import {Accordion, Card} from "react-bootstrap";
import {DateInput} from "react-hichestan-datetimepicker";

function Licenses(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        date_received : '',
        expiration_date: '',
        granting: '',
        grade : '',
        field : '',
        capacity: '',
        maximum_activity_amount: '',
        supplier_licences : []
    })
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [is_render, setRender] = useState(false);
    const [new_collapse, setNewCollapse] = useState(false);
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/supplier/licences", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let supplier_licences = data?.supplier_licences
                setInputs(prevState => ({
                    ...prevState,
                    supplier_licences : supplier_licences
                }))
            setMoreData({sheet_status: data?.sheet_status,status_colors: data?.status_colors, isLoad: false})
         }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const datePickerHandler = (e) => {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let selected_date = e.target.value
        if(date.normalize() < selected_date.normalize()){
            toast.error('تاریخ انتخابی نمیتواند بعد امروز باشد')
            return false
        }else{
            let date2 = e.target.value
            setInputs(prevState => ({
                ...prevState, [e.target.name]: date2?.split('T')[0]
            }))
        }
    }

    const datePickerHandlerExpire = (e) => {
        let date2 = e.target.value
        setInputs(prevState => ({
            ...prevState, [e.target.name]: date2?.split('T')[0]
        }))
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = inputs?.supplier_licences?.find((row) => {
            return row.id === id
        })
        if (data) {
            setInputs((prevState => ({
                ...prevState,
                [`grade[${id}]`]: data?.grade,
                [`field[${id}]`]: data?.field,
                [`granting[${id}]`]: data?.granting,
                [`date_received[${id}]`]: data?.date_received,
                [`expiration_date[${id}]`]: data?.expiration_date,
                [`capacity[${id}]`]: data?.capacity,
                [`maximum_activity_amount[${id}]`]: data?.maximum_activity_amount,
            })))
        }
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/supplier/licences/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.supplier_licences;
                    let new_arr = remove_item_of_arr_with_id(arr , id)
                    setInputs(prevState => ({...prevState , supplier_licences: new_arr}))
                }
            }
        })
    }

    const removeTab2 = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                setNewCollapse(false)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const saveEditChange = async (id) => {
        let formData = new FormData()
        if (inputs?.[`grade[${id}]`] !== undefined) {formData.append('grade', inputs?.[`grade[${id}]`])}
        if (inputs?.[`field[${id}]`] !== undefined) {formData.append('field', inputs?.[`field[${id}]`])}
        if (inputs?.[`granting[${id}]`] !== undefined) {formData.append('granting', inputs?.[`granting[${id}]`])}
        if (inputs?.[`date_received[${id}]`] !== undefined) {formData.append('date_received', inputs?.[`date_received[${id}]`])}
        if (inputs?.[`expiration_date[${id}]`] !== undefined) {formData.append('expiration_date', inputs?.[`expiration_date[${id}]`])}
        if (inputs?.[`capacity[${id}]`] !== undefined) {formData.append('capacity', inputs?.[`capacity[${id}]`])}
        if (inputs?.[`maximum_activity_amount[${id}]`] !== undefined) {formData.append('maximum_activity_amount', inputs?.[`maximum_activity_amount[${id}]`])}

        let api_result = await getFormDataPost(`profile/supplier/licences/${id}`, formData)
        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            let arrays = edit_item_with_id(inputs?.supplier_licences , api_result?.data?.supplier_licence)
            setInputs(prevState => ({...prevState , supplier_licences: arrays}))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addNewCollapse = () => {
        setInputs(prevState => ({
            ...prevState,
            date_received : '',
            expiration_date: '',
            granting: '',
            grade : '',
            field : '',
            capacity: '',
            maximum_activity_amount: '',
        }));
        setNewCollapse(true)
    }

    const saveNewCollapse = async () => {
        let formData = new FormData()
        if (inputs?.[`grade`] !== undefined) {formData.append('grade', inputs?.[`grade`])}
        if (inputs?.[`field`] !== undefined) {formData.append('field', inputs?.[`field`])}
        if (inputs?.[`granting`] !== undefined) {formData.append('granting', inputs?.[`granting`])}
        if (inputs?.[`date_received`] !== undefined) {formData.append('date_received', inputs?.[`date_received`])}
        if (inputs?.[`expiration_date`] !== undefined) {formData.append('expiration_date', inputs?.[`expiration_date`])}
        if (inputs?.[`capacity`] !== undefined) {formData.append('capacity', inputs?.[`capacity`])}
        if (inputs?.[`maximum_activity_amount`] !== undefined) {formData.append('maximum_activity_amount', inputs?.[`maximum_activity_amount`])}

        let api_result = await getFormDataPost('profile/supplier/licences', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            if(inputs?.supplier_licences?.length > 0 ){
                let arr = inputs?.supplier_licences;
                arr.push(api_result?.data?.supplier_licence)
                setInputs(prevState => ({
                    ...prevState,
                    date_received : '',
                    expiration_date: '',
                    granting: '',
                    grade : '',
                    field : '',
                    capacity: '',
                    maximum_activity_amount: '',
                    supplier_licences : arr
                }))
            }else{
                setRender(!is_render)
            }
            setNewCollapse(false)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs?.[`grade`] !== undefined) {formData.append('grade', inputs?.[`grade`])}
        if (inputs?.[`field`] !== undefined) {formData.append('field', inputs?.[`field`])}
        if (inputs?.[`granting`] !== undefined) {formData.append('granting', inputs?.[`granting`])}
        if (inputs?.[`date_received`] !== undefined) {formData.append('date_received', inputs?.[`date_received`])}
        if (inputs?.[`expiration_date`] !== undefined) {formData.append('expiration_date', inputs?.[`expiration_date`])}
        if (inputs?.[`capacity`] !== undefined) {formData.append('capacity', inputs?.[`capacity`])}
        if (inputs?.[`maximum_activity_amount`] !== undefined) {formData.append('maximum_activity_amount', inputs?.[`maximum_activity_amount`])}

        let api_result = await getFormDataPost('profile/supplier/licences', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            if(inputs?.supplier_licences?.length > 0 ){
                let arr = inputs?.supplier_licences;
                arr.push(api_result?.data?.supplier_licence)
                setInputs(prevState => ({
                    ...prevState,
                    date_received : '',
                    expiration_date: '',
                    granting: '',
                    grade : '',
                    field : '',
                    capacity: '',
                    maximum_activity_amount: '',
                    supplier_licences : arr
                }))
            }else{
                setRender(!is_render)
            }
            setNewCollapse(false)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }
    return (
        <>
            {
                // !inputs?.["isLoad"] &&
                <div className='row'>
                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                        <TabStatusComplete style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                            {
                                props.status_value === 'pending' || props.status_value === 'denied' ?
                                    <span style={{
                                        color: props.status_colors?.colors?.color,
                                        backgroundColor: props.status_colors?.colors?.background
                                    }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                    <span style={{
                                        color: props.status_colors?.colors?.color,
                                        backgroundColor: props.status_colors?.colors?.background
                                    }} className='icon-tick afa-icon-tick'/>
                            }
                            <p className='mb-0 f-14 '><span>وضعیت : </span><span>{props.status_colors?.title}</span></p>
                        </TabStatusComplete>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-8 mb-4">
                        {
                            more_data.sheet_status?.message &&
                            <TabStatusContent style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                                <p className='mb-0 f-14'><span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                                </p>
                            </TabStatusContent>
                        }
                    </div>

                    <div className="col-12 mb-4">
                        <h6 className='afa-part-title'>پروانه ها / گواهی صلاحیت</h6>
                    </div>

                    <form onSubmit={formHandler} className='row'>
                        {
                            inputs?.supplier_licences?.length > 0 ?
                                <>
                                    <Accordion defaultActiveKey="0">
                                        {
                                            inputs?.supplier_licences?.map((item, i) => (
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey={`${item.id}`} key={i} onClick={() => changeArrow(item.id)}>
                                                        <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab(item.id)}></span>
                                                            {
                                                                item?.grade && <span
                                                                    className="pl-4 f-14 my-2"> پایه : {item?.grade}</span>
                                                            }
                                                            {
                                                                item?.field && <span
                                                                    className="pl-4 f-14 my-2"> رشته تخصصی : {item?.field}</span>
                                                            }
                                                            {
                                                                item?.granting && <span
                                                                    className="pl-4 f-14 my-2"> صادر کننده : {item?.granting}</span>
                                                            }
                                                        </div>
                                                        <span
                                                            className={(arrow_item.arrow_id === item.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey={`${item.id}`}>
                                                        <Card.Body className='row'>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>پایه
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`grade[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={`grade[${item.id}]`}
                                                                        value={inputs?.[`grade[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`grade[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`grade[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>رشته تخصصی
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`field[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={`field[${item.id}]`}
                                                                        value={inputs?.[`field[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`field[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`field[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>صادر کننده
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`granting[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        maxLength={192}
                                                                        name={`granting[${item.id}]`}
                                                                        value={inputs?.[`granting[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`granting[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`granting[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>تاریخ اخذ
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <DateInput
                                                                        value={inputs[`date_received[${item.id}]`]}
                                                                        name={`date_received[${item.id}]`}
                                                                        className={error[`date_received[${item.id}]`] && 'afa-error-input'}
                                                                        placeholder={'تاریخ اخذ را انتخاب کنید'}
                                                                        onChange={datePickerHandler}/>
                                                                    <p className='afa-error-text'>{error[`date_received[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>تاریخ انقضا
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <DateInput
                                                                        value={inputs[`expiration_date[${item.id}]`]}
                                                                        name={`expiration_date[${item.id}]`}
                                                                        className={error[`expiration_date[${item.id}]`] && 'afa-error-input'}
                                                                        placeholder={'تاریخ انقضا را انتخاب کنید'}
                                                                        onChange={datePickerHandlerExpire}/>
                                                                    <p className='afa-error-text'>{error[`expiration_date[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>ظرفیت
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`capacity[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={`capacity[${item.id}]`}
                                                                        value={inputs?.[`capacity[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`capacity[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`capacity[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>حداکثر مبلغ فعالیت
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`maximum_activity_amount[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={`maximum_activity_amount[${item.id}]`}
                                                                        value={inputs?.[`maximum_activity_amount[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`maximum_activity_amount[${item.id}]`]: CommaFormat(e.target.value)
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`maximum_activity_amount[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 d-flex justify-content-center">
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs>
                                                                        <button type='button'
                                                                                onClick={() => saveEditChange(item.id)}
                                                                                className='afa-add-more-btn'>
                                                                            <span className='pr-3'>ذخیره تغییرات</span>
                                                                        </button>
                                                                    </Inputs>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            ))
                                        }
                                        {
                                            new_collapse &&
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey={'0'}
                                                                  onClick={() => changeArrow(0)}>
                                                    <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab2('rasoul')}></span>
                                                        {
                                                            inputs?.grade && <span
                                                                className="pl-4 f-14 my-2"> پایه : {inputs?.grade}</span>
                                                        }
                                                        {
                                                            inputs?.field && <span
                                                                className="pl-4 f-14 my-2"> رشته تخصصی : {inputs?.field}</span>
                                                        }
                                                        {
                                                            inputs?.granting && <span
                                                                className="pl-4 f-14 my-2"> صادر کننده : {inputs?.granting}</span>
                                                        }
                                                    </div>
                                                    <span
                                                        className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={'0'}>
                                                    <Card.Body className='row'>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>پایه
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`grade`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`grade`}
                                                                    value={inputs?.[`grade`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`grade`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`grade`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>رشته تخصصی
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`field`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`field`}
                                                                    value={inputs?.[`field`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`field`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`field`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>صادر کننده
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`granting`] && 'afa-error-input'}
                                                                    type="text"
                                                                    maxLength={192}
                                                                    name={`granting`}
                                                                    value={inputs?.[`granting`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`granting`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`granting`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>تاریخ اخذ
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <DateInput
                                                                    value={inputs[`date_received`]}
                                                                    name={`date_received`}
                                                                    className={error[`date_received`] && 'afa-error-input'}
                                                                    placeholder={'تاریخ اخذ را انتخاب کنید'}
                                                                    onChange={datePickerHandler}/>
                                                                <p className='afa-error-text'>{error[`date_received`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>تاریخ انقضا
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <DateInput
                                                                    value={inputs[`expiration_date`]}
                                                                    name={`expiration_date`}
                                                                    className={error[`expiration_date`] && 'afa-error-input'}
                                                                    placeholder={'تاریخ انقضا را انتخاب کنید'}
                                                                    onChange={datePickerHandlerExpire}/>
                                                                <p className='afa-error-text'>{error[`expiration_date`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>ظرفیت
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`capacity`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`capacity`}
                                                                    value={inputs?.[`capacity`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`capacity`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`capacity`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>حداکثر مبلغ فعالیت
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`maximum_activity_amount`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`maximum_activity_amount`}
                                                                    value={inputs?.[`maximum_activity_amount`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`maximum_activity_amount`]: CommaFormat(e.target.value)
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`maximum_activity_amount`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-center">
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <button type='button'
                                                                            onClick={saveNewCollapse}
                                                                            className='afa-add-more-btn'>
                                                                        <span className='pr-3'>ذخیره تغییرات</span>
                                                                    </button>
                                                                </Inputs>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        }

                                    </Accordion>

                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-8 mb-4"/>

                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>

                                            <Inputs>

                                                <button type='button' onClick={addNewCollapse}
                                                        className='afa-add-more-btn'>

                                                    <span className='icon-add f-20'/>

                                                    <span className='pr-3'>افزودن اطلاعات</span>

                                                </button>

                                            </Inputs>

                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>پایه
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`grade`] && 'afa-error-input'}
                                                type="text"
                                                name={`grade`}
                                                value={inputs?.[`grade`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`grade`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`grade`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>رشته تخصصی
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`field`] && 'afa-error-input'}
                                                type="text"
                                                name={`field`}
                                                value={inputs?.[`field`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`field`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`field`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>صادر کننده
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`granting`] && 'afa-error-input'}
                                                type="text"
                                                maxLength={192}
                                                name={`granting`}
                                                value={inputs?.[`granting`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`granting`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`granting`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>تاریخ اخذ
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <DateInput
                                                value={inputs[`date_received`]}
                                                name={`date_received`}
                                                className={error[`date_received`] && 'afa-error-input'}
                                                placeholder={'تاریخ اخذ را انتخاب کنید'}
                                                onChange={datePickerHandler}/>
                                            <p className='afa-error-text'>{error[`date_received`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>تاریخ انقضا
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <DateInput
                                                value={inputs[`expiration_date`]}
                                                name={`expiration_date`}
                                                className={error[`expiration_date`] && 'afa-error-input'}
                                                placeholder={'تاریخ انقضا را انتخاب کنید'}
                                                onChange={datePickerHandlerExpire}/>
                                            <p className='afa-error-text'>{error[`expiration_date`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>ظرفیت
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`capacity`] && 'afa-error-input'}
                                                type="text"
                                                name={`capacity`}
                                                value={inputs?.[`capacity`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`capacity`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`capacity`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>حداکثر مبلغ فعالیت
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`maximum_activity_amount`] && 'afa-error-input'}
                                                type="text"
                                                name={`maximum_activity_amount`}
                                                value={inputs?.[`maximum_activity_amount`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`maximum_activity_amount`]: CommaFormat(e.target.value)
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`maximum_activity_amount`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 d-flex justify-content-center">
                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>
                                            <Inputs>
                                                <button type='submit' className='afa-add-more-btn'>
                                                    <span className='icon-add f-20'/>
                                                    <span className='pr-3'>افزودن اطلاعات</span>
                                                </button>
                                            </Inputs>
                                        </div>
                                    </div>
                                </>
                        }
                    </form>

                </div>
            }
        </>
    )
}

export default Licenses;