import React, {useState} from 'react';
import {ArticleList, MainContent} from "./style";
import {SearchInput} from "../Child/SupplierMangement/style";
import {Images} from "../../../assets/scripts/Images";

function Dashboard(props) {
    return (
        <MainContent>
            <div className='row'>
                <div className="col-12 col-sm-6 col-lg-6 mb-2"><h6 className='text-red fw-bold'>داشبورد</h6></div>
            </div>
        </MainContent>
    );
}

export default Dashboard;