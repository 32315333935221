import React, {useState, useEffect} from 'react';
import {
    TabStatusComplete,
    TabStatusNotComplete,
    Inputs,
    LeftIcon,
    FileInputs, TabStatusContent
} from "./style";
import moment from "moment-jalaali";
import {
    checkCodeMelli,
    fileUpload,
    fixNumber,
    just_persian,
    edit_item_with_id,
    getData,
    getFormDataPost, remove_item_of_arr_with_id
} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

function Equipment(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        name: '',
        technical_info: '',
        production_year: '',
        count: '',
        ownership_type : 'انتخاب کنید',
        producer_country: '',
        brand : '',
        supplier_equipments: []
    })
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [is_render, setRender] = useState(false);
    const [new_collapse, setNewCollapse] = useState(false);
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/supplier/equipments", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let supplier_equipment = data?.supplier_equipment
            setInputs(prevState => ({
                ...prevState,
                supplier_equipments : supplier_equipment
            }))

            setMoreData({sheet_status: data?.sheet_status,status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const numberInputsHandler = (e) => {
        const regexp = /^[0-9\b]+$/;
        if (e.target.value === '' || regexp.test(e.target.value)) {
            setInputs(prevState => ({
                ...prevState, [e.target.name]: e.target.value
            }))
        }
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = inputs?.supplier_equipments?.find((row) => {
            return row.id === id
        })
        if (data) {
            setInputs((prevState => ({
                ...prevState,
                [`name[${id}]`]: data?.name,
                [`technical_info[${id}]`]: data?.technical_info,
                [`production_year[${id}]`]: data?.production_year,
                [`count[${id}]`]: data?.count,
                [`ownership_type[${id}]`]: data?.ownership_type,
                [`brand[${id}]`]: data?.brand,
                [`producer_country[${id}]`]: data?.producer_country,
            })))
        }
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/supplier/equipments/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.supplier_equipments;
                    let new_arr = remove_item_of_arr_with_id(arr , id)
                    setInputs(prevState => ({...prevState , supplier_equipments: new_arr}))
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }
    const removeTab2 = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                setNewCollapse(false)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const saveEditChange = async (id) => {
        let formData = new FormData()
        
        if (inputs?.[`name[${id}]`] !== undefined) {formData.append('name', inputs?.[`name[${id}]`])}
        if (inputs?.[`technical_info[${id}]`] !== undefined) {formData.append('technical_info', inputs?.[`technical_info[${id}]`])}
        if (inputs?.[`production_year[${id}]`] !== undefined) {formData.append('production_year', inputs?.[`production_year[${id}]`])}
        if (inputs?.[`count[${id}]`] !== undefined) {formData.append('count', inputs?.[`count[${id}]`])}
        if (inputs?.[`ownership_type[${id}]`] !== 'انتخاب کنید') {formData.append('ownership_type', inputs?.[`ownership_type[${id}]`])}
        if (inputs?.[`brand[${id}]`] !== undefined) {formData.append('brand', inputs?.[`brand[${id}]`])}
        if (inputs?.[`producer_country[${id}]`] !== undefined) {formData.append('producer_country', inputs?.[`producer_country[${id}]`])}


        let api_result = await getFormDataPost(`profile/supplier/equipments/${id}`, formData)
        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            let arrays = edit_item_with_id(inputs?.supplier_equipments , api_result?.data?.supplier_equipment)
            setInputs(prevState => ({...prevState , supplier_equipments: arrays}))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addNewCollapse = () => {
        setInputs(prevState => ({
            ...prevState,
            name: '',
            technical_info: '',
            production_year: '',
            count: '',
            ownership_type : 'انتخاب کنید',
            producer_country: '',
            brand : '',
        }));
        setNewCollapse(true)
    }

    const saveNewCollapse = async () => {
        let formData = new FormData()
        if (inputs?.[`name`] !== undefined) {formData.append('name', inputs?.[`name`])}
        if (inputs?.[`technical_info`] !== undefined) {formData.append('technical_info', inputs?.[`technical_info`])}
        if (inputs?.[`production_year`] !== undefined) {formData.append('production_year', inputs?.[`production_year`])}
        if (inputs?.[`count`] !== undefined) {formData.append('count', inputs?.[`count`])}
        if (inputs?.[`ownership_type`] !== 'انتخاب کنید') {formData.append('ownership_type', inputs?.[`ownership_type`])}
        if (inputs?.[`brand`] !== undefined) {formData.append('brand', inputs?.[`brand`])}
        if (inputs?.[`producer_country`] !== undefined) {formData.append('producer_country', inputs?.[`producer_country`])}

        // let api_result = await getFormDataPost('profile/family-members', formData)
        //
        // if (api_result?.status === 200) {
        //     toast.success('با موفقیت افزوده شد')
        //     setError('')
        //     setRender(!is_render)
        //     setNewCollapse(false)
        // } else if (api_result?.status === 400) {
        //     setError(api_result?.data)
        // }
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs?.[`name`] !== undefined) {formData.append('name', inputs?.[`name`])}
        if (inputs?.[`technical_info`] !== undefined) {formData.append('technical_info', inputs?.[`technical_info`])}
        if (inputs?.[`production_year`] !== undefined) {formData.append('production_year', inputs?.[`production_year`])}
        if (inputs?.[`count`] !== undefined) {formData.append('count', inputs?.[`count`])}
        if (inputs?.[`ownership_type`] !== 'انتخاب کنید') {formData.append('ownership_type', inputs?.[`ownership_type`])}
        if (inputs?.[`brand`] !== undefined) {formData.append('brand', inputs?.[`brand`])}
        if (inputs?.[`producer_country`] !== undefined) {formData.append('producer_country', inputs?.[`producer_country`])}

        let api_result = await getFormDataPost('profile/supplier/equipments', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            if(inputs?.supplier_equipments?.length > 0 ){
                let arr = inputs?.supplier_equipments;
                arr.push(api_result?.data?.supplier_equipment)
                setInputs(prevState => ({
                    ...prevState,
                    name: '',
                    technical_info: '',
                    production_year: '',
                    count: '',
                    ownership_type : 'انتخاب کنید',
                    producer_country: '',
                    brand : '',
                    supplier_equipments: arr
                }))
            }else{
                setRender(!is_render)
            }
            setNewCollapse(false)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    return (
        <>
            {
                <>
                    <form onSubmit={formHandler} className="row">
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <TabStatusComplete style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                                {
                                    props.status_value === 'pending' || props.status_value === 'denied' ?
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-tick afa-icon-tick'/>
                                }
                                <p className='mb-0 f-14 '><span>وضعیت : </span><span>{props.status_colors?.title}</span></p>
                            </TabStatusComplete>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-8 mb-4">
                            {
                                more_data.sheet_status?.message &&
                                <TabStatusContent style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                                    <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </span>
                                    <p className='mb-0 f-14'><span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                                    </p>
                                </TabStatusContent>
                            }
                        </div>
                        {
                            inputs?.supplier_equipments?.length > 0 ?
                                <>
                                    <Accordion defaultActiveKey="0">
                                        {
                                            inputs?.supplier_equipments?.map((item, i) => (
                                                <Card key={i}>
                                                    <Accordion.Toggle as={Card.Header} eventKey={`${item.id}`}
                                                                      onClick={() => changeArrow(item.id)}>
                                                        <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab(item.id)}></span>
                                                            {
                                                                item?.name && <span
                                                                    className="pl-4 f-14 my-2"> نام ابزار/تجهیزات : {item?.name}</span>
                                                            }
                                                            {
                                                                item?.technical_info && <span
                                                                    className="pl-4 f-14 my-2"> مشخصات فنی : {item?.technical_info}</span>
                                                            }
                                                            {
                                                                item?.production_year && <span
                                                                    className="pl-4 f-14 my-2"> سال ساخت : {item?.production_year}</span>
                                                            }
                                                            {
                                                                item?.ownership_type && <span
                                                                    className="pl-4 f-14 my-2"> نوع مالکیت : {item?.ownership_type}</span>
                                                            }
                                                        </div>
                                                        <span
                                                            className={(arrow_item.arrow_id === item.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey={`${item.id}`}>
                                                        <Card.Body className='row'>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>نام ابزار / تجهیزات
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`name[${item.id}]`] && 'afa-error-input'}
                                                                        type="text" name={`name[${item.id}]`}
                                                                        value={inputs?.[`name[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`name[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`name[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>برند
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`brand[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={`brand[${item.id}]`}
                                                                        value={inputs?.[`brand[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`brand[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`brand[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>سال ساخت
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`production_year[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        max={9999}
                                                                        maxLength={4}
                                                                        name={`production_year[${item.id}]`}
                                                                        value={inputs?.[`production_year[${item.id}]`]}
                                                                        onChange={numberInputsHandler}/>
                                                                    <p className='afa-error-text'>{error[`production_year[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>تعداد
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`count[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        max={99999}
                                                                        maxLength={5}
                                                                        name={`count[${item.id}]`}
                                                                        value={inputs?.[`count[${item.id}]`]}
                                                                        onChange={numberInputsHandler}/>
                                                                    <p className='afa-error-text'>{error[`count[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>نوع مالکیت
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <select
                                                                        className={error[`ownership_type[${item.id}]`] && 'afa-error-input'}
                                                                        value={inputs?.[`ownership_type[${item.id}]`]}
                                                                        onChange={inputHandler}
                                                                        name={`ownership_type[${item.id}]`}>
                                                                        <option value='انتخاب کنید' disabled>انتخاب کنید
                                                                        </option>
                                                                        <option value="ملکی">ملکی</option>
                                                                        <option value="استیجاری">استیجاری</option>
                                                                    </select>
                                                                    <p className='afa-error-text'>{error[`ownership_type[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>کشور سازنده
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`producer_country[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={`producer_country[${item.id}]`}
                                                                        value={inputs?.[`producer_country[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`producer_country[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`producer_country[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 mb-4">
                                                                <Inputs>
                                                                    <span>مشخصات فنی
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <textarea
                                                                        className={error[`technical_info[${item.id}]`] && 'afa-error-input'}
                                                                        rows={2} name={`technical_info[${item.id}]`}
                                                                        value={inputs?.[`technical_info[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`technical_info[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`technical_info[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 d-flex justify-content-center">
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs>
                                                                        <button type='button'
                                                                                onClick={() => saveEditChange(item.id)}
                                                                                className='afa-add-more-btn'>
                                                                            <span className='pr-3'>ذخیره تغییرات</span>
                                                                        </button>
                                                                    </Inputs>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            ))
                                        }

                                        {
                                            new_collapse &&
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey={'0'} onClick={() => changeArrow(0)}>
                                                    <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab2('rasoul')}></span>
                                                        {
                                                            inputs?.name && <span
                                                                className="pl-4 f-14 my-2"> نام ابزار/تجهیزات : {inputs?.name}</span>
                                                        }
                                                        {
                                                            inputs?.technical_info && <span
                                                                className="pl-4 f-14 my-2"> مشخصات فنی : {inputs?.technical_info}</span>
                                                        }
                                                        {
                                                            inputs?.production_year && <span
                                                                className="pl-4 f-14 my-2"> سال ساخت : {inputs?.production_year}</span>
                                                        }
                                                        {
                                                            inputs?.ownership_type && <span
                                                                className="pl-4 f-14 my-2"> نوع مالکیت : {inputs?.ownership_type}</span>
                                                        }
                                                    </div>
                                                    <span
                                                        className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={'0'}>
                                                    <Card.Body className='row'>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>نام ابزار / تجهیزات
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`name`] && 'afa-error-input'}
                                                                    type="text" name={`name`}
                                                                    value={inputs?.[`name`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`name`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`name`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>برند
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`brand`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`brand`}
                                                                    value={inputs?.[`brand`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`brand`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`brand`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>سال ساخت
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`production_year`] && 'afa-error-input'}
                                                                    type="text"
                                                                    max={9999}
                                                                    maxLength={4}
                                                                    name={`production_year`}
                                                                    value={inputs?.[`production_year`]}
                                                                    onChange={numberInputsHandler}/>
                                                                <p className='afa-error-text'>{error[`production_year`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>تعداد
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`count`] && 'afa-error-input'}
                                                                    type="text"
                                                                    max={99999}
                                                                    maxLength={5}
                                                                    name={`count`}
                                                                    value={inputs?.[`count`]}
                                                                    onChange={numberInputsHandler}/>
                                                                <p className='afa-error-text'>{error[`count`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>نوع مالکیت
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <select
                                                                    className={error[`ownership_type`] && 'afa-error-input'}
                                                                    value={inputs?.[`ownership_type`]}
                                                                    onChange={inputHandler}
                                                                    name={`ownership_type`}>
                                                                    <option value='انتخاب کنید' disabled>انتخاب کنید
                                                                    </option>
                                                                    <option value="ملکی">ملکی</option>
                                                                    <option value="استیجاری">استیجاری</option>
                                                                </select>
                                                                <p className='afa-error-text'>{error[`ownership_type`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>کشور سازنده
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`producer_country`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`producer_country`}
                                                                    value={inputs?.[`producer_country`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`producer_country`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`producer_country`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 mb-4">
                                                            <Inputs>
                                                                <span>مشخصات فنی
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <textarea
                                                                    rows={2}
                                                                    className={error[`technical_info`] && 'afa-error-input'}
                                                                    name={`technical_info`}
                                                                    value={inputs?.[`technical_info`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`technical_info`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`technical_info`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-center">
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <button type='submit'
                                                                            // onClick={saveNewCollapse}
                                                                            className='afa-add-more-btn'>
                                                                        <span className='pr-3'>ذخیره تغییرات</span>
                                                                    </button>
                                                                </Inputs>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        }

                                    </Accordion>

                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-8 mb-4"/>

                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>

                                            <Inputs>

                                                <button type='button'
                                                        onClick={addNewCollapse}
                                                        className='afa-add-more-btn'>

                                                    <span className='icon-add f-20'/>

                                                    <span className='pr-3'>افزودن اطلاعات</span>

                                                </button>

                                            </Inputs>

                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>نام ابزار / تجهیزات
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`name`] && 'afa-error-input'}
                                                type="text" name={`name`}
                                                value={inputs?.[`name`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`name`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`name`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>برند
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`brand`] && 'afa-error-input'}
                                                type="text"
                                                name={`brand`}
                                                value={inputs?.[`brand`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`brand`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`brand`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>سال ساخت
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`production_year`] && 'afa-error-input'}
                                                type="text"
                                                max={9999}
                                                maxLength={4}
                                                name={`production_year`}
                                                value={inputs?.[`production_year`]}
                                                onChange={numberInputsHandler}/>
                                            <p className='afa-error-text'>{error[`production_year`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>تعداد
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`count`] && 'afa-error-input'}
                                                type="text"
                                                max={99999}
                                                maxLength={5}
                                                name={`count`}
                                                value={inputs?.[`count`]}
                                                onChange={numberInputsHandler}/>
                                            <p className='afa-error-text'>{error[`count`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>نوع مالکیت
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <select
                                                className={error[`ownership_type`] && 'afa-error-input'}
                                                value={inputs?.[`ownership_type`]}
                                                onChange={inputHandler}
                                                name={`ownership_type`}>
                                                <option value='انتخاب کنید' disabled>انتخاب کنید
                                                </option>
                                                <option value="ملکی">ملکی</option>
                                                <option value="استیجاری">استیجاری</option>
                                            </select>
                                            <p className='afa-error-text'>{error[`ownership_type`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>کشور سازنده
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`producer_country`] && 'afa-error-input'}
                                                type="text"
                                                name={`producer_country`}
                                                value={inputs?.[`producer_country`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`producer_country`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`producer_country`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 mb-4">
                                        <Inputs>
                                            <span>مشخصات فنی
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <textarea
                                                rows={2}
                                                className={error[`technical_info`] && 'afa-error-input'}
                                                name={`technical_info`}
                                                value={inputs?.[`technical_info`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`technical_info`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`technical_info`]}</p>
                                        </Inputs>
                                    </div>
                                    
                                    <div className="col-12 d-flex justify-content-center">
                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>
                                            <Inputs>
                                                <button type='submit' className='afa-add-more-btn'>
                                                    <span className='icon-add f-20'/>
                                                    <span className='pr-3'>افزودن اطلاعات</span>
                                                </button>
                                            </Inputs>
                                        </div>
                                    </div>
                                </>
                        }
                    </form>
                </>
            }
        </>
    )
}

export default Equipment;