import React, {useEffect, useState} from 'react';
import {ArticleList, MainContent} from "../style";
import {SearchInput} from "../../Child/SupplierMangement/style";
import {getData, priceFormat, remove_item_of_arr_with_id, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import {Images} from "../../../../assets/scripts/Images";
import {Link} from "react-router-dom";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Chart from "react-apexcharts";
import Skeleton from 'react-loading-skeleton'
import 'react-tabs/style/react-tabs.css';
import Swal from "sweetalert2";

function Products(props) {

    const [inputs, setInputs] = useState({
        search_value: '', products: [NaN, NaN, NaN, NaN], prices: [NaN, NaN, NaN, NaN], tab_title: 'product'
    })

    const [item, setItem] = useState({
        // mini Charts
        series: [
            {
                name: "Desktops",
                data: [31, 40, 28, 51, 42, 109, 100, 50, 25, 85, 14, 100],
            },],
        options: {
            chart: {
                type: 'line',
                zoom: {
                    enabled: true
                },
                sparkline: {
                    enabled: true
                }
            },
            // dataLabels: {
            //     enabled: false
            // },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                marker: {
                    show: false
                }
            },
            stroke: {
                width: 3,
                curve: 'smooth'
            },
            // title: {
            //     text: 'Product Trends by Month',
            //     align: 'center'
            // },
            colors: ['#ff0000'],
            fontFamily: 'iranYekan'
            // grid: {
            // row: {
            //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            //     opacity: 0.5
            // },
            // },
            // xaxis: {
            //     categories: ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور']
            // }
        }
        // end mini charts
    })

    const [is_all_items_load, setAllLoad] = useState(true)

    const [data, setObject] = useState({
        'offset': 0,
        'limit': 30,
    })

    useEffect(async () => {
        setTitle('آتاویتا | محصولات')
        let api_result = await getData(MAIN_URL, `product-management/products`, 'get', data, true, false);
        if (api_result?.status === 200) {
            let product_api = await getData(MAIN_URL, `product-management/prices`, 'get', data, true, false);
            localStorage.setItem('product_categories', JSON.stringify(api_result?.data?.product_categories))
            setInputs(prevState => ({
                ...prevState,
                products: api_result?.data?.products,
                prices: product_api?.data?.products,
            }))
            // setItem(prevState => ({...prevState , series: }))
        }
    }, []);

    const searchHandler = async (e) => {
        let object = {};
        object = data;
        object['search'] = e.target.value
        setInputs(prevState => ({
            ...prevState,
            search_value: e.target.value,
            products: [NaN, NaN, NaN, NaN],
        }))
        let api_result = await getData(MAIN_URL, `product-management/products`, 'get', data, true, false);
        if (api_result?.status === 200) {
            setInputs(prevState => ({
                ...prevState,
                products: api_result?.data?.products,
            }))
            setObject(object)
        }
    }

    const getTableDataLazyLoad = async (obj) => {
        if (parseFloat(obj.target.offsetHeight + obj.target.scrollTop) == parseFloat(obj.target.scrollHeight)) {
            if (inputs?.products?.length % 30 === 0 && is_all_items_load === true) {
                let object = {};
                object = data;
                object['offset'] = inputs?.products?.length
                let moreData = await getData(MAIN_URL, `product-management/products`, 'get', object, true, true);
                if (moreData) {
                    setObject(obj)
                    setInputs(prevState => ({...prevState, products: inputs?.products.concat(moreData.data?.products)}))
                    if (moreData.data?.products?.length < 30) {
                        setAllLoad({is_all_items_load: false})
                    }
                }
            }
        }
    }

    const removeItem = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `product-management/products/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.products;
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, products: new_arr}))
                }
            }
        })
    }

    const tabHandler = (title) => {
        setInputs(prevState => ({...prevState, tab_title: title}))
    }

    return (
        <MainContent>
            <Tabs>
                <div className='row'>
                    <div
                        className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-between mb-3">
                        <TabList className='afa-product-tabs'>
                            <Tab onClick={() => tabHandler('product')}>محصولات</Tab>
                            <Tab onClick={() => tabHandler('price')}>قیمت</Tab>
                        </TabList>
                        <div className="d-flex flex-wrap flex-md-nowrap">
                            {
                                inputs?.tab_title === 'product' &&
                                <SearchInput className='mb-3'>
                                    <span className='icon-Search dv-search-icon'/>
                                    <input type="search" value={inputs?.search_value} onChange={searchHandler}
                                           placeholder='جستجو'/>
                                </SearchInput>
                            }
                            <Link to={'/new_product'} className='add-new-btn'>
                                <span className="icon-add_paper"/>
                                <span>افزودن محصول جدید</span>
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 mb-3 afa-lazyload" onScroll={getTableDataLazyLoad}>
                        <TabPanel>
                            {
                                inputs?.products?.length > 0 ?
                                    !inputs?.products[0] ?
                                        inputs?.products?.map((row, index) => (
                                            <>
                                                <Skeleton key={index} borderRadius={8} height={55}/>
                                            </>
                                        )) :
                                        inputs?.products?.map((row, index) => (
                                            <ArticleList className='row align-items-center' key={index}>
                                                <div
                                                    className="col-12 col-sm-6 col-lg-2 mb-3 d-flex align-items-center justify-content-center">
                                                     <span
                                                         className="icon-Delete dv-icon-delete ps-5 my-2"
                                                         onClick={() => removeItem(row.id)}></span>
                                                    <div className="afa-article-img">
                                                        <img
                                                            src={row?.image ? `${MAIN_URL_IMAGE}${row?.image}` : Images?.article.default}
                                                            className='img-fluid' alt="مقاله"/>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-9">
                                                    <div className="d-flex flex-column align-items-stretch">
                                                        <h6 className='afa-header-article mb-3'>{row?.title?.fa}</h6>
                                                        <div className="d-flex flex-wrap">
                                                            <div className="d-flex flex-column ml-2-rem">
                                                                <p className='afa-product-option-title mb-0'>وزن
                                                                    طولی</p>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={Images.right_top.default}
                                                                             className='img-fluid'
                                                                             alt="آتاویتا"/>
                                                                        <span
                                                                            className='afa-product-option-value-top'>{row?.longitudinal_weight?.[1]}</span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={Images.right_bottom.default}
                                                                             className='img-fluid'
                                                                             alt="آتاویتا"/>
                                                                        <span
                                                                            className='afa-product-option-value-bottom'>{row?.longitudinal_weight?.[0]}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-column ml-2-rem">
                                                                <p className='afa-product-option-title mb-0'>تنش
                                                                    تسلیم</p>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={Images.right_top.default}
                                                                             className='img-fluid'
                                                                             alt="آتاویتا"/>
                                                                        <span
                                                                            className='afa-product-option-value-top'>{row?.tensile_strength?.[1]}</span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={Images.right_bottom.default}
                                                                             className='img-fluid'
                                                                             alt="آتاویتا"/>
                                                                        <span
                                                                            className='afa-product-option-value-bottom'>{row?.tensile_strength?.[0]}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                <p className='afa-product-option-title mb-0'>مقاومت
                                                                    کشش</p>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={Images.right_top.default}
                                                                             className='img-fluid'
                                                                             alt="آتاویتا"/>
                                                                        <span
                                                                            className='afa-product-option-value-top'>{row?.surrender_tension?.[1]}</span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={Images.right_bottom.default}
                                                                             className='img-fluid'
                                                                             alt="آتاویتا"/>
                                                                        <span
                                                                            className='afa-product-option-value-bottom'>{row?.surrender_tension?.[0]}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-12 col-sm-6 col-lg-1 my-3 d-flex align-items-center justify-content-center">
                                                    <Link className={'text link-color'}
                                                          to={`/manage-product/${row?.id}`}>مشاهده<span
                                                        className="icon-cheveron-left"></span></Link>
                                                </div>
                                            </ArticleList>
                                        )) : <p className='text-center'>محصولی وجود ندارد</p>
                            }
                        </TabPanel>
                        <TabPanel>
                            {
                                inputs?.prices?.length > 0 &&
                                !inputs?.prices[0] ?
                                    inputs?.prices?.map((row, index) => (
                                        <>
                                            <Skeleton key={index} borderRadius={8} height={55}/>
                                        </>
                                    )) :
                                    inputs?.prices?.map((row, index) => (
                                        <ArticleList className='row align-items-center' key={index}>
                                            <div
                                                className="col-12 col-sm-6 col-lg-2 mb-3 d-flex align-items-center justify-content-center">
                                                <div className="afa-article-img">
                                                    <img src={`${MAIN_URL_IMAGE}${row?.image}`} className='img-fluid'
                                                         alt="مقاله"/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-9">
                                                <div className="d-flex flex-column align-items-stretch">
                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <h6 className='afa-header-article mb-0 ml-2-rem'>{row?.title?.fa}</h6>
                                                        <div className="d-flex flex-column ml-25-rem">
                                                            <p className='afa-product-option-title mb-0'>کد محصول</p>
                                                            <span
                                                                className='afa-product-option-value-top'>#{row?.code}</span>
                                                        </div>
                                                        <div className="d-flex flex-column ml-25-rem">
                                                            <p className='afa-product-option-title mb-0'>اخرین قیمت</p>
                                                            <span
                                                                className='afa-product-option-value-top'>{priceFormat(row?.last_price)}</span>
                                                        </div>
                                                        <div className="m-auto">
                                                            <Chart
                                                                options={item.options}
                                                                series={
                                                                    [{
                                                                        name: "Desktops",
                                                                        data: row?.weekly_prices_mini_chart,
                                                                    }]
                                                                }
                                                                width={'137'}
                                                                height={'75'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-12 col-sm-6 col-lg-1 my-3 d-flex align-items-center justify-content-center">
                                                <Link className={'text link-color'}
                                                      to={`/manage-product/${row?.id}/product_price`}>مشاهده<span
                                                    className="icon-cheveron-left"></span></Link>
                                            </div>
                                        </ArticleList>
                                    ))
                            }
                        </TabPanel>
                    </div>
                </div>
            </Tabs>
        </MainContent>
    );
}

export default Products;