import styled from "styled-components";

const dark_green = '#379521';
const custom_gray = '#ECECEC';
const light_red = '#FC4F59';
const label_text = '#2F4858'
const main_color = '#E73444';
const main_btn_color = 'linear-gradient(263.94deg, #E73444 32.54%, #895193 109.18%)';
const main_btn_color_hover = 'linear-gradient(263.94deg, #E73444 100%, #895193 0%)';
const next_btn_color = '#16A596';
const stepper_color = '#844E8E';
const bg_gray = '#F6F6F6';

export const TabStatusComplete = styled.div`
  width: 100%;
  height: 65px;
  background-color: #F8F8F8;
  border-radius: 8px;
  border-right: 13px solid;
  display: flex;
  align-items: center;
  padding-right: 1rem;

  .afa-icon-tick {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  p {
    padding-right: 1rem;
  }
`

export const TabStatusNotComplete = styled.div`
  width: 100%;
  height: 65px;
  background-color: #F8F8F8;
  border-radius: 8px;
  border-right: 13px solid ${light_red};
  display: flex;
  align-items: center;
  padding-right: 1rem;

  .afa-icon-info {
    width: 24px;
    height: 24px;
    background-color: #F9D7D9;
    color: ${main_color};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  p {
    padding-right: 1rem;
  }
`

export const TabStatusContent = styled.div`
  border-right: 13px solid;
  width: 100%;
  min-height: 65px;
  background-color: #F8F8F8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-right: 1rem;

  .afa-icon-message {
    font-size: 22px;
  }

  p {
    padding-right: 1rem;
  }

`

export const Inputs = styled.label`
  width: 100%;

  span {
    font-size: 14px;
    // margin-bottom: 0.6rem;
    color: ${label_text};
    font-weight: 500;
  }
  .afa-shaba-input{
    direction: ltr;
    .input-group-prepend,.input-group-text{
      width: 42px;
      height: 48px;
      margin:auto;
      background-color: ${custom_gray} !important;
      color: #212121;
      border: none !important;
      border-radius: 10px 0 0 10px !important;
    }
    input.afa-shba-number-input{
      border-radius: 0 10px 10px 0 !important;
      background-color: ${custom_gray} !important;
    }
  }
  .searchWrapper{
    width: 100% !important;
  }

  input, select, textarea, .searchWrapper, .afa-social-link-array-name {
    background-color: ${custom_gray};
    padding: 0.75rem 1rem;
    border-radius: 10px !important;
    width: 100%;
    color: #212121;
    border: none !important;

    &:focus {
      border: none;
      background-color: ${custom_gray};
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: #C2C2C2;
      font-size: 14px !important;
    }

    .chip {
      color: #fff !important;
      padding: 10px !important;

      .icon_cancel {
        margin-right: 0.5rem !important;
        width: 17px;
        height: 17px;
      }
    }
  }

  .afa-social-link-array-name {
    .afa-social-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 24px;

        &.icon-telegram , &.icon-linkedin , &.icon-whatsapp {
          margin-left: 1rem;
        }

      }
    }
  }

  .searchWrapper {
    input {
      padding: 0 !important;
    }
  }

  button {
    background: ${main_btn_color};
    border-radius: 50px;
    width: 100%;
    height: 48px;
    border: none;
    margin-bottom: 1rem;
    position: relative;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.afa-save-super-admin-btn {
      width: 220px !important;
    }

    &:hover {
      background: ${main_btn_color_hover};
    }

    span {
      color: #fff;
    }

    @media (max-width: 576px) {
      justify-content: start;
      padding-right: 1rem;
    }
  }

`

export const LeftIcon = styled.label`
  background-color: #C40E19;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
    margin-bottom: 0;
    font-size: 24px;
  }

  @media (max-width: 576px) {
    width: 30px;
    height: 30px;
    top: 9.5px;
  }
`

export const LargeFileInputs = styled.div`
  border: 1px dashed ${main_color};
  border-radius: 8px;
  padding: 2rem;
  position: relative;
  height: 161px;
  width: 100%;
  background-color: ${custom_gray};

  .dv-upload-file {
    visibility: hidden;
  }

  .afa-center-pos {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #341a1a;
    display: flex;
    cursor: pointer;
  }

  span.upload-text {
    position: absolute;
    right: 45%;
    top: 10%;
    font-size: 14px;
  }

  span.upload-text.personal-text {
    right: 30%;
  }

  span.icon-cloud-computing {
    font-size: 32px;
    color: ${main_color};
    position: absolute;
    right: 25%;
  }

  span.icon-cloud-computing.personal-icon {
    right: 15%;
  }

  .afa-perseneli-img {
    width: 88px;
    height: 76px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .afa-content-after-img {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-55%, 50%);
    color: #341a1a;
    display: flex;
    cursor: pointer;
  }
`

export const MiniFileInputs = styled.div`
  border: 1px dashed ${main_color};
  border-radius: 8px;
  padding: 2rem;
  cursor: pointer;
  position: relative;
  height: 80px;
  width: 100%;
  background-color: ${custom_gray};

  .afa-img {
    width: 70px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    img {
      max-width: 70px;
      max-height: 60px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      margin: auto;
    }
  }

  .dv-upload-file {
    visibility: hidden;
  }

  .afa-center-pos {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #341a1a;
    display: flex;
  }

  .upload-text {
    color: #341a1a !important;
  }

  span.upload-text {
    position: absolute;
    right: 48%;
    top: 10%;
    font-size: 14px;
    @media (max-width: 576px) {
      right: 34.5% !important;
      top: 75% !important;
      font-size: 12px !important;
    }
  }

  span.icon-cloud-computing {
    font-size: 32px;
    color: ${main_color};
    position: absolute;
    right: 33%;
  }
`

export const MiniFileInputsDocs = styled.div`
  border: 1px dashed ${main_color};
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 105px;
  width: 100%;
  background-color: ${custom_gray};

  .afa-img {
    width: 55px;
    height: 48px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    img {
      max-width: 55px;
      max-height: 48px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      margin: auto;
    }
  }

  .dv-upload-file {
    visibility: hidden;
  }

  .afa-center-pos {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #341a1a;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  span.upload-text {
    font-size: 14px;
    position: absolute;
    right: 50%;
    width: fit-content;
    transform: translate(50%, -30%);
  }

  span.icon-cloud-computing {
    font-size: 32px;
    color: ${main_color};
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, 10%);
  }
`

export const FileInputs = styled.div`
  border: 1px dashed ${main_color};
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  position: relative;
  height: 75px;
  width: 100%;
  background-color: ${custom_gray};

  .afa-img {
    width: 70px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    img {
      max-width: 70px;
      max-height: 60px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      margin: auto;
    }
  }

  .dv-upload-file {
    visibility: hidden;
  }

  .afa-center-pos {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #341a1a;
    display: flex;
  }

  span.upload-text {
    position: absolute;
    right: 33%;
    font-size: 14px;
    text-align: center;
    @media (max-width: 576px) {
      top: 50%;
      right: 27%;
    }
  }

  span.icon-cloud-computing {
    font-size: 32px;
    color: ${main_color};
    position: absolute;
    right: 10%;
    @media (max-width: 576px) {
      top: -40%;
      right: 43%;
    }
  }
`
export const ArticleFile = styled.div`
  border: 1px dashed #16A596;
  border-radius: 10px;
  //padding: 1rem;
  cursor: pointer;
  position: relative;
  height: 210px;
  width: 100%;
  background-color: #fff;

  .afa-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;

    img {
      max-width: 250px;
      max-height: 200px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
      display: block;
      margin: auto;
    }

    .afa-remove-position {
      display: none;
    }

    &:hover {
      .afa-remove-position {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #fff;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .dv-upload-file {
    visibility: hidden;
  }

  .afa_upload_file {
    cursor: pointer !important;
  }

  .afa-article-image-to-upload {
    width: 94px;
    height: 94px;
    margin-top: 1rem;
  }

  .upload-text {
    background: transparent;
    border-radius: 7px;
    border: 1.5px solid #16A596;
    font-size: 13px;
    color: #16A596;
    padding : 0.7em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;

    &:hover {
      background: #16A596;
      color: #fff;
    }
  }
`
export const BannerFile = styled.div`
  border: 1px dashed #16A596;
  border-radius: 10px;
  //padding: 1rem;
  cursor: pointer;
  position: relative;
  height: 210px;
  width: 100%;
  background-color: #fff;

  .afa-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;

    img {
      max-width: 250px;
      max-height: 200px;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: contain;
      display: block;
      margin: auto;
    }

    .afa-remove-position {
      display: none;
    }

    &:hover {
      .afa-remove-position {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #fff;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .dv-upload-file {
    visibility: hidden;
  }

  .afa_upload_file {
    cursor: pointer !important;
  }

  .afa-article-image-to-upload {
    width: 94px;
    height: 94px;
    margin-top: 1rem;
  }

  .upload-text {
    background: transparent;
    border-radius: 7px;
    border: 1.5px solid #16A596;
    font-size: 13px;
    color: #16A596;
    padding : 0.7em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;

    &:hover {
      background: #16A596;
      color: #fff;
    }
  }
`
export const BannerFileMini = styled.div`
  border: 1px dashed #16A596;
  border-radius: 10px;
  //padding: 1rem;
  cursor: pointer;
  position: relative;
  height: 170px;
  width: 100%;
  background-color: #fff;

  .afa-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;

    img {
      max-width: 230px;
      max-height: 170px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
      display: block;
      margin: auto;
    }

    .afa-remove-position {
      display: none;
    }

    &:hover {
      .afa-remove-position {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #fff;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .dv-upload-file {
    visibility: hidden;
  }

  .afa_upload_file {
    cursor: pointer !important;
  }

  .afa-article-image-to-upload {
    width: 61px;
    height: 64px;
    margin-top: 1rem;
  }

  .upload-text {
    background: transparent;
    border-radius: 7px;
    border: 1.5px solid #16A596;
    font-size: 13px;
    color: #16A596;
    padding : 0.7em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;

    &:hover {
      background: #16A596;
      color: #fff;
    }
  }
`
export const ProductFile = styled.div`
  border: 1px dashed #16A596;
  border-radius: 10px;
  //padding: 1rem;
  cursor: pointer;
  position: relative;
  height: 255px;
  width: 100%;
  background-color: #fff;

  .afa-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;

    img {
      max-width: 250px;
      max-height: 200px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
      display: block;
      margin: auto;
    }

    .afa-remove-position {
      display: none;
    }

    &:hover {
      .afa-remove-position {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #fff;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .dv-upload-file {
    visibility: hidden;
  }

  .afa_upload_file {
    cursor: pointer !important;
  }

  .afa-article-image-to-upload {
    width: 121px;
    height: 128px;
    margin-top: 1rem;
  }

  .upload-text {
    background: transparent;
    border-radius: 7px;
    border: 1.5px solid #16A596;
    font-size: 13px;
    color: #16A596;
    padding : 0.7em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;

    &:hover {
      background: #16A596;
      color: #fff;
    }
  }
`

export const HomeNumberInputs = styled.label`
  width: 100%;

  span {
    font-size: 14px;
    margin-bottom: 0.6rem;
    color: ${label_text};
    font-weight: 500;
  }

  input.afa-prev-code {
    background-color: ${custom_gray};
    padding: 0.75rem 1rem;
    border-radius: 10px 0 0 10px !important;
    width: 20%;
    color: #212121;
    border: none;
    text-align: left;
    border-right: 1px solid darkblue;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: #C2C2C2;
    }
  }

  input.afa-phone-input {
    background-color: ${custom_gray};
    padding: 0.75rem 1rem;
    border-radius: 0 10px 10px 0 !important;
    width: 80%;
    color: #212121;
    border: none;
    text-align: left;
    border-left: 1px solid darkblue;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: #C2C2C2;
    }
  }
`
// export const Inputs = styled.label``






