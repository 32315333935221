import React, {useEffect, useState} from 'react';
import {
    checkCodeMelli,
    fileUpload,
    getData,
    getFormDataPost,
    just_persian
} from "../../../../../assets/scripts/GeneralFunctions";
import {FileInputs, Inputs, LeftIcon, TabStatusComplete, TabStatusContent, TabStatusNotComplete} from "./style";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import {MAIN_URL} from "../../../../../assets/scripts/GeneralVariables";
import {LoopCircleLoading} from "react-loadingg";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

function Skills(props) {
    const [error, setError] = useState({})
    const [error2, setError2] = useState({})
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});
    const [is_render, setRender] = useState(false);
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [new_collapse, setNewCollapse] = useState(false);
    const [show_add_more, setShowAddMore] = useState(false);
    const [loader, setLoader] = useState(false)
    const [inputs, setInputs] = useState({
        technical_departments: [],
        technical_department: 'انتخاب کنید',
        skill: 'انتخاب کنید',
        level: 'پیشرفته',
        note: '',
        skills: [],
        technical_skills: []
    })
    const [inputs2, setInputs2] = useState({
        technical_departments: [],
        level: 'پیشرفته',
        note: '',
        skills: [],
        technical_skills: [],
        technical_department_name: '',
        skill_name: '',
        skill: 'انتخاب کنید',
        technical_department: 'انتخاب کنید',
    })

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/skills", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let skills = data?.skills
            if (skills?.length === 0) {
                setInputs({
                    technical_department: 'انتخاب کنید',
                    level: 'پیشرفته',
                    note: '',
                    technical_departments: data?.technical_departments
                })
                setShowAddMore(false)
            } else {
                setInputs(prevState => ({
                    ...prevState,
                    skills: skills,
                    technical_departments: data?.technical_departments
                }))
                setShowAddMore(true)
            }
            setMoreData({sheet_status: data?.sheet_status, status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const inputHandler2 = (e) => {
        setInputs2(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const unitHandler = async (e) => {
        let id = e.target.value
        setLoader(true)
        let api_result = await getData(MAIN_URL, `profile/skills/technical-skills/${id}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setLoader(false)
            setInputs(prevState => ({
                ...prevState, technical_skills: api_result?.data?.technical_skills, technical_department: id
            }))
        }
    }

    const unitHandler2 = async (e, name) => {
        let id = e.target.value
        setLoader(true)
        let api_result = await getData(MAIN_URL, `profile/skills/technical-skills/${id}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setLoader(false)
            setInputs2(prevState => ({
                ...prevState,
                technical_skills: api_result?.data?.technical_skills,
                technical_department: id,
                technical_department_name: name
            }))
        }
    }

    const unitHandlerEditApi = async (e, name) => {
        let id = e.target.value
        setLoader(true)
        let api_result = await getData(MAIN_URL, `profile/skills/technical-skills/${id}`, 'get', {}, true, false);
        if (api_result?.status === 200) {
            setLoader(false)
            setInputs(prevState => ({
                ...prevState,
                [name]: id,
                technical_skills: api_result?.data?.technical_skills
            }))
        }
    }

    const addMoreCollapse = () => {
        setInputs2(prevState => ({
            ...prevState,
            technical_departments: [],
            level: 'پیشرفته',
            note: '',
            skills: [],
            technical_skills: [],
            technical_department_name: '',
            skill_name: '',
            skill: 'انتخاب کنید',
            technical_department: 'انتخاب کنید',
        }));
        setLoader(false)
        setShowAddMore(false)
        setNewCollapse(true)
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/skills/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    setRender(!is_render)
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs['skill']) {
            formData.append('skill', inputs['skill'])
        }
        if (inputs['level']) {
            formData.append('level', inputs['level'])
        }
        if (inputs['note']) {
            formData.append('note', inputs['note'])
        }

        let api_result = await getFormDataPost('profile/skills', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setShowAddMore(true)
            setNewCollapse(false)
            setRender(!is_render)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const changeArrow = async (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = inputs.skills?.find((row) => {
            return row.id === id
        })
        if (data) {
            setLoader(true)
            let api_result = await getData(MAIN_URL, `profile/skills/technical-skills/${data?.skill?.technical_department_id}`, 'get', {}, true, false);
            if (api_result?.status === 200) {
                setLoader(false)
                setInputs((prevState => ({
                    ...prevState,
                    [`technical_department[${id}]`]: data?.skill?.technical_department_id,
                    [`skill[${id}]`]: data?.skill_id,
                    [`level[${id}]`]: data?.level,
                    [`note[${id}]`]: data?.note,
                    technical_skills: api_result?.data?.technical_skills
                })))
            }
        }
    }

    const editHandler = async (id) => {
        let formData = new FormData()
        formData.append('skill', inputs[`skill[${id}]`])
        formData.append('level', inputs[`level[${id}]`])
        formData.append('note', inputs[`note[${id}]`] ? inputs[`note[${id}]`] : '')

        let api_result = await getFormDataPost(`profile/skills/${id}`, formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            setShowAddMore(true)
            // setNewCollapse(false)
            setRender(!is_render)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
            setError(prevState => ({...prevState, id: id}))
        }
    }

    const addNewWhenCollapseHave = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs2['skill']) {
            formData.append('skill', inputs2['skill'])
        }
        if (inputs2['level']) {
            formData.append('level', inputs2['level'])
        }
        if (inputs2['note']) {
            formData.append('note', inputs2['note'])
        }

        let api_result = await getFormDataPost('profile/skills', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setShowAddMore(true)
            setNewCollapse(false)
            setRender(!is_render)
            setInputs2({
                technical_skills: [],
                technical_department_name: '',
                skill_name: '',
                skill: 'انتخاب کنید',
                technical_department: 'انتخاب کنید',
            })
        } else if (api_result?.status === 400) {
            setError2(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const removeNewCollapse = () => {
        setNewCollapse(false)
        setShowAddMore(true)
    }

    const goToNextPage = () => {
        props.handleChange(6)
    }

    let skills = inputs?.skills

    return (
        <>
            <form onSubmit={formHandler} className="row">
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <TabStatusComplete
                        style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                        {
                            more_data.sheet_status?.status === 'pending' || more_data.sheet_status?.status === 'denied' ?
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-tick afa-icon-tick'/>
                        }
                        <p className='mb-0 f-14 '>
                            <span>وضعیت : </span><span>{more_data.status_colors?.[more_data.sheet_status?.status]?.title}</span>
                        </p>
                    </TabStatusComplete>
                </div>
                <div className="col-12 col-sm-6 col-lg-8 mb-4">
                    {
                        more_data.sheet_status?.message &&
                        <TabStatusContent
                            style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <p className='mb-0 f-14'>
                                <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                            </p>
                        </TabStatusContent>
                    }
                </div>
                {
                    skills?.length > 0 ?
                        <>
                            <Accordion defaultActiveKey="0">
                                {
                                    skills?.map((row, index) => (
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey={row.id}
                                                              onClick={() => changeArrow(row.id)}>
                                                <div className="d-flex flex-wrap">
                                                    <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                          onClick={() => removeTab(row.id)}/>
                                                    <span
                                                        className="pl-4 f-14 my-2"> واحد : {row?.skill?.technical_department?.name}</span>
                                                    <span
                                                        className="pl-4 f-14 my-2"> نام مهارت : {row?.skill?.name}</span>
                                                    <span className="pl-4 f-14 my-2"> سطح : {row?.level}</span>
                                                </div>
                                                <span
                                                    className={(arrow_item.arrow_id === row.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}></span>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={row.id}>
                                                <Card.Body className='row'>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs className={'position-relative'}>
                                                            <span>واحد
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <select
                                                                className={error['technical_department'] && 'afa-error-input'}
                                                                value={inputs[`technical_department[${row.id}]`]}
                                                                onChange={(e) => unitHandlerEditApi(e, `technical_department[${row.id}]`)}
                                                                name={`technical_department[${row.id}]`}>
                                                                <option value='انتخاب کنید'>انتخاب کنید</option>
                                                                {
                                                                    inputs?.technical_departments?.map((item, i) => (
                                                                        <option value={item?.id}
                                                                                key={i}>{item?.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            {
                                                                loader && <div className="dv-skill-loader">
                                                                    <LoopCircleLoading color={'green'}/>
                                                                </div>
                                                            }
                                                            <p className='afa-error-text'>{error['technical_department']}</p>
                                                        </Inputs>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <span>نام مهارت
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            {
                                                                inputs?.technical_skills?.length === 0 ?
                                                                    <h6 className={'mt-2'}>مهارتی تعریف نشده است</h6>
                                                                    :
                                                                    <select
                                                                        className={(row.id === error['id'] && error['skill']) && 'afa-error-input'}
                                                                        value={inputs[`skill[${row.id}]`]}
                                                                        onChange={inputHandler}
                                                                        name={`skill[${row.id}]`}>
                                                                        <option value='انتخاب کنید'>انتخاب کنید</option>
                                                                        {
                                                                            inputs?.technical_skills?.map((row, i) => (
                                                                                <option
                                                                                    value={row.id}>{row?.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                            }
                                                            {
                                                                (row.id === error['id'] && error['skill']) &&
                                                                <p className='afa-error-text'>{error['skill']}</p>
                                                            }
                                                        </Inputs>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <span className=' f-14'>سطح
                                                        <span className='dv-required-star'>*</span>
                                                        </span>
                                                        <Inputs>
                                                            <select name={`level[${row.id}]`} onChange={inputHandler}
                                                                    value={inputs[`level[${row.id}]`]}>
                                                                <option value="مبتدی">مبتدی</option>
                                                                <option value="متوسط">متوسط</option>
                                                                <option value="پیشرفته">پیشرفته</option>
                                                            </select>
                                                        </Inputs>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <Inputs>
                                                            <span className=' f-14'>توضیحات</span>
                                                            <textarea name={`note[${row.id}]`}
                                                                      value={inputs[`note[${row.id}]`]}
                                                                      onChange={inputHandler}
                                                                      className={error['note'] && 'afa-error-input'}
                                                                      id="note" cols="30" rows="3"/>
                                                            <p className='afa-error-text'>{error['note']}</p>
                                                        </Inputs>
                                                    </div>

                                                    <div className="col-12 d-flex justify-content-center">
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>

                                                                <button type='button'
                                                                        onClick={() => editHandler(row.id)}>

                                                                    <span>ثبت تغییرات</span>

                                                                    <LeftIcon><span
                                                                        className='icon-cheveron-left'/></LeftIcon>

                                                                </button>

                                                            </Inputs>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))
                                }
                                {
                                    new_collapse &&
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => changeArrow(0)}>

                                            <div className="d-flex flex-wrap">

                                                    <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                          onClick={removeNewCollapse}></span>

                                                {
                                                    inputs2?.technical_department_name &&
                                                    <span
                                                        className="pl-4 f-14 my-2"> واحد : {inputs2.technical_department_name}</span>
                                                }
                                                {
                                                    inputs2?.skill?.name &&
                                                    <span
                                                        className="pl-4 f-14 my-2"> نام مهارت : {inputs2.skill_name}</span>
                                                }
                                                {
                                                    inputs2?.level &&
                                                    <span
                                                        className="pl-4 f-14 my-2"> سطح : {inputs2.level}</span>
                                                }

                                            </div>

                                            <span
                                                className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}></span>

                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className='row'>
                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <Inputs className='position-relative'>
                                                        <span>مهارت
                                                        <span className='dv-required-star'>*</span>
                                                        </span>
                                                        <select
                                                            className={error['technical_department'] && 'afa-error-input'}
                                                            value={inputs2.technical_department}
                                                            onChange={unitHandler2}
                                                            name={'technical_department'}>
                                                            <option value='انتخاب کنید'>انتخاب کنید</option>
                                                            {
                                                                inputs?.technical_departments?.map((item, i) => (
                                                                    <option value={item?.id}
                                                                            key={i}>{item?.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        {
                                                            loader && <div className="dv-skill-loader">
                                                                <LoopCircleLoading color={'green'}/>
                                                            </div>
                                                        }
                                                        <p className='afa-error-text'>{error['technical_department']}</p>
                                                    </Inputs>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <Inputs>
                                                        <span>نام مهارت
                                                        <span className='dv-required-star'>*</span>
                                                        </span>
                                                        {
                                                            inputs2?.technical_skills?.length === 0 ?
                                                                <h6 className={'mt-2'}>مهارتی تعریف نشده است</h6>
                                                                :
                                                                <select className={error2['skill'] && 'afa-error-input'}
                                                                        value={inputs2.skill}
                                                                        onChange={inputHandler2} name={'skill'}>
                                                                    <option value='انتخاب کنید'>انتخاب کنید</option>
                                                                    {
                                                                        inputs2?.technical_skills?.map((row, i) => (
                                                                            <option value={row.id}>{row?.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                        }
                                                        <p className='afa-error-text'>{error2['skill']}</p>
                                                    </Inputs>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <span className=' f-14'>سطح
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                    <Inputs>
                                                        <select name={`level`} onChange={inputHandler2}
                                                                value={inputs2[`level`]}>
                                                            <option value="مبتدی">مبتدی</option>
                                                            <option value="متوسط">متوسط</option>
                                                            <option value="پیشرفته">پیشرفته</option>
                                                        </select>
                                                    </Inputs>
                                                </div>

                                                <div className="col-12 mb-4">
                                                    <Inputs>
                                                        <span className=' f-14'>توضیحات</span>
                                                        <textarea name="note"
                                                                  value={inputs2.note} onChange={inputHandler2}
                                                                  className={error2['note'] && 'afa-error-input'}
                                                                  id="note" cols="30" rows="3"/>
                                                        <p className='afa-error-text'>{error2['note']}</p>
                                                    </Inputs>
                                                </div>

                                                <div
                                                    className="col-12 d-flex flex-column flex-sm-row justify-content-between">
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <button type='button' onClick={addNewWhenCollapseHave}>
                                                                <span>ثبت نهایی</span>
                                                                <LeftIcon><span
                                                                    className='icon-cheveron-left'/></LeftIcon>
                                                            </button>
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <button type='button' className={'dv-save-information-btn dv-next-btn'}
                                                                    onClick={goToNextPage}>
                                                                <span>مرحله بعد</span>
                                                                <LeftIcon><span
                                                                    className='icon-cheveron-left'/></LeftIcon>
                                                            </button>
                                                        </Inputs>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                }
                            </Accordion>
                            {
                                show_add_more &&
                                <div
                                    className="row d-flex flex-column flex-sm-row justify-content-between">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>
                                        <Inputs>
                                            <button type='button' onClick={addMoreCollapse}
                                                    className='afa-add-more-btn'>
                                                <span className='icon-add f-20'/>
                                                <span className='pr-3'>افزودن جدید</span>
                                            </button>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <button type='button'
                                                    className={'dv-save-information-btn dv-next-btn'}
                                                    onClick={goToNextPage}>
                                                <span>مرحله بعد</span>
                                                <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                            </button>
                                        </Inputs>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Inputs className='position-relative'>
                                    <span>واحد
                                    <span className='dv-required-star'>*</span>
                                    </span>
                                    <select
                                        className={error['technical_department'] && 'afa-error-input'}
                                        value={inputs.technical_department}
                                        onChange={unitHandler} name={'technical_department'}>
                                        <option value='انتخاب کنید'>انتخاب کنید</option>
                                        {
                                            inputs?.technical_departments?.map((item, i) => (
                                                <option value={item?.id}
                                                        key={i}>{item?.name}</option>
                                            ))
                                        }
                                    </select>
                                    {
                                        loader && <div className="dv-skill-loader">
                                            <LoopCircleLoading color={'green'}/>
                                        </div>
                                    }
                                    <p className='afa-error-text'>{error['technical_department']}</p>
                                </Inputs>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Inputs>
                                    <span>نام مهارت
                                    <span className='dv-required-star'>*</span>
                                    </span>
                                    {
                                        inputs?.technical_skills?.length === 0 ?
                                            <h6 className={'mt-2'}>مهارتی تعریف نشده است</h6>
                                            :
                                            <select className={error['skill'] && 'afa-error-input'}
                                                    value={inputs.skill}
                                                    onChange={inputHandler} name={'skill'}>
                                                <option value='انتخاب کنید'>انتخاب کنید</option>
                                                {
                                                    inputs?.technical_skills?.map((row, i) => (
                                                        <option value={row.id}>{row?.name}</option>
                                                    ))
                                                }
                                            </select>
                                    }
                                    <p className='afa-error-text'>{error['skill']}</p>
                                </Inputs>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <span className=' f-14'>سطح
                                <span className='dv-required-star'>*</span>
                                </span>
                                <Inputs>
                                    <select name={`level`} onChange={inputHandler}
                                            value={inputs[`level`]}>
                                        <option value="مبتدی">مبتدی</option>
                                        <option value="متوسط">متوسط</option>
                                        <option value="پیشرفته">پیشرفته</option>
                                    </select>
                                </Inputs>
                            </div>

                            <div className="col-12 mb-4">
                                <Inputs>
                                    <span className=' f-14'>توضیحات</span>
                                    <textarea name="note"
                                              value={inputs.note} onChange={inputHandler}
                                              className={error['note'] && 'afa-error-input'}
                                              id="note" cols="30" rows="3"/>
                                    <p className='afa-error-text'>{error['note']}</p>
                                </Inputs>
                            </div>

                            <div
                                className="col-12 d-flex flex-column flex-sm-row justify-content-between">
                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                    <Inputs>
                                        <button type='submit'
                                                disabled={inputs.language === 'انتخاب کنید'}>
                                            <span>ثبت این صفحه</span>
                                        </button>
                                    </Inputs>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                    <Inputs>
                                        <button type='button'
                                                className={'dv-save-information-btn dv-next-btn'}
                                                onClick={goToNextPage}>
                                            <span>مرحله بعد</span>
                                            <LeftIcon><span
                                                className='icon-cheveron-left'/></LeftIcon>
                                        </button>
                                    </Inputs>
                                </div>
                            </div>

                            {
                                show_add_more &&
                                <div className="d-flex justify-content-end">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>

                                        <Inputs>

                                            <button type='button' onClick={addMoreCollapse}
                                                    className='afa-add-more-btn'>

                                                <span className='icon-add f-20'/>

                                                <span className='pr-3'>افزودن جدید</span>

                                            </button>

                                        </Inputs>

                                    </div>
                                </div>
                            }
                        </>
                }
            </form>
        </>
    )
}

export default Skills;