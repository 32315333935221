import {SHOW_USER_INFORMATION, NOTIFICATION_COUNT} from "./types"

export const setUserInformation = (status) => {
    return{
        type: SHOW_USER_INFORMATION,
        payload: status
    }
};

export const setNotificationCount = (status) => {
    return{
        type: NOTIFICATION_COUNT,
        payload: status
    }
};