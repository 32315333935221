import styled from "styled-components";

const dark_green = '#379521';
const custom_gray = '#ECECEC';
const light_red = '#FC4F59';
const label_text = '#2F4858'

const main_color = '#E73444';
const main_btn_color = 'linear-gradient(263.94deg, #E73444 32.54%, #895193 109.18%)';
const main_btn_color_hover = 'linear-gradient(263.94deg, #E73444 100%, #895193 0%)';
const next_btn_color = '#16A596';
const stepper_color = '#844E8E';
export const LoginTheme = styled.div`
  width: 100vw;
  // height: 100%;
  background-color: #191A1B;
  background-size: 100% 100%;
  display: flex;
  //overflow-y: hidden;
  @media (max-width: 992px) {
    flex-direction: column;
    // height: auto;
    background-size:cover;
  
  }
`;

export const RightContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3.85rem;

  h6 {
    font-size: 16px;
    line-height: 27px;
    color: #fff;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  @media (max-width: 992px) {
    width: 100%;
    height: 100vh;
  }
`;

export const LeftContent = styled.div`
  width: 50%;
  height: 100vh;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

export const LoginInput = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 350px;
  color: #fff;
  margin: auto;
  margin-top: 3rem;
  background-color: #191A1B;

  span {
    margin-bottom: 0.5rem;
  }

  input {
    background-color: ${custom_gray};
    padding: 0.75rem 1rem;
    border-radius: 10px;
    margin-bottom: 2rem;
    width: 100%;
    border: none;
    color: #000;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  button {
    background: ${main_btn_color};
    color: #fff;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    width: 100%;
    border: none;
    margin-bottom: 1rem;
    &:hover{
      background: ${main_btn_color_hover};
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    background-color: #191A1B;
    padding: 1rem;
  }
`

export const ConfirmCodeDiv = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #191A1B;
  width: 350px;
  color: #fff;
  margin: auto;
  margin-top: 3rem;

  input {
    background-color: ${custom_gray};
    padding: 0.75rem 1rem;
    border-radius: 10px !important;
    width: 100%;
    border: none !important;
    color: #000 !important;
    margin: 0 0.25rem 2rem 0.25rem;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  button {
    background: ${main_btn_color};
    color: #fff;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    width: 100%;
    border: none;
    margin-bottom: 1rem;
    transition: all 0.25s linear;

    &:hover {
      background: ${main_btn_color_hover};
    }
  }

  span {
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    background-color: #191A1B;
    padding: 1rem;
  }
`

export const EditNumber = styled.div`
  width: 70%;
  margin: auto;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: #FC4C56;
      font-size: 14px;
      line-height: 25px;

      &:hover {
        color: ${main_color};
      }
    }

    p {
      color: #fff;
      text-align: left;
      direction: ltr;
      margin-bottom: 0;
    }
  }
`