import React, {useEffect, useState} from 'react';
import {MainContent} from "../style";
import {fileUpload, fixNumber, just_number} from "../../../../assets/scripts/GeneralFunctions";
import {Images} from "../../../../assets/scripts/Images";
import {ArticleFile, BannerFile, BannerFileMini, Inputs} from "../../Child/Supplier/Child/style";
import {MAIN_URL_IMAGE} from "../../../../assets/scripts/GeneralVariables";
import CkEditorText from "../Article/CkEditorText";
import TagsInput from "react-tagsinput";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import Select from "react-select";
import {Modal} from "react-bootstrap";

function SettingEn(props) {
    const [inputs, setInputs] = useState({
        title: '', short_description: '', description: '', tags: [],
        banner_file_url: '', banner_file: '',banner_title: '',banner_description : '',
        main_address : '',main_phone : '',main_zip_code: '',main_fax: '',
        factory_address : '',factory_phone : '',factory_zip_code: '',factory_fax: '',
        about_us : '',social_name : 'انتخاب کنید',link_list : [],link : '',help_link_list : []
    })
    const [error, setError] = useState({})
    const [modal, setModal] = useState({show_edit_banner: false , show_add_banner : false});
    useEffect(async () => {
        let article_id = props.match.params.article_id;
    }, [])

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let upload_file = await fileUpload(files[0]);
        setInputs(prevState => ({
            ...prevState,
            [e.target.name + '_url']: file,
            [e.target.name]: upload_file,
            [e.target.name + '_file']: e.target.files[0]
        }))
    }

    const removeImage = (val) => {
        setInputs(prevState => ({
            ...prevState, [val]: null, [val + '_url']: null, [val + '_file']: null, [`remove_${val}`]: 1
        }))
    }

    // Edit
    const editBanner = (val) => {
        setModal(prevState => ({...prevState, show_edit_banner: true}))
    }
    const editBannerHandler = (e) => {
        e.preventDefault()
        setModal(prevState => ({...prevState, show_edit_banner: false}))
    }

    // Add
    const addBannerShow = () => {
        setModal(prevState => ({...prevState, show_add_banner: true}))
    }
    const addBanner = (e) => {
        e.preventDefault()
        setModal(prevState => ({...prevState, show_add_banner: false}))
    }


    const closeModal = () => {
        setModal(prevState => ({...prevState, show_edit_banner: false , show_add_banner: false}))
    }

    const setData = (value) => {
        setInputs(prevState => ({...prevState, about_us: value}))
    }

    const formHandler = async (e) => {
        e.preventDefault()
    }

    const data = [
        {
            value: 'تلگرام',
            text: 'تلگرام',
            icon: <span className="icon-telegram"><span className="path1"></span><span className="path2"></span><span
                className="path3"></span><span className="path4"></span></span>
        },
        {
            value: 'واتس آپ',
            text: 'واتس آپ',
            icon: <span className="icon-whatsapp"><span className="path1"></span><span className="path2"></span><span
                className="path3"></span><span className="path4"></span><span className="path5"></span></span>
        },
        {
            value: 'لینکدین',
            text: 'لینکدین',
            icon: <span className="icon-linkedin"></span>
        }
    ];
    const [selectedOption, setSelectedOption] = useState(null);

    // handle onChange event of the dropdown
    const handleChange = e => {
        setSelectedOption(e);
    }

    const addNewLink = () =>{
        let arr = [];
        let help_arr = [];
        arr = inputs?.link_list;
        help_arr = inputs?.help_link_list;
        let obj = {}
        let help_obj = {}
        if(inputs?.link && selectedOption?.value){
            obj['link'] = inputs?.link
            obj['social_name'] = selectedOption?.value

            help_obj['link'] = inputs?.link
            help_obj['social'] = selectedOption

            arr.push(obj)
            help_arr.push(help_obj)
            setInputs(prevState => ({...prevState , link_list: arr , link: '' , help_link_list : help_arr}))
            setSelectedOption(null);
        }else{
            toast.error('فیلد های مورد نظر نمی توانند خالی باشند')
        }

    }

    const handleRemoveSocial = (index) => {
        let help_arr = inputs?.help_link_list;
        let arr = inputs?.link_list;

        delete inputs?.help_link_list[index]
        delete inputs?.link_list[index]

        setInputs(prevState => ({...prevState , help_link_list: help_arr , link_list: arr}))
    }
    return (
        <MainContent>
            <form onSubmit={formHandler} className='row'>
                <div className="col-12 w-100 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3">
                    <h6 className='text-red fw-bold'>مدیریت بنرها</h6>
                    <Link to={'/manage-site'}
                          className='add-new-btn d-flex justify-content-around align-items-center w-100 mx-0'
                          style={{maxWidth: '215px'}}>
                        <img src={Images?.persian.default} className='img-fluid' alt="آتاویتا"/>
                        {/*<img src={Images?.persian.default} className='img-fluid' alt="آتاویتا"/>*/}
                        <span>ترجمه به فارسی</span>
                    </Link>
                </div>

                <div className="col-12 mb-3">
                    <div className="row px-0">
                        <div className="col-12 col-md-3 mb-3">
                            <div className="afa-upload-file-parent">
                                <BannerFile className={error[`banner_file`] && 'afa-error-input'}>
                                    <div className="afa-img">
                                        <img
                                            src={Images.article.default ? Images.article.default : `${MAIN_URL_IMAGE}${inputs[`banner_file`]}`}
                                            alt="آتاویتا"/>
                                        <span
                                            className='afa-remove-position icon-edit'
                                            onClick={() => editBanner(`banner_file`)}/>
                                    </div>
                                </BannerFile>
                                <p className='afa-error-text'>{error[`banner_file`]}</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <div className="afa-upload-file-parent">
                                <BannerFile>
                                    <label className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                        <img src={Images?.article_upload?.default} className='afa-article-image-to-upload' alt="آتاویتا"/>
                                        <span className='upload-text mt-4' onClick={addBannerShow}>ایجاد بنر</span>
                                    </label>
                                </BannerFile>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <h6 className='text-red fw-bold mb-4'>اطلاعات تماس کارخانه</h6>
                    <Inputs className='mb-3'>
                        <span>ادرس کامل</span>
                        <input className={'afa-error-input'}
                               type="text"
                               name={'factory_address'}
                               value={inputs.factory_address}
                               onChange={(e) =>
                                   setInputs(prevState => ({
                                       ...prevState, 'factory_address': e.target.value
                                   }))
                               }/>
                        <p className='afa-error-text'>{error[`factory_address`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>شماره تماس</span>
                        <input
                            className={error[`factory_phone`] && 'afa-error-input'}
                            type="text"
                            max={99999999999}
                            maxLength={11}
                            name={`factory_phone`}
                            value={inputs?.[`factory_phone`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`factory_phone`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`factory_phone`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>کد پستی</span>
                        <input
                            className={error[`factory_zip_code`] && 'afa-error-input'}
                            type="text"
                            max={9999999999}
                            maxLength={10}
                            name={`factory_zip_code`}
                            value={inputs?.[`factory_zip_code`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`factory_zip_code`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`factory_zip_code`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>فکس</span>
                        <input
                            className={error[`factory_fax`] && 'afa-error-input'}
                            type="text"
                            name={`factory_fax`}
                            value={inputs?.[`factory_fax`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`factory_fax`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`factory_fax`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <h6 className='text-red fw-bold mb-4'>اطلاعات تماس دفتر مرکزی</h6>
                    <Inputs className='mb-3'>
                        <span>ادرس کامل</span>
                        <input className={'afa-error-input'}
                               type="text"
                               name={'main_address'}
                               value={inputs.main_address}
                               onChange={(e) =>
                                   setInputs(prevState => ({
                                       ...prevState, 'main_address': e.target.value
                                   }))
                               }/>
                        <p className='afa-error-text'>{error[`main_address`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>شماره تماس</span>
                        <input
                            className={error[`main_phone`] && 'afa-error-input'}
                            type="text"
                            max={99999999999}
                            maxLength={11}
                            name={`main_phone`}
                            value={inputs?.[`main_phone`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`main_phone`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`main_phone`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>کد پستی</span>
                        <input
                            className={error[`main_zip_code`] && 'afa-error-input'}
                            type="text"
                            max={9999999999}
                            maxLength={10}
                            name={`main_zip_code`}
                            value={inputs?.[`main_zip_code`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`main_zip_code`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`main_zip_code`]}</p>
                    </Inputs>
                    <Inputs className='mb-3'>
                        <span>فکس</span>
                        <input
                            className={error[`main_fax`] && 'afa-error-input'}
                            type="text"
                            name={`main_fax`}
                            value={inputs?.[`main_fax`]}
                            onChange={(e) =>
                                just_number(e.target.value) && setInputs(prevState => ({
                                    ...prevState, [`main_fax`]: fixNumber(e.target.value)
                                }))
                            } placeholder='- - - - - - - - - - -'/>
                        <p className='afa-error-text'>{error[`main_fax`]}</p>
                    </Inputs>
                </div>
                <div className="col-12 mb-4">
                    <h6 className='text-red fw-bold mb-3'>اطلاعات درباره ما</h6>
                    <Inputs className='mb-3'>
                        <span>چگونه شروع کردیم</span>
                        <textarea
                            className={error[`about_us`] && 'afa-error-input'}
                            rows={3}
                            name={`about_us`}
                            value={inputs?.[`about_us`]}
                            onChange={(e) =>
                                setInputs(prevState => ({
                                    ...prevState, [`about_us`]: e.target.value
                                }))
                            }/>
                        <p className='afa-error-text'>{error[`about_us`]}</p>
                    </Inputs>
                </div>

                <div className="col-12 mb-3">
                    <h6 className='text-red fw-bold mb-3'>لینک های مهم</h6>

                    {
                        inputs?.help_link_list?.length > 0 &&
                        inputs?.help_link_list?.map((row , index)=>(
                            <div className="row px-0" key={index}>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="afa-remove-link" onClick={()=>handleRemoveSocial(index)}>
                                            <span className="icon-Delete"/>
                                        </div>
                                        <Inputs>
                                            <span>شبکه اجتماعی</span>
                                            <div className='afa-social-link-array-name' style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="afa-social-icon">{row?.social.icon}</span>
                                                <span style={{ marginLeft: 5 }}>{row?.social.text}</span>
                                            </div>
                                        </Inputs>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <Inputs>
                                            <span>لینک</span>
                                            <div className='afa-social-link-array-name' style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginLeft: 5 }}>{row?.link}</span>
                                            </div>
                                        </Inputs>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className="row px-0">
                        <div className="col-12 col-md-6 mb-3">
                            <div className="d-flex align-items-center">
                                <Inputs>
                                    <span>شبکه اجتماعی</span>
                                    <Select
                                        className={'afa-icon-select'}
                                        placeholder="انتخاب کنید"
                                        value={selectedOption}
                                        options={data}
                                        onChange={handleChange}
                                        getOptionLabel={e => (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {e.icon}
                                                <span style={{ marginLeft: 5 }}>{e.text}</span>
                                            </div>
                                        )}
                                    />
                                    <p className='afa-error-text'>{error[`social_name`]}</p>
                                </Inputs>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <div className="d-flex align-items-center">
                                <Inputs className='mb-3'>
                                    <span>لینک</span>
                                    <input
                                        className={error[`link`] && 'afa-error-input'}
                                        type="text"
                                        name={`link`}
                                        value={inputs?.[`link`]}
                                        onChange={(e) =>
                                            setInputs(prevState => ({
                                                ...prevState, [`link`]: e.target.value
                                            }))
                                        }/>
                                    <p className='afa-error-text'>{error[`link`]}</p>
                                </Inputs>
                                <div className="afa-add-link" onClick={addNewLink}>+</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12 d-flex align-items-center justify-content-center mb-4">
                    <Inputs>
                        <button type='submit'
                                className='m-auto afa-save-super-admin-btn'>
                            <span className='pr-3'>ذخیره</span>
                        </button>
                    </Inputs>
                </div>
            </form>

            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_edit_banner} onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={editBannerHandler}>
                        <div className="col-12 col-md-7 mb-3">
                            <p className='text-red fw-bold'>ویرایش بنر</p>
                            <Inputs>
                                <span>عنوان بنر</span>
                                <input className={'afa-error-input'}
                                       type="text"
                                       name={'banner_title'}
                                       value={inputs.banner_title}
                                       onChange={(e) =>
                                           setInputs(prevState => ({
                                               ...prevState, 'banner_title': fixNumber(e.target.value)
                                           }))
                                       }/>
                            </Inputs>
                        </div>
                        <div className="col-12 col-md-5 mb-3">
                            <div className="afa-upload-file-parent">
                                <BannerFileMini
                                    className={error[`banner_file`] && 'afa-error-input'}>
                                    {
                                        (inputs[`banner_file_url`] || inputs[`banner_file`]) ?
                                            <div className="afa-img">
                                                <img
                                                    src={inputs[`banner_file_url`] ? inputs[`banner_file_url`] : `${MAIN_URL_IMAGE}${inputs[`banner_file`]}`}
                                                    alt="آتاویتا"/>
                                                <span
                                                    className='afa-remove-position icon-delete'
                                                    onClick={() => removeImage(`banner_file`)}/>
                                            </div> :
                                            <label
                                                className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                                <img src={Images?.article_upload?.default}
                                                     className='afa-article-image-to-upload' alt="آتاویتا"/>
                                                <input type="file" accept="image/*"
                                                       onChange={thisUploadDocs}
                                                       name={`banner_file`}
                                                       className='dv-upload-file'/>
                                                {
                                                    !inputs[`banner_file`] &&
                                                    <span className='upload-text'>بارگزاری بنر</span>
                                                }
                                            </label>
                                    }
                                </BannerFileMini>
                                <p className='afa-error-text'>{error[`banner_file`]}</p>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>توضیحات بنر</span>
                                <textarea className={'afa-error-input'}
                                          rows={2}
                                          name={'banner_description'}
                                          value={inputs.banner_description}
                                          onChange={(e) =>
                                              setInputs(prevState => ({
                                                  ...prevState, 'banner_description': fixNumber(e.target.value)
                                              }))
                                          }/>
                            </Inputs>
                        </div>
                        <div className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            <Modal centered={true} className='afa-user-status-modal' show={modal?.show_add_banner} onHide={closeModal}>
                <Modal.Body className='p-md-4' dir='rtl'>
                    <form className="row" onSubmit={addBanner}>
                        <div className="col-12 col-md-7 mb-3">
                            <p className='text-red fw-bold'>افزودن بنر</p>
                            <Inputs>
                                <span>عنوان بنر</span>
                                <input className={'afa-error-input'}
                                       type="text"
                                       name={'banner_title'}
                                       value={inputs.banner_title}
                                       onChange={(e) =>
                                           setInputs(prevState => ({
                                               ...prevState, 'banner_title': fixNumber(e.target.value)
                                           }))
                                       }/>
                            </Inputs>
                        </div>
                        <div className="col-12 col-md-5 mb-3">
                            <div className="afa-upload-file-parent">
                                <BannerFileMini
                                    className={error[`banner_file`] && 'afa-error-input'}>
                                    {
                                        (inputs[`banner_file_url`] || inputs[`banner_file`]) ?
                                            <div className="afa-img">
                                                <img
                                                    src={inputs[`banner_file_url`] ? inputs[`banner_file_url`] : `${MAIN_URL_IMAGE}${inputs[`banner_file`]}`}
                                                    alt="آتاویتا"/>
                                                <span
                                                    className='afa-remove-position icon-delete'
                                                    onClick={() => removeImage(`banner_file`)}/>
                                            </div> :
                                            <label
                                                className="afa_upload_file d-flex flex-column align-items-center justify-content-center">
                                                <img src={Images?.article_upload?.default}
                                                     className='afa-article-image-to-upload' alt="آتاویتا"/>
                                                <input type="file" accept="image/*"
                                                       onChange={thisUploadDocs}
                                                       name={`banner_file`}
                                                       className='dv-upload-file'/>
                                                {
                                                    !inputs[`banner_file`] &&
                                                    <span className='upload-text'>بارگزاری بنر</span>
                                                }
                                            </label>
                                    }
                                </BannerFileMini>
                                <p className='afa-error-text'>{error[`banner_file`]}</p>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <Inputs>
                                <span>توضیحات بنر</span>
                                <textarea className={'afa-error-input'}
                                          rows={2}
                                          name={'banner_description'}
                                          value={inputs.banner_description}
                                          onChange={(e) =>
                                              setInputs(prevState => ({
                                                  ...prevState, 'banner_description': fixNumber(e.target.value)
                                              }))
                                          }/>
                            </Inputs>
                        </div>
                        <div className='col-12 w-100 d-flex align-items-center'>
                            <button type='submit' className='afa-btn-save-modal'>ذخیره</button>
                            <button type='button' onClick={closeModal}
                                    className='afa-btn-cancel-modal'>انصراف
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </MainContent>
    );
}

export default SettingEn;