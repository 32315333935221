import React, {useEffect, useState} from 'react';
import {PanelContent, TopInformation} from "./style";
import {Modal, ProgressBar} from "react-bootstrap";
import {Stepper} from '@progress/kendo-react-layout';
import {getData, setTitle} from "../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../assets/scripts/GeneralVariables";

import {toast} from "react-toastify";
import PublicInformation from "../SupplierMangement/Child/PublicInformation";
import DirectorBoards from "./Child/DirectorBoards";
import TechnicalStaff from "./Child/TechnicalStaff";
import Experience from "./Child/Experience";
import Bachelors from "./Child/Bachelors";
import Licences from "./Child/Licences";
import FinancialIndicators from "./Child/FinancialIndicators";
import Equipments from "./Child/Equipments";
import Services from "./Child/Services";

const items = [{
    label: 'اطلاعات عمومی',
    icon: 'icon-steper_user'
}, {
    label: 'هیئت مدیره',
    icon: 'icon-managers'
}, {
    label: 'کادر فنی',
    icon: 'icon-Technical-staff'
}, {
    label: 'سوابق شغلی',
    icon: 'icon-steper_job',
}, {
    label: 'لیسانس ها',
    icon: 'icon-steper_certificate'
}, {
    label: 'پروانه ها',
    icon: 'icon-steper_certificate'
}, {
    label: 'شاخص های مالی',
    icon: 'icon-Financial'
}, {
    label: 'تجهیزات',
    icon: 'icon-Equipment-and-production'
}, {
    label: 'خدمات',
    icon: 'icon-services'
}
];

function SuppliersTabsDetails(props) {
    let user_id = props.match.params.user_id;
    const [stepper_value, setValue] = useState(0);
    const [modal, setModal] = useState({show_user_type: false});
    const [inputs, setInput] = useState({is_personnel: 1, description: '', card_id: '', personnel_id: '',units: [], unit: 'انتخاب کنید' , identification_code : '' , full_name : '' , mobile : ''});

    useEffect(async () => {
        window.scroll(0,0)
        setTitle('آتاویتا | تامین کنندگان')
        // let api_result = await getData(MAIN_URL, `hr-management/info/${props?.user_id}`, 'get', {}, true, true);
        // if (api_result?.status === 200) {
        //     let user = api_result?.data?.user
        //     setInput(prevState => ({...prevState , full_name : `${user?.first_name} ${user?.last_name}` , identification_code : user?.identification_code ,
        //         mobile : user?.mobile , units: api_result?.data?.units, card_id : user?.card_id , unit: user?.unit_id , personnel_id : user?.personnel_id,
        //     }))
        // }
    }, [props?.user_id]);

    const handleChange = e => {
        setValue(e.value);
    };

    return (
        <>
            <PanelContent>
                <div className="d-flex flex-row-reverse flex-lg-column">
                    <Stepper className='mb-3' value={stepper_value} onChange={handleChange}
                             orientation={window.innerWidth > 992 ? 'horizontal' : 'vertical'} items={items}/>
                    <div className="afa-size-parent">
                        {
                            stepper_value === 0 &&
                            <PublicInformation user_id={user_id}/>
                        }
                        {
                            stepper_value === 1 &&
                            <DirectorBoards user_id={user_id}/>
                        }
                        {
                            stepper_value === 2 &&
                            <TechnicalStaff user_id={user_id}/>
                        }
                        {
                            stepper_value === 3 &&
                            <Experience user_id={user_id}/>
                        }
                        {
                            stepper_value === 4 &&
                            <Bachelors user_id={user_id}/>
                        }
                        {
                            stepper_value === 5 &&
                            <Licences user_id={user_id}/>
                        }
                        {
                            stepper_value === 6 &&
                            <FinancialIndicators user_id={user_id}/>
                        }
                        {
                            stepper_value === 7 &&
                            <Equipments user_id={user_id}/>
                        }
                        {
                            stepper_value === 8 &&
                            <Services user_id={user_id}/>
                        }
                    </div>
                </div>
            </PanelContent>
        </>
    );
}

export default SuppliersTabsDetails;