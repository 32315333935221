import React, {useEffect, useState} from 'react';
import {
    checkCodeMelli,
    fileUpload,
    getData,
    getFormDataPost,
    just_persian
} from "../../../../../assets/scripts/GeneralFunctions";
import {
    FileInputs,
    Inputs,
    LeftIcon,
    TabStatusContent,
    TabStatusNotComplete,
    TabStatusComplete,
    LargeFileInputs
} from "./style";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import Swal from "sweetalert2";
import {Images} from "../../../../../assets/scripts/Images";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {toast} from "react-toastify";

function Documents(props) {
    const [error, setError] = useState({})
    const [error2, setError2] = useState({})
    const [inputs, setInputs] = useState({title: '', document: null, documents: []})
    const [inputs2, setInputs2] = useState({
        title: '',
        document: null,
        upload_url_document: null
    })
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [uploading_docs, setUploadingDocs] = useState([])
    const [uploading_preview_docs, setUploadingPreviewDocs] = useState([])
    const [remove_id, setRemoveId] = useState({})
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});
    const [is_render, setRender] = useState(false);
    const [new_collapse, setNewCollapse] = useState(false);
    const [show_add_more, setShowAddMore] = useState(false);
    let docsObj = [];
    let fileArray = [];

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/documents", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data

            let documents = data?.documents
            if (documents?.length === 0) {
                setInputs({
                    title: '', document: null, documents: []
                })
                setShowAddMore(false)
            } else {
                setInputs(prevState => ({
                    ...prevState,
                    documents: documents,
                }))
                setShowAddMore(true)
            }
            setMoreData({sheet_status: data?.sheet_status,status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const errorHandler = () => {
        let error_result = true
        let error = {};


        if (!inputs['title']) {
            error['title'] = 'فیلد عنوان مدرک نمیتواند خالی باشد'
            error_result = false
        }
        if (!uploading_docs) {
            error['attached_images'] = 'فیلد ضمیمه نمیتواند خالی باشد'
            error_result = false
        }

        setError(error)
        return error_result
    }

    const checkBoxHandler = (id) => {
        let selected = inputs.selected
        let find = selected.indexOf(id)

        if (find > -1) {
            selected.splice(find, 1)
        } else {
            selected.push(id)
        }

        setInputs(prevState => ({...prevState, selected}))
    }

    const thisUploadDocs = async (e) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let file_upload = await fileUpload(files[0])
        setInputs(prevState => ({
            ...prevState, [e.target.name]: file_upload, upload_url_document: file
        }))
    }

    const thisUploadDocs2 = async (e, id) => {
        let files = e.target.files;
        let file = URL.createObjectURL(await fileUpload(files[0]))
        let file_upload = await fileUpload(files[0])
        setInputs(prevState => ({
            ...prevState, [`document[${id}]`]: file_upload, [`upload_url_document[${id}]`]: file
        }))
    }

    const removeHandlerDocs = (id) => {
        setRemoveId(prevState => ({
            ...prevState, remove_doc_id: id
        }))
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این عکس را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {

                swalWithBootstrapButtons.fire(
                    'حذف شد!',
                    'عکس مورد نظر شما با موفقیت حذف شد',
                    'success'
                )
                setInputs((prevState => ({
                    ...prevState,
                    [`document[${id}]`]: '',
                })))
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    'عکس مورد نظر شما بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs['document']) {
            formData.append('document', inputs['document'])
        }
        if (inputs['title']) {
            formData.append('title', inputs['title'])
        }

        let api_result = await getFormDataPost('profile/documents', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setShowAddMore(true)
            setNewCollapse(false)
            setRender(!is_render)
            setInputs({
                document: null,
                title: '',
                upload_url_document: null
            })
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }
        let data = inputs.documents?.find((row) => {
            return row.id === id
        })

        if (data) {
            setInputs((prevState => ({
                ...prevState,
                [`title[${id}]`]: data?.title,
                [`document[${id}]`]: data?.document,
            })))
        }
    }

    const removeImage = (val) => {
        setInputs(prevState => ({
            ...prevState, [val]: '', upload_url_document: ''
        }))
    }

    const addMoreCollapse = () => {
        setInputs2(prevState => ({
            ...prevState,
            title: '',
            document: null,
            upload_url_document: null
        }));
        setShowAddMore(false)
        setNewCollapse(true)
    }

    const removeNewCollapse = () => {
        setNewCollapse(false)
        setShowAddMore(true)
    }

    const editHandler = async (id) => {
        let formData = new FormData()

        formData.append('title', inputs[`title[${id}]`])

        if(inputs[`upload_url_document[${id}]`]){
            formData.append('document', inputs[`document[${id}]`])
        }


        let api_result = await getFormDataPost(`profile/documents/${id}`, formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            setShowAddMore(true)
            // setNewCollapse(false)
            setRender(!is_render)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            setError(prevState => ({...prevState, id: id}))
        }
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/documents/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    setRender(!is_render)
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const addNewWhenCollapseHave = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs['document']) {
            formData.append('document', inputs['document'])
        }
        if (inputs['title']) {
            formData.append('title', inputs['title'])
        }

        let api_result = await getFormDataPost('profile/documents', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            setShowAddMore(true)
            setNewCollapse(false)
            setRender(!is_render)
            setInputs({
                document: null,
                title: '',
                upload_url_document: null
            })
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    let documents = inputs?.documents;
    return (
        <>
            <form onSubmit={formHandler} className="row">
                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                    <TabStatusComplete style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                        {
                            more_data.sheet_status?.status === 'pending' || more_data.sheet_status?.status === 'denied' ?
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                <span style={{
                                    color: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.color,
                                    backgroundColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.background
                                }} className='icon-tick afa-icon-tick'/>
                        }
                        <p className='mb-0 f-14 '><span>وضعیت : </span><span>{more_data.status_colors?.[more_data.sheet_status?.status]?.title}</span></p>
                    </TabStatusComplete>
                </div>
                <div className="col-12 col-sm-6 col-lg-8 mb-4">
                    {
                        more_data.sheet_status?.message &&
                        <TabStatusContent style={{borderRightColor: more_data.status_colors?.[more_data.sheet_status?.status]?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <p className='mb-0 f-14'>
                                <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                            </p>
                        </TabStatusContent>
                    }
                </div>
                {
                    documents?.length > 0 ?
                        <>
                            <Accordion defaultActiveKey="0">
                                {
                                    documents?.map((row, index) => (
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey={row.id}
                                                              onClick={() => changeArrow(row.id)}>
                                                <div className="d-flex flex-wrap">
                                                    <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                          onClick={() => removeTab(row.id)}/>
                                                    <span className="pl-4 f-14 my-2"> عنوان مدرک : {row?.title}</span>
                                                </div>
                                                <span
                                                    className={(arrow_item.arrow_id === row.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}></span>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={row.id}>
                                                <Card.Body className='row'>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs className='mb-3'>
                                                            <span>عنوان مدرک
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <input
                                                                className={(row.id === error['id'] && error['title']) && 'afa-error-input'}
                                                                type="text"
                                                                name={`title[${row.id}]`}
                                                                value={inputs[`title[${row.id}]`]}
                                                                onChange={(e) =>
                                                                    setInputs(prevState => ({
                                                                        ...prevState,
                                                                        [`title[${row.id}]`]: e.target.value
                                                                    }))
                                                                }
                                                            />
                                                            <p className='afa-error-text'>{(row.id === error['id'] && error['title']) && error['title']}</p>
                                                        </Inputs>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <LargeFileInputs
                                                            className={(row.id === error['id'] && error['document']) && 'afa-error-input'}>
                                                            {
                                                                inputs[`document[${row.id}]`] &&
                                                                <div className="afa-perseneli-img">
                                                                    <img
                                                                        src={(inputs[`document[${row.id}]`] && !inputs[`upload_url_document[${row.id}]`]) ? `${MAIN_URL_IMAGE}${inputs[`document[${row.id}]`]}` : inputs[`upload_url_document[${row.id}]`]}
                                                                        alt="بساز بفروش"/>
                                                                    <span
                                                                        className='icon-close-solid afa-remove-position'
                                                                        onClick={() => removeHandlerDocs(row.id)}/>
                                                                </div>
                                                            }
                                                            <label
                                                                className={inputs[`document[${row.id}]`] ? 'afa-content-after-img' : 'afa-center-pos'}>

                                                                <span className='icon-cloud-computing'></span>

                                                                <input type="file" accept="image/*" multiple={true}
                                                                       onChange={(e) => thisUploadDocs2(e, row.id)}
                                                                       name='document'
                                                                       className='dv-upload-file'/>
                                                                <span className='upload-text'>آپلود</span>
                                                            </label>
                                                        </LargeFileInputs>
                                                        <p className='afa-error-text'>{error['document']}</p>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <button type='button'
                                                                        onClick={() => editHandler(row.id)}>
                                                                    <span>ذخیره</span>
                                                                    <LeftIcon><span
                                                                        className='icon-cheveron-left'/></LeftIcon>
                                                                </button>
                                                            </Inputs>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))
                                }
                                {
                                    new_collapse &&
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => changeArrow(0)}>

                                            <div className="d-flex flex-wrap">
                                                <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                      onClick={removeNewCollapse}></span>
                                                {
                                                    inputs['title'] &&
                                                    <span
                                                        className="pl-4 f-14 my-2"> عنوان مدرک : {inputs['title']}</span>
                                                }
                                            </div>

                                            <span
                                                className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}></span>

                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className='row'>
                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <Inputs className='mb-3'>
                                                        <span>عنوان مدرک
                                                        <span className='dv-required-star'>*</span>
                                                        </span>
                                                        <input className={error['title'] && 'afa-error-input'}
                                                               type="text"
                                                               name={'title'}
                                                               value={inputs.title}
                                                               onChange={(e) =>
                                                                   setInputs(prevState => ({
                                                                       ...prevState, 'title': e.target.value
                                                                   }))
                                                               }/>
                                                        <p className='afa-error-text'>{error['title']}</p>
                                                    </Inputs>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                    <LargeFileInputs className={error['document'] && 'afa-error-input'}>
                                                        {
                                                            inputs['document'] &&
                                                            <div className="afa-perseneli-img">
                                                                <img
                                                                    src={inputs['upload_url_document']}
                                                                    alt="آتاویتا"/>
                                                                <span className='icon-close-solid afa-remove-position'
                                                                      onClick={() => removeImage('document')}/>
                                                            </div>
                                                        }
                                                        <label
                                                            className={inputs['document'] ? 'afa-content-after-img' : 'afa-center-pos'}>
                                                            <span className='icon-cloud-computing'/>
                                                            <input type={'file'} accept="image/*"
                                                                   onChange={thisUploadDocs}
                                                                   name='document'
                                                                   className='dv-upload-file'/>
                                                            <span className='upload-text'>اپلود</span>
                                                        </label>
                                                    </LargeFileInputs>

                                                    <p className='afa-error-text'>{error['document']}</p>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                        <Inputs>
                                                            <button type='submit'>
                                                                <span>ذخیره</span>
                                                                <LeftIcon><span
                                                                    className='icon-cheveron-left'/></LeftIcon>
                                                            </button>
                                                        </Inputs>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                }
                            </Accordion>
                            {
                                show_add_more &&
                                <div className="row d-flex justify-content-end">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>
                                        <Inputs>
                                            <button type='button' onClick={addMoreCollapse}
                                                    className='afa-add-more-btn'>
                                                <span className='icon-add f-20'/>
                                                <span className='pr-3'>افزودن جدید</span>
                                            </button>
                                        </Inputs>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <>
                            <div className="col-12 my-4">
                                <p>در صورت نیاز به اپلود مدارک دیگر با افزودن عنوان مدارک خود را اپلود کنید</p>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <Inputs className='mb-3'>
                                    <span>عنوان مدرک
                                    <span className='dv-required-star'>*</span>
                                    </span>
                                    <input className={error['title'] && 'afa-error-input'} type="text"
                                           name={'title'}
                                           value={inputs.title}
                                           onChange={(e) =>
                                               setInputs(prevState => ({
                                                   ...prevState, 'title': e.target.value
                                               }))
                                           }/>
                                    <p className='afa-error-text'>{error['title']}</p>
                                </Inputs>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                <LargeFileInputs className={error['document'] && 'afa-error-input'}>
                                    {
                                        inputs['document'] &&
                                        <div className="afa-perseneli-img">
                                            <img
                                                src={inputs['upload_url_document']}
                                                alt="آتاویتا"/>
                                            <span className='icon-close-solid afa-remove-position'
                                                  onClick={() => removeImage('document')}/>
                                        </div>
                                    }
                                    <label className={inputs['document'] ? 'afa-content-after-img' : 'afa-center-pos'}>
                                        <span className='icon-cloud-computing'/>
                                        <input type={'file'} accept="image/*"
                                               onChange={thisUploadDocs}
                                               name='document'
                                               className='dv-upload-file'/>
                                        <span className='upload-text'>اپلود</span>
                                    </label>
                                </LargeFileInputs>

                                <p className='afa-error-text'>{error['document']}</p>
                            </div>
                            <>
                                {/*<div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex flex-wrap">*/}
                                {/*    {*/}
                                {/*        uploading_preview_docs &&*/}
                                {/*        (uploading_preview_docs || ![null]).user_information((url, index) => (*/}
                                {/*            <div className='position-relative afa-img-size' key={index}>*/}
                                {/*                <img*/}
                                {/*                    // onError={(e) => {*/}
                                {/*                    //     e.target.onerror = null;*/}
                                {/*                    //     e.target.src = `${Images?.placeholder_img.default}`*/}
                                {/*                    /*}}*/}
                                {/*                    src={url} alt="بساز بفروش"/>*/}
                                {/*                <span className='icon-close-solid afa-remove-position'*/}
                                {/*                      onClick={() => removeHandlerDocs(index)}/>*/}
                                {/*            </div>*/}
                                {/*        ))}*/}
                                {/*</div>*/}
                            </>
                            <div className="col-12 d-flex justify-content-center">
                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                    <Inputs>
                                        <button type='submit'>
                                            <span>ذخیره این صفحه</span>
                                            <LeftIcon><span className='icon-cheveron-left'/></LeftIcon>
                                        </button>
                                    </Inputs>
                                </div>
                            </div>
                            {
                                show_add_more &&
                                <div className="d-flex justify-content-end">
                                    <div className={"col-12 col-sm-6 col-lg-4 mb-4"}>
                                        <Inputs>
                                            <button type='button' onClick={addMoreCollapse}
                                                    className='afa-add-more-btn'>
                                                <span className='icon-add f-20'/>
                                                <span className='pr-3'>افزودن جدید</span>
                                            </button>
                                        </Inputs>
                                    </div>
                                </div>
                            }
                        </>
                }
            </form>
        </>
    )
}

export default Documents;