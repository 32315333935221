import styled from "styled-components";

const dark_green = '#379521';
const custom_gray = '#ECECEC';
const light_red = '#FC4F59';
const label_text = '#2F4858'
const main_color = '#E73444';
const main_btn_color = 'linear-gradient(263.94deg, #E73444 32.54%, #895193 109.18%)';
const main_btn_color_hover = 'linear-gradient(263.94deg, #E73444 100%, #895193 0%)';
const next_btn_color = '#16A596';
const stepper_color = '#844E8E';
const bg_gray = '#F6F6F6';

export const PanelContent = styled.div`
  background-color: #fff;
  border-radius: 0;
  border: none;
  box-shadow: 0 3px 30px rgb(0 0 0 / 13%);
  margin: 3rem;
  padding: 2rem;

  .progress {
    height: 0.5rem !important;

    .progress-bar {
      background: ${main_btn_color};
    }
  }
  
  .accordion{
    border: none;
  }

  @media (max-width: 992px) {
    box-shadow: none;
    padding: 1rem;
  }
  @media (max-width: 576px) {
    box-shadow: none;
    padding: 0.5rem;
    margin : 0;
  }
`
