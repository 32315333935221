import React, {useEffect, useState} from 'react';
import {InformationContent} from "../../HumanResources/style";
import moment from "moment-jalaali";
import {priceFormat, getData} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../../assets/scripts/GeneralVariables";
import {Inputs, LeftIcon} from "./style";
import Swal from "sweetalert2";

function ReviewData(props) {

    // states
    const [inputs, setInput] = useState({
        personal_info: {},
        family_members: [],
        educational_backgrounds: [],
        professional_experiences: [],
        languages: [],
        skills: [],
        certificates: [],
        softwares: [],
    });

    // get list
    useEffect(async () => {
        let api_result = await getData(MAIN_URL, `profile/get-confirmed-information`, 'get', {}, true, true);
        if (api_result?.status === 200) {
            setInput(prevState => ({
                ...prevState,
                personal_info: api_result?.data?.personal_info,
                family_members: api_result?.data?.family_members,
                educational_backgrounds: api_result?.data?.educational_backgrounds,
                professional_experiences: api_result?.data?.professional_experiences,
                languages: api_result?.data?.languages,
                skills: api_result?.data?.skills,
                certificates: api_result?.data?.certificates,
                softwares: api_result?.data?.softwares,
            }))
        }
    }, [props?.user_id]);

    const saveDataInPreview = async () => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "آیا صحت اطلاعات خود را تایید مینمایید؟",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله',
            cancelButtonText: 'خیر',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let api_result = await getData(MAIN_URL, `profile/confirmed-information`, 'get', {}, true, true);
                if (api_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'تایید شد',
                        'صحت اطلاعات شما با موفقیت تایید شد',
                        'success'
                    )
                }
            }
        })
    }

    let personal_info = inputs?.personal_info;
    let family_members = inputs?.family_members;
    let educational_backgrounds = inputs?.educational_backgrounds;
    let professional_experiences = inputs?.professional_experiences;
    let languages = inputs?.languages;
    let skills = inputs?.skills;
    let certificates = inputs?.certificates;
    let softwares = inputs?.softwares;

    console.log(family_members)
    return (
        <>
            <InformationContent>
                <div className="row mb-0">

                    {/*Start Personal Information*/}
                    <div className="col-12 mb-3">
                        <h6 className='text-red fw-bold'>اطلاعات فردی</h6>
                    </div>
                    <div className="col-12 d-flex flex-wrap mb-0">
                        <div>
                            <span className='afa-title'>نام : </span>
                            {personal_info?.first_name ?
                                <span className="afa-value">{personal_info?.first_name}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}
                        </div>
                        <div>
                            <span className='afa-title'>نام خانوادگی : </span>
                            {personal_info?.last_name ?
                                <span className="afa-value">{personal_info?.last_name}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}
                        </div>
                        <div>
                            <span className='afa-title'>کد ملی : </span>
                            {personal_info?.identification_code ?
                                <span className="afa-value">{personal_info?.identification_code}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}
                        </div>
                        <div>
                            <span className='afa-title'>شماره شناسنامه : </span>
                            {personal_info?.birth_certificate_id ?
                                <span className="afa-value">{personal_info?.birth_certificate_id}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}
                        </div>
                        <div>
                            <span className='afa-title'>جنسیت : </span>
                            {personal_info?.gender ? <span className="afa-value">{personal_info?.gender}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}

                        </div>
                        <div>
                            <span className='afa-title'>تاریخ تولد : </span>
                            {personal_info?.birth_date ?
                                <span className="afa-value"
                                      dir={'ltr'}>{moment(personal_info?.birth_date).format('jYYYY-jMM-jDD')}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}
                        </div>
                        <div>
                            <span className='afa-title'>شماره همراه : </span>
                            {personal_info?.mobile ? <span className="afa-value">{personal_info?.mobile}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}
                        </div>
                        <div>
                            <span className='afa-title'>استان: </span>
                            {personal_info?.city?.state?.name ?
                                <span className="afa-value">{personal_info?.city?.state?.name}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}
                        </div>
                        <div>
                            <span className='afa-title'>شهر : </span>
                            {personal_info?.city?.name ?
                                <span className="afa-value">{personal_info?.city?.name}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}
                        </div>
                        <div>
                            <span className='afa-title'>نشانی محل سکونت : </span>
                            {personal_info?.address ? <span className="afa-value">{personal_info?.address}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}
                        </div>
                        <div>
                            <span className='afa-title'>کد پستی</span>
                            {personal_info?.postal_code ?
                                <span className="afa-value">{personal_info?.postal_code}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}
                        </div>
                        <div>
                            <span className='afa-title'>وضعیت تأهل : </span>
                            {personal_info?.marital_status ?
                                <span className="afa-value">{personal_info?.marital_status}</span> :
                                <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                    className='icon-Info-Circle afa-info-circle'
                                    title={'فیلد مورد نظر خالی است'}/></span>}
                        </div>
                        {
                            personal_info?.gender === 'مرد' &&
                            <div>
                                <span className='afa-title'>وضعیت نظام وظیفه : </span>
                                {personal_info?.military_service_status ?
                                    <span className="afa-value">{personal_info?.military_service_status}</span> :
                                    <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                        className='icon-Info-Circle afa-info-circle'
                                        title={'فیلد مورد نظر خالی است'}/></span>}
                            </div>
                        }
                    </div>
                    {/*End Personal Information*/}
                    {/************************************************************************************/}
                    {/*Start family information*/}
                    <div className="col-12 mb-3">
                        <h6 className='text-red fw-bold'>اعضای خانواده</h6>
                    </div>
                    {
                        family_members?.map((item, index) => (
                            <div key={item.id} className="d-flex flex-column">
                                <div className="col-12 d-flex flex-wrap mb-0">
                                    <div>{parseInt(index) + 1} -</div>
                                    <div>
                                        <span className='afa-title'>نام و نام خانوادگی : </span>
                                        {item?.full_name ?
                                            <span className="afa-value">{item.name} {item?.full_name}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>نسبت : </span>
                                        {item?.relation ?
                                            <span className="afa-value">{item?.relation}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>کد ملی : </span>
                                        {item?.identification_code ?
                                            <span className="afa-value">{item?.identification_code}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>تاریخ تولد : </span>
                                        {item?.birth_date ?
                                            <span className="afa-value"
                                                  dir={'ltr'}>{moment(item?.birth_date).format('jYYYY-jMM-jDD')}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {/*End family information*/}
                    {/************************************************************************************/}
                    {/*Start Education information*/}
                    <div className="col-12 mb-3">
                        <h6 className='text-red fw-bold'>سوابق تحصیلی</h6>
                    </div>
                    <div className="col-12 d-flex flex-column">
                        {
                            educational_backgrounds?.map((item, index) => (
                                <div key={item.id} className="d-flex flex-wrap mb-0">
                                    <div>{parseInt(index) + 1} -</div>
                                    <div>
                                        <span className='afa-title'>مقطع : </span>
                                        {item?.grade ?
                                            <span className="afa-value">{item?.grade}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                    {
                                        item?.field &&
                                        <div>
                                            <span className='afa-title'>رشته تحصیلی : </span>
                                            <span className="afa-value">{item?.field}</span>
                                        </div>
                                    }

                                    {item?.orientation &&
                                        <div>
                                            <span className='afa-title'>گرایش : </span>
                                            <span className="afa-value">{item?.orientation}</span>
                                        </div>
                                    }

                                    <div>
                                        <span className='afa-title'>دانشگاه/موسسه : </span>
                                        {item?.institute ?
                                            <span className="afa-value">{item?.institute}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>معدل : </span>
                                        {item?.grade_average ?
                                            <span
                                                className="afa-value">{item?.grade_average}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>از تاریخ : </span>
                                        {item?.from_date ?
                                            <span className="afa-value">{item?.from_date}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>تا تاریخ : </span>
                                        {item?.to_date ?
                                            <span className="afa-value">{item?.to_date}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {/*End Education information*/}
                    {/************************************************************************************/}
                    {/*Start Education information*/}
                    <div className="col-12 mb-3">
                        <h6 className='text-red fw-bold'>سوابق شغلی</h6>
                    </div>
                    <div className="col-12 d-flex flex-column">
                        {
                            professional_experiences?.map((item, index) => (
                                <div key={item.id} className="d-flex flex-wrap mb-0">
                                    <div>{parseInt(index) + 1} -</div>

                                    <div>
                                        <span className='afa-title'>نام سازمان/ شرکت : </span>
                                        {item?.organization ?
                                            <span className="afa-value">{item?.organization}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>سمت : </span>
                                        {item?.position ?
                                            <span className="afa-value">{item?.position}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>از تاریخ : </span>
                                        {item?.from_date ?
                                            <span className="afa-value">{item?.from_date}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>تا تاریخ : </span>
                                        {item?.to_date ?
                                            <span className="afa-value">{item?.to_date}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>حقوق دریافتی : </span>
                                        {item?.salary ?
                                            <span className="afa-value">{priceFormat(item?.salary)}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {/*End Education information*/}
                    {/************************************************************************************/}
                    {/*Start Education information*/}
                    <div className="col-12 mb-3">
                        <h6 className='text-red fw-bold'>زبان های خارجی</h6>
                    </div>
                    <div className="col-12 d-flex flex-column">
                        {
                            languages?.map((item, index) => (
                                <div key={item.id} className="d-flex flex-wrap mb-0">
                                    <div>{parseInt(index) + 1} -</div>
                                    <div>
                                        <span className='afa-title'>زبان : </span>
                                        {item?.language ?
                                            <span className="afa-value">{item?.language}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>مکالمه : </span>
                                        {item?.conversation ?
                                            <span className="afa-value">{item?.conversation}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>خواندن نوشتن : </span>
                                        {item?.reading_writing ?
                                            <span className="afa-value">{item?.reading_writing}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'> ترجمه و گزارش نویسی : </span>
                                        {item?.translation_report ?
                                            <span className="afa-value">{item?.translation_report}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>

                                    <div>
                                        <span className='afa-title'>مذاکره تجاری : </span>
                                        {item?.business_negotiation ?
                                            <span className="afa-value">{item?.business_negotiation}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {/*End Education information*/}
                    {/************************************************************************************/}
                    {/*Start Education information*/}
                    <div className="col-12 mb-3">
                        <h6 className='text-red fw-bold'>مهارت ها</h6>
                    </div>
                    {
                        skills?.map((item, index) => (
                            <div key={item.id} className="col-12 d-flex flex-column">
                                <div className="d-flex flex-wrap mb-0">
                                    <div>{parseInt(index) + 1} -</div>

                                    <div>
                                        <span className='afa-title'>واحد : </span>
                                        {item?.skill?.technical_department?.name ?
                                            <span
                                                className="afa-value">{item?.skill?.technical_department?.name}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                    <div>
                                        <span className='afa-title'>نام مهارت : </span>
                                        {item?.skill?.name ?
                                            <span className="afa-value">{item?.skill?.name}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                    <div>
                                        <span className='afa-title'>سطح : </span>
                                        {item?.level ?
                                            <span className="afa-value">{item?.level}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {/*End Education information*/}
                    {/************************************************************************************/}
                    {/*Start Education information*/}
                    <div className="col-12 mb-3">
                        <h6 className='text-red fw-bold'>گواهی ها</h6>
                    </div>
                    <div className="col-12 d-flex flex-column">
                        {
                            certificates?.map((item, index) => (
                                <div key={item.id} className="d-flex flex-wrap mb-0">
                                    <div>{parseInt(index) + 1} -</div>

                                    <div>
                                        <span className='afa-title'>نام دوره : </span>
                                        {item?.course ?
                                            <span className="afa-value">{item?.course}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                    <div>
                                        <span className='afa-title'>محل اموزش : </span>
                                        {item?.location ?
                                            <span className="afa-value">{item?.location}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                    <div>
                                        <span className='afa-title'>سال : </span>
                                        {item?.year ?
                                            <span className="afa-value">{item?.year}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                    <div>
                                        <span className='afa-title'>مدت : </span>
                                        {item?.duration ?
                                            <span className="afa-value">{item?.duration}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {/*End Education information*/}
                    {/************************************************************************************/}
                    {/*Start Education information*/}
                    <div className="col-12 mb-3">
                        <h6 className='text-red fw-bold'>نرم افزار ها</h6>
                    </div>
                    <div className="col-12 d-flex flex-column">
                        {
                            softwares?.map((item, index) => (
                                <div key={item.id} className="d-flex flex-wrap mb-0">
                                    <div>{parseInt(index) + 1} -</div>

                                    <div>
                                        <span className='afa-title'>نرم افزار : </span>
                                        {item?.name ?
                                            <span className="afa-value">{item?.name}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                    <div>
                                        <span className='afa-title'>سطح : </span>
                                        {item?.pivot?.level ?
                                            <span className="afa-value">{item?.pivot?.level}</span> :
                                            <span className="afa-value" style={{verticalAlign: 'text-top'}}><span
                                                className='icon-Info-Circle afa-info-circle'
                                                title={'فیلد مورد نظر خالی است'}/></span>}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {/*End Education information*/}
                </div>
            </InformationContent>
            <div className="col-12">
                <div className="row d-flex flex-column flex-sm-row justify-content-between">
                    <div className="col-12 col-sm-6 col-lg-4"></div>
                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                        <Inputs>
                            <button type='button' onClick={saveDataInPreview}>
                                <span>ثبت نهایی فرم</span>
                                <LeftIcon><span className='icon-cheveron-left'></span></LeftIcon>
                            </button>
                        </Inputs>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4"></div>
                </div>
            </div>
        </>
    )
}

export default ReviewData;