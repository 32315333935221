import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Loader from "./components/Main/Loader/Loader";
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/stepper.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import './assets/scss/fonts.css';
import './assets/scss/icomoon.css';
import './assets/scss/styles.scss';
import AppWrapper from "./App";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <AppWrapper />
            <Loader id='loader'/>
        </BrowserRouter>
    </React.StrictMode>,
  document.getElementById('root')
);
