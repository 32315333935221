import React, {useState, useEffect} from 'react';
import {
    TabStatusComplete,
    TabStatusNotComplete,
    Inputs,
    LeftIcon,
    FileInputs, TabStatusContent
} from "./style";
import moment from "moment-jalaali";
import {
    checkCodeMelli,
    fileUpload,
    fixNumber,
    just_persian,
    getData,
    getFormDataPost, edit_item_with_id, remove_item_of_arr_with_id
} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

function Staff(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        skill : '',
        education : 'انتخاب کنید',
        experience : '',
        count : '',
        technical_staff_list : []
    })
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [is_render, setRender] = useState(false);
    const [new_collapse, setNewCollapse] = useState(false);
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/supplier/technical-staff", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let technical_staff = data?.technical_staff
                setInputs(prevState => ({
                    ...prevState,
                    technical_staff_list : technical_staff
                }))

            setMoreData({sheet_status: data?.sheet_status,status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = inputs?.technical_staff_list?.find((row) => {
            return row.id === id
        })
        if (data) {
            setInputs((prevState => ({
                ...prevState,
                [`count[${id}]`]: data?.count,
                [`skill[${id}]`]: data?.skill,
                [`education[${id}]`]: data?.education,
                [`experience[${id}]`]: data?.experience,
            })))
        }
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/supplier/technical-staff/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.technical_staff_list;
                    let new_arr = remove_item_of_arr_with_id(arr , id)
                    setInputs(prevState => ({...prevState , technical_staff_list: new_arr}))
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }
    const removeTab2 = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                setNewCollapse(false)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const saveEditChange = async (id) =>
    {
        let formData = new FormData()
        if (inputs?.[`count[${id}]`]) {
            formData.append('count', inputs?.[`count[${id}]`])
        }
        if (inputs?.[`experience[${id}]`]) {
            formData.append('experience', inputs?.[`experience[${id}]`])
        }
        if (inputs?.[`skill[${id}]`]) {
            formData.append('skill', inputs?.[`skill[${id}]`])
        }
        if (inputs?.[`education[${id}]`] !== 'انتخاب کنید') {
            formData.append('education', inputs?.[`education[${id}]`])
        }

        let api_result = await getFormDataPost(`profile/supplier/technical-staff/${id}`, formData)
        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            let arrays = edit_item_with_id(inputs?.technical_staff_list , api_result?.data?.technical_staff)
            setInputs(prevState => ({...prevState , technical_staff_list: arrays}))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addNewCollapse = () => {
        setInputs(prevState => ({
            ...prevState,
            count : '',
            skill :'',
            education : 'انتخاب کنید',
            experience : '',
        }));
        setNewCollapse(true)
    }

    const saveNewCollapse = async () => {
        let formData = new FormData()
        if (inputs?.[`count`]) {
            formData.append('count', inputs?.[`count`])
        }
        if (inputs?.[`experience`]) {
            formData.append('experience', inputs?.[`experience`])
        }
        if (inputs?.[`skill`]) {
            formData.append('skill', inputs?.[`skill`])
        }
        if (inputs?.[`education`] !== 'انتخاب کنید') {
            formData.append('education', inputs?.[`education`])
        }

        let api_result = await getFormDataPost('profile/supplier/technical-staff', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')

            if(inputs?.technical_staff_list?.length > 0 ){
                let arr = inputs?.technical_staff_list;
                arr.push(api_result?.data?.technical_staff)
                setInputs(prevState => ({
                    ...prevState,
                    skill : '',
                    education : 'انتخاب کنید',
                    experience : '',
                    count : '',
                    technical_staff_list: arr
                }))
                setNewCollapse(false)
            }else{
                setRender(!is_render)
                setNewCollapse(false)
            }
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs?.[`count`]) {
            formData.append('count', inputs?.[`count`])
        }
        if (inputs?.[`experience`]) {
            formData.append('experience', inputs?.[`experience`])
        }
        if (inputs?.[`skill`]) {
            formData.append('skill', inputs?.[`skill`])
        }
        if (inputs?.[`education`] !== 'انتخاب کنید') {
            formData.append('education', inputs?.[`education`])
        }

        let api_result = await getFormDataPost('profile/supplier/technical-staff', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            if(inputs?.technical_staff_list?.length > 0 ){
                let arr = inputs?.technical_staff_list;
                arr.push(api_result?.data?.technical_staff)
                setInputs(prevState => ({
                    ...prevState,
                    skill : '',
                    education : 'انتخاب کنید',
                    experience : '',
                    count : '',
                    technical_staff_list: arr
                }))
            }else{
                setRender(!is_render)
            }
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    return (
        <>
            {
                !inputs?.["isLoad"] &&
                <>
                    <form onSubmit={formHandler} className="row">
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <TabStatusComplete style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                                {
                                    props.status_value === 'pending' || props.status_value === 'denied' ?
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-tick afa-icon-tick'/>
                                }
                                <p className='mb-0 f-14 '><span>وضعیت : </span><span>{props.status_colors?.title}</span></p>
                            </TabStatusComplete>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-8 mb-4">
                            {
                                more_data.sheet_status?.message &&
                                <TabStatusContent style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                                    <p className='mb-0 f-14'><span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                                    </p>
                                </TabStatusContent>
                            }
                        </div>
                        {
                            inputs?.technical_staff_list?.length > 0 ?
                                <>
                                    <Accordion defaultActiveKey="0">
                                        {
                                            inputs?.technical_staff_list?.map((item, i) => (
                                                <Card key={i}>
                                                    <Accordion.Toggle as={Card.Header} eventKey={`${item.id}`} key={i}
                                                                      onClick={() => changeArrow(item.id)}>
                                                        <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab(item.id)}></span>
                                                            {
                                                                item?.skill && <span
                                                                    className="pl-4 f-14 my-2"> زمینه تخصص : {item?.skill}</span>
                                                            }
                                                            {
                                                                item?.experience && <span
                                                                    className="pl-4 f-14 my-2"> سابقه کار (ماه) : {item?.experience}</span>
                                                            }
                                                        </div>
                                                        <span
                                                            className={(arrow_item.arrow_id === item.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey={`${item.id}`}>
                                                        <Card.Body className='row'>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>زمینه تخصص
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`skill[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={`skill[${item.id}]`}
                                                                        value={inputs?.[`skill[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`skill[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`skill[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>میزان تحصیلات
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <select
                                                                        className={error[`education[${item.id}]`] && 'afa-error-input'}
                                                                        value={inputs?.[`education[${item.id}]`]}
                                                                        onChange={inputHandler}
                                                                        name={`education[${item.id}]`}>
                                                                        <option value='انتخاب کنید' disabled>انتخاب کنید
                                                                        </option>
                                                                        <option value="دیپلم">دیپلم</option>
                                                                        <option value="فوق دیپلم">فوق دیپلم</option>
                                                                        <option value="لیسانس">لیسانس</option>
                                                                        <option value="فوق لیسانس">فوق لیسانس</option>
                                                                        <option value="دکترا">دکترا</option>
                                                                    </select>
                                                                    <p className='afa-error-text'>{error[`education[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>سابقه کار (ماه)
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`experience[${item.id}]`] && 'afa-error-input'}
                                                                        type="number"
                                                                        name={`experience[${item.id}]`}
                                                                        value={inputs?.[`experience[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`experience[${item.id}]`]: fixNumber(e.target.value)
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`experience[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>تعداد
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`count[${item.id}]`] && 'afa-error-input'}
                                                                        type="number"
                                                                        name={`count[${item.id}]`}
                                                                        value={inputs?.[`count[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`count[${item.id}]`]: fixNumber(e.target.value)
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`count[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 d-flex justify-content-center">
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs>
                                                                        <button type='button'
                                                                                onClick={() => saveEditChange(item.id)}
                                                                                className='afa-add-more-btn'>
                                                                            <span className='pr-3'>ذخیره تغییرات</span>
                                                                        </button>
                                                                    </Inputs>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            ))
                                        }

                                        {
                                            new_collapse &&
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey={'0'}
                                                                  onClick={() => changeArrow(0)}>
                                                    <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab2('rasoul')}></span>
                                                        {
                                                            inputs?.skill && <span
                                                                className="pl-4 f-14 my-2"> زمینه تخصص : {inputs?.skill}</span>
                                                        }
                                                        {
                                                            inputs?.experience && <span
                                                                className="pl-4 f-14 my-2"> سابقه کار (ماه) : {inputs?.experience}</span>
                                                        }
                                                    </div>
                                                    <span
                                                        className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={'0'}>
                                                    <Card.Body className='row'>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>زمینه تخصص
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`skill`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`skill`}
                                                                    value={inputs?.[`skill`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`skill`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`skill`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>میزان تحصیلات
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <select
                                                                    className={error[`education`] && 'afa-error-input'}
                                                                    value={inputs?.[`education`]}
                                                                    onChange={inputHandler}
                                                                    name={`education`}>
                                                                    <option value='انتخاب کنید' disabled>انتخاب کنید
                                                                    </option>
                                                                    <option value="دیپلم">دیپلم</option>
                                                                    <option value="فوق دیپلم">فوق دیپلم</option>
                                                                    <option value="لیسانس">لیسانس</option>
                                                                    <option value="فوق لیسانس">فوق لیسانس</option>
                                                                    <option value="دکترا">دکترا</option>
                                                                </select>
                                                                <p className='afa-error-text'>{error[`education`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>سابقه کار (ماه)
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`experience`] && 'afa-error-input'}
                                                                    type="number"
                                                                    name={`experience`}
                                                                    value={inputs?.[`experience`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`experience`]: fixNumber(e.target.value)
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`experience`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>تعداد
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`count`] && 'afa-error-input'}
                                                                    type="number"
                                                                    name={`count`}
                                                                    value={inputs?.[`count`]}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`count`]: fixNumber(e.target.value)
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`count`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-center">
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <button type='button'
                                                                            onClick={saveNewCollapse}
                                                                            className='afa-add-more-btn'>
                                                                        <span className='pr-3'>ذخیره تغییرات</span>
                                                                    </button>
                                                                </Inputs>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        }

                                    </Accordion>

                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-8 mb-4"/>

                                        <div className={

                                            inputs['relation'] === 'child' ? "col-12 col-sm-6 col-lg-4 mb-4" : "col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>

                                            <Inputs>

                                                <button type='button' onClick={addNewCollapse}
                                                        className='afa-add-more-btn'>

                                                    <span className='icon-add f-20'/>

                                                    <span className='pr-3'>افزودن اطلاعات</span>

                                                </button>

                                            </Inputs>

                                        </div>

                                        {/*<div className="col-12 col-sm-6 col-lg-4 mb-4"/>*/}

                                        {/*<div className="col-12 col-sm-6 col-lg-4 offset-lg-4 mb-4">*/}

                                        {/*    <Inputs>*/}

                                        {/*        <button type='submit'>*/}

                                        {/*            <span>ثبت نهایی</span>*/}

                                        {/*            <LeftIcon><span className='icon-cheveron-left'></span></LeftIcon>*/}

                                        {/*        </button>*/}

                                        {/*    </Inputs>*/}

                                        {/*</div>*/}
                                    </div>
                                </> :
                                <>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>زمینه تخصص
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`skill`] && 'afa-error-input'}
                                                type="text"
                                                name={`skill`}
                                                value={inputs?.[`skill`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`skill`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`skill`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>میزان تحصیلات
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <select
                                                className={error[`education`] && 'afa-error-input'}
                                                value={inputs?.[`education`]}
                                                onChange={inputHandler}
                                                name={`education`}>
                                                <option value='انتخاب کنید' disabled>انتخاب کنید
                                                </option>
                                                <option value="دیپلم">دیپلم</option>
                                                <option value="فوق دیپلم">فوق دیپلم</option>
                                                <option value="لیسانس">لیسانس</option>
                                                <option value="فوق لیسانس">فوق لیسانس</option>
                                                <option value="دکترا">دکترا</option>
                                            </select>
                                            <p className='afa-error-text'>{error[`education`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>سابقه کار (ماه)
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`experience`] && 'afa-error-input'}
                                                type="number"
                                                name={`experience`}
                                                value={inputs?.[`experience`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`experience`]: fixNumber(e.target.value)
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`experience`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>تعداد
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`count`] && 'afa-error-input'}
                                                type="number"
                                                name={`count`}
                                                value={inputs?.[`count`]}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`count`]: fixNumber(e.target.value)
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`count`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 d-flex justify-content-center">
                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>
                                            <Inputs>
                                                <button type='submit' className='afa-add-more-btn'>
                                                    <span className='icon-add f-20'/>
                                                    <span className='pr-3'>افزودن اطلاعات</span>
                                                </button>
                                            </Inputs>
                                        </div>
                                    </div>
                                </>
                        }
                    </form>
                </>
            }
        </>
    )
}

export default Staff;