export const Images = {
    main_logo: require('../images/afa.png'),
    logo_mini: require('../images/logo192.png'),
    login_left: require('../images/login.png'),
    bell: require('../images/bell.png'),
    article: require('../images/article.png'),
    article_upload: require('../images/article_upload.png'),
    united_kingdom: require('../images/united-kingdom.png'),
    persian: require('../images/persian.png'),
    right_top: require('../images/right-top.png'),
    right_bottom: require('../images/right-bottom.png'),
    perseneli : require('../images/عکس پرسنلی.jpg'),
    mapIcon: require('../images/mapIcon.png'),
    placeHolder: require('../images/placeholder.png'),
    mini_placeHolder: require('../images/mini-placeholder.png'),
    pdf_place_holder: require('../images/pdf.png'),
    large_logo: require('../images/large_logo.png'),
}