import React from 'react';
import {Route, Redirect} from 'react-router-dom';
let token = localStorage.getItem('AFAToken');
const PublicRoute = ({component: Component, user_token, panel, ...rest}) => (

    <Route {...rest}
           component={
               (props) => (
                   // user_token && user_information === 'owner' ? <Redirect to={'/owner/land-list'}/>:
                   //     user_token && user_information === 'manufacturer' ? <Redirect to={'/builder/new-land'}/>:
                       token ? <Redirect to={'/profile'}/>:
                   panel ? <Redirect to={'/login'}/> :
                   //     user_type === 'manufacturer' ? <Redirect to={'/builder/new-land'}/> :
                   //         user_type === 'middleman' ? <Redirect to={'/middleman/new-land'}/> :
                               // props.is_login && (props.user_information === 'vendor_admin' || props.user_information === 'vendor_user' ) ? <Redirect to={'/vendor/profile'}/>:
                               <Component {...props}/>
               )}

    />
)

export default PublicRoute;

