import React, {useState, useEffect} from 'react';
import {
    TabStatusComplete,
    TabStatusNotComplete,
    Inputs,
    LeftIcon,
    FileInputs, TabStatusContent
} from "./style";
import moment from "moment-jalaali";
import {
    checkCodeMelli,
    fileUpload,
    fixNumber,
    just_persian,
    getData,
    getFormDataPost, remove_item_of_arr_with_id, edit_item_with_id
} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

function Board_directors(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        full_name: '',//نام و نام خانوادگی
        position: '',//سمت
        identification_code: '',//کد ملی
        joined_at: '',//تاریخ عضویت
        education: 'انتخاب کنید',//تحصیلات
        orientation: '',//گرایش
        share: '',//سهم
        director_boards: []
    })
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [is_render, setRender] = useState(false);
    const [new_collapse, setNewCollapse] = useState(false);
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/supplier/director-boards", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let director_boards = data?.director_boards
            setInputs(prevState => ({
                ...prevState,
                director_boards: director_boards
            }))
            setMoreData({sheet_status: data?.sheet_status, status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const numberInputsHandler = (e) => {
        const regexp = /^[0-9\b]+$/;
        if (e.target.value === '' || regexp.test(e.target.value)) {
            setInputs(prevState => ({
                ...prevState, [e.target.name]: e.target.value
            }))
        }
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = inputs?.director_boards?.find((row) => {
            return row.id === id
        })
        if (data) {
            setInputs((prevState => ({
                ...prevState,
                [`full_name[${id}]`]: data?.full_name,
                [`position[${id}]`]: data?.position,
                [`identification_code[${id}]`]: data?.identification_code,
                [`joined_at[${id}]`]: data?.joined_at,
                [`education[${id}]`]: data?.education,
                [`orientation[${id}]`]: data?.orientation,
                [`share[${id}]`]: data?.share,
            })))
        }
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/supplier/director-boards/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.director_boards;
                    let new_arr = remove_item_of_arr_with_id(arr, id)
                    setInputs(prevState => ({...prevState, director_boards: new_arr}))
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const removeTab2 = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                setNewCollapse(false)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const saveEditChange = async (id) => {
        let formData = new FormData()
        if (inputs?.[`full_name[${id}]`]) {
            formData.append('full_name', inputs?.[`full_name[${id}]`])
        }
        if (inputs?.[`share[${id}]`]) {
            formData.append('share', inputs?.[`share[${id}]`])
        }
        if (inputs?.[`orientation[${id}]`]) {
            formData.append('orientation', inputs?.[`orientation[${id}]`])
        }
        if (inputs?.[`joined_at[${id}]`]) {
            formData.append('joined_at', inputs?.[`joined_at[${id}]`]?.split('T')[0])
        }
        if (inputs?.[`identification_code[${id}]`]) {
            formData.append('identification_code', inputs?.[`identification_code[${id}]`])
        }
        if (inputs?.[`position[${id}]`]) {
            formData.append('position', inputs?.[`position[${id}]`])
        }
        if (inputs?.[`education[${id}]`] !== 'انتخاب کنید') {
            formData.append('education', inputs?.[`education[${id}]`])
        }

        let api_result = await getFormDataPost(`profile/supplier/director-boards/${id}`, formData)
        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            let arrays = edit_item_with_id(inputs?.director_boards, api_result?.data?.director_board)
            setInputs(prevState => ({...prevState, director_boards: arrays}))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addNewCollapse = () => {
        setInputs(prevState => ({
            ...prevState,
            full_name: '',
            position: '',
            identification_code: '',
            joined_at: '',
            education: 'انتخاب کنید',
            orientation: '',
            share: '',
        }));
        setNewCollapse(true)
    }

    const datePickerHandler = (e) => {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let selected_date = e.target.value
        if (date.normalize() < selected_date.normalize()) {
            toast.error('تاریخ انتخابی نمیتواند بعد امروز باشد')
            return false
        } else {
            let date2 = e.target.value
            setInputs(prevState => ({
                ...prevState, [e.target.name]: date2?.split('T')[0]
            }))
        }
    }

    const saveNewCollapse = async () => {
        let formData = new FormData()
        if (inputs?.[`full_name`]) {
            formData.append('full_name', inputs?.[`full_name`])
        }
        if (inputs?.[`share`]) {
            formData.append('share', inputs?.[`share`])
        }
        if (inputs?.[`orientation`]) {
            formData.append('orientation', inputs?.[`orientation`])
        }
        if (inputs?.[`joined_at`]) {
            formData.append('joined_at', inputs?.[`joined_at`]?.split('T')[0])
        }
        if (inputs?.[`identification_code`]) {
            formData.append('identification_code', inputs?.[`identification_code`])
        }
        if (inputs?.[`position`]) {
            formData.append('position', inputs?.[`position`])
        }
        if (inputs?.[`education`] !== 'انتخاب کنید') {
            formData.append('education', inputs?.[`education`])
        }

        let api_result = await getFormDataPost('profile/supplier/director-boards', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            if (inputs?.director_boards?.length > 0) {
                let arr = inputs?.director_boards;
                arr.push(api_result?.data?.director_board)
                setInputs(prevState => ({
                    ...prevState,
                    full_name: '',
                    position: '',
                    identification_code: '',
                    joined_at: '',
                    education: '',
                    orientation: '',
                    share: '',
                    director_boards: arr
                }))
                setNewCollapse(false)
            } else {
                setRender(!is_render)
                setNewCollapse(false)
            }

        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs?.[`full_name`]) {
            formData.append('full_name', inputs?.[`full_name`])
        }
        if (inputs?.[`share`]) {
            formData.append('share', inputs?.[`share`])
        }
        if (inputs?.[`orientation`]) {
            formData.append('orientation', inputs?.[`orientation`])
        }
        if (inputs?.[`joined_at`]) {
            formData.append('joined_at', inputs?.[`joined_at`]?.split('T')[0])
        }
        if (inputs?.[`identification_code`]) {
            formData.append('identification_code', inputs?.[`identification_code`])
        }
        if (inputs?.[`position`]) {
            formData.append('position', inputs?.[`position`])
        }
        if (inputs?.[`education`] !== 'انتخاب کنید') {
            formData.append('education', inputs?.[`education`])
        }

        let api_result = await getFormDataPost('profile/supplier/director-boards', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            if (inputs?.director_boards?.length > 0) {
                let arr = inputs?.director_boards;
                arr.push(api_result?.data?.director_board)
                setInputs(prevState => ({
                    ...prevState,
                    full_name: '',
                    position: '',
                    identification_code: '',
                    joined_at: '',
                    education: '',
                    orientation: '',
                    share: '',
                    director_boards: arr
                }))
                setNewCollapse(false)
            } else {
                setRender(!is_render)
                setNewCollapse(false)
            }

        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }
    return (
        <>
            {
                !inputs?.["isLoad"] &&
                <>
                    <form onSubmit={formHandler} className="row">
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <TabStatusComplete style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                                {
                                    props.status_value === 'pending' || props.status_value === 'denied' ?
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-tick afa-icon-tick'/>
                                }
                                <p className='mb-0 f-14 '><span>وضعیت : </span><span>{props.status_colors?.title}</span>
                                </p>
                            </TabStatusComplete>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-8 mb-4">
                            {
                                more_data.sheet_status?.message &&
                                <TabStatusContent style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                                    <p className='mb-0 f-14'>
                                        <span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                                    </p>
                                </TabStatusContent>
                            }
                        </div>
                        {
                            inputs?.director_boards?.length > 0 ?
                                <>
                                    <Accordion defaultActiveKey="0">
                                        {
                                            inputs?.director_boards?.map((item, i) => (
                                                <Card key={i}>
                                                    <Accordion.Toggle as={Card.Header} eventKey={`${item.id}`}
                                                                      onClick={() => changeArrow(item.id)}>
                                                        <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab(item.id)}></span>
                                                            {
                                                                item?.full_name && <span className="pl-4 f-14 my-2"> نام و نام خانوادگی : {item?.full_name}</span>
                                                            }
                                                            {
                                                                item?.position && <span
                                                                    className="pl-4 f-14 my-2"> سمت : {item?.position}</span>
                                                            }
                                                            {
                                                                item?.identification_code && <span
                                                                    className="pl-4 f-14 my-2"> کد ملی : {item?.identification_code}</span>
                                                            }
                                                        </div>
                                                        <span
                                                            className={(arrow_item.arrow_id === item.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey={`${item.id}`}>
                                                        <Card.Body className='row'>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>نام و نام خانوادگی
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`full_name[${item.id}]`] && 'afa-error-input'}
                                                                        type="text" name={`full_name[${item.id}]`}
                                                                        value={inputs?.[`full_name[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            just_persian(e.target.value) && setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`full_name[${item.id}]`]: e.target.value
                                                                            }))
                                                                        } placeholder='نام و نام خانوادگی'/>
                                                                    <p className='afa-error-text'>{error[`full_name[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>سمت
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`position[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={`position[${item.id}]`}
                                                                        value={inputs?.[`position[${item.id}]`]}
                                                                        onChange={inputHandler}/>
                                                                    <p className='afa-error-text'>{error[`position[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>کد ملی
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`identification_code[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        max={9999999999}
                                                                        maxLength={10}
                                                                        name={`identification_code[${item.id}]`}
                                                                        value={inputs?.[`identification_code[${item.id}]`]}
                                                                        onChange={numberInputsHandler}
                                                                        placeholder='- - - - - - - - - - -'/>
                                                                    <p className='afa-error-text'>{error[`identification_code[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>تاریخ عضویت
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <DateInput
                                                                        value={inputs?.[`joined_at[${item.id}]`]}
                                                                        name={`joined_at[${item.id}]`}
                                                                        className={error[`joined_at[${item.id}]`] && 'afa-error-input'}
                                                                        placeholder={'تاریخ عضویت را انتخاب کنید'}
                                                                        onChange={datePickerHandler}/>
                                                                    <p className='afa-error-text'>{error[`joined_at[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>مدرک تحصیلی
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <select
                                                                        className={error[`education[${item.id}]`] && 'afa-error-input'}
                                                                        value={inputs?.[`education[${item.id}]`]}
                                                                        onChange={inputHandler}
                                                                        name={`education[${item.id}]`}>
                                                                        <option value='انتخاب کنید' disabled>انتخاب کنید
                                                                        </option>
                                                                        <option value="دیپلم">دیپلم</option>
                                                                        <option value="فوق دیپلم">فوق دیپلم</option>
                                                                        <option value="لیسانس">لیسانس</option>
                                                                        <option value="فوق لیسانس">فوق لیسانس</option>
                                                                        <option value="دکترا">دکترا</option>
                                                                    </select>
                                                                    <p className='afa-error-text'>{error[`education[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>رشته تحصیلی
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`orientation[${item.id}]`] && 'afa-error-input'}
                                                                        type="text" name={`orientation[${item.id}]`}
                                                                        value={inputs?.[`orientation[${item.id}]`]}
                                                                        onChange={(e) =>
                                                                            just_persian(e.target.value) && setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`orientation[${item.id}]`]: e.target.value
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`orientation[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>درصد سهم
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`share[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        max={101}
                                                                        maxLength={3}
                                                                        name={`share[${item.id}]`}
                                                                        value={inputs?.[`share[${item.id}]`]}
                                                                        onChange={numberInputsHandler}/>
                                                                    <p className='afa-error-text'>{error[`share[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 d-flex justify-content-center">
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs>
                                                                        <button type='button'
                                                                                onClick={() => saveEditChange(item.id)}
                                                                                className='afa-add-more-btn'>
                                                                            <span className='pr-3'>ذخیره تغییرات</span>
                                                                        </button>
                                                                    </Inputs>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            ))
                                        }

                                        {
                                            new_collapse &&
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey={'0'}
                                                                  onClick={() => changeArrow(0)}>
                                                    <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab2('rasoul')}></span>
                                                        {
                                                            inputs?.full_name && <span className="pl-4 f-14 my-2"> نام و نام خانوادگی : {inputs?.full_name}</span>
                                                        }
                                                        {
                                                            inputs?.position && <span
                                                                className="pl-4 f-14 my-2"> سمت : {inputs?.position}</span>
                                                        }
                                                        {
                                                            inputs?.identification_code && <span
                                                                className="pl-4 f-14 my-2"> کد ملی : {inputs?.identification_code}</span>
                                                        }
                                                    </div>
                                                    <span
                                                        className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={'0'}>
                                                    <Card.Body className='row'>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>نام و نام خانوادگی
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`full_name`] && 'afa-error-input'}
                                                                    type="text" name={`full_name`}
                                                                    value={inputs?.[`full_name`]}
                                                                    onChange={(e) =>
                                                                        just_persian(e.target.value) && setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`full_name`]: e.target.value
                                                                        }))
                                                                    } placeholder='نام و نام خانوادگی'/>
                                                                <p className='afa-error-text'>{error[`full_name`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>سمت
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`position`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`position`}
                                                                    value={inputs?.[`position`]}
                                                                    onChange={inputHandler}
                                                                />
                                                                <p className='afa-error-text'>{error[`position`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>کد ملی
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`identification_code`] && 'afa-error-input'}
                                                                    type="text"
                                                                    max={9999999999}
                                                                    maxLength={10}
                                                                    name={`identification_code`}
                                                                    value={inputs?.[`identification_code`]}
                                                                    onChange={numberInputsHandler}
                                                                    placeholder='- - - - - - - - - - -'/>
                                                                <p className='afa-error-text'>{error[`identification_code`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>تاریخ عضویت
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <DateInput
                                                                    value={inputs?.[`joined_at`]}
                                                                    name={`joined_at`}
                                                                    className={error[`joined_at`] && 'afa-error-input'}
                                                                    placeholder={'تاریخ عضویت را انتخاب کنید'}
                                                                    onChange={datePickerHandler}/>
                                                                <p className='afa-error-text'>{error[`joined_at`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>مدرک تحصیلی
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <select
                                                                    className={error[`education`] && 'afa-error-input'}
                                                                    value={inputs?.[`education`]}
                                                                    onChange={inputHandler}
                                                                    name={`education`}>
                                                                    <option value='انتخاب کنید' disabled>انتخاب کنید
                                                                    </option>
                                                                    <option value="دیپلم">دیپلم</option>
                                                                    <option value="فوق دیپلم">فوق دیپلم</option>
                                                                    <option value="لیسانس">لیسانس</option>
                                                                    <option value="فوق لیسانس">فوق لیسانس</option>
                                                                    <option value="دکترا">دکترا</option>
                                                                </select>
                                                                <p className='afa-error-text'>{error[`education`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>رشته تحصیلی
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`orientation`] && 'afa-error-input'}
                                                                    type="text" name={`orientation`}
                                                                    value={inputs?.[`orientation`]}
                                                                    onChange={(e) =>
                                                                        just_persian(e.target.value) && setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`orientation`]: e.target.value
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`orientation`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>درصد سهم
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`share`] && 'afa-error-input'}
                                                                    type="text"
                                                                    max={101}
                                                                    maxLength={3}
                                                                    name={`share`}
                                                                    value={inputs?.[`share`]}
                                                                    onChange={numberInputsHandler}/>
                                                                <p className='afa-error-text'>{error[`share`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-center">
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <button type='button'
                                                                            onClick={saveNewCollapse}
                                                                            className='afa-add-more-btn'>
                                                                        <span className='pr-3'>ذخیره تغییرات</span>
                                                                    </button>
                                                                </Inputs>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        }

                                    </Accordion>

                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-8 mb-4"/>

                                        <div className={

                                            inputs['relation'] === 'child' ? "col-12 col-sm-6 col-lg-4 mb-4" : "col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>

                                            <Inputs>

                                                <button type='button' onClick={addNewCollapse}
                                                        className='afa-add-more-btn'>

                                                    <span className='icon-add f-20'/>

                                                    <span className='pr-3'>افزودن اطلاعات</span>

                                                </button>

                                            </Inputs>

                                        </div>

                                        {/*<div className="col-12 col-sm-6 col-lg-4 mb-4"/>*/}

                                        {/*<div className="col-12 col-sm-6 col-lg-4 offset-lg-4 mb-4">*/}

                                        {/*    <Inputs>*/}

                                        {/*        <button type='submit'>*/}

                                        {/*            <span>ثبت نهایی</span>*/}

                                        {/*            <LeftIcon><span className='icon-cheveron-left'></span></LeftIcon>*/}

                                        {/*        </button>*/}

                                        {/*    </Inputs>*/}

                                        {/*</div>*/}
                                    </div>
                                </> :
                                <>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>نام و نام خانوادگی
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`full_name`] && 'afa-error-input'}
                                                type="text" name={`full_name`}
                                                value={inputs?.[`full_name`]}
                                                onChange={(e) =>
                                                    just_persian(e.target.value) && setInputs(prevState => ({
                                                        ...prevState,
                                                        [`full_name`]: e.target.value
                                                    }))
                                                } placeholder='نام و نام خانوادگی'/>
                                            <p className='afa-error-text'>{error[`full_name`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>سمت
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`position`] && 'afa-error-input'}
                                                type="text"
                                                name={`position`}
                                                value={inputs?.[`position`]}
                                                onChange={inputHandler}
                                            />
                                            <p className='afa-error-text'>{error[`position`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>کد ملی
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`identification_code`] && 'afa-error-input'}
                                                type="text"
                                                max={9999999999}
                                                maxLength={10}
                                                name={`identification_code`}
                                                value={inputs?.[`identification_code`]}
                                                onChange={numberInputsHandler} placeholder='- - - - - - - - - - -'/>
                                            <p className='afa-error-text'>{error[`identification_code`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>تاریخ عضویت
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <DateInput
                                                value={inputs?.[`joined_at`]}
                                                name={`joined_at`}
                                                className={error[`joined_at`] && 'afa-error-input'}
                                                placeholder={'تاریخ عضویت را انتخاب کنید'}
                                                onChange={datePickerHandler}/>
                                            <p className='afa-error-text'>{error[`joined_at`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>مدرک تحصیلی
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <select
                                                className={error[`education`] && 'afa-error-input'}
                                                value={inputs?.[`education`]}
                                                onChange={inputHandler}
                                                name={`education`}>
                                                <option value='انتخاب کنید' disabled>انتخاب کنید
                                                </option>
                                                <option value="دیپلم">دیپلم</option>
                                                <option value="فوق دیپلم">فوق دیپلم</option>
                                                <option value="لیسانس">لیسانس</option>
                                                <option value="فوق لیسانس">فوق لیسانس</option>
                                                <option value="دکترا">دکترا</option>
                                            </select>
                                            <p className='afa-error-text'>{error[`education`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>رشته تحصیلی
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`orientation`] && 'afa-error-input'}
                                                type="text" name={`orientation`}
                                                value={inputs?.[`orientation`]}
                                                onChange={(e) =>
                                                    just_persian(e.target.value) && setInputs(prevState => ({
                                                        ...prevState,
                                                        [`orientation`]: e.target.value
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`orientation`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>درصد سهم
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`share`] && 'afa-error-input'}
                                                type="text"
                                                max={101}
                                                maxLength={3}
                                                name={`share`}
                                                value={inputs?.[`share`]}
                                                onChange={numberInputsHandler}/>
                                            <p className='afa-error-text'>{error[`share`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 d-flex justify-content-center">
                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>
                                            <Inputs>
                                                <button type='submit' className='afa-add-more-btn'>
                                                    <span className='icon-add f-20'/>
                                                    <span className='pr-3'>افزودن اطلاعات</span>
                                                </button>
                                            </Inputs>
                                        </div>
                                    </div>
                                </>
                        }
                    </form>
                </>
            }
        </>
    )
}

export default Board_directors;