import React, {useState, useEffect} from 'react';
import {
    TabStatusComplete,
    TabStatusNotComplete,
    Inputs,
    LeftIcon,
    FileInputs, TabStatusContent
} from "./style";
import moment from "moment-jalaali";
import {
    checkCodeMelli,
    fileUpload,
    fixNumber,
    just_persian,
    getData,
    getFormDataPost, edit_item_with_id, remove_item_of_arr_with_id, CommaFormat
} from "../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, MAIN_URL_IMAGE} from "../../../../../assets/scripts/GeneralVariables";
import {DateInput} from "react-hichestan-datetimepicker";
import {Accordion, Card} from "react-bootstrap";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
const commaNumber = require('comma-number')

function FinancialIndex(props) {
    const [error, setError] = useState({})
    const [inputs, setInputs] = useState({
        bank_guarantee : "بله",
        financial_year : "",
        annual_gross_income : "",
        fixed_assets : "",
        approved_bank_credit : "",
        supplier_financial_indicators: []
    })
    const [arrow_item, setArrowItem] = useState({arrow_id: 0, arrow_status: false})
    const [is_render, setRender] = useState(false);
    const [new_collapse, setNewCollapse] = useState(false);
    const [more_data, setMoreData] = useState({sheet_status: {}, isLoad: true});

    useEffect(async () => {
        let api_result = await getData(MAIN_URL, "profile/supplier/financial-indicators", 'get', {}, true, true);
        if (api_result?.status === 200) {
            let data = api_result?.data
            let supplier_financial_indicators = data?.supplier_financial_indicators
                setInputs(prevState => ({
                    ...prevState,
                    supplier_financial_indicators : supplier_financial_indicators
                }))
            setMoreData({sheet_status: data?.sheet_status,status_colors: data?.status_colors, isLoad: false})
        }
    }, [is_render]);

    const inputHandler = (e) => {
        setInputs(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const numberInputsHandler = (e) => {
        const regexp = /^[0-9\b]+$/;
        if (e.target.value === '' || regexp.test(e.target.value)) {
            setInputs(prevState => ({
                ...prevState, [e.target.name]: e.target.value
            }))
        }
    }

    const changeArrow = (id) => {
        if (arrow_item.arrow_id === id && arrow_item.arrow_status === true) {

            setArrowItem({arrow_id: id, arrow_status: false})

        } else if (arrow_item.arrow_id === id && arrow_item.arrow_status === false) {

            setArrowItem({arrow_id: id, arrow_status: true})

        } else {
            setArrowItem({arrow_id: id, arrow_status: true})
        }

        let data = inputs?.supplier_financial_indicators?.find((row) => {
            return row.id === id
        })
        if (data) {
            setInputs((prevState => ({
                ...prevState,
                [`bank_guarantee[${id}]`]: data?.bank_guarantee,
                [`financial_year[${id}]`]: data?.financial_year,
                [`annual_gross_income[${id}]`]: data?.annual_gross_income,
                [`fixed_assets[${id}]`]: data?.fixed_assets,
                [`approved_bank_credit[${id}]`]: data?.approved_bank_credit,
            })))
        }
    }

    const removeTab = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                let remove_result = await getData(MAIN_URL, `profile/supplier/financial-indicators/remove/${id}`, 'post', {}, true, true);
                if (remove_result?.status === 200) {
                    swalWithBootstrapButtons.fire(
                        'حذف شد!',
                        'با موفقیت حذف شد',
                        'success'
                    )
                    let arr = inputs?.supplier_financial_indicators;
                    let new_arr = remove_item_of_arr_with_id(arr , id)
                    setInputs(prevState => ({...prevState , supplier_financial_indicators: new_arr}))
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const removeTab2 = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'آیا مطمئن هستید؟',
            text: "میخواهید این مورد را حذف کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'بله حذف کن',
            cancelButtonText: 'نه حذف نشود',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                setNewCollapse(false)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'لغو شد',
                    ' بدون تغییر باقی ماند',
                    'error'
                )
            }
        })
    }

    const saveEditChange = async (id) => {
        let formData = new FormData()
        formData.append('bank_guarantee', inputs?.[`bank_guarantee[${id}]`] === 1 ? 'بله' : 'خیر')
        if (inputs?.[`financial_year[${id}]`] !== undefined) {formData.append('financial_year', inputs?.[`financial_year[${id}]`])}
        if (inputs?.[`annual_gross_income[${id}]`] !== undefined) {formData.append('annual_gross_income', inputs?.[`annual_gross_income[${id}]`].replace(/,/g,''))}
        if (inputs?.[`fixed_assets[${id}]`] !== undefined) {formData.append('fixed_assets', inputs?.[`fixed_assets[${id}]`].replace(/,/g,''))}
        if (inputs?.[`approved_bank_credit[${id}]`] !== undefined) {formData.append('approved_bank_credit', inputs?.[`approved_bank_credit[${id}]`].replace(/,/g,''))}

        let api_result = await getFormDataPost(`profile/supplier/financial-indicators/${id}`, formData)
        if (api_result?.status === 200) {
            toast.success('با موفقیت تغییر یافت')
            setError('')
            let arrays = edit_item_with_id(inputs?.supplier_financial_indicators , api_result?.data?.financial_indicators)
            setInputs(prevState => ({...prevState , supplier_financial_indicators: arrays}))
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    const addNewCollapse = () => {
        setInputs(prevState => ({
            ...prevState,
            bank_guarantee : "بله",
            financial_year : "",
            annual_gross_income : "",
            fixed_assets : "",
            approved_bank_credit : "",
        }));
        setNewCollapse(true)
    }

    // const saveNewCollapse = async () => {
    //     let formData = new FormData()
    //     if (inputs?.[`bank_guarantee`] !== undefined) {formData.append('bank_guarantee', inputs?.[`bank_guarantee`])}
    //     if (inputs?.[`financial_year`] !== undefined) {formData.append('financial_year', inputs?.[`financial_year`])}
    //     if (inputs?.[`annual_gross_income`] !== undefined) {formData.append('annual_gross_income', inputs?.[`annual_gross_income`])}
    //     if (inputs?.[`fixed_assets`] !== undefined) {formData.append('fixed_assets', inputs?.[`fixed_assets`])}
    //     if (inputs?.[`approved_bank_credit`] !== undefined) {formData.append('approved_bank_credit', inputs?.[`approved_bank_credit`])}
    //
    //     let api_result = await getFormDataPost('profile/supplier/financial-indicators', formData)
    //
    //     if (api_result?.status === 200) {
    //         toast.success('با موفقیت افزوده شد')
    //         setError('')
    //         if(inputs?.supplier_financial_indicators?.length > 0 ){
    //             let arr = inputs?.supplier_financial_indicators;
    //             arr.push(api_result?.data?.financial_indicators)
    //             setInputs(prevState => ({
    //                 ...prevState,
    //                 bank_guarantee : "بله",
    //                 financial_year : "",
    //                 annual_gross_income : "",
    //                 fixed_assets : "",
    //                 approved_bank_credit : "",
    //                 supplier_financial_indicators : arr
    //             }))
    //         }else{
    //             setRender(!is_render)
    //         }
    //         setNewCollapse(false)
    //     } else if (api_result?.status === 400) {
    //         setError(api_result?.data)
    //     }
    // }

    const formHandler = async (e) => {
        e.preventDefault()
        let formData = new FormData()

        if (inputs?.[`bank_guarantee`] !== undefined) {formData.append('bank_guarantee', inputs?.[`bank_guarantee`])}
        if (inputs?.[`financial_year`] !== undefined) {formData.append('financial_year', inputs?.[`financial_year`])}
        if (inputs?.[`annual_gross_income`] !== undefined) {formData.append('annual_gross_income', inputs?.[`annual_gross_income`].replace(/,/g,''))}
        if (inputs?.[`fixed_assets`] !== undefined) {formData.append('fixed_assets', inputs?.[`fixed_assets`].replace(/,/g,''))}
        if (inputs?.[`approved_bank_credit`] !== undefined) {formData.append('approved_bank_credit', inputs?.[`approved_bank_credit`].replace(/,/g,''))}

        let api_result = await getFormDataPost('profile/supplier/financial-indicators', formData)

        if (api_result?.status === 200) {
            toast.success('با موفقیت افزوده شد')
            setError('')
            if(inputs?.supplier_financial_indicators?.length > 0 ){
                let arr = inputs?.supplier_financial_indicators;
                arr.push(api_result?.data?.financial_indicators)
                setInputs(prevState => ({
                    ...prevState,
                    bank_guarantee : "بله",
                    financial_year : "",
                    annual_gross_income : "",
                    fixed_assets : "",
                    approved_bank_credit : "",
                    supplier_financial_indicators : arr
                }))
            }else{
                setRender(!is_render)
            }
            setNewCollapse(false)
        } else if (api_result?.status === 400) {
            setError(api_result?.data)
            toast.error('خطا : لطفا فرم را با دقت تکمیل فرمایید')
        }
    }

    return (
        <>
            {
                !inputs?.["isLoad"] &&
                <>
                    <form onSubmit={formHandler} className="row">
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <TabStatusComplete style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                                {
                                    props.status_value === 'pending' || props.status_value === 'denied' ?
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-Iconly-Bulk-Info-Square afa-icon-tick'/> :
                                        <span style={{
                                            color: props.status_colors?.colors?.color,
                                            backgroundColor: props.status_colors?.colors?.background
                                        }} className='icon-tick afa-icon-tick'/>
                                }
                                <p className='mb-0 f-14 '><span>وضعیت : </span><span>{props.status_colors?.title}</span></p>
                            </TabStatusComplete>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-8 mb-4">
                            {
                                more_data.sheet_status?.message &&
                                <TabStatusContent style={{borderRightColor: props.status_colors?.colors?.border_right}}>
                            <span className="icon-Iconly-Bulk-Message afa-icon-message">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                                    <p className='mb-0 f-14'><span>پیام ادمین : </span><span>{more_data.sheet_status?.message}</span>
                                    </p>
                                </TabStatusContent>
                            }
                        </div>
                        {
                            inputs?.supplier_financial_indicators?.length > 0 ?
                                <>
                                    <Accordion defaultActiveKey="0">
                                        {
                                            inputs?.supplier_financial_indicators?.map((item, i) => (
                                                <Card key={i}>
                                                    <Accordion.Toggle as={Card.Header} eventKey={`${item.id}`}
                                                                      onClick={() => changeArrow(item.id)}>
                                                        <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab(item.id)}></span>
                                                             <span className="pl-4 f-14 my-2"> امکان ارائه ضمانت نامه بانکی : {item?.bank_guarantee === 1 ? 'بله' : 'خیر'}</span>
                                                            {
                                                                item?.financial_year && <span
                                                                    className="pl-4 f-14 my-2"> سال مالی : {item?.financial_year}</span>
                                                            }
                                                            {
                                                                item?.annual_gross_income && <span
                                                                    className="pl-4 f-14 my-2"> درآمد ناخالص سالانه : {commaNumber(item?.annual_gross_income)}</span>
                                                            }
                                                        </div>
                                                        <span
                                                            className={(arrow_item.arrow_id === item.id && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey={`${item.id}`}>
                                                        <Card.Body className='row'>
                                                            <div className="d-flex flex-column col-12 col-sm-6 col-lg-4 mb-4">
                                                                <span className=' f-14 mb-2'>امکان ارائه ضمانت نامه بانکی
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <div className="d-flex flex-wrap">
                                                                    <label className="afa-custom-checkbox ml-2">
                                                                        بله
                                                                        <input type="radio" value='بله' onChange={inputHandler}
                                                                               checked={inputs?.[`bank_guarantee[${item.id}]`] == '1'} name={`bank_guarantee[${item.id}]`}/>
                                                                        <span className="checkmark"/>
                                                                    </label>

                                                                    <label className="afa-custom-checkbox ml-2">
                                                                        خیر
                                                                        <input type="radio" value='خیر' onChange={inputHandler}
                                                                               checked={inputs?.[`bank_guarantee[${item.id}]`] == '0'} name={`bank_guarantee[${item.id}]`}/>
                                                                        <span className="checkmark"/>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>سال مالی
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`financial_year[${item.id}]`] && 'afa-error-input'}
                                                                        type="text" max={9999} maxLength={4} name={`financial_year[${item.id}]`}
                                                                        value={inputs?.[`financial_year[${item.id}]`]}
                                                                        onChange={numberInputsHandler} placeholder='سال مالی'/>
                                                                    <p className='afa-error-text'>{error[`financial_year[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>درآمد ناخالص سالانه
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`annual_gross_income[${item.id}]`] && 'afa-error-input'}
                                                                        type="text" name={`annual_gross_income[${item.id}]`}
                                                                        value={commaNumber(inputs?.[`annual_gross_income[${item.id}]`])}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`annual_gross_income[${item.id}]`]: CommaFormat(e.target.value)
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`annual_gross_income[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>دارایی ثابت
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`fixed_assets[${item.id}]`] && 'afa-error-input'}
                                                                        type="text"
                                                                        name={`fixed_assets[${item.id}]`}
                                                                        value={commaNumber(inputs?.[`fixed_assets[${item.id}]`])}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`fixed_assets[${item.id}]`]: CommaFormat(e.target.value)
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`fixed_assets[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <span>اعتبار بانکی تایید شده
                                                                    <span className='dv-required-star'>*</span>
                                                                    </span>
                                                                    <input
                                                                        className={error[`approved_bank_credit[${item.id}]`] && 'afa-error-input'}
                                                                        type="text" name={`approved_bank_credit[${item.id}]`}
                                                                        value={commaNumber(inputs?.[`approved_bank_credit[${item.id}]`])}
                                                                        onChange={(e) =>
                                                                            setInputs(prevState => ({
                                                                                ...prevState,
                                                                                [`approved_bank_credit[${item.id}]`]: CommaFormat(e.target.value)
                                                                            }))
                                                                        }/>
                                                                    <p className='afa-error-text'>{error[`approved_bank_credit[${item.id}]`]}</p>
                                                                </Inputs>
                                                            </div>

                                                            <div className="col-12 d-flex justify-content-center">
                                                                <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                    <Inputs>
                                                                        <button type='button'
                                                                                onClick={() => saveEditChange(item.id)}
                                                                                className='afa-add-more-btn'>
                                                                            <span className='pr-3'>ذخیره تغییرات</span>
                                                                        </button>
                                                                    </Inputs>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            ))
                                        }

                                        {
                                            new_collapse &&
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey={'0'}
                                                                  onClick={() => changeArrow(0)}>
                                                    <div className="d-flex flex-wrap">
                                                        <span className="icon-Delete dv-icon-delete pl-2 my-2"
                                                              onClick={() => removeTab2('rasoul')}></span>
                                                         <span className="pl-4 f-14 my-2"> امکان ارائه ضمانت نامه بانکی : {inputs?.bank_guarantee}</span>
                                                        {
                                                            inputs?.financial_year && <span
                                                                className="pl-4 f-14 my-2"> سال مالی : {inputs?.financial_year}</span>
                                                        }
                                                        {
                                                            inputs?.annual_gross_income && <span
                                                                className="pl-4 f-14 my-2"> درآمد ناخالص سالانه : {inputs?.annual_gross_income}</span>
                                                        }
                                                    </div>
                                                    <span
                                                        className={(arrow_item.arrow_id === 0 && arrow_item.arrow_status) ? "icon-cheveron-up dv-collapse-arrow" : "icon-cheveron-down dv-collapse-arrow"}/>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={'0'}>
                                                    <Card.Body className='row'>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex flex-column">
                                                            <span className=' f-14 mb-2'>امکان ارائه ضمانت نامه بانکی
                                                            <span className='dv-required-star'>*</span>
                                                            </span>
                                                            <div className="d-flex flex-wrap">
                                                                <label className="afa-custom-checkbox ml-2">
                                                                    بله
                                                                    <input type="radio" value='بله' onChange={inputHandler}
                                                                           checked={inputs?.bank_guarantee == 'بله'} name="bank_guarantee"/>
                                                                    <span className="checkmark"/>
                                                                </label>

                                                                <label className="afa-custom-checkbox ml-2">
                                                                    خیر
                                                                    <input type="radio" value='خیر' onChange={inputHandler}
                                                                           checked={inputs.bank_guarantee == 'خیر'} name="bank_guarantee"/>
                                                                    <span className="checkmark"/>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>سال مالی
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`financial_year`] && 'afa-error-input'}
                                                                    type="text" max={9999} maxLength={4} name={`financial_year`}
                                                                    value={inputs?.[`financial_year`]}
                                                                    onChange={numberInputsHandler} placeholder='سال مالی'/>
                                                                <p className='afa-error-text'>{error[`financial_year`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>درآمد ناخالص سالانه
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`annual_gross_income`] && 'afa-error-input'}
                                                                    type="text" name={`annual_gross_income`}
                                                                    value={commaNumber(inputs?.[`annual_gross_income`])}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`annual_gross_income`]: CommaFormat(e.target.value)
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`annual_gross_income`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>دارایی ثابت
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`fixed_assets`] && 'afa-error-input'}
                                                                    type="text"
                                                                    name={`fixed_assets`}
                                                                    value={commaNumber(inputs?.[`fixed_assets`])}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`fixed_assets`]: CommaFormat(e.target.value)
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`fixed_assets`]}</p>
                                                            </Inputs>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                            <Inputs>
                                                                <span>اعتبار بانکی تایید شده
                                                                <span className='dv-required-star'>*</span>
                                                                </span>
                                                                <input
                                                                    className={error[`approved_bank_credit`] && 'afa-error-input'}
                                                                    type="text" name={`approved_bank_credit`}
                                                                    value={commaNumber(inputs?.[`approved_bank_credit`])}
                                                                    onChange={(e) =>
                                                                        setInputs(prevState => ({
                                                                            ...prevState,
                                                                            [`approved_bank_credit`]: CommaFormat(e.target.value)
                                                                        }))
                                                                    }/>
                                                                <p className='afa-error-text'>{error[`approved_bank_credit`]}</p>
                                                            </Inputs>
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-center">
                                                            <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                                                <Inputs>
                                                                    <button type='type'
                                                                            // onClick={saveNewCollapse}
                                                                            className='afa-add-more-btn'>
                                                                        <span className='pr-3'>ذخیره تغییرات</span>
                                                                    </button>
                                                                </Inputs>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        }

                                    </Accordion>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-8 mb-4"/>

                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>

                                            <Inputs>

                                                <button type='button' onClick={addNewCollapse}
                                                        className='afa-add-more-btn'>

                                                    <span className='icon-add f-20'/>

                                                    <span className='pr-3'>افزودن اطلاعات</span>

                                                </button>

                                            </Inputs>

                                        </div>

                                        {/*<div className="col-12 col-sm-6 col-lg-4 mb-4"/>*/}

                                        {/*<div className="col-12 col-sm-6 col-lg-4 offset-lg-4 mb-4">*/}

                                        {/*    <Inputs>*/}

                                        {/*        <button type='submit'>*/}

                                        {/*            <span>ثبت نهایی</span>*/}

                                        {/*            <LeftIcon><span className='icon-cheveron-left'></span></LeftIcon>*/}

                                        {/*        </button>*/}

                                        {/*    </Inputs>*/}

                                        {/*</div>*/}
                                    </div>
                                </> :
                                <>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex flex-column">
                                        <span className=' f-14 mb-2'>امکان ارائه ضمانت نامه بانکی
                                        <span className='dv-required-star'>*</span>
                                        </span>
                                        <div className="d-flex flex-wrap">
                                            <label className="afa-custom-checkbox ml-2">
                                                بله
                                                <input type="radio" value='بله' onChange={inputHandler}
                                                       checked={inputs?.bank_guarantee == 'بله'} name="bank_guarantee"/>
                                                <span className="checkmark"/>
                                            </label>

                                            <label className="afa-custom-checkbox ml-2">
                                                خیر
                                                <input type="radio" value='خیر' onChange={inputHandler}
                                                       checked={inputs.bank_guarantee == 'خیر'} name="bank_guarantee"/>
                                                <span className="checkmark"/>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>سال مالی
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`financial_year`] && 'afa-error-input'}
                                                type="text" maxLength={4} max={9999} name={`financial_year`}
                                                value={inputs?.[`financial_year`]}
                                                onChange={numberInputsHandler} placeholder='سال مالی'/>
                                            <p className='afa-error-text'>{error[`financial_year`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>درآمد ناخالص سالانه
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`annual_gross_income`] && 'afa-error-input'}
                                                type="text" name={`annual_gross_income`}
                                                value={commaNumber(inputs?.[`annual_gross_income`])}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`annual_gross_income`]: CommaFormat(e.target.value)
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`annual_gross_income`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>دارایی ثابت
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`fixed_assets`] && 'afa-error-input'}
                                                type="text"
                                                name={`fixed_assets`}
                                                value={commaNumber(inputs?.[`fixed_assets`])}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`fixed_assets`]: CommaFormat(e.target.value)
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`fixed_assets`]}</p>
                                        </Inputs>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                                        <Inputs>
                                            <span>اعتبار بانکی تایید شده
                                            <span className='dv-required-star'>*</span>
                                            </span>
                                            <input
                                                className={error[`approved_bank_credit`] && 'afa-error-input'}
                                                type="text" name={`approved_bank_credit`}
                                                value={commaNumber(inputs?.[`approved_bank_credit`])}
                                                onChange={(e) =>
                                                    setInputs(prevState => ({
                                                        ...prevState,
                                                        [`approved_bank_credit`]: CommaFormat(e.target.value)
                                                    }))
                                                }/>
                                            <p className='afa-error-text'>{error[`approved_bank_credit`]}</p>
                                        </Inputs>
                                    </div>

                                    <div className="col-12 d-flex justify-content-center">
                                        <div className={"col-12 col-sm-6 col-lg-4 mb-4 d-flex align-items-center"}>
                                            <Inputs>
                                                <button type='submit' className='afa-add-more-btn'>
                                                    <span className='icon-add f-20'/>
                                                    <span className='pr-3'>افزودن اطلاعات</span>
                                                </button>
                                            </Inputs>
                                        </div>
                                    </div>
                                </>
                        }
                    </form>
                </>
            }
        </>
    )
}

export default FinancialIndex;